<template>
  <core-text v-if="value">
    {{ displayDate }}
  </core-text>
</template>

<script>
  import moment from "moment"
  export default {
    props: {
      value: { type: [String, Number, Array], default: "n/a" }
    },
    computed: {
      displayDate() {
        const newDate = new Date(this.value)
        return moment(newDate).format('DD/MM/YYYY');
      }
    }
  }
</script>
import routes, { apiActions } from "./routes"

const attributes = {
    id: 1,
    archived: 1,
    ctime: null,
    status: null,
    tracking_code: null,
    shipping_type: null,
    payment_type: null,
    voucher_id: null,
    cost: null,
    paid: null,
    notes: null,
    vin: null,
    invoiceLocation: null
}

const definition = { attributes, routes }

export { apiActions }
export default definition
<template>
  <div class="checkout-wizard">
    <el-steps
        :active="activeStep"
        finish-status="success"
        align-center>
      <el-step title="Check Cart" />
      <el-step title="Select Payment" />
      <el-step title="Finalise Checkout" />
    </el-steps>

    <div class="wizard-content">
      <template v-if="activeStep === 0">
        <CheckCart />
      </template>
      <template v-else-if="activeStep === 1">
        <SelectPayment />
      </template>
      <template v-else-if="activeStep === 2">
        <Finalise @closeModal="closeModal()" />
      </template>
    </div>
  </div>
</template>

<script>
import CheckCart from "./steps/CheckCart"
import SelectPayment from "./steps/SelectPayment"
import Finalise from "./steps/Finalise"
export default {
  name: "CheckoutWizard",
  components: {
    CheckCart,
    SelectPayment,
    Finalise
  },
  computed: {
    activeStep() {
      return this.$store.state.PointOfSale.checkoutStep
    }
  },
  methods: {
    closeModal() {
      this.$emit("closeModal")
    }
  }
}
</script>

<style lang="scss" scoped>
.checkout-wizard {
  padding: 2em;

  .wizard-content {
    padding: 1em;
    margin: 1em 0 0 0;
  }
}
</style>
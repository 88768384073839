import routes, { apiActions } from "./routes"

const attributes = {
  id: null,
  version: null
}

const definition = { attributes, routes }

export { apiActions }
export default definition

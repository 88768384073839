

<template>
  <div class="core-icon flex-center">
    <i
        v-if="isElementUi"
        :class="icon" />
      
    <FontAwesomeIcon
        v-else
        :icon="getIcon()" />
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faCoffee,
  faTimes,
  faWheelchair,
  faUserLock,
  faBan,
  faRoute,
  faUser,
  faPlus,
  faSlidersH
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

library.add(faCoffee, faWheelchair, faUserLock, faTimes, faBan, faRoute, faUser, faPlus, faSlidersH)

export default {
  name: "CoreIcon",
  components: { FontAwesomeIcon },
  props: {
    icon: { type: String, default: null }
  },
  data() {
    return {
      key: "value"
    }
  },
  computed: {
    isElementUi() {
      return this.icon.includes("el-")
    }
  },
  methods: {
    getIcon() {
      const { icon } = this

      const iconMap = {
        close: "times",
        create: "plus",
        nonAccessible: "ban",
        serviceObjective: "sliders-h"
      }

      return iconMap[icon] || icon
    }
  }
}
</script>


<style lang="scss" scoped>
</style>

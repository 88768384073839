<template>
  <div class="core-data-importer">
    <template v-if="tableRows.length <= 0">
      <input
          id="file"
          type="file"
          name="csvUpload"
          class="input-file"
          accept=".csv"
          @change="filesChange($event)">
      <label for="file">
        Choose a file
      </label>
      <core-text
          hint
          class="hint">
        .csv files with a size less than 500kb
      </core-text>
    </template>
    
    <CoreDataImporterSuccessMessage
        v-if="successfullyImportedRows > 0"
        :entity-name="entityName"
        :successfully-imported-rows="successfullyImportedRows" />
    
    <CoreDataImporterMappingTable
        v-if="successfullyImportedRows <= 0"
        :is-loading="isLoading"
        :total-imported-files="totalImportedFiles"
        :table-headers="tableHeaders"
        :table-rows="tableRows"
        :map-values="mapValues"
        :mapping-to-fields="mappingToFields"
        :entity-name="entityName"
        :default-field-selectors="defaultFieldSelectors"
        :default-field-values="defaultFieldValues"
        :total="total" />

    <div class="form-actions-wrapper">
      <core-button
          v-if="showCancelButton"
          type="cancel"
          @click="cancelImport">
        Cancel Import
      </core-button>
    
      <div
          v-if="showImportButton"
          class="import-data-button-wrapper">
        <core-button
            type="success"
            @click="submitImport">
          Import Data ({{ tableRows.length }} {{ entityName }})
        </core-button>
      </div>
    </div>
  </div>
</template>

<script>
  import CoreDataImporterSuccessMessage from "@/Modules/Core/components/CoreDataImporter/CoreDataImporterSuccessMessage"
  import CoreDataImporterMappingTable from "@/Modules/Core/components/CoreDataImporter/CoreDataImporterMappingTable"
  import camelCase from "lodash/camelCase"

  export default {
    name: "CoreDataImporter",
    components: {
      CoreDataImporterSuccessMessage,
      CoreDataImporterMappingTable
    },
    props: {
      entityName: {
        type: String,
        required: true
      },
      mappingToFields: {
        type: Array,
        required: true
      },
      successfullyImportedRows: {
        type: Number,
        required: true
      },
      defaultFieldSelectors: {
        type: Object,
        required: false
      }
    },
    data() {
      return {
        tableHeaders: [],
        tableRows: [],
        uploadData: false,
        totalImportedFiles: null,
        mapValues: {},
        messages: [],
        isLoading: false,
        fileList: [],
        uploadResponse: {},
        total: null,
        defaultFieldValues: {}
      }
    },
    computed: {
      showCancelButton() {
        return this.tableRows.length > 0 && this.successfullyImportedRows <= 0
      },
      showImportButton() {
        const foundNotIgnoreValue = Object.keys(this.mapValues).find(key => this.mapValues[key] != "_ignore_")
        return this.tableRows.length > 0
            && foundNotIgnoreValue
            && this.successfullyImportedRows === 0
      },
    },
    methods: {
      readUploadedFileAsText(inputFile) {
        const temporaryFileReader = new FileReader()

        return new Promise((resolve, reject) => {
          temporaryFileReader.onerror = () => {
            temporaryFileReader.abort();
            reject(new DOMException("Problem parsing input file."));
          }

          temporaryFileReader.onload = () => {
            resolve(temporaryFileReader.result);
          }
          temporaryFileReader.readAsText(inputFile);
        })
      },
      splitComponentsByComma(str){
        if(str.indexOf("\"")>-1){
          const inputSplit = str.split(",")
          const output = []
          let match = 0

          for(let i=0;i<inputSplit.length;i++){

            if(inputSplit[i].indexOf("\"") >- 1){
              let stringWithCommas = inputSplit[i]
              
              for(let z=i;z<inputSplit.length;z++){
                if(z !== i && inputSplit[z].indexOf("\"") === -1){
                  stringWithCommas+= ","+inputSplit[z]
                  
                }else if(z !== i && inputSplit[z].indexOf("\"") > -1){
                  stringWithCommas+= ","+inputSplit[z]
                  stringWithCommas.replace(new RegExp("\"", 'g'), "")
                  output.push(stringWithCommas)

                  i=z
                  z=inputSplit.length + 1
                  match++
                }
                if(z === inputSplit.length - 1){
                  if(match === 0){
                    output.push(inputSplit[z])
                  }
                  match = 0
                }
              }
            }else{
              output.push(inputSplit[i])
            }
          }
          return output
        }else{
          return str.split(",")
        }
      },
      convertCSVToJSON(str, delimiter = ',') {
        const headers = str.slice(0, str.indexOf('\n')).split(delimiter)
        const titles = headers.map(header => camelCase(header))

        const rows = str.slice(str.indexOf('\n') + 1).split('\n')


        const mergedRows = rows.map((row) => {
          if (row) {
            // const values = row.split(delimiter)
            const values = this.splitComponentsByComma(row)

            return titles.reduce((object, curr, i) => (object[curr] = values[i], object), {})
          }
        })

        return {
          headers: titles,
          rows: mergedRows
        }
      },
      cancelImport() {
        this.total = 0
        this.tableRows = []
        this.totalImportedFiles = null
      },
      async filesChange(event) {
        this.totalImportedFiles = null
        this.$emit("resetSuccessfullyImportedRows")

        const file = event.target.files[0];

        try {
          const fileContents = await this.readUploadedFileAsText(file)
          this.handleImport(this.convertCSVToJSON(fileContents))

        } catch (e) {

          const notificationsPayload = {
            component: "Toast",
            type: "error",
            title: "Error importing CSV",
            message: e.message,
            visible: true,
            decay: 3500
          }
          store.dispatch("core/notifications/addToNotifications", notificationsPayload)

        }
      },
      mutateData(dataToMutate) {

        const mutatedData = {}

        dataToMutate.forEach((entries, index) => {

          mutatedData[index] = {}
          const newEntries = {}

          Object.keys(entries).forEach(key => {

            if (this.mapValues[key] !== "_ignore_") {
              const newKey = this.mapValues[key]
              this.$set(newEntries, newKey, entries[key])
            }
          })
          mutatedData[index] = newEntries

        })

        return mutatedData
      },
      handleImport(importData) {
        const data = importData.rows
        this.total = data.length

        this.tableHeaders = importData.headers
        this.tableRows = data

        this.tableHeaders.forEach(key => {
          this.$set(this.mapValues, key, "_ignore_")
        })
      },
      async submitImport() {
        this.isLoading = true
        this.totalImportedFiles = null

        const uploadData = this.mutateData(this.tableRows)
        const metaData = this.defaultFieldValues

        const submitData = {
          data: uploadData,
          meta: metaData
        }

        this.$emit("submitImport", submitData)
        this.isLoading = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .hint {
    color: rgba(0, 0, 0, 0.7) !important;
    margin: 1em 0;
    padding: 0.5em 1em;

    &.yellow {
      background: var(--main-light-yellow);
      border-radius: var(--border-radius-xs)

    }
  }

  .form-actions-wrapper {
    margin: 1em 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .input-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    + label {
      color: white;
      background-color: var(--main-primary);
      border-radius: var(--border-radius-xxs);
      padding: 0.25em 0.5em;
      box-shadow: var(--box-shadow-container);
      display: inline-block;
      transition: var(--main-transition);
    }

    &:focus {
      + label {
        cursor: pointer;
        background-color: var(--main-secondary);
        outline: 1px dotted rgba(0,0,0,0.2);
        outline: -webkit-focus-ring-color auto 5px;
      }
    }
  }
</style>
<template>
  <router-link :to="link">
    <core-text
        semibold
        clickable
        class="breadcrumb-link">
      {{ visibleLabel }}
    </core-text>
  </router-link>
</template>

<script>
import isFunction from "lodash/isFunction"
export default {
  name: "BreadCrumbItems",
  props: {
    url: {
      type: [Function, String],
      required: true
    },
    label: {
      type: [String, Function],
      required: true
    }
  },
  computed: {
    link() {
      return isFunction(this.url) ? this.url(this.$route.params) : this.url
    },
    visibleLabel() {
      if (!this.label) return this.link()

      if (isFunction(this.label)) {
        return this.label({
          params: this.$route.params,
          url: this.url,
          state: this.$store.state
        })
      }

      return this.label
    }
  }
}
</script>

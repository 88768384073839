import config from "./config"
import Login from "./views/Login"
import ForgotPassword from "./views/ForgotPassword"
import ResetPassword from "./views/ResetPassword"

const routes = [
  {
    path: `${config.path}/Login`,
    name: "Login",
    component: Login
  },
  {
    path: `${config.path}/Traveller/ForgotPassword`,
    name: "ForgotPassword",
    component: ForgotPassword
  },
  {
    path: `${config.path}/Traveller/ResetPassword/:token?`,
    name: "ResetPassword",
    component: ResetPassword
  },
  {
    path: `${config.path}/Admin/ForgotPassword`,
    name: "ForgotPassword",
    component: ForgotPassword
  },
  {
    path: `${config.path}/Admin/ResetPassword/:token?`,
    name: "ResetPassword",
    component: ResetPassword
  }
]

export default routes

<template>
  <div
      id="point-selector"
      class="fit-parent">
    <div class="searchbox flex">
      <div class="waypoints">
        <ul>
          <li><span class="el-icon-location-outline" /></li>
          <li
              v-for="i in liElementCount"
              :key="`li-${i}`" />
          <li><span class="el-icon-location" /></li>
        </ul>
      </div>
      <div class="address-input">
        <template v-for="inputIndex in initialInputFields">
          <el-form-item
              :key="`input-${inputIndex}`"
              :label="`${getInputLabel(inputIndex)} address`">
            <el-select
                v-model="selectedInputValue[inputIndex]"
                :remote-method="fetchLocations"
                :loading="loading"
                filterable
                :placeholder="`Choose ${getInputLabel(inputIndex)} address`"
                remote
                reserve-keyword
                :size="size"
                @focus="currentInputIndex = inputIndex">
              <el-option
                  v-for="item in inputOptions[inputIndex]"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value" />
            </el-select>
          </el-form-item>
        </template>
      </div>
      <div
          v-if="initialInputFields === 2 && hasAddressFieldsCompleted"
          class="address-swap">
        <span
            class="el-icon-sort"
            aria-label="Swap Locations"
            title="Swap Locations"
            @click="swapLocations" />
      </div>
    </div>

    <GmapMap
        ref="mapRef"
        :options="mapOptions"
        :center="{ lat: 10, lng: 10 }"
        :zoom="zoom"
        style="width: 100%; height: 350px">
      <template v-for="(marker, markerIndex) in markerPositions">
        <CoreCustomDraggableMarker
            v-if="hasCoordinates(markerIndex)"
            :key="`marker-${markerIndex}`"
            :draggable="true"
            :position="marker.latLng"
            :marker-id="markerIndex"
            :on-drag-end="onDragEnd">
          <CoreMapIcon :label="getInputLabel(markerIndex)" />
        </CoreCustomDraggableMarker>
      </template>
    </GmapMap>
    <CoreMapZoomSlider
        v-model="zoom"
        :zoom-boundaries="zoomBoundaries" />
  </div>
</template>
<script>
import { gmapApi } from "vue2-google-maps"
import get from "lodash/get"
import isArray from "lodash/isArray"

import CoreCustomDraggableMarker from "@/Modules/Core/components/Maps/CoreCustomDraggableMarker"
import CoreMapIcon from "@/Modules/Core/components/Maps/CoreMapIcon"
import CoreMapZoomSlider from "@/Modules/Core/components/Maps/CoreMapZoomSlider"

// TODO: move all google maps utils into CORE
const getLatLngFromGoogle = ({ lat, lng }) => ({
  lat: lat(),
  lng: lng()
})
export default {
  name: "CoreFormBuilderAddressLookup",
  components: {
    CoreCustomDraggableMarker,
    CoreMapIcon,
    CoreMapZoomSlider
  },
  props: {
    formMeta: { type: Object, default: () => ({}) },
    value: { type: [Array, String, Object], default: () => [] },
    initialSearchValue: { type: [String, Array], default: null },
    initialInputFields: { type: Number, default: 2 },
    label: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    size: { type: String, default: "medium" },
    arrayPoint: { type: Boolean, default: false }
  },
  data() {
    return {
      currentInputIndex: 1,
      selectedInputValue: {},
      inputOptions: {},
      markerPositions: {},
      geocoder: null,
      loading: false,
      mounted: false,
      placeholder: "Finding Location...",
      markerLabels: ["Pick-up", "Drop-off", "Trip-Stop"],
      zoom: 12,
      zoomBoundaries: {
        min: 1,
        max: 20
      },
      mapOptions: {
        fullscreenControl: false,
        mapTypeControl: false,
        zoomControl: false,
        gestureHandling: "cooperative",
        streetViewControl: false,
        clickableIcons: false,
        styles: [
          {
            featureType: "poi",
            stylers: [{ visibility: "off" }]
          }
        ]
      }
    }
  },
  computed: {
    google: gmapApi,
    liElementCount() {
      return this.initialInputFields * 3 - 3
    },
    hasAddressFieldsCompleted() {
      return this.selectedInputValue[1].length && this.selectedInputValue[2].length
    }
  },
  watch: {
    selectedInputValue: {
      deep: true,
      async handler() {
        let {
          selectedInputValue,
          currentInputIndex,
          inputOptions,
          markerPositions,
          locationHasBeenSelected
        } = this

        if (locationHasBeenSelected) {
          const optionId = Object.keys(inputOptions[currentInputIndex]).find(
            key =>
              inputOptions[currentInputIndex][key].value == selectedInputValue[currentInputIndex]
          )
          const location = inputOptions[currentInputIndex][optionId]

          if (location && location.point.length) {
            const latLng = this.getPointLatLng(location.point)

            markerPositions[currentInputIndex].latLng = latLng
            markerPositions[currentInputIndex].name = location.label

            this.setMapCenter(latLng)
            this.$emit("input", markerPositions)
          }
        }
      }
    },
    google: {
      immediate: true,
      async handler() {
        this.$nextTick(async () => {
          if (this.$refs.mapRef && this.google) {
            const {
              selectedInputValue,
              currentInputIndex,
              inputOptions,
              markerPositions,
              placeholder
            } = this

            if (!this.geocoder) {
              this.geocoder = new this.google.maps.Geocoder()
            }
            selectedInputValue[currentInputIndex] = placeholder

            const hasInitialValue = isArray(this.value) ? this.value.length : this.value

            if (hasInitialValue) {
              const nextCenter = this.getPointLatLng(this.value)
              this.setMapCenter(nextCenter)
            } else if (this.initialSearchValue) {
              // If multiple address-input fields are filled out
              if (isArray(this.initialSearchValue)) {
                let index = 1
                for (const item of this.initialSearchValue) {
                  await this.fetchLocations([item.point[1], item.point[0]], index)

                  // Pre-fill input fields
                  selectedInputValue[index] = inputOptions[index][0].value

                  // Set markers
                  markerPositions[index] = {
                    latLng: { lat: item.point[1], lng: item.point[0] },
                    name: item.name
                  }
                  index++
                }
              } else {
                await this.fetchLocations(this.initialSearchValue)
                selectedInputValue[currentInputIndex] = inputOptions[currentInputIndex][0].value
              }
            }

            const latLng = this.getPointLatLng(inputOptions[currentInputIndex][0].point)
            const nextCenter = latLng
            this.setMapCenter(nextCenter)
          }
        })
      }
    }
  },
  created() {
    let { initialInputFields, selectedInputValue, inputOptions, markerPositions } = this

    // Prime generated objects with relevant data and reactivity
    for (let index = 1; index <= initialInputFields; index++) {
      this.$set(selectedInputValue, index, {})
      this.$set(markerPositions, index, { latLng: {} })
      this.$set(inputOptions, index, {})
    }
  },
  methods: {
    locationHasBeenSelected() {
      let { selectedInputValue, currentInputIndex, inputOptions, placeholder } = this

      return (
        selectedInputValue[currentInputIndex] !== {} &&
        inputOptions[currentInputIndex] !== {} &&
        selectedInputValue[currentInputIndex] !== placeholder
      )
    },
    swapLocations() {
      // (WIP) Needs refactoring so that it could work with more than just 2 addresses
      let { markerPositions, selectedInputValue, inputOptions } = this
      const _tempMarker1 = markerPositions[1]
      const _tempMarker2 = markerPositions[2]
      const _tempSelectedInputValue1 = selectedInputValue[1]
      const _tempSelectedInputValue2 = selectedInputValue[2]
      const _tempInputOptions1 = inputOptions[1]
      const _tempInputOptions2 = inputOptions[2]

      markerPositions[1] = _tempMarker2
      markerPositions[2] = _tempMarker1

      selectedInputValue[1] = _tempSelectedInputValue2
      selectedInputValue[2] = _tempSelectedInputValue1

      inputOptions[1] = _tempInputOptions2
      inputOptions[2] = _tempInputOptions1
    },
    hasCoordinates(markerIndex) {
      return Object.keys(this.markerPositions[markerIndex].latLng).length !== 0
    },
    getInputLabel(markerIndex) {
      if (markerIndex < 2) {
        return this.markerLabels[0]
      } else if (markerIndex < this.initialInputFields) {
        return this.markerLabels[2]
      }
      return this.markerLabels[1]
    },
    async onDragEnd({ latLng, markerIndex }) {
      const { lng, lat } = getLatLngFromGoogle(latLng)

      this.currentInputIndex = markerIndex
      this.updateInputsAndMarkers({ lng, lat })
    },
    async updateInputsAndMarkers(point = {}) {
      this.loading = true

      let { currentInputIndex, inputOptions, selectedInputValue, markerPositions } = this
      const locationData = await this.getAddressFromLatLng(point)

      inputOptions[currentInputIndex][0] = {
        label: locationData.input_address,
        value: locationData.place_id,
        point: point
      }

      markerPositions[currentInputIndex] = {
        latLng: {
          lat: point.lat,
          lng: point.lng
        },
        name: locationData.input_address
      }

      selectedInputValue[currentInputIndex] = locationData.place_id

      this.loading = false
    },
    async getAddressFromLatLng(coordinates) {
      return new Promise(async resolve => {
        this.geocoder.geocode({ location: coordinates }, function(results, status) {
          if (status === "OK") {
            const address = get(results, "[0]")
            const streetNumber = get(address, "address_components[0].short_name")
            const streetName = get(address, "address_components[1].short_name")
            const city = get(address, "address_components[2].short_name")
            const state = get(address, "address_components[4].short_name")
            const streetAddress = `${streetNumber} ${streetName}`
            const inputAddress = `${streetAddress}, ${city}, ${state}`
            const placeId = get(address, "place_id")

            const newLocationData = {
              name: streetAddress,
              state,
              city,
              street_address: streetAddress,
              input_address: inputAddress,
              place_id: placeId,
              ...coordinates
            }

            resolve(newLocationData)
          }
        })
      })
    },
    mutateOption(point) {
      if (this.arrayPoint) {
        const { lng, lat } = point
        return [lng, lat]
      }
      return point
    },
    getPointLatLng(point) {
      if (this.arrayPoint) {
        const [lng, lat] = point
        return { lng, lat }
      }
      return point
    },
    setMapCenter(nextCenter) {
      return this.$refs.mapRef.$mapPromise.then(async map => {
        map.panTo(nextCenter)
        map.setZoom(12)
      })
    },
    fetchLocations(query, index = null) {
      let { mutateOption, inputOptions, currentInputIndex } = this

      if (index !== null) {
        currentInputIndex = index
      }

      if (this.google && this.$refs.mapRef) {
        return new Promise(resolve => {
          this.$refs.mapRef.$mapPromise.then(async map => {
            loading = true

            const service = new this.google.maps.places.PlacesService(map)
            const request = { query }

            service.textSearch(request, function(place) {
              inputOptions[currentInputIndex] = place.map(
                ({ formatted_address, place_id, geometry }) => {
                  const { lat, lng } = getLatLngFromGoogle(geometry.location)
                  return {
                    label: formatted_address,
                    value: place_id,
                    point: mutateOption({ lng, lat })
                  }
                }
              )

              resolve(inputOptions[currentInputIndex])
              loading = false
            })
          })
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.searchbox {
  background: var(--main-green);
  color: white;
  padding: 1em 2em 1em 1em;
  /deep/ .el-select {
    width: 100%;
    margin: 0.5em 0;
  }
  /deep/ .el-form-item__label {
    color: white !important;
  }

  /deep/ .el-input__inner {
    border-color: transparent !important;
  }
  .searchbox-label {
    font-weight: 700;
  }
  .waypoints {
    flex: 1;
    max-width: 3em;
    ul {
      list-style: disc;
      padding-top: 1.25em;
      padding-left: 0;
      font-size: 1.3em;
      li {
        margin-left: 2em;
        height: 1.25em;
        text-align: center;
        &:first-child,
        &:last-child {
          margin-left: 0em;
          list-style: none;
        }
      }
    }
  }
  .address-input {
    flex: 6;
  }
  .address-swap {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
    max-width: 3em;
    width: 3em;
    padding-top: 1em;
    cursor: pointer;
    transition: var(--main-transition);

    &:hover {
      color: var(--main-primary);
    }
  }
}
</style>

<template>
  <div class="credit-card-payment">
    <template v-if="settings.shopCreditCardSurCharge">
      <div class="credit-card-surcharge">
        <strong>Important</strong>
        <p>We request a surcharge of <strong>{{ settings.shopCreditCardSurCharge }}%</strong> on Credit Card payments.</p>
      </div>
    </template>
    <div class="price-total">
      <div class="title">
        Total
      </div>
      <div class="price">
        AUD {{ priceTotal }}
      </div>
    </div>
    <el-form ref="form">
      <el-form-item label="Credit Card Transaction ID">
        <el-input v-model="transactionId" />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {Settings} from "@/Modules/OdysseyModels"
export default {
  name: "CreditCardPayment",
  data() {
    return {
      transactionId: null
    }
  },
  computed: {
    settings() {
      const settings = Settings.all()
      return settings[0]
    },
    products() {
      return this.$store.state.PointOfSale.selectedProducts
    },
    priceTotal() {
      if (this.products.length > 0) {
        let total = 0
        this.products.forEach(product => {
          total = total + (parseFloat(product.price) * product.quantity)
        })
        if (this.settings.shopCreditCardSurCharge) {
          total = (total / 100) * (100 + parseFloat(this.settings.shopCreditCardSurCharge))
        }
        return total.toFixed(2)
      }
      return null
    }
  },
  mounted() {
    this.getSettings()
  },
  methods: {
    async getSettings() {
      this.isLoading = true
      await Settings.api().fetchById()
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.credit-card-payment {
  text-align: center;
  margin: 3em auto;
  max-width: 30em;

  .credit-card-surcharge {
    padding: 1em;
    margin-bottom: 2em;
    background: var(--main-light-yellow);
  }

  .price-total {
    background: white;
    display: flex;
    justify-content: space-between;
    font-size: 1.2em;
    padding: 1em;
    font-weight: bold;
    margin: 1em -1em;
    box-shadow: var(--box-shadow-container-light);
    color: var(--main-success-green);

    .title {
      flex: 1;
    }

    .price {
      flex: 1;
      text-align: right;
    }
  }
}
</style>
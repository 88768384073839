import SystemSettingsMainView from "./views/SystemSettingsMainView"

const routes = [
  {
    path: "",
    name: "systemSettings-main-view",
    component: SystemSettingsMainView
  }
]

export default routes
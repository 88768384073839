<template>
  <CoreDialog
      :title="title"
      :loading="isLoading"
      :dialog-props="dialogProps">
    <template slot="content">
      <OrderManagementForm
          :dialog-props="dialogProps"
          :order-data="orderData" />
    </template>
  </CoreDialog>
</template>

<script>
  import OrderManagementForm from "../../forms/OrderManagementForm/OrderManagementForm"
  import { Order } from "@/Modules/OdysseyModels"
  import startCase from "lodash/startCase"

  export default {
    name: "OrderManagementDialog",
    components: {
      OrderManagementForm
    },
    props: {
      id: { type: [String, Number], default: null },
      closeDialog: { type: Function, required: true },
      dialogProps: { type: Object, required: true },
    },
    data() {
      return {
        isLoading: true
      }
    },
    computed: {
      orderData() {
        return Order.find(this.id)
      },
      mode() {
        return this.dialogProps.mode
      },
      title() {
        if (this.orderData) {
          return `${startCase(this.mode)}ing Order ${this.orderData.fullName} (ID: ${this.id})`
        }

        return "Create New Order"
      }
    },
    mounted() {
      if (this.id) {
        this.fetchOrder()
      } else {
        this.isLoading = false
      }
    },
    methods: {
      async fetchOrder() {
        this.isLoading = true

        await Order.api().fetchById(this.id)

        this.isLoading = false
      }
    }
  }
</script>
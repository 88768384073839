import routes, { apiActions } from "./routes"

const attributes = {
    id: null,
    staffId: null,
    customerId: null,
    productIds: null,
    date: null,
    startTime: null,
    endTime: null,
    notes: null,
    type: null,
    notificationSent: null,
    updated: null,
    userFirstName: null,
    userLastName: null,
    userColor: null
}

const definition = { attributes, routes }

export { apiActions }
export default definition
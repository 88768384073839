<template>
  <div class="fit-parent">
    <core-loader />
  </div>
</template>

<script>
export default {
  name: "LoaderWrapper"
}
</script>

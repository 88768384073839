import ExtendedModel from "@/Modules/Core/utils/extended-vue-orm-model"
import definition, { apiActions } from "./definition"
import Actions from "./actions"

class User extends ExtendedModel {
  static entity = "User"
  static globalSearchEnabled = true
  static definition = definition

  static apiConfig = {
    dataKey: "data.data",
    actions: apiActions
  }

  get Actions() {
    return new Actions(this)
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`
  }

  get valueList() {
    const {id} = this
    return {label: this.fullName, value: id, id}
  }

  get globalSearchLabel() {
    return this.fullName
  }

}
export default User

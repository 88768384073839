<template>
  <div>
    <core-data-table-v2
        :model="customerModel"
        :columns="columns"
        :searches-and-filters="filters"
        table-name="customerManagement-management"
        v-on="$listeners" />
  </div>
</template>

<script>
import { Customer } from "@/Modules/OdysseyModels"
import columns from "../components/CustomerManagementTableView/columns"
import filterConfig from "../components/CustomerManagementTableView/filterConfig";
export default {
  name: "CustomerManagementTableView",
  data() {
    return {
      filters: filterConfig
    }
  },
  computed: {
    customerModel() {
      return Customer
    },
    columns() {
      return columns
    }
  }
}
</script>

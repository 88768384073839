
const defaultColumns = [
  {
    prop: "id",
    label: "ID"
  },
  {
    prop: "firstName",
    label: "First Name"
  },
  {
    prop: "lastName",
    label: "Last Name",
  },
  {
    prop: "email",
    label: "Email",
  },
  {
    prop: "phone",
    label: "Phone",
  }
]

export default defaultColumns
import ProductCategoryManagementTableView from "./views/ProductCategoryManagementTableView"

const routes = [
  {
    path: "",
    name: "productCategoryManagement-list-view",
    component: ProductCategoryManagementTableView
  }
]

export default routes
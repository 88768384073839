<template>
  <div>
    <router-view />
    <!-- Notifications -->
    <CoreNotifications />
  </div>
</template>

<script>
  import versionCheck from "@/Modules/Core/utils/versionCheck"

  export default {
    data() {
      return {
        versionCheckInterval: false
      }
    },
    mounted() {
      versionCheck()
      this.updateVersionCheck()
    },
    beforeDestroy() {
      clearInterval(this.versionCheckInterval)
    },
    methods: {
      updateVersionCheck() {
        if (this.versionCheckInterval) {
          clearInterval(this.versionCheckInterval)
        }
        this.versionCheckInterval = setInterval(() => {
          versionCheck()
        }, 1000 * 60 * 5)
      }
    }
  }
</script>
<style lang="scss">
  @import "@/Modules/Core/assets/css/index.scss";
</style>

<template>
  <core-dialog
      :dialog-props="{closeDialog:onClose}"
      :title="dialogTitle"
      :append-to-body="true"
      :visible="true"
      width="800px"
      class="dialog"
      :before-close="onClose">
    <div
      
        slot="content"
        v-loading="loading"
        :element-loading-text="loadingText">
      <div
          v-if="!errors.length"
          class="">
        <div class="flex justify-between hint-box">
          <core-text
              hint
              semibold
              v-html="hintText" />
          <el-checkbox
              v-model="checkAll"
              :indeterminate="isIndeterminate"
              @change="onCheckAll">
            {{ modeText }} all
          </el-checkbox>
        </div>

        <div
            v-for="{records, type} of recordsByType"
            :key="type"
            class="record-container unsaved-data">
          <template v-if="records.length">
            <core-text
                :class="type"
                tag="h3"
                bold>
              {{ type | capitalize }} {{ modelName | pluralize }}
            </core-text>
            <div class="records">
              <template
                  v-for="record of records">
                <div
                    :key="record.id"
                    class="record flex-column"
                    :class="{[type]:true, 'is-checked':checkedItems.includes(record.id)}">
                  <div class="flex justify-between">
                    <core-text
                        bold
                        class="record-label">
                      <span v-if="type !== 'new'">(ID: {{ record.id }})</span>  {{ record.valueList.label }}
                    </core-text>

                    <el-checkbox
                        :value="checkedItems.includes(record.id)"
                        @change="onCheck(record.id)">
                      {{ modeText }}
                    </el-checkbox>
                  </div>

                  <div
                      v-if="type==='updated'"
                      class="attributes-changed-container">
                    <core-text
                        link
                        @click="toggleVisibleAttributes(record)">
                      {{ record.id === visibleRecordId ? 'hide': 'show' }} changed attributes
                    </core-text>

                    <template v-for="{key, from, to} of getChangedAttributes(record)">
                      <div
                          v-if="record.id === visibleRecordId"
                          :key="key"
                          class="flex-column attribute">
                        <core-text
                            class="attribute-name"
                            bold>
                          {{ key }}
                        </core-text>

                        <div class="flex-column from-to">
                          <core-text class="from">
                            <strong>from:</strong> {{ from }}
                          </core-text>

                          <core-text class="to">
                            <strong>to:</strong> {{ to }}
                          </core-text>
                        </div>
                      </div>
                    </template>
                  </div>
              

                  <div
                      v-else
                      class="attributes-changed-container">
                    <core-text
                        link
                        @click="toggleVisibleAttributes(record)">
                      {{ record.id === visibleRecordId ? 'hide': 'show' }} attributes
                    </core-text>

                    <template v-for="{key, value} of getNewlyCreatedAttributes(record)">
                      <div
                          v-if="record.id === visibleRecordId"
                          :key="key"
                          class="flex-column attribute">
                        <core-text
                            class="attribute-name"
                            bold>
                          <strong>{{ key }}:</strong> {{ value }}
                        </core-text>
                      </div>
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>

      <div
          v-else
          class="errors flex-column">
        <core-text
            tag="h3"
            bold>
          Oops! Something went wrong.
        </core-text>

        <core-text
            tag="h4"
            hint>
          Please review the following errors and make changes or report the error accordingly.
        </core-text>

        <core-text
            v-for="(error, index) of errors"
            :key="index"
            semibold>
          <span class="error">Error:</span>{{ error.detail }}
        </core-text>
      </div>

      <div class="flex justify-end">
        <template v-for="button of buttons">
          <core-button
           
              v-if="!button.isHidden"
              :key="button.label"
              :type="button.type"
              :disabled="button.disabled"
              @click="button.onClick">
            {{ button.label }}
          </core-button>
        </template>
      </div>
    </div>
  </core-dialog>
</template>

<script>
import pluralize from "pluralize"
import startCase from "lodash/startCase"
import get from "lodash/get"
import isArray from "lodash/isArray"
import isPlainObject from "lodash/isPlainObject"

export default {
  components: {},
  filters: {
    pluralize(value) {
      return pluralize(value)
    },
    capitalize(value) {
      return startCase(value)
    }
  },
  props: {
    errors: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
    onClose: { type: Function, required: true },
    action: { type: Function, required: true },
    mode: { type: String, required: true },
    onDraftDiscard: { type: Function, default: null },
    model: { type: Function, required: true }
  },
  data() {
    return {
      visibleRecordId: null,
      totalChangesCount: 0,
      checkedItems: [],
      checkAll: true,
      isIndeterminate: false
    }
  },
  computed: {
    buttons() {
      const {
        mode,
        modeText,
        checkedItems,
        totalChangesCount,
        onClose,
        action,
        newRecords,
        updatedRecords,
        deletedRecords,
        checkedRecords
      } = this

      return [
        {
          label: "Cancel",
          isHidden: mode === "unsavedChanges",
          onClick: onClose
        },
        {
          label: "Discard All Changes",
          type: "danger",
          isHidden: mode !== "unsavedChanges",
          onClick: () =>
            action({
              recordsToCreate: newRecords,
              recordsToUpdate: updatedRecords,
              recordsToDelete: deletedRecords,
              forceDiscard: true
            })
        },
        {
          type: mode === "discard" ? "danger" : "success",
          label: `${startCase(modeText)} ${checkedItems.length} of ${totalChangesCount} changes`,
          onClick: () => action(checkedRecords),
          disabled: !!this.errors.length
        }
      ]
    },
    loadingText() {
      const { checkedItems, totalChangesCount, mode } = this

      switch (mode) {
        case "discard":
          return `Discarding ${checkedItems.length} of ${totalChangesCount} changes...`

        default:
          return `Saving ${checkedItems.length} of ${totalChangesCount} changes...`
      }
    },
    hintText() {
      const happyLittleAccidents =
        "<br/><br/> Remember... We don't make mistakes, just happy little accidents."

      switch (this.mode) {
        case "unsavedChanges":
          return `Please review the following changes before discarding.<br>All checked changes will be saved.${happyLittleAccidents}`

        case "discard":
          return `Please review the following changes before discarding.<br>All checked changes will be discarded. ${happyLittleAccidents}`

        default:
          return `Please review the following changes before discarding.<br>All checked changes will be saved.${happyLittleAccidents}`
      }
    },
    modeText() {
      switch (this.mode) {
        case "discard":
          return "discard"

        default:
          return "save"
      }
    },
    buttonType() {
      return this.mode === "discard" ? "danger" : "success"
    },
    dialogTitle() {
      const { mode } = this

      switch (mode) {
        case "unsavedChanges":
          return "You have unsaved changes!"

        default:
          return `Are you sure you want to ${mode} these changes?`
      }
    },
    modelName() {
      return startCase(this.model.entity)
    },
    history() {
      const { model } = this

      return model.store().state.entities[model.entity].draft.history
    },
    recordsByType() {
      const { newRecords, updatedRecords, deletedRecords } = this

      return [
        { type: "new", records: newRecords },
        { type: "updated", records: updatedRecords },
        { type: "deleted", records: deletedRecords }
      ]
    },
    newRecords() {
      return this.model
        .query()
        .where("_saved", false)
        .where("_delete", false)
        .where(({ $id }) => $id.includes("$uid"))
        .get()
    },
    updatedRecords() {
      return this.model
        .query()
        .where("_saved", false)
        .where("_delete", false)
        .where(({ $id }) => !$id.includes("$uid"))
        .get()
    },
    deletedRecords() {
      return this.model
        .query()
        .where("_saved", false)
        .where("_delete", true)
        .where(({ $id }) => !$id.includes("$uid"))
        .get()
    },
    checkedRecords() {
      const { newRecords, updatedRecords, deletedRecords, checkedItems } = this
      const filterById = ({ id }) => checkedItems.includes(id)

      return {
        recordsToCreate: newRecords.filter(filterById),
        recordsToUpdate: updatedRecords.filter(filterById),
        recordsToDelete: deletedRecords.filter(filterById)
      }
    },
    allIds() {
      const { newRecords, updatedRecords, deletedRecords } = this
      const getId = ({ id }) => id

      return [...newRecords.map(getId), ...updatedRecords.map(getId), ...deletedRecords.map(getId)]
    }
  },
  mounted() {
    this.checkedItems = this.allIds
    this.totalChangesCount = this.allIds.length
  },

  methods: {
    onCheckAll(val) {
      this.checkedItems = val ? this.allIds : []
      this.isIndeterminate = false
    },
    onCheck(id) {
      if (this.checkedItems.includes(id)) {
        this.checkedItems = this.checkedItems.filter(checked => checked !== id)
      } else {
        this.checkedItems.push(id)
      }

      const checkedCount = this.checkedItems.length

      this.checkAll = checkedCount === this.allIds.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.allIds.length
    },
    toggleVisibleAttributes(record) {
      if (record.id === this.visibleRecordId) {
        this.visibleRecordId = null
      } else {
        this.visibleRecordId = record.id
      }
    },
    getAttributeCountText(record) {
      const count = this.getChangedAttributes(record).length

      return `${count} ${count > 1 ? "attributes" : "attribute"} changed.`
    },

    stringifyValue(value) {
      if (isPlainObject(value)) {
        return Object.entries(value)
          .map(([key, val]) => `${key} ${val}`)
          .join(", ")
      }

      if (isArray(value)) {
        return value.join(", ")
      }

      return value
    },

    getNewlyCreatedAttributes(record) {
      return Object.entries(record)
        .map(([key, value]) => {
          if (key !== "_saved" && !key.includes("$") && key !== "id") {
            return { key, value: `${this.stringifyValue(value)}` }
          }
        })
        .filter(attr => attr)
    },
    getChangedAttributes(updatedrecord) {
      const { history } = this

      const historyPoint = history.find(({ id }) => id === updatedrecord.id)
      const originalRecord = get(historyPoint, "from", {})
      const changedAttributes = []

      Object.entries(originalRecord).forEach(([key, value]) => {
        const newValue = updatedrecord[key]

        if (newValue !== value && key !== "_saved" && !key.includes("$")) {
          changedAttributes.push({
            key,
            from: this.stringifyValue(value),
            to: this.stringifyValue(newValue)
          })
        }
      })

      return changedAttributes
    }
  }
}
</script>

<style lang="scss" scoped>
.hint-box {
  padding-bottom: var(--padding-l);
}

.errors {
  .core-text {
    padding-bottom: var(--padding-xl);
  }
  .error {
    font-weight: bold;
    color: var(--color-bridj-red);
    padding-right: var(--padding-m);
  }
}
.record-container {
  padding-bottom: var(--padding-l);

  .records {
    padding-left: var(--padding-l);
  }

  /deep/ h3 {
    padding-bottom: var(--padding-m);
  }

  .record {
    width: 100%;
    box-shadow: var(--box-shadow-container-light);
    border-radius: var(--border-radius-xs);
    padding: var(--padding-s) var(--padding-m);
    margin-bottom: var(--margin-l);

    &.new {
      border: 1px dashed #35c58b;
    }
    &.updated {
      border: 1px dashed #e6a23c;
    }
    &.deleted {
      border: 1px dashed #f56c6c;
    }

    &.is-checked {
      border-style: solid;
    }

    .record-label {
      padding-bottom: var(--padding-s);
      span {
        color: rgba(0, 0, 0, 0.4);
      }
    }

    .attribute-toggle {
      padding-left: var(--padding-m);
      text-decoration: none;
    }

    .attribute {
      padding-left: var(--padding-m);

      &:first-child {
        margin-top: var(--margin-m);
      }
    }
    .attribute-name {
      padding-bottom: var(--padding-xs);
    }

    .from,
    .to {
      padding-bottom: var(--padding-xs);
      padding-left: var(--padding-m);
    }
  }
}
</style>

<template>
  <div>
    <slot name="icon" />

    <div
        class="tab-container">
      <div class="tab-inner">
        <core-text
            truncate
            :tooltip-props="tab.tooltipProps"
            @click="tab.onClick()">
          {{ tab.label }}
        </core-text>

        <slot
            name="tab-status" />

        <slot
            name="close-button" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClickTab",
  props: { tab: { type: Object, default: () => ({}) } }
}
</script>

<template>
  <div>
    <div class="finalise">
      <div class="progress">
        <el-progress
            type="circle"
            :percentage="100"
            status="success" />
        <div class="checkout-options">
          <h3>Purchase Successful!</h3>

          <template v-if="canSendSMS && showSMSButton">
            <div class="receipt-options">
              <template v-if="showPhoneNumberInput">
                <el-form
                    ref="form"
                    class="customer-phone-number">
                  <el-form-item label="Customer Phone Number">
                    <el-input v-model="phoneNumber" />
                  </el-form-item>
                </el-form>
              </template>

              <el-button
                  type="abel"
                  icon="el-icon-mobile-phone"
                  @click="sendSMS">
                Send Receipt via SMS
              </el-button>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="options">
      <StepOptions
          prev-title="Select Payment"
          :close-till="true"
          @closeModal="closeModal()" />
    </div>
  </div>
</template>

<script>
import { Settings, Order, SmsMessage } from "@/Modules/OdysseyModels";
import StepOptions from "@/Modules/PointOfSale/components/checkout/steps/StepOptions";
export default {
  name: "Finalise",
  components: {
    StepOptions
  },
  data() {
    return {
      isSending: false,
      systemSettings: {},
      showPhoneNumberInput: false,
      phoneNumber: "",
      smsSent: false,
      showSMSButton: true
    }
  },
  computed: {
    canSendSMS() {
      return !!this.systemSettings.smsEnabled
    },
    checkoutStatus() {
      return this.$store.state.PointOfSale.checkoutStatus
    }
  },
  mounted() {
    this.getSettings()
    // @todo: Change this to dynamic variable for the company id

    // At this point, submit the order of the checkout status is not "Finalised"
    if (this.checkoutStatus !== 'finalised') {
      this.submitOrder()
    }
  },
  methods: {
    async submitOrder() {
      const posData = this.$store.state.PointOfSale
      let customerId
      if (posData.selectedCustomer) {
        customerId = posData.selectedCustomer.id
      } else {
        // For Anonymous purchases
        customerId = 0
      }

      let notes = ''
      if (this.$store.state.PointOfSale.notes) {
        notes = this.$store.state.PointOfSale.notes
      }

      const orderData = {
        customerId: customerId,
        paymentType: posData.selectedPayment,
        status: "completed",
        priceCost: posData.checkoutTotal,
        pricePaid: posData.checkoutTotal,
        items: posData.selectedProducts,
        notes: notes
      }

      await Order.api().create({
        ...orderData
      })
      const notificationsPayload = {
        component: "Toast",
        type: "success",
        title: "Thank you",
        message: "Your order has been successfully created",
        visible: true,
        decay: 3500
      }
      this.$store.dispatch("core/notifications/addToNotifications", notificationsPayload)
      this.$store.dispatch("PointOfSale/updateStatus", "finalised", { root: true })
      this.formUpdated = false
    },
    closeModal() {
      this.$emit("closeModal")
    },
    async sendTextMessage() {
      this.isSending = true
      const date = new Date().toLocaleDateString('en-AU')
      const total = this.$store.state.PointOfSale.checkoutTotal
      const products = this.$store.state.PointOfSale.selectedProducts.map(product =>
      `${product.quantity}x ${product.title} AUD ${product.price}\r\n`)

      const message = `Receipt from ${date}:\r\n ${products}\r\n Total: ${total} \r\n`
      const response = await SmsMessage.api().sendSMS(
          {
            phoneNumber: this.phoneNumber,
            message: message
          }
      )

      if (response.response.data.success) {
        this.showSMSButton = false
        this.showPhoneNumberInput = false
        const notificationsPayload = {
          component: "Toast",
          type: "success",
          title: "Thank you",
          message: `The Receipt has been successfully sent. ${response.response.data.data}`,
          visible: true,
          decay: 4500
        }
        await this.$store.dispatch("core/notifications/addToNotifications", notificationsPayload)
      }

      this.isSending = false
      this.$emit('updateMessages')
    },
    async getSettings() {
      this.isLoading = true
      await Settings.api().fetchById()
      this.systemSettings = Settings.find(1)
      this.isLoading = false
    },
    sendSMS() {
      if (this.$store.state.PointOfSale.selectedCustomer && this.$store.state.PointOfSale.selectedCustomer.phone) {
        this.phoneNumber = this.$store.state.PointOfSale.selectedCustomer.phone
      } else if (!this.phoneNumber) {
        this.showPhoneNumberInput = true
      }
      if (this.phoneNumber) {
        this.sendTextMessage()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.finalise {
  background: white;
  box-shadow: var(--box-shadow-container);
  padding: 2em 1em;

  .progress {
    text-align: center;

    display: flex;
    align-items: center;
    .checkout-options {
      flex: 1;

      h3 {
        color: var(--main-success-green);
        font-size: 2em
      }
    }

  }

  .options {
    text-align: right;
    margin: 2em 0 0 0;
  }

  .customer-phone-number {
    max-width: 20em;
    margin: auto;
  }

  /deep/ .el-progress {
    background: #f0f9eb;
    border-radius: 50%;
    padding: 1.5em;
  }
}
</style>
import store from "./store"
import Fragment from "vue-fragment"
import layouts from "./layouts"
import components from "./components"
import UIWidgets from "./UI-Widgets"
import Vue from "vue"
import App from "./App"
import VueRouter from "vue-router"
import createAppSettings from "@/Modules/Core/utils/create-app-settings"
import router from "./router"
import makeServer from "@/Modules/Core/utils/miragejs/makeServer"
import directives from "./directives"
import { getEnvironment } from "./utils/getPermissions/isDisabledForEnvironment"
import getPermissions from "./utils/getPermissions"
import config from "@/config/config.js"
import get from "lodash/get"

const startMirageJSServer = ({ vuexModels }) => {
  const currentEnvironment = getEnvironment()
  let server = null

  const isMock = get(config, `mockEnv.${currentEnvironment}`, false)
  const isHybridMock = get(config, `hybridMockEnv.${currentEnvironment}`, false)

  if (isMock || isHybridMock) {
    const mode = isHybridMock ? "hybrid-mock" : "full-mock"
    const logging = get(config, "mockLogging", false)

    /* eslint-disable */
    console.log(`\nStarting mirageJS in "${mode}" mode.`)
    console.log(`MirageJS logging is ${logging ? "on" : "off"}.\n`)
    /* eslint-enable */

    server = makeServer({ vuexModels, isHybridMock })

    server.logging = logging

    const hybridMockDBDump = JSON.parse(localStorage.getItem("hybridMockDB"))

    if (hybridMockDBDump && isHybridMock) {
      delete hybridMockDBDump.serviceObjectives
      delete hybridMockDBDump.userRoles

      server.db.loadData(hybridMockDBDump)
    }
  }
}

const initCore = function() {
  if (!store) {
    throw new Error("Please register the WGBase.vue Plugin by passing a Vuex Store")
  }

  Vue.use(Fragment.Plugin)
  Vue.use(layouts)
  Vue.use(components)
  Vue.use(UIWidgets)
}

Vue.prototype.$isPermitted = function(permissionKey) {
  if (!permissionKey) {
    return true
  }

  return getPermissions(permissionKey)
}

const createApp = (config = {}) => {
  // config takes Base app to create app and addLibraries to init any third party libraries
  // This is easily extensible in the future! ^_^
  const { Base, initialState, productionTip = false, addLibraries = () => {} } = config

  if (!Base) {
    throw new Error("Please pass in a Base module to get yor app started!")
  }

  Vue.use(VueRouter)
  addLibraries(Vue)

  const vuexModels = {}

  // Initializes core components
  initCore()

  // adds appSettings to store for theme-ability etc
  store.registerModule(
    "settings",
    createAppSettings({
      appConfig: Base.appConfig,
      initialState
    })
  )

  // A helper function for registering the BaseModule and SubModules
  const addModule = Module => {
    if (Module.models) {
      Module.models.forEach(model => {
        vuexModels[model.entity] = model

        if (model.initializeModel) model.initializeModel()

        store.$database.register(model, model.customModule)
      })
    }

    if (!Module.main) {
      store.dispatch("settings/addToModules", Module)
    }

    if (Module.registerComponents) {
      Module.registerComponents(Vue)
    }

    if (Module.installPlugins) {
      Module.installPlugins({ Vue, store, router })
    }

    if (Module.store) {
      store.registerModule(Module.name, Module.store)
    }

    if (Module.children && Module.registerChildren) {
      Module.children.forEach(addModule)
    }
  }

  addModule(Base)
  router.addRoutes([Base])

  Base.children.forEach(Module => {
    addModule(Module)
  })

  // Setting up LogRocket
  if (store.state.settings.app.errorReporting.logRocket) {
    LogRocket.init(store.state.settings.app.errorReporting.logRocket)
  }

  Vue.config.productionTip = productionTip

  Object.entries(directives).forEach(([key, value]) => {
    Vue.directive(key, value)
  })

  startMirageJSServer({ vuexModels })

  return () =>
    new Vue({
      store,
      router,
      render(h) {
        return h(App)
      }
    }).$mount("#app")
}

export default createApp

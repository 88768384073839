export default {
  fields: {
    appointmentDetails: {
      customerId: {
        type: "entity-select",
        queryType: "filter",
        props: {
          entity: "Customer",
          filterable: true,
          clearable: true,
          inputMode: 'search',
        },
        label: "Select Customer",
        value: null,
        validation: [{required: true, message: "Please select a customer", trigger: "blur"}]
      },
      staffId: {
        type: "entity-select",
        queryType: "filter",
        props: {
          entity: "User",
          filterable: true,
          clearable: true,
        },
        validation: [{required: true, message: "Please select a staff member", trigger: "blur"}],
        label: "Select Staff Member",
        value: null
      },
      productIds: {
        type: "entity-select",
        queryType: "filter",
        props: {
          entity: "Product",
          filterable: true,
          clearable: true,
          multiple: true
        },
        label: "Select Products",
        value: null
      },
      date: {
        type: "date",
        value: null,
        label: "Appointment Date",
        props: {
          format: "dd/MM/yyyy",
          valueFormat: "yyyy-MM-dd HH:mm:ss"
        },
        validation: [{required: true, message: "Please select a date", trigger: "blur"}]
      },
      startTime: {
        type: "time-select",
        value: null,
        label: "Start time",
        props: {
          format: "HH:mm",
          valueFormat: "HH:mm",
          pickerOptions: {
            start: '06:00',
            step: '00:05',
            end: '23:30',
          }
        },
        validation: [{required: true, message: "Please select a time", trigger: "blur"}]
      },
      endTime: {
        type: "time-select",
        value: null,
        label: "End time",
        props: {
          format: "HH:mm",
          valueFormat: "HH:mm",
          pickerOptions: {
            start: '06:00',
            step: '00:05',
            end: '23:30',
          }
        },
        validation: [{required: true, message: "Please select a time", trigger: "blur"}]
      },

    },
    misc: {
      _options: {
        rowSpan: 24,
        hideGroupTitle: true
      },
      notes: {
        type: "textarea",
        value: null,
        label: "Notes",
        placeholder: "Location, equipment, requirements etc."
      }
    }
  }
}

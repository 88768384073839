import { environments } from "@/Modules/Core/utils/getPermissions"
import { api } from "@/config/api"
import config from "@/config/config"
import get from "lodash/get"

const hasAuth0Config = () => {
  const domain = get(config, "auth0.domain", "%%")
  const clientId = get(config, "auth0.clientId", "%%")

  return !domain.includes("%%") && !clientId.includes("%%")
}
const features = {
  Module: {
    dependencies: [api.nunki.lightwerk]
  },
  auth0: {
    disabledEnvironments: [environments.prod, environments.staging],
    dependencies: [hasAuth0Config()],
    permissions: {
      canRead: true,
      canCreate: true,
      canUpdate: true,
      canDestroy: true
    }
  }
}

export default features

<template>
  <div>
    <core-data-table-v2
        :model="productCategoryModel"
        :columns="columns"
        table-name="productCategoryManagement-management"
        v-on="$listeners" />
  </div>
</template>

<script>
import { ProductCategory } from "@/Modules/OdysseyModels"
import columns from "../components/ProductCategoryManagementTableView/columns"
export default {
  name: "ProductCategoryManagementTableView",
  computed: {
    productCategoryModel() {
      return ProductCategory
    },
    columns() {
      return columns
    }
  }
}
</script>

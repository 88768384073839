import ProductManagementStatusColumn from "./ProductManagementStatusColumn"
import ProductCategoryColumn from "./ProductCategoryColumn"

const defaultColumns = [
    {
      prop: "id",
      label: "ID"
    },
    {
      prop: "title",
      label: "Title"
    },
    {
      prop: "productPrice",
      label: "Price",
    },
    {
      prop: "categoryTitle",
      label: "Category",
      Component: ProductCategoryColumn
    },
    {
      prop: "timeBlock",
      label: "Time Frame",
    },
    {
      prop: "active",
      label: "Status",
      Component: ProductManagementStatusColumn
    }
  ]

export default defaultColumns
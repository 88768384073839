<template>
  <div
      :key="selectedDateFormatted"
      class="staff-view-container">
    <div class="day-header">
      <div class="date">
        {{ dayName }}
        <strong><span class="tall">{{ selectedDateFormatted }}</span></strong> {{ monthName }}
      </div>
      <div class="day">
        <el-button
            size="small"
            @click="changeDay('back')">
          -
        </el-button>
        <strong>{{ selectedDateFormatted }} {{ monthName }}</strong>
        <el-button
            size="small"
            @click="changeDay()">
          +
        </el-button>
      </div>
    </div>

    <div v-if="isLoading">
      Loading ...
    </div>
    <div
        v-else
        class="grid-container">
      <div class="hours">
        <div
            v-for="(hour, index) in hours"
            :key="`hour-${index}`"
            class="hour">
          {{ hour }}:00
          <template v-if="hour < 12">
            am
          </template>
          <template v-else>
            pm
          </template>
        </div>
      </div>

      <div
          v-for="(staff, staffIndex) in staffMembers"
          :key="`staff-${staffIndex}`"
          class="staff">
        <div class="header">
          {{ staff.firstName }}
        </div>
        
        <div
            v-for="(hour, index) in hours"
            :key="`hour-${index}`"
            class="staff-hours"
            @click="createAppointment(hour, staff.id)" />

        <div class="appointment-container">
          <template v-for="(appointment, appointmentIndex) in getAppointmentsPerStaffMember(staff.id)">
            <div
                :key="`appointment-${appointmentIndex}`"
                class="appointment">
              <AppointmentDetails
                  :appointment="appointment"
                  :business-starting-time="settings.startingTime"
                  @reloadAppointments="getAppointments" />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {Appointment, Customer, Settings, User} from "@/Modules/OdysseyModels"
  import AppointmentDetails from "@/Modules/AppointmentManagement/components/AppointmentDetails"
  import moment from "moment"

  export default {
    name: "DayView",
    components: {
      AppointmentDetails
    },
    data() {
      return {
        selectedDate: new Date(),
        currentDate: new Date(),
        appointments: null,
        staffMembers: null,
        settings: null
      }
    },
    computed: {
      isLoading: {
        get() {
          if (this.appointments && this.settings && this.staffMembers) {
            return false
          }
          return true
        },
        set(val) {
            return val
        }
      },
      monthName() {
        const monthNames = ["January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ]
        const d = this.selectedDate
        return `${monthNames[d.getMonth()]} ${d.getFullYear()}`
      },
      selectedDateFormatted() {
        return this.selectedDate.getDate()
      },
      dateFormattedSessions() {
        if (this.appointments) {
          const appointments = JSON.parse(JSON.stringify(this.appointments))
          let startTime = moment().toDate();  // This will return a copy of the Date that the moment uses

          startTime.setHours(9);
          startTime.setMinutes(0);
          startTime.setSeconds(0);
          startTime.setMilliseconds(0);

          let endTime = moment().toDate();  // This will return a copy of the Date that the moment uses

          endTime.setHours(10);
          endTime.setMinutes(0);
          endTime.setSeconds(0);
          endTime.setMilliseconds(0);


          appointments.map((appointment, index) => {
            if (!appointment.startTime) {

              let newStartTime = new Date(startTime.setHours(startTime.getHours() + index))
              appointment.startTime = `${newStartTime.getHours()}:${newStartTime.getMinutes()}`
            }
            if (!appointment.endTime) {
              let newEndTime = new Date(endTime.setHours(endTime.getHours() + index))
              appointment.endTime = `${newEndTime.getHours()}:${newEndTime.getMinutes()}`
            }
            appointment.formattedStartTime = appointment.startTime.substring(0, 2)
            appointment.marginTop = appointment.startTime.substring(3, 5)
          })
          return appointments
        }
         return null
      },
      hours() {
        if (this.settings) {
          const startingHours = this.settings.startingTime.substring(0, 2)
          const finishingHours = this.settings.finishingTime.substring(0, 2)
          const hours = []
          for (let i = startingHours; i <= finishingHours; i++) {
            hours.push(i)
          }
          return hours
        }
        return null
      },
      dayName() {
        const names = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return names[this.selectedDate.getDay()]
      },
      reloadAppointments() {
        return this.$store.state.AppointmentManagement.reloadAppointments
      }
    },
    watch: {
      reloadAppointments() {
        this.getAppointments()
      },
      selectedDate() {
        this.isLoading = true
        this.getSettings()
        this.getStaff()
        this.getAppointments()
      }
    },
    created() {
      this.getSettings()
      this.getStaff()
      this.getAppointments()
    },
    methods: {
      async getSettings() {
        await Settings.api().fetchById()
        this.settings = Settings.find(1)
      },
      async getStaff() {
        await User.api().fetchAll()
        this.staffMembers = User.all()
      },
      async getAppointments() {
        const results = await Appointment.api().fetchByDate(this.createSQLDate(this.selectedDate))
        this.appointments = results.response.data.data.data
      },
      createSQLDate(dateString) {
        const date = new Date(dateString)
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} 00:00:00`
      },
      createAppointment(hour, staffId) {
        const options = {
          optionsParams: {
            startTime: hour,
            staffId: staffId
          }
        }
        new Appointment().Actions.openAppointmentDialog(options)
      },
      editClientAppointment(appointment) {
        this.$emit("editClientAppointment", appointment)
      },
      calculatedWidth(appointments) {
        const percentage = 100 / appointments.length
        return `width: ${percentage}%`
      },
      changeDay(direction = "forward") {

        if (direction === "forward") {
          this.selectedDate = moment(this.selectedDate, "DD/MM/YYYY").add(1, 'day').format("MM/DD/YYYY")
        } else {
          this.selectedDate =  moment(this.selectedDate, "DD/MM/YYYY").subtract(1, 'day').format("MM/DD/YYYY")
        }
        this.selectedDate = new Date(this.selectedDate)

        this.$store.dispatch("AppointmentManagement/updateCurrentDate", moment(this.selectedDate).format("YYYY-MM-DD 00:00:00"))
       },
      formatDate(date) {
        const dateString = new Date(date)
        const day = dateString.getDate()
        const month = dateString.getMonth() + 1
        const year = dateString.getFullYear()
        return `${day}/${month}/${year}`
      },
      convertDateForIOS(date) {
        const arr = date.split(/[- :]/)
        return new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5])
      },
      getAppointmentsPerStaffMember(staffMemberId) {
        const findDate = this.formatDate(this.selectedDate)
        let foundAppointments = []
        this.dateFormattedSessions.forEach(item => {

          item.dateIOSConverted = this.convertDateForIOS(item.date)
          item.dateFormatted = this.formatDate(item.dateIOSConverted)

          if (item.dateFormatted === findDate
              && parseInt(item.staffId) === parseInt(staffMemberId)) {

            if (item.customerId) {
              item.customer = Customer.find(item.customerId)
            }
            foundAppointments.push(item)
          }
        })
        foundAppointments.sort((a, b) => (parseInt(a.startTime) > parseInt(b.startTime)) ? 1 : -1)

        return foundAppointments
      }
    }
  }
</script>

<style lang="scss" scoped>
  .staff-view-container {
    overflow-x: auto;
    max-width: 100%;

    .day-header {
      padding: 0em 1em;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .date {
        flex: 1;
        .tall {
          font-size: 1.2em;
        }
      }
      .day {
        flex: 2;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        strong {
          display: inline-block;
          margin: 0 1em;
        }

      }
    }

    .grid-container {
      display: flex;
      padding: 0;
      margin: 1em 0 1em 3em;

      .hours {
        flex: 1;
        margin-top: 3em;
        max-width: 5em;

        .hour {
          height: 60px;
          position: relative;
          text-align: left;

          &:after {
            border-bottom: 1px solid #EAEAEA;
            content: "";
            width: 2em;
            position: absolute;
            right: -1.4em;
            top: 0.9em;
          }
        }
      }

      .staff {
        flex: 1;
        justify-content: center;
        min-width: 15em;
        border-right: 1px solid #EBEEF5;
        position: relative;

        .day-options {
          position: absolute;
          right: 0.5em;
          top: 0.5em;
        }



        &:first-child {
          border-left: 1px solid #EBEEF5;
        }


        .header {
          border-top: 1px solid #EBEEF5;
          padding: 1em 0;
          font-weight: bold;
          text-align: center;
          border-bottom: 1px solid #EBEEF5;
        }

        .staff-hours {
          height: 60px;
          border-bottom: 1px solid #eaeaea;
          transition: var(--main-transition);
          cursor: pointer;

          &:hover {
            background: var(--main-light-blue);
          }
        }
        .appointment-container {
          position: absolute;
          top: 0;
          width: 100%;
          .appointment {
            width: 100%;
            margin: 1px;
            &:hover {
              z-index: 1000;
            }
          }
        }

        .hour {
          border-bottom: 1px solid #EBEEF5;
          height: 60px;
          position: relative;
          .time {
            top: -1.85em;
            left: -3em;
            padding-right: 0.75em;
            position: absolute;
            border-bottom: 1px solid #EBEEF5;
          }

        }
      }
    }
  }
</style>
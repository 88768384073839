import { api } from "@/config/api"
import createDefaultCRUD from "@/Modules/Core/utils/miragejs/createDefaultCRUD"

const baseURL = api.nunki.lightwerk
const endpoint = "/keyStatistic"

const defaults = createDefaultCRUD({
  baseURL,
  endpoint
})

const apiActions = {
  ...defaults.apiActions
}

const routes = server => {
  defaults.routes(server)
}

export { apiActions }
export default routes

import ExtendedModel from "@/Modules/Core/utils/extended-vue-orm-model"
import definition, { apiActions } from "./definition"
import Actions from "./actions"
import BulkActions from "./bulkActions"
import config from "@/config"

const baseURL = config.apiConfig.lightwerk.api
const endpoint = "/generalPractitioners"

class GeneralPractitioner extends ExtendedModel {
  static entity = "GeneralPractitioner"
  static globalSearchEnabled = true
  static definition = definition

  static apiConfig = {
    dataKey: "data.data",
    actions: {
      ...apiActions,
      async createBulkCSV(patientIds, options = {}) {
        const url = `${baseURL}${endpoint}/createBulkCSV/${patientIds}`
        return this.get(url, null)
      }
    },
  }

  get Actions() {
    return new Actions(this)
  }

  get BulkActions() {
    return new BulkActions(this)
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`
  }

  get globalSearchLabel() {
    return this.fullName
  }

  get valueList() {
    const {id} = this
    return {label: this.fullName, value: id, id}
  }


}
export default GeneralPractitioner

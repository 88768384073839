<template>
  <div class="button-bar">
    <div class="button-bar-group left">
      <template v-for="button of leftButtons">
        <el-form-item
            v-if="button.isVisible"
            :key="button.label"
            class="btn"
            :class="button.className">
          <el-button
              :size="buttonSize"
              :disabled="button.disabled"
              class="button"
              :class="`btn-${button.btnClass}`"
              :type="button.type"
              @click="button.onClick(formName)">
            {{ button.label }}
          </el-button>
        </el-form-item>
      </template>
    </div>


    <div class="button-bar-group right">
      <template v-for="button of rightButtons">
        <el-form-item
            v-if="button.isVisible"
            :key="button.label"
            class="btn"
            :class="button.className">
          <el-button
              :size="buttonSize"
              :disabled="button.disabled"
              class="button"
              :class="`btn-${button.btnClass}`"
              :type="button.type"
              @click="button.onClick(formName)">
            {{ button.label }}
          </el-button>
        </el-form-item>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    formName: { type: String, default: "form" },
    compact: { type: Boolean, default: false },
    isSubmitting: { type: Boolean, default: false },
    formUpdated: { type: Boolean, default: false },
    onDelete: { type: Function, default: null },
    onCancel: { type: Function, default: null },
    onReset: { type: Function, default: null },
    onSubmit: { type: Function, default: null }
  },
  computed: {
    buttonSize() {
      return this.compact ? "mini" : "medium"
    },
    rightButtons() {
      const { isSubmitting, formUpdated, onReset, onSubmit, $listeners } = this
      // const { onCancel, onReset, onSubmit } = this.$listeners

      return [
        {
          label: "Reset",
          onClick: onReset,
          disabled: isSubmitting,
          isVisible: formUpdated && $listeners.onReset,
          btnClass: "cancel",
          className: "right"
        },
        {
          label: "Submit",
          onClick: onSubmit,
          disabled: isSubmitting,
          type: "success",
          isVisible: formUpdated && $listeners.onSubmit,
          btnClass: "confirm",
          className: "right"
        }
      ]
    },
    leftButtons() {
      const { isSubmitting, onDelete, $listeners, onCancel } = this

      return [
        {
          label: "Delete",
          onClick: onDelete,
          disabled: isSubmitting,
          isVisible: $listeners.onDelete,
          btnClass: "delete"
        },
        {
          label: "Cancel",
          onClick: onCancel,
          disabled: isSubmitting,
          isVisible: $listeners.onCancel,
          btnClass: "cancel",
          className: "right"
        }
      ]
    }
  }
}
</script>

<style lang="scss" >
.el-form-item.btn {
  margin-bottom: 0;
}
</style>

<style lang="scss" scoped>
.button-bar {
  display: flex;
  width: 100%;

  .button-bar-group {
    display: flex;

    &.right {
      margin-left: auto;
    }
  }

  .btn:not(:last-child) {
    padding-right: var(--padding-s);
  }
}
</style>

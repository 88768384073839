<template>
  <div class="notifications-wrapper">
    <template v-if="notifications">
      <template v-for="(notification, index) in notifications">
        <transition
            :key="`transition-${index}`"
            name="fade">
          <component
              :is="notification.component"
              :key="`notification-${index}`"
              :class="notification.type"
              :title="notification.title"
              :type="notification.type"
              :message="notification.message"
              :decay="notification.decay"
              :payload="notification.payload" />
        </transition>
      </template>
      <template v-if="hasManyNotifications">
        <div class="close-all-notifications">
          <core-button
              type="abel"
              size="mini"
              icon="el-icon-close"
              @click="closeAll">
            Close all Notifications
          </core-button>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
  import Toast from "./Toast"
  export default {
    name: "CoreNotifications",
    components: {
      Toast
    },
    computed: {
      notifications() {
        return this.$store.state.core.notifications.notifications
      },
      hasManyNotifications() {
        return this.notifications.length > 1
      }
    },
    methods: {
      closeAll() {
        this.$store.dispatch("core/notifications/removeAllNotifications", this.key)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .notifications-wrapper {
    z-index: 10000;
    position: fixed;
    bottom: 1em;
    right: 1em;

    .close-all-notifications {
      text-align: right;
      margin-top: -0.75em;
      margin-right: 1em;
    }
  }
</style>

import config from "./config"

const url_prefix = config.nunki.lightwerk.secure ? "https://" : "http://"

const api = {
  nunki: {
    lightwerk: url_prefix + config.nunki.lightwerk.api
  },
  google: {
    maps: {
      key: config.google.maps.apikey,
      url: config.google.maps.url
    }
  },
  // here: {
  //   router: {
  //     key: config.here.router.apikey,
  //     url: config.here.router.url
  //   }
  // }
}

export { api }

<template>
  <div> 
    <GmapPolygon 
        :paths="serviceAreaFog"      
        :options="{...serviceAreaFogOptions, clickable:fogOfWarClickable}"
        @mouseover="onFogOfWarMouseOver" />
    <GmapPolygon  
        v-for="(hole, index) in serviceAreaFogHoles"
        :key="index"     
        :paths="[hole]"
        :options="{...serviceAreaHoleOptions, clickable:fogOfWarClickable}" 
        @mouseover="onFogOfWarMouseOver" />

    <template>
      <GmapPolygon  
          v-for="(zServiceArea, index) in zoneServiceAreas"
          :key="`zServiceArea${index}`"
          :paths="zServiceArea.path"
          :options="{...zServiceAreaOptions, clickable: hoveredZone !== zServiceArea.zoneId, strokeOpacity:hoveredZone == zServiceArea.zoneId ?1:0.6}"
          @mouseover="onMouseOver(zServiceArea.zoneId)" />
    </template>
  </div>
</template>

<script>
import * as turf from "@turf/turf"

const convertPointToArray = ({ lat, lng }) => [lng, lat]
const convertArrayPointToObject = ([lng, lat]) => ({ lng, lat })

const closeBoundaryPolygon = (boundary = []) => {
  const closedBoundaryPolygon = boundary
  closedBoundaryPolygon.push(boundary[0])

  return closedBoundaryPolygon
}

const getPolygonFromBoundary = boundary => {
  return turf.polygon([closeBoundaryPolygon(boundary)])
}

export default {
  props: { zoneBoundaries: { type: Array, default: () => [] } },
  data() {
    const serviceAreaColor = "#00B9BD"
    const fogStyles = {
      strokeColor: serviceAreaColor,
      strokeOpacity: 0,
      strokeWeight: 2,
      fillColor: "#00000",
      fillOpacity: 0.15
    }

    return {
      serviceAreaFog: [],
      serviceAreaFogHoles: [],
      zoneServiceAreas: [],
      hoveredZone: null,
      polygons: {},
      fogOfWarClickable: true,
      serviceAreaFogOptions: {
        ...fogStyles
      },
      serviceAreaHoleOptions: {
        ...fogStyles
      },
      zServiceAreaOptions: {
        strokeColor: serviceAreaColor,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillOpacity: 0
      },
      entireWorldPath: [
        { lat: -85.1054596961173, lng: 0 },
        { lat: -85.1054596961173, lng: 180 },
        { lat: 85.1054596961173, lng: 180 },
        { lat: 85.1054596961173, lng: -180 },
        { lat: -85.1054596961173, lng: -180 }
      ]
    }
  },
  watch: {
    zoneBoundaries() {
      this.createPolygons()
    }
  },
  mounted() {
    this.createPolygons()
  },
  methods: {
    onMouseOver(zoneId) {
      this.fogOfWarClickable = true
      this.hoveredZone = zoneId
    },
    onFogOfWarMouseOver() {
      this.fogOfWarClickable = false
      this.hoveredZone = null
    },
    createPolygons() {
      const { entireWorldPath, zoneBoundaries, getServiceAreaUnions } = this
      const serviceAreaFog = [entireWorldPath]
      const serviceAreaFogHoles = []

      const zoneServiceAreas = zoneBoundaries.map(({ zoneId, boundaries }) => {
        const unions = getServiceAreaUnions(boundaries)

        const path = unions
          .map(points => {
            const [zoneArea, ...zoneHoles] = points.map(boundary =>
              boundary.map(convertArrayPointToObject)
            )

            serviceAreaFog.push(zoneArea)
            zoneHoles.forEach(zoneHole => {
              serviceAreaFogHoles.push(zoneHole)
            })

            return [zoneArea, ...zoneHoles]
          })
          .flat()

        return { zoneId, path }
      })

      this.zoneServiceAreas = zoneServiceAreas
      this.serviceAreaFog = serviceAreaFog
      this.serviceAreaFogHoles = serviceAreaFogHoles
    },
    getServiceAreaUnions(boundaries = []) {
      const boundariesAsArrays = JSON.parse(JSON.stringify(boundaries)).map(boundary =>
        boundary.map(convertPointToArray)
      )

      if (!boundaries.length) {
        return []
      }

      const union = turf.union(...boundariesAsArrays.map(getPolygonFromBoundary))

      if (union.geometry.type === "MultiPolygon") {
        return union.geometry.coordinates
      } else {
        return [union.geometry.coordinates]
      }
    }
  }
}
</script>

<style>
</style>
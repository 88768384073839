import CustomerManagementTableView from "./views/CustomerManagementTableView"

const routes = [
  {
    path: "",
    name: "customerManagement-list-view",
    component: CustomerManagementTableView
  }
]

export default routes
<template>
  <el-form-item
      :prop="fieldPath"
      :rules="validation">
    <el-checkbox
        :indeterminate="isIndeterminate"
        v-bind="props"
        v-on="$listeners">
      <slot />
    </el-checkbox>
  </el-form-item>
</template>

<script>
import elementUIDefaultProps from "./elementUIDefaultProps"

export default {
  props: {
    label: { type: String, default: "" },
    fieldPath: { type: String, default: "" },
    validation: { type: Array, default: () => [] }
  },
  computed: {
    isIndeterminate() {
      const { value } = this.$attrs

      return null === value || "undefined" === `${value}`
    },
    props() {
      return { ...elementUIDefaultProps, ...this.$attrs }
    }
  }
}
</script>

import routes, { apiActions } from "./routes"

const attributes = {
  id: null,
  invitationSent: null,
  lastLogin: null,
  firstName: null,
  lastName: null,
  street: null,
  city: null,
  zip: null,
  country: null,
  email: null,
  dob: null,
  phone: null,
  lifeTimeValue: null,
  status: null
}

const definition = { attributes, routes }

export { apiActions }
export default definition
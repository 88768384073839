import autobind from "auto-bind"
import get from "lodash/get"

class Actions {
  constructor(entity) {
    this.entity = entity
    autobind(this)
  }

  openDialog(options = {}) {
    this.openInvoiceDialog(options)
  }

  openInvoiceDialog(options = {}) {
    const type = "InvoiceDialog"
    const { mode = "view" } = options
    const { id, store } = this.entity

    store.dispatch("core/dialogStack/addDialog", { type, mode, id })
  }

  async createPDF() {
    const { id, store } = this.entity
    const response = await this.entity.Api.createPDF(id)

    const fileLocation = get(response.response.data,"fileLocation")
    let notificationsPayload

    if (fileLocation) {
      notificationsPayload = {
        component: "Toast",
        type: "success",
        title: "Thank you",
        message: `Your Invoice has been successfully created and can be downloaded from here: <a href="${fileLocation}" target="_blank">Download PDF</a>`,
        visible: true,
        decay: 0
      }
    } else {
      notificationsPayload = {
        component: "Toast",
        type: "error",
        title: "Error creating PDF",
        message: `Sorry, your PDF could not be created.`,
        visible: true,
        decay: 3500
      }
    }
    store.dispatch("core/notifications/addToNotifications", notificationsPayload)

  }

  get actionsList() {

    return [
      {
        visible: true,
        label: "View Invoice",
        icon: "el-icon-view",
        onClick: () => this.openInvoiceDialog({ mode: "view" })
      },
      {
        visible: true,
        label: "Create PDF",
        icon: "el-icon-edit",
        onClick: () => this.createPDF()
      },
    ].filter(({ visible }) => visible)
  }
}

export default Actions

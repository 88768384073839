<template>
  <div
      ref="mapIcon"
      class="map-icon-container flex-center"
      :class="`${mapIconType} `"
      @click="onClick">
    <svg
        :fill="iconColor"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 100 125"
        enable-background="new 0 0 100 100"
        xml:space="preserve">
      <g>
        <path
            d="M80,35.017C80,18.438,66.566,5,49.999,5C33.43,5,20,18.438,20,35.017c0,4.909,1.185,9.538,3.272,13.627h-0.009
      l26.736,46.534l26.736-46.534h-0.009C78.813,44.555,80,39.926,80,35.017z M36.375,35.017c0-7.529,6.1-13.632,13.624-13.632
      c7.523,0,13.625,6.103,13.625,13.632c0,7.453-5.974,13.504-13.393,13.627h-0.464C42.35,48.521,36.375,42.47,36.375,35.017z" />
      </g>
    </svg>
    <template v-if="label">
      <div class="label">
        {{ label }}
      </div>
    </template>
  </div>
</template>

<script>

export default {
  props: {
    itemId: { type: [String, Number], default: null },
    label: { type: [String, Number], default: null },
    iconColor: { type: String, default: "#d73d22" }
  },
  data() {
    return {}
  },
  computed: {
    mapIcon() {
      return null
    },
    mapIconType() {
      return null
    }
  },
  methods: {
    onClick(e) {
      this.$emit("click", e)
    }
  }
}
</script>

<style lang="scss" scoped>
.map-icon-container {
  --background-color: transparent;
  background-color: var(--background-color);

  padding: var(--padding-xs);
  height: 50px;
  width: 60px;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  margin-top: -33px;
  margin-left: -29px;

  svg {
    height: 100%;
    width: 100%;
    filter: drop-shadow(4px 4px 3px rgba(0,0,0,0.2));
  }

  .map-icon {
    color: white;
  }

  &:hover {
    transform: scale(1.2);
  }

  .label {
    background: white;
    padding: 0.25em;
    border-radius: 0.5em 0.5em 0.5em 0;
    position: absolute;
    top: -11px;
    right: -60%;
    width: 100%;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.1);
    word-break: break-word;
    text-align: center;
    font-weight: 500;
  }
}
</style>

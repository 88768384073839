<template>
  <div
      ref="scrollContainer"
      class="scrollbar-container flex-auto"
      @click="onClick"
      @mousemove="onScrollContainerMove"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave">
    <div
        ref="scrollZone"
        class="scroll-zone"
        @scroll="onScroll">
      <div
          ref="content"
          class="content">
        <slot />
      </div>
    </div>

    <div
        v-show="scrollbarVisible"
        ref="trackY"
        class="scrollbar-track">
      <div
          id="thumbY"
          ref="thumbY"
          class="scrollbar-thumb"
          :style="thumbStyles" />
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce"

export default {
  props: {},
  data() {
    return {
      scrollbarVisible: false,
      thumbIsDragging: false,
      thumbHeight: 0,
      pos1: 0,
      pos2: 0,
      pos3: 0,
      pos4: 0
    }
  },
  computed: {
    thumbStyles() {
      return {
        "--thumb-height": `${this.thumbHeight}px`
      }
    }
  },

  mounted() {
    this.debouncedGetThumbHeight = debounce(this.getThumbHeight, 100)
    document.addEventListener("mousedown", this.onMouseDown)
    document.addEventListener("mouseup", this.onMouseUp)
    document.addEventListener("mousemove", this.onMouseMove)
  },
  beforeDestroy() {
    document.removeEventListener("mousedown", this.onMouseDown)
    document.removeEventListener("mouseup", this.onMouseUp)
    document.removeEventListener("mousemove", this.onMouseMove)
  },

  methods: {
    onMouseDown(e) {
      const elId = e.target.id
      this.pos3 = e.clientX
      this.pos4 = e.clientY

      if (elId === "thumbY") {
        // e.preventDefault()
        this.thumbIsDragging = "Y"
      }
    },

    onMouseUp(e) {
      // e.preventDefault()

      this.thumbIsDragging = null

      if (!this.$refs.scrollContainer.contains(e.target)) {
        this.scrollbarVisible = false
      }
    },
    onClick() {
      this.$nextTick(() => {
        this.getThumbHeight()
      })
    },

    setScrollTop(thumbYTop) {
      const scrollZone = this.$refs.scrollZone

      scrollZone.scrollTop = thumbYTop * this.getScrollJumpRatio()
    },
    dragThumbY(e) {
      const thumbY = this.$refs.thumbY
      const trackY = this.$refs.trackY
      const thumbYTop = Math.round(thumbY.getClientRects()[0].top)
      const thumbYbottom = Math.round(thumbY.getClientRects()[0].bottom)
      const trackYTop = Math.round(trackY.getClientRects()[0].top)
      const trackYBottom = Math.round(trackY.getClientRects()[0].bottom)

      this.pos2 = this.pos4 - e.clientY
      this.pos4 = e.clientY

      let nextPosition = thumbY.offsetTop - this.pos2
      const isScrolling = this.pos2 !== 0

      if (isScrolling) {
        const scrollingDown = Math.sign(this.pos2) === -1
        const scrollingUp = Math.sign(this.pos2) === 1

        if (scrollingUp && thumbYTop + nextPosition >= trackYTop) {
          thumbY.style.top = nextPosition + "px"
          this.setScrollTop(thumbYTop - trackYTop)
        }

        if (scrollingDown && thumbYbottom <= trackYBottom) {
          thumbY.style.top = nextPosition + "px"
          this.setScrollTop(thumbYTop - trackYTop)
        }
      }
    },

    onMouseMove(e) {
      // e.preventDefault()

      if (this.thumbIsDragging === "Y") {
        this.dragThumbY(e)
      }
    },
    onMouseEnter() {
      this.scrollbarVisible = true
      this.getThumbHeight()
    },
    onMouseLeave() {
      if (!this.thumbIsDragging) {
        this.scrollbarVisible = false
      }
    },
    onScroll(e) {
      if (!this.thumbIsDragging) {
        this.getThumbYPosition(e.target.scrollTop)
        this.getThumbHeight()
      }
    },
    getScrollJumpRatio() {
      const scrollTrackSpace = this.contentHeight - this.containerheight
      const scrollThumbSpace = this.containerheight - this.thumbHeight
      const scrollJumpRatio = scrollTrackSpace / scrollThumbSpace

      return scrollJumpRatio
    },
    getThumbYPosition(scrollTop) {
      const thumbY = this.$refs.thumbY

      thumbY.style.top = scrollTop / this.getScrollJumpRatio() + "px"
    },
    onScrollContainerMove() {
      this.debouncedGetThumbHeight()
    },
    getThumbHeight() {
      setTimeout(() => {
        if (this.$refs.scrollContainer && this.$refs.content) {
          this.contentHeight = this.$refs.content.clientHeight
          this.containerheight = this.$refs.scrollContainer.clientHeight
          const arrowHeight = 0

          const viewableRatio = this.containerheight / this.contentHeight
          const scrollBarArea = this.containerheight - arrowHeight * 2
          this.thumbHeight = scrollBarArea * viewableRatio
        }
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.scrollbar-container {
  position: relative;
  min-height: 1px;
  overflow: hidden;
}

@media not all and (min-resolution:.001dpcm) {
    .scrollbar-container {
      overflow: auto;
    }
}

.scroll-zone {
  overflow: auto;
  height: 100%;
  transition: all 0.2s;
}

.scrollbar-track {
  width: 10px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}

.scrollbar-thumb {
  width: 10px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.3);
  cursor: pointer;
  height: var(--thumb-height);
  position: absolute;
  z-index: 100;

  transition: height 0.2s;
}

::-webkit-scrollbar {
  display: none;
}
</style>

<template>
  <div class="single-alert">
    <h4>{{ alertType }}</h4>
    <ul
        v-infinite-scroll="load"
        class="single-alert-list-wrapper">
      <router-link
          v-for="(item, index) in sortedAlerts"
          :key="`single-alert-${index}`"
          :to="item.link"
          tag="li"
          class="single-alert-container"
          :class="{ unread: item.status === 'unread' }">
        <header>
          <h4>{{ item.title }}</h4>
          <div class="time-stamp">
            <timeago :datetime="item.date" />
          </div>
        </header>
        <p>{{ item.message }}</p>
      </router-link>
    </ul>
  </div>
</template>

<script>
import VueTimeago from "vue-timeago"
import Vue from "vue"
Vue.use(VueTimeago, {
  name: "Timeago",
  locale: "en"
})
export default {
  name: "CoreAlertsAlertBox",
  props: {
    items: {
      type: Array,
      required: true
    },
    alertType: {
      type: String,
      required: true
    }
  },
  computed: {
    sortedAlerts() {
      const alerts = JSON.parse(JSON.stringify(this.items))
      // alerts.sort((a, b) => {
      //   return new Date(b.date) - new Date(a.date)
      // })
      return alerts
    }
  },
  methods: {
    load() {
      // Preparing for infinite scroll
    }
  }
}
</script>

<style lang="scss" scoped>
.single-alert {
  h4 {
    margin: 0.5em 0 0.5em 1em;
  }
  .single-alert-list-wrapper {
    padding: 0 1em 0 1em;

    .single-alert-container {
      background: #fff;
      border-radius: 0.5em;
      padding: 1em;
      margin: 1em 0;
      transition: var(--main-transition);
      box-shadow: var(--box-shadow-container-light);
      cursor: pointer;

      &:hover {
        &.unread {
          background: white;
        }
      }

      &.unread {
        background: var(--main-light-yellow);
      }

      header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        h4 {
          margin: 0.5em;
        }
        .time-stamp {
          padding: 0.5em;
          font-size: 0.8em;
        }
      }
      &:hover {
        box-shadow: var(--box-shadow-container);
      }
    }
  }
}
</style>

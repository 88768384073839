<template>
  <div class="bread-crumbs flex-auto">
    <template v-if="urlParams.path !== '/'">
      <div class="container">
        <ul class="bread-crumbs-list">
          <template v-for="(breadcrumb, index) in breadCrumbs">
            <li
                :key="`link-${index}`"
                class="breadcrumbs"
                :class="{
                  active: index + 1 === itemCount,
                  'first-item': index === 0,
                  'last-item': index === breadCrumbs.length - 1,
                }">
              <div class="flex-column">
                <core-text
                    class="breadcrumb-label"
                    :class="{visible: breadcrumb.metaLabel}"
                    size="sml"
                    semibold
                    label
                    uppercase>
                  {{ breadcrumb.metaLabel }}
                </core-text>

                <div class="breadcrumb-item">
                  <component
                      :is="breadcrumb.component || 'CoreBreadCrumbItem'"
                      :url="breadcrumb.url"
                      :label="breadcrumb.label" />
                  <i class="el-icon-arrow-right" />
                </div>
              </div>
            </li>
          </template>
        </ul>
      </div>
    </template>
  </div>
</template>

<script>
import CoreBreadCrumbItem from "./BreadCrumbItem"
import isArray from "lodash/isArray"

export default {
  name: "CoreBreadCrumbs",
  components: {
    CoreBreadCrumbItem
  },
  filters: {
    deDash(string) {
      return string.replace(/-/g, " ")
    }
  },
  props: {
    showHome: {
      type: Boolean,
      default: true
    },
    homeTitle: {
      type: String,
      default: "Home"
    }
  },
  computed: {
    breadCrumbs() {
      const getBreadcrumb = ({ meta, name, path }) => {
        const breadcrumb = {
          label: name,
          url: params => {
            let pathWithParamsReplaced = path

            Object.entries(params).forEach(([paramKey, paramValue]) => {
              pathWithParamsReplaced = pathWithParamsReplaced.replace(`:${paramKey}`, paramValue)
            })

            return pathWithParamsReplaced
          }
        }

        if (meta.breadcrumb) {
          if (isArray(meta.breadcrumb))
            return meta.breadcrumb.map(metaBreadcrumb => ({
              ...breadcrumb,
              ...metaBreadcrumb
            }))

          return { ...breadcrumb, ...meta.breadcrumb }
        }

        return breadcrumb
      }

      return this.$route.matched
        .filter(({ path, name }) => path && name)
        .map(getBreadcrumb)
        .filter(({ hidden }) => !hidden)
        .flat()
    },
    urlItems() {
      return this.$route.fullPath.split("/")
    },
    urlParams() {
      return this.$route
    },
    itemCount() {
      return this.urlItems.length
    }
  }
}
</script>

<style lang="scss" scoped>
.bread-crumbs {
  padding: 1em 0;
  display: flex;
  align-items: center;

  .container {
    min-height: 28px;
  }

  ul.bread-crumbs-list {
    list-style: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0;

    li.breadcrumbs {
      font-size: 1.1em;
      text-transform: capitalize;
      margin-right: 1em;
      height: 100%;
      display: flex;

      .breadcrumb-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: auto;
      }

      &.active {
        font-weight: bold;
      }

      .breadcrumb-label {
        padding-right: 1.5rem;
      }

      i {
        margin-left: 1em;
        transform: scale(0.8);
      }

      &.last-item i {
        display: none;
      }

      a {
        text-decoration: none;
      }
    }
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 812px) {
  .bread-crumbs {
    ul.bread-crumbs-list {
      padding: 1em;
    }
  }
}
</style>

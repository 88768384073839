const PERMISSION_STRING_EXAMPLE = `{moduleName}:{featureName}:{permissionType||OPTIONAL}`

const environments = {
  staging: "staging",
  prod: "production",
  dev: "development",
  demo: "demo",
  uat: "uat"
}

export { PERMISSION_STRING_EXAMPLE, environments }

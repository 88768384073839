// import store from "../../store"
import parsePermissionString from "./parsePermissionString"

// @NOTE: enabledFeatures example to be replaced by backend driven api.
const enabledFeatures = {
  "ZoneManagement:Module": true
}

const isDisabled = ({ permissionString }) => {
  const { moduleName, featureName } = parsePermissionString(permissionString)

  const featureKey = `${moduleName}:${featureName}`

  if (enabledFeatures.hasOwnProperty(featureKey)) {
    return !enabledFeatures[featureKey]
  }

  return false
}

export default isDisabled

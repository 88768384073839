import ProductManagementTableView from "./views/ProductManagementTableView"

const routes = [
  {
    path: "",
    name: "productManagement-list-view",
    component: ProductManagementTableView
  }
]

export default routes
<template>
  <div
      class="global-search-bar"
      :class="`${isOpen? 'is-open':''}`">
    <div class="flex-auto flex">
      <el-select
          v-model="selectedEntity"
          class="entity-selector"
          placeholder="Search for ..."
          filterable
          size="small"
          @change="onChange">
        <el-option
            v-for="(item, index) in entityTypes"
            :key="`option-${index}-${item.value}`"
            :label="item.label"
            :value="item.value" />
      </el-select>

      <el-tooltip
          :manual="true"
          placement="bottom-end"
          effect="light"
          popper-class="global-search-tooltip"
          :value="isOpen">
        <el-input
            ref="coreGlobalSearchInput"
            v-model="searchQuery"
            :disabled="!selectedEntity"
            size="small"
            clearable
            class="core-search-input"
            suffix-icon="el-icon-search"
            placeholder="Quick Search"
            @focus="onInputFocus" />

        <CoreGlobalSearchDropdown
            v-if="selectedEntity"
            ref="coreGlobalSearchDropdown"
            slot="content"
            :store="$store"
            :selected-entity="selectedEntity"
            :search-query="searchQuery"
            @setSearchQuery="setSearchQuery" 
            @setSelectedEntity="setSelectedEntity" />
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import CoreGlobalSearchDropdown from "./CoreGlobalSearchDropdown"
import Models from "@/Modules/OdysseyModels"
import startCase from "lodash/startCase"
import snakeCase from "lodash/snakeCase"
import pluralize from "pluralize"
import config from "@/config"

export default {
  name: "CoreGlobalSearch",
  components: { CoreGlobalSearchDropdown },
  data() {
    return {
      isOpen: false,
      searchQuery: "",
      selectedEntity: null
    }
  },
  computed: {
    searchableEntities() {
      if (config.appConfig.enabledGlobalSearchModels !== null) {
        return Object.values(Models).filter(model => {
          return (model.globalSearchEnabled && config.appConfig.enabledGlobalSearchModels.includes(model.entity))
        })
      }
      return null
    },
    entityTypes() {
      if (config.appConfig.enabledGlobalSearchModels !== null) {
        return this.searchableEntities.map(({entity}) => ({
          label: pluralize(startCase(entity)),
          value: snakeCase(pluralize.singular(entity))
        }))
      }
      return null
    }
  },

  mounted() {
    if (config.appConfig.enabledGlobalSearchModels !== null) {
      document.addEventListener("click", this.onDocumentClick)

      this.selectedEntity = this.entityTypes[0].value
    }
  },
  beforeDestroy() {
    document.removeEventListener("click", this.onDocumentClick)
  },
  methods: {
    setSearchQuery(newSearchQuery) {
      this.searchQuery = newSearchQuery
    },
    onInputFocus() {
      this.isOpen = true
    },
    setSelectedEntity(nextEntity) {
      this.selectedEntity = nextEntity
    },
    onDocumentClick(e) {
      const { coreGlobalSearchInput, coreGlobalSearchDropdown } = this.$refs
      if (
        coreGlobalSearchInput &&
        coreGlobalSearchDropdown &&
        !coreGlobalSearchInput.$el.contains(e.target) &&
        !coreGlobalSearchDropdown.$el.contains(e.target) &&
        !window.location.href.includes("?d=")
      ) {
        this.isOpen = false
      }
    },
    onChange(value) {
      this.selectedEntity = value

      this.$nextTick(() => {
        const el = this.$refs.coreGlobalSearchInput.$el.getElementsByTagName("input")

        el[0].focus()
      })
    }
  }
}
</script>

<style lang="scss">
.global-search-bar {
  display: flex;
  align-items: center;
  width: 500px;
  margin-right: var(--padding-m);
  transition: all 0.2s;

  &.is-open {
    width: 800px;
  }
}

.entity-selector {
  width: 210px;
  min-width: 150px;

  /deep/ .el-input__inner {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.core-search-input {
  width: 500px;

  /deep/ .el-input__inner {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

.global-search-tooltip {
  padding: 0 !important;

  .popper__arrow {
    border-color: transparent !important;
  }
}
</style>

import ExtendedModel from "@/Modules/Core/utils/extended-vue-orm-model"
import definition, { apiActions } from "./definition"

class Version extends ExtendedModel {
  static entity = "version"
  static definition = definition
  static globalSearchEnabled = false

  static apiConfig = {
    dataKey: "data",
    actions: apiActions
  }
}

export default Version

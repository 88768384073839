import ProductCategoryManagementStatusColumn from "./ProductCategoryManagementStatusColumn"
import ProductCategoryColorColumn from "./ProductCategoryColorColumn"

const defaultColumns = [
    {
      prop: "id",
      label: "ID"
    },
    {
      prop: "title",
      label: "Title"
    },
    {
      prop: "color",
      label: "Color",
      Component: ProductCategoryColorColumn
    },
    {
      prop: "status",
      label: "Status",
      Component: ProductCategoryManagementStatusColumn
    }
  ]

export default defaultColumns
import get from "lodash/get"
import isArray from "lodash/isArray"
import router from "../router"

let interceptorsApplied = false

const handleUrlQuery = query => {
  let newQuery = {}
  const exclusionList = ["d"]

  Object.entries(query).forEach(([key, value]) => {
    if (!exclusionList.includes(key)) {
      if (key.includes(":")) {
        newQuery = { ...newQuery, ...value }
      } else {
        newQuery[key] = value
      }
    }
  })

  return newQuery
}

const applyInterceptors = (axios, store) => {
  if (!interceptorsApplied) {
    interceptorsApplied = true

    const onRequestSuccess = function(config) {
      const token = get(store, "state.auth.token.payload")

      config.headers.Authorization = `Bearer ${token}`

      const { query } = router.currentRoute

      if (config._mergeUrlParams) {
        config.params = { ...config.params, ...handleUrlQuery(query) }
      }

      return config
    }

    const onRequestFailure = function(error) {
      return Promise.reject(error)
    }

    const onResponseSuccess = function(response) {
      return response
    }

    const onResponseFailure = function(error) {
      if (get(error, "response.status") == 401) {
        store.dispatch("auth/signUserOut", { root: true })
      }

      if (error.response && isArray(get(error, "response.data.errors"))) {
        const messages = error.response.data.errors.map(
          err =>
            `<strong>${get(err, "title", "") || get(err, "source.pointer", "")}:</strong> ${
              err.detail
            }`
        )

        const errorPayload = {
          type: "Error fetching data from API",
          error,
          message: [`API error when trying to fetch from ${error.config.url}`, ...messages],
          visible: true
        }

        store.dispatch("core/errors/addToErrors", errorPayload, { root: true })
      }

      return Promise.reject(error)
    }

    axios.interceptors.request.use(onRequestSuccess, onRequestFailure)
    axios.interceptors.response.use(onResponseSuccess, onResponseFailure)
  }
}

export default applyInterceptors

<template>
  <div class="core-screen-container">
    <header class="core-screen__header">
      <div
          v-if="header"
          :class="{ 'card-style-header': card }">
        <div class="header-icon-wrapper">
          <div
              v-if="icon"
              class="icon">
            <component
                :is="icon"
                color="#2c3e50" />
          </div>

          <core-text
              tag="h3"
              class="">
            {{ header }}
          </core-text>
        </div>

        <div class="options flex-auto">
          <CoreTabRouter
              v-if="tabs"
              :tabs="tabs" />
          <template v-if="hasMultipleCreateButtons">
            <core-button
                v-for="(button, index) in createButton"
                :key="`create-button-${index}`"
                class="tab-button"
                type="success"
                @click="button.onCreate()">
              {{ button.label }}
            </core-button>
          </template>
          <template v-else>
            <core-button
                v-if="createButton"
                class="tab-button"
                type="success"
                @click="createButton.onCreate()">
              {{ createButton.label }}
            </core-button>
          </template>        

          <portal-target name="core-screen-buttons" />
        </div>
      </div>

      <slot name="header" />
    </header>

    <main
        class="screen-content"
        :class="{ card }"
        :style="screenContentStyles">
      <slot />
    </main>
  </div>
</template>

<script>
export default {
  name: "CoreScreen",
  props: {
    header: { type: String, default: null },
    tabs: { type: Array, default: () => [] },
    card: { type: Boolean, default: false },
    createButton: { type: [Array, Object], default: null },
    icon: { type: String, default: null },
    fixedHeight: { type: String, default: null }
  },
  computed: {
    hasMultipleCreateButtons() {
      return Array.isArray(this.createButton) && this.createButton.length > 0
    },
    screenContentStyles() {
      return this.fixedHeight ? { height: this.fixedHeight } : {}
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/Modules/Core/assets/css/index.scss";

.core-screen-container {
  padding: 1em 1em;
}

.screen-content {
  height: fit-content;
  padding: var(--padding-m);
  z-index: 2;
  position: relative;
}

.core-screen__header {
  display: flex;
  align-items: center;
}

.core-screen__footer {
  display: flex;
  align-items: center;
  min-height: 4em;
}

.card {
  background: white;
  box-shadow: var(--box-shadow-container);
  border-radius: var(--border-radius-s);
}

/deep/.card-style-header {
  background: var(--main-light-grey);
  // background: rgba(0, 0, 0, 0.1);
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  padding: 0.2em 2em 0.8em 2em;
  box-shadow: var(--box-shadow-container);
  margin-bottom: -1em;
  width: 100%;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  height: 4em;

  & .header-icon-wrapper {
    display: flex;
    // width: 40%;
    padding-right: var(--padding-xl);
  }

  .icon {
    flex: 1;
    height: 2em;
    max-width: 2em;
    margin-right: 1em;

    svg {
      filter: drop-shadow(1px 1px 0px rgba(255, 255, 255, 1));
    }
  }

  h1 {
    flex: 1;
    font-size: 1.2em;
    margin: 0;
    width: fit-content;
  }

  .options {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 60%;
    height: 100%;

    button.tab-button {
      padding: 1.25em 1em 1.25em 1em;
      font-size: 0.9em;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      box-shadow: var(--box-shadow-dropdown-button);
      transition: ease-in-out all 0.3s;
      color: white;

      &:hover:not(:disabled) {
        box-shadow: none;
        transform: translateY(0.6em);
      }

      &:disabled {
        cursor: not-allowed;
      }
    }
  }
}
</style>

import getCookie from "../../../utils/getCookie"
import { UserRole } from "@/Modules/OdysseyModels"
import mergeWith from "lodash/mergeWith"
import merge from "lodash/merge"
import isBoolean from "lodash/isBoolean"
import get from "lodash/get"
import decodeJwt from "jwt-decode"
import { getEnvironment } from "@/Modules/Core/utils/getPermissions/isDisabledForEnvironment"
import config from "@/config/config.js"

const handleToken = (token, isMocked = false) => {
  const decodedJwt = decodeJwt(token)

  let expiryDate = new Date(decodedJwt.exp * 1000)

  if (isMocked) {
    // We can't use the expiry date of our mocked JWT (as this will always be in the past)
    expiryDate = new Date()
    expiryDate.setDate(expiryDate.getDate() + 7)
  }

  // Write Cookie if not already exists
  if (!getCookie("token")) {
    document.cookie = `token=${token}; expires=${expiryDate}; path=/`
  }

  return {
    token: { type: "jwt", payload: token, meta: { expiry: expiryDate } },
    user: decodedJwt
  }
}

const message = {
  success: { success: true, message: "We are logging you in now." },
  failure: { success: false, message: "Your login details are incorrect. Please try again!" },
  noToken: { success: false, message: "no_token" }
}

const state = {
  loggedIn: false,
  user: null,
  token: null
}

const actions = {
  async handleJWT({ rootState, commit }, payload) {
    const { user, token } = handleToken(payload)
    const allPermissions = rootState.settings.featureFlags


    // @TODO: remove this hack once permissions matrix actually comes form backend
    // TEMPORARY HACK TO LET US LOGIN ON STAGING/LOCAL

    const currentEnvironment = getEnvironment()

    const isMock = get(config, `mockEnv.${currentEnvironment}`, false)
    const isHybridMock = get(config, `hybridMockEnv.${currentEnvironment}`, false)

    if (isMock || isHybridMock) {
      await UserRole.api().fetchAll()
    }

    let loginUserRoles

    if (!user.roles) {
      loginUserRoles = ["operator", "superadmin"]
    } else {
      loginUserRoles = user.roles
    }

    if (loginUserRoles && loginUserRoles.length) {

      const userRolePermissions = loginUserRoles.map(loginUserRole => {
        let role = UserRole.query()
            .where(userRole => userRole.name === loginUserRole)
            .get()[0]

        role = role ? role.$toJson() : null

        return get(role, "permissions")
      })

      user.permissions = mergeWith(...userRolePermissions, (objValue, srcValue) => {
        if (isBoolean(objValue) && isBoolean(srcValue)) {
          return objValue || srcValue
        }
      })
      // END TEMPORARY HACK

      const permissionsMatrix = merge(allPermissions, user.permissions)

      user.permissions = permissionsMatrix
    }

    commit("UPDATE_TOKEN", token)
    commit("UPDATE_LOGIN", true)
    commit("UPDATE_USER_DATA", user)

    return { user, token }
  },
  async checkToken({ dispatch }) {
    try {
      const tokenCookie = getCookie("token")

      if (tokenCookie) {
        dispatch("handleJWT", tokenCookie)

        return message.success
      } else {
        return message.noToken
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
      return message.failure
    }
  },
  signUserOut({ commit }) {
    document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`
    commit("SIGN_USER_OUT")

    localStorage.setItem("fromUrl", "/")
  }
}

// mutations
const mutations = {
  SIGN_USER_OUT(state) {
    state.user = null
    state.token = null
    state.loggedIn = false
  },
  UPDATE_USER_DATA(state, payload) {
    state.user = payload
  },
  UPDATE_TOKEN(state, payload) {
    state.token = payload
  },
  UPDATE_LOGIN(state, payload) {
    state.loggedIn = payload
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}

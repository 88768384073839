<template>
  <div class="loading">
    <div class="table flex-column">
      <div class="columns">
        <template
            v-for="(column) in columns">
          <div
              :key="`col-${column.label}`"
              :style="{width: `calc(100% / ${columns.length})`}"
              class="col">
            <div class="inner fit-parent">
              <p
                  class="title ghost-element-loading"
                  :style="{width: `60%`}">
                {{ column.label }}
              </p>
            </div>
          </div>
        </template>
      </div>

      <div class="rows">
        <template
            v-for="(row) in rows">
          <div
              :key="row"
              class="row ">
            <template
                v-for="column in columns">
              <div
                  :key="`cel-${column.label}`"
                  :style="{width: `calc(100% / ${columns.length})`}"
                  class="row-cell">
                <div
                    class="inner ghost-element-loading fit-parent"
                    :style="{width: `70%`}" />
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import getRandomInt from "@/Modules/Core/utils/get-random-int"

export default {
  name: "CoreDataTable",
  props: { columns: { type: Array, default: () => [] } },
  computed: {
    rows() {
      return 9
    }
  },
  methods: {
    randomInt(lower = 35, higher = 90) {
      return getRandomInt(lower, higher)
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-button {
  width: 143px;
}

.table {
  width: 100%;

  .columns {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ebeef5;

    .col {
      padding: 0 1rem;

      .title {
        height: 16px;
        width: 60%;
        margin-bottom: 0.5rem;
      }

      .search {
        height: 26px;
        width: 100%;
        border-radius: var(--border-radius-xxs);
      }
    }
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 63px;
    border-bottom: 1px solid #ebeef5;

    &:nth-child(odd) {
      background-color: rgba(0, 0, 0, 0.02);
    }

    .row-cell {
      padding: 0 1rem;
      height: 16px;
    }
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 40px;

    .element {
      width: 100px;
      height: 22px;
      margin-right: 1rem;
    }
  }
}

.header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 7em;
}
</style>

<template>
  <div class="logo-wrapper">
    <template v-if="config.basePath === 'JKWMemberPortalBase'">
      <img :src="logo">
    </template>
    <template v-else>
      <svg
          xmlns="http://www.w3.org/2000/svg"
          :fill="color"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xml:space="preserve"
          version="1.0"
          style="shape-rendering:geometricPrecision;text-rendering:geometricPrecision;image-rendering:optimizeQuality;"
          viewBox="0 0 10000 12500"
          x="0px"
          y="0px"
          fill-rule="evenodd"
          clip-rule="evenodd"><defs /><g><path
              class="fil0"
              d="M9018 2367c-1650,215 -7761,173 -7634,1908 86,1177 58,1521 0,1880 -57,359 -402,1478 -402,1478 0,0 574,-459 876,-1722 301,-1262 287,-1707 932,-1851 1995,-443 5040,359 6228,-1693z" /><path
                  class="fil0"
                  d="M6951 4060l-3888 330c-581,110 -703,339 -703,904l3286 -172c529,-63 1169,-559 1305,-1062z" /><path
                      class="fil0"
                      d="M2360 5667l3028 -244c-504,1486 -1817,1014 -3286,976l258 -732z" /><path
                          class="fil0"
                          d="M2012 6718l2059 129c1029,65 -1485,740 -2339,689l280 -818z" /></g></svg>
    </template>
  </div>
</template>

<script>
import config from "@/config"
import Logo from "@/Modules/JKWMemberPortalBase/assets/images/logo.svg"

 export default {
  name: "ClientPortalLogo",
   props: {
    color: {
      type: String,
      default: "#FFFFFF"
    }
   },
   data() {
    return {
      logo: Logo
    }
   },
   computed: {
     config() {
       return config.appConfig
     }
   }
}
</script>

<style lang="scss" scoped>
  .logo-wrapper {
    background: var(--main-brand);
    padding: 2em 0.5em 0 0.5em;

    .svg-icon {
      width: 70%;
      height: 100%;
    }

  }
</style>

import autobind from "auto-bind"
import get from "lodash/get"

class Actions {
  constructor(entity) {
    this.entity = entity
    autobind(this)
  }

  openDialog(options = {}) {
    this.openAppointmentNoteDialog(options)
  }

  openPatientDialog(options = {}) {
    const type = "PatientDialog"
    const { mode = "view" } = options
    const { patientId, store } = this.entity

    const id = patientId

    store.dispatch("core/dialogStack/addDialog", { type, mode, id })
  }

  openAppointmentNoteDialog(options = {}) {
    const type = "AppointmentNoteDialog"
    const { mode = "view" } = options
    const { bookingData = null } = options
    const { id, store } = this.entity

    store.dispatch("core/dialogStack/addDialog", { type, mode, id, props: { bookingData: bookingData } })
  }

  async createPDF() {
    const { id, store } = this.entity
    const response = await this.entity.Api.createPDF(id)
    const patientName = `${this.entity.patientFirstName} ${this.entity.patientLastName}`

    const fileLocation = get(response.response.data,"fileLocation")
    let notificationsPayload

    if (fileLocation) {
      notificationsPayload = {
        component: "Toast",
        type: "download",
        title: "Thank you",
        position: "top-right",
        message: `Your PDF has been successfully created and can be downloaded from here: <a href="${fileLocation}" target="_blank">Appointment Notes (${patientName})</a>`,
        visible: true,
        decay: 0
      }
    } else {
      notificationsPayload = {
        component: "Toast",
        type: "error",
        title: "Error creating PDF",
        message: `Sorry, your PDF could not be created.`,
        visible: true,
        decay: 3500
      }
    }
    store.dispatch("core/notifications/addToNotifications", notificationsPayload)

  }

  async createEPCClaim() {
    const { id, store } = this.entity
    const response = await this.entity.Api.createEPCClaim(id)
    const patientName = `${this.entity.patientFirstName} ${this.entity.patientLastName}`

    const fileLocation = get(response.response.data,"fileLocation")
    let notificationsPayload

    if (fileLocation) {
      notificationsPayload = {
        component: "Toast",
        type: "download",
        title: "Thank you",
        message: `Your EPC Claim PDF has been successfully created and can be downloaded from here: <a href="${fileLocation}" target="_blank">Download EPC Claim (${patientName})</a>`,
        visible: true,
        decay: 0
      }
    } else {
      notificationsPayload = {
        component: "Toast",
        type: "error",
        title: "Error creating EPC Claim PDF",
        message: `Sorry, your EPC Claim PDF could not be created.`,
        visible: true,
        decay: 3500
      }
    }
    store.dispatch("core/notifications/addToNotifications", notificationsPayload)

  }


  get actionsList() {
    const self = this.entity

    return [
      {
        visible: true,
        label: "View Appointment Note",
        icon: "el-icon-view",
        onClick: () => this.openAppointmentNoteDialog({ mode: "view" })
      },
      {
        visible: true,
        label: "Edit Appointment Note",
        icon: "el-icon-edit",
        onClick: () => this.openAppointmentNoteDialog({ mode: "edit" })
      },
      {
        visible: true,
        label: "View Patient File",
        icon: "el-icon-view",
        onClick: () => this.openPatientDialog({ mode: "view" })
      },
      {
        visible: true,
        divided: true,
        label: "Download Notes as PDF",
        icon: "el-icon-printer",
        onClick: () => this.createPDF()
      },
      {
        visible: true,
        label: "Download EPC Claim",
        icon: "el-icon-printer",
        onClick: () => this.createEPCClaim()
      },
      // {
      //   visible: true,
      //   label: `${capitalize(statusChangeText)} AppointmentNote`,
      //   icon: isApproved ? "el-icon-close" : "el-icon-check",
      //   onConfirm: () =>
      //       self.Api.update({ id: self.id, status: isApproved ? "deactivated" : "activated" }),
      //   confirmProps: {
      //     title: `Are you sure you want to ${statusChangeText} this AppointmentNote?`,
      //     confirmButtonText: `${capitalize(statusChangeText)} AppointmentNote`
      //   }
      // },
      {
        visible: true,
        divided: true,
        class: "danger",
        label: "Delete",
        icon: "el-icon-delete",
        onConfirm: () => self.Api.destroy(self),
        confirmProps: {
          title: "Are you sure you want to delete this AppointmentNote?",
          confirmButtonText: "Delete AppointmentNote"
        }
      }
    ].filter(({ visible }) => visible)
  }
}

export default Actions

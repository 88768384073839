<template>
  <fragment>
    <slot />
    
    <el-tooltip
        effect="light"
        :value="showTooltip"
        manual>
      <i
          class="el-icon-info hint"
          @click="hintVisible = !hintVisible" />

      <div
          slot="content"
          class="hint-content tooltip">
        <p
            v-html="hintText" />
      </div>
    </el-tooltip>

    <div v-show="showBox">
      <div
          class="hint-content box">
        <p
            v-html="hintText" />
      </div>
    </div>
  </fragment>
</template>

<script>
// @TODO: WIP -- Make this work with core-text
export default {
  name: "CoreDialogStack",
  props: {
    hintText: { type: String, default: null },
    box: { type: Boolean, default: false }
  },
  data() {
    return {
      hintVisible: false
    }
  },
  computed: {
    showTooltip() {
      return this.hintVisible && !this.box
    },
    showBox() {
      return this.hintVisible && this.box
    }
  }
}
</script>


<style lang="scss" scoped>
.hint {
  color: #606266;
  font-size: 14px;
  vertical-align: middle;
  margin-left: var(--margin-xs);

  &:hover {
    color: #409eff;
  }
}

.hint-content {
  padding: 8px 16px;
  background-color: #ecf8ff;
  border-radius: 4px;
  border-left: 5px solid #50bfff;

  p {
    margin: 0;
  }

  &.box {
    width: 100%;
    margin-top: var(--margin-m);
  }

  &.tooltip {
    max-width: 400px;
    min-width: 200px;
  }
}
</style>

<template>
  <div class="import-success">
    <div class="icon">
      <i class="el-icon-check" />
    </div>
    <div class="text">
      <core-text tag="h2">
        Great!
      </core-text>
      <core-text>
        You have imported a total of <strong>{{ successfullyImportedRows }}</strong> {{ entityName }}.
      </core-text>
    </div>
  </div>
</template>

<script>
  export default {
    name: "CoreDataImporterSuccessMessage",
    props: {
      entityName: {
        type: String,
        required: true
      },
      successfullyImportedRows: {
        type: Number,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .import-success {
    box-shadow: var(--box-shadow-container-light);
    border-radius: 1em;
    margin: 2em 10em;
    display: flex;
    align-items: center;
    overflow: hidden;

    .icon {
      flex: 1;
      min-width: 3em;
      font-size: 3em;
      padding: 1em;
      i {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--main-success-green);
        background: var(--main-light-green);
        width: 2em;
        height: 2em;
        border-radius: 50%;
      }
    }
    .text {
      flex: 4;
      font-size: 1.5em;
      h2 {
        display: block;
      }
    }
  }
</style>
import createApp from "./Modules/Core"
// Main Module
import TheStylex from "./Modules/TheStylexBase"
// import Lightwerk from "./Modules/LightwerkBase"
// import Otocare from "./Modules/OtocareBase"
// import JKWMemberPortal from "./Modules/JKWMemberPortalBase"
// import FMS from "./Modules/FMSBase"

// UI Framework Element UI
import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
import locale from "element-ui/lib/locale/lang/en"
import VueFilterDateFormat from "vue-filter-date-format"

const baseAppModules = {
  // Lightwerk: Lightwerk,
  TheStylex: TheStylex
  // Otocare: Otocare,
  // JKWMemberPortal: JKWMemberPortal
  // FMS: FMS
}

const environment = process.env.VUE_APP_BASE_PATH

const AppBase = baseAppModules[environment]

export default createApp({
  Base: AppBase,
  initialState: {useMockData: false},
  addLibraries: Vue => {
    Vue.use(ElementUI, {locale})
    Vue.use(VueFilterDateFormat)
  }
})

<template>
  <div class="tab-status">
    <template v-if="status === 'loading'">
      <el-icon class="el-icon-loading" />
    </template>

    <template v-else>
      <div
          class="status-icon"
          :class="`${tabStatus}`" />
    </template>
  </div>
</template>

<script>
import isFunction from "lodash/isFunction"

export default {
  name: "TabStatus",
  props: { status: { type: [String, Function], default: null } },
  computed: {
    tabStatus() {
      const { status } = this

      return isFunction(status) ? status() : status
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/Modules/Core/assets/css/index.scss";

.tab-status {
  position: absolute;
  top: 0.25em;
  right: 0.25em;

  .status-icon {
    background: var(--color-info-lighter);
    height: 7px;
    width: 7px;
    border-radius: 50%;

    &.pulse {
      animation: pulse infinite;
      animation-duration: 1s;
      --pulse-diameter: 4px;
    }
    &.success {
      background: var(--color-success-lighter);
      --pulse-color: var(--color-success-lighter);
    }
    &.warning {
      background: var(--color-warning-lighter);
      --pulse-color: var(--color-warning-lighter);
    }
    &.danger {
      background: var(--color-danger-lighter);
      --pulse-color: var(--color-danger-lighter);
    }
  }
}
</style>

<template>
  <div class="appointments-container">
    <h3>Upcoming Appointments</h3>
    <AppointmentTemplate
        v-for="(appointment, index) in appointments"
        :key="`appointment-${index}`"
        :appointment="appointment" />
  </div>
</template>

<script>
  import { Appointment } from "@/Modules/OdysseyModels";
  import AppointmentTemplate from "./Appointment"

  export default {
    name: "Appointments",
    components: {
      AppointmentTemplate
    },
    data() {
      return {
        appointments: {}
      }
    },
    async mounted() {
      this.get7Days()
    },
    methods: {
      async get7Days() {
        this.isLoading = true
        const response = await Appointment.api().fetchAll({ params: { period: "next7Days" } })
        this.appointments = response.response.data.data.data
        this.isLoading = false
      }
    }
  }
</script>

<style scoped>

</style>
<template>
  <div class="draft-wrapper">
    <portal
        v-if="history.length"
        to="core-screen-buttons">
      <div class="flex">
        <template v-for="(buttons,key) of buttonGroups">
          <el-button-group
              :key="key"

              class="group">
            <template v-for="button of buttons">
              <transition
                  :key="button.label"
                  name="fade">
                <core-button
                    v-if="!button.isHidden"
                    :key="button.label"
                    class="tab-button"
                    :class="`${button.class}`"
                    :disabled="button.disabled"
                    :type="button.type"
                    @click="button.onClick">
                  {{ button.label }}
                  <i
                      v-if="button.icon"
                      :class="button.icon" />
                </core-button>
              </transition>
            </template>
          </el-button-group>
        </template>
      </div>
    </portal>

    <slot />

    <CoreDraftDialog
        v-if="dialogVisible"
        :on-close="onClose"
        :mode="mode"
        :errors="errors"
        :loading="loading"
        :action="action"
        :model="model" />
  </div>
</template>

<script>
import get from "lodash/get"
import CoreDraftDialog from "./CoreDraftDialog"

export default {
  components: { CoreDraftDialog },
  props: {
    historyEnabled: { type: Boolean, default: false },
    onDraftSave: { type: Function, default: null },
    onDraftDiscard: { type: Function, default: null },
    model: { type: Function, required: true }
  },
  data() {
    return {
      nextRoute: null,
      dialogVisible: false,
      loading: false,
      mode: null,
      errors: []
    }
  },
  computed: {
    buttonGroups() {
      const {
        onDraftSave,
        discardDraft,
        openDialog,
        historyEnabled,
        unsavedData,
        currentStep
      } = this

      const undoDisabledState = !this.model.draft().canUndo(currentStep)
      const redoDisabledState = !this.model.draft().canRedo(currentStep)

      return [
        [
          {
            label: "Discard Changes",
            onClick: () => openDialog("discard"),
            isHidden: !discardDraft || !unsavedData.length,
            class: "discard-draft",
            type: "danger"
          }
        ],
        [
          {
            label: "Save Changes",
            onClick: () => openDialog("save"),
            isHidden: !onDraftSave || !unsavedData.length,
            class: "save-draft",
            type: "success"
          }
        ],
        [
          {
            label: "Undo",
            onClick: this.model.draft().undo,
            isHidden: !historyEnabled,
            class: "history-button undo",
            icon: "el-icon-refresh-left",
            disabled: undoDisabledState,
            type: "abel"
          },
          {
            label: "Redo",
            onClick: this.model.draft().redo,
            isHidden: !historyEnabled,
            class: "history-button",
            icon: "el-icon-refresh-right",
            disabled: redoDisabledState,
            type: "abel"
          }
        ]
      ]
    },
    unsavedData() {
      return this.model
        .query()
        .where("_saved", false)
        .get()
    },
    history() {
      return this.model.store().state.entities[this.model.entity].draft.history
    },
    currentStep() {
      return this.model.store().state.entities[this.model.entity].draft.currentHistoryStep
    }
  },

  methods: {
    onRouteChange(to, from, next) {
      const hasUnsavedData = this.unsavedData.length

      if (hasUnsavedData) {
        this.openDialog("unsavedChanges")
        this.nextRoute = to
      } else {
        next()
      }
    },
    onAlertClose() {
      this.$router.push(this.$route.path)
    },
    onClose() {
      this.dialogVisible = false
      this.mode = null
      this.errors = []
    },
    discardDraft() {
      this.dialogVisible = true
    },
    openDialog(mode) {
      this.dialogVisible = true
      this.mode = mode
    },

    async action(checkedRecords) {
      try {
        const { mode, nextRoute } = this
        this.loading = true

        const { forceDiscard } = checkedRecords

        const shapeRecord = record => {
          const recordJson = record.$toJson()

          if (`${recordJson.id}`.includes("$")) {
            delete recordJson.id
          }

          delete recordJson._saved
          delete recordJson._delete

          return recordJson
        }

        if (mode === "discard" || forceDiscard) {
          this.model.draft().discardChanges(checkedRecords)
        }

        if ((mode === "save" || mode === "unsavedChanges") && !forceDiscard) {
          const { recordsToCreate, recordsToUpdate, recordsToDelete } = checkedRecords

          await this.onDraftSave({
            recordsToCreate: recordsToCreate.map(shapeRecord),
            recordsToUpdate: recordsToUpdate.map(shapeRecord),
            recordsToDelete: recordsToDelete.map(shapeRecord)
          })

          recordsToCreate.map(record => record.$delete())

          this.model.draft().onSaveSuccess(checkedRecords)
        }

        this.loading = false

        if (nextRoute) {
          this.$router.push(nextRoute)
        }

        this.$store.dispatch("core/alerts/toggleUnsavedDataAlert", null)

        this.onClose()
      } catch (error) {
        const errors = get(error, "response.data.errors", [])
        this.errors = errors

        this.loading = false
      }
    }
  }
}
</script>

<style>
</style>

<style lang="scss" scoped>
.group {
  button:first-child {
    margin-left: var(--margin-s);
  }
}

.history-button {
  i {
    font-weight: 700;
    font-size: 1em;
  }
}

.draft-wrapper {
  /deep/ .core-divider {
    padding-top: var(--padding-xs);
    padding-bottom: var(--padding-xs);
  }

  .draft-mode-bar {
    width: 100%;
    padding-top: var(--padding-xs) var(--padding-m);
  }

  .content {
    background-color: white;
  }
}
</style>

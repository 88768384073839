<template>
  <div>
    <core-data-table-v2
        :model="customerModel"
        :columns="columns"
        :searches-and-filters="filters"
        table-name="customerManagement-management"
        @row-click="onRowClick"
        v-on="$listeners" />
  </div>
</template>

<script>
  import { Customer } from "@/Modules/OdysseyModels"
  import columns from "./CustomerTableView/columns"
  import filterConfig from "./CustomerTableView/filterConfig"

  export default {
    name: "CustomerManagementTableView",
    data() {
      return {
        filters: filterConfig
      }
    },
    computed: {
      customerModel() {
        return Customer
      },
      columns() {
        return columns
      }
    },
    methods: {
      onRowClick(row) {
        this.$store.dispatch("PointOfSale/selectCustomer", row, { root: true })
        this.$emit('closeModal')
      }
    }
  }
</script>

<style lang="scss">
  table td {
    cursor: pointer !important;
  }

</style>
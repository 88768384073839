<template>
  <div class="slider-wrapper">
    <el-slider
        v-bind="props"
        v-on="$listeners" />
  </div>
</template>

<script>
import elementUIDefaultProps from "./elementUIDefaultProps"

export default {
  props: {
    fieldPath: { type: String, default: "" },
    validation: { type: Array, default: () => [] }
  },
  computed: {
    props() {
      return { ...elementUIDefaultProps, ...this.$attrs }
    }
  }
}
</script>

<style lang="scss" scoped>
.slider-wrapper {
  padding: 0 15px;
  margin-bottom: var(--margin-m);
}
</style>
<template>
  <div>
    <template v-for="(dialog, index) in dialogStack">
      <component
          :is="dialog.type"
          :id="dialog.id"
          :ref="`d-${dialog.uuid}`"
          :key="index"
          :uuid="dialog.uuid"
          :close-dialog="closeDialog(dialog.uuid)"
          :dialog-props="getDialogProps(dialog)"
          v-bind="dialog.props" />
    </template>
  </div>
</template>

<script>
export default {
  name: "CoreDialogStack",
  data() {
    return {
      isVisible: false
    }
  },
  computed: {
    dialogStack() {
      return this.$store.state.core.dialogStack.stack
    }
  },
  mounted() {
    this.$store.dispatch("core/dialogStack/getDialogsFromUrl")
  },
  methods: {
    getDialogProps(dialog) {
      const { uuid, props = {} } = dialog
      const { closeDialog, changeTab, changeMode } = this

      return {
        ...dialog,
        changeTab: changeTab(uuid, dialog),
        changeMode: changeMode(uuid, dialog),
        closeDialog: closeDialog(uuid, dialog),
        ...props
      }
    },
    closeDialog(uuid) {
      return () => this.$store.dispatch("core/dialogStack/removeDialog", uuid)
    },
    changeTab(uuid, dialog) {
      return newTab => {
        const newUUID = uuid.split("-")

        newUUID[3] = newTab

        this.$store.dispatch("core/dialogStack/updateDialogUrl", {
          oldUUID: uuid,
          payload: { ...dialog, uuid: newUUID.join("-") }
        })
      }
    },
    changeMode(uuid, dialog) {
      return newMode => {
        const newUUID = uuid.split("-")

        newUUID[1] = newMode

        this.$store.dispatch("core/dialogStack/updateDialogUrl", {
          oldUUID: uuid,
          payload: { ...dialog, uuid: newUUID.join("-"), mode: newMode }
        })
      }
    },
    extractId({ type, uuid }) {
      return uuid.replace(`${type}-`, "")
    }
  }
}
</script>

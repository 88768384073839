<template>
  <div class="checkout-wrapper">
    <div class="checkout-title">
      Shopping Cart
      <div
          v-if="selectedCustomer"
          class="selected-customer">
        <div>
          <i class="el-icon-user" /> {{ selectedCustomer.firstName }} {{ selectedCustomer.lastName }}
          <template v-if="selectedCustomer.birthday">
            [{{ selectedCustomer.shortBirthday }}]
          </template>
        </div>

        <div
            class="show-customer-orders"
            @click="openCustomerHistory">
          <i class="el-icon-s-order show-customer-orders" />
          History
        </div>
      </div>
      <div class="checkout-options">
        <el-button
            type="success"
            icon="el-icon-user"
            @click="createCustomer">
          Create Customer
        </el-button>
        <el-button
            type="success"
            icon="el-icon-search"
            @click="selectCustomer">
          <template v-if="selectedCustomer">
            Change Customer
          </template>
          <template v-else>
            Find Customer
          </template>
        </el-button>
      </div>
    </div>

    <div class="checkout-products">
      <transition-group name="list">
        <Product
            v-for="(product, index) in products"
            :key="`product-${index}`"
            :product="product" />
      </transition-group>
    </div>
    <div
        v-if="priceTotal"
        class="checkout-total">
      A$ {{ priceTotal }}
    </div>
    <template v-if="notes">
      <div class="customer-notes">
        <h4>Notes</h4>
        {{ notes }}
      </div>
    </template>
    <transition name="fade">
      <div
          v-if="products.length"
          class="checkout-finalise">
        <el-button
            type="clear"
            @click="clearCheckout()">
          Clear
        </el-button>
        <el-button
            type="success"
            @click="openCheckoutWizard()">
          Checkout &rsaquo;
        </el-button>
      </div>
    </transition>

    <!-- Dialog Box -->
    <el-dialog
        :title="modalTitle"
        :append-to-body="true"
        :visible.sync="showModal"
        width="80%"
        @closeModal="closeModal()">
      <component
          :is="modal"
          :customer="selectedCustomer"
          mode="create-and-select"
          @closeModal="closeModal()" />
    </el-dialog>
  </div>
</template>

<script>
import Product from "./Product"
import SelectCustomer from "../customer/SelectCustomer"
import CheckoutWizard from "./CheckoutWizard"
import ClientForm from "../../../ClientManagement/components/ClientForm"
import CustomerHistory from "./CustomerHistory"
import { Customer } from "@/Modules/OdysseyModels"

export default {
  name: "Checkout",
  components: {
    Product,
    SelectCustomer,
    CheckoutWizard,
    ClientForm,
    CustomerHistory
  },
  data() {
    return {
      showModal: false,
      modal: null,
      modalTitle: null
    }
  },
  computed: {
    notes() {
      return this.$store.state.PointOfSale.notes
    },
    selectedCustomer() {
      return this.$store.state.PointOfSale.selectedCustomer
    },
    products() {
      return this.$store.state.PointOfSale.selectedProducts
    },
    priceTotal() {
      if (this.products.length > 0) {
        let total = 0
        this.products.forEach(product => {
           total = total + (parseFloat(product.price) * product.quantity)
          }
        )
        return total.toFixed(2)
      }
      return null
    }
  },
  methods: {
    openCustomerHistory() {
      this.showModal = true
      this.modal = "customerHistory"
      this.modalTitle = `Customer History for ${this.selectedCustomer.firstName} ${this.selectedCustomer.lastName}`
    },
    openCheckoutWizard() {
      this.showModal = true
      this.modal = "checkoutWizard"
      this.modalTitle = "Checkout"
    },
    clearCheckout() {
      this.$store.dispatch("PointOfSale/clearCheckout", { root: true })
    },
    selectCustomer() {
      this.showModal = true
      this.modal = "selectCustomer"
      this.modalTitle = "Select a customer"
    },
    createCustomer() {
      new Customer().Actions.openCustomerDialog()
    },
    closeModal() {
      this.showModal = false
      this.modal = null
      this.modalTitle = null
    }
  }
}
</script>

<style scoped lang="scss">
.checkout-wrapper {
  box-shadow: 1px 1px 10px rgba(0,0,0,0.2);

  .checkout-title {
    color: white;
    background: var(--main-brand);
    font-size: 1.5em;
    font-weight: 500;
    text-align: center;
    padding: 0.5em 0 0 0;

    .selected-customer {
      background: rgba(0,0,0,0.1);
      font-size: .8em;
      padding: 0.25em 1em;
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .checkout-options {
      background: rgba(0,0,0,0.2);
      padding: 0;
      display: flex;

      .el-button {
        font-family: var(--primary-font);
        flex: 1;
        font-size: 0.7em;
        margin: 0 0.1em;
        padding: 1em 1.25em;
        background: rgba(0,0,0,0.2) !important;
        border: 0 !important;
        border-radius: 0 !important;
        box-shadow: var(--main-box-shadow-long);
      }
    }
  }


  .checkout-products {
    background: white;
    padding: 0 1em;
  }

  .customer-notes {
    h4 {
      margin: 0;
      padding: 0;
    }
    background: lightyellow;
    padding: 10px;
    font-size: 14px;

  }

  .checkout-finalise,
  .checkout-total {
    text-align: right;
    font-size: 1.6em;
    padding: 0.5em 1em;
  }

  .checkout-finalise {
    background: var(--main-brand);
    color: #FFF;
    display: flex;
    padding: 0;
    .el-button {
      border: 0 !important;
      margin: 0 !important;
      flex: 2;
      border-radius: 0 !important;
    }
    .el-button--clear {
      flex: 1;
      background: black !important;
      color: #FFF;
    }
  }
}
</style>
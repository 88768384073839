import ProductCategoryManagement from "./ProductCategoryManagement"
import ProductCategoryManagementIcon from "./components/ProductCategoryManagementIcon"

import dialogs from "./dialogs"
import features from "./features"
import routes from "./routes"

const ProductCategoryManagementModule = {
  moduleGrouping: "Inventory Management",
  name: "ProductCategoryManagement",
  menuName: "Product Categories",
  component: ProductCategoryManagement,
  path: "ProductCategoryManagement",
  icon: ProductCategoryManagementIcon,
  iconTitle: "Do you want to know more about your business?",
  iconDescription: "View ProductCategoryManagement data here",
  enabled: true,
  features,
  dialogs,
  children: routes,
  registerComponents: Vue => {
    Vue.component("ProductCategoryManagement", ProductCategoryManagement)
    Vue.component("ProductCategoryManagementIcon", ProductCategoryManagementIcon)
  }
}
export default ProductCategoryManagementModule

<template>
  <fieldset class="select-options-container">
    <legend>Select Options</legend>
    <template v-if="value.length > 0">
      <el-row
          v-for="(pair, index) in value"
          :key="`pair-${index}`"
          :gutter="10"
          class="selection-options-pair">
        <el-col :span="8">
          <el-form-item label="Label">
            <el-input
                v-model="pair.label"
                type="text"
                placeholder="label"
                :value="pair.label" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Value">
            <el-input
                v-model="pair.value"
                type="text"
                placeholder="value"
                :value="pair.value" />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <br>
          <el-button
              type="danger"
              @click="removeOptionsPair(index)">
            Remove
          </el-button>
        </el-col>
      </el-row>
    </template>
    <el-row
        :gutter="10"
        class="selection-options-pair">
      <el-col :span="8">
        <el-form-item label="label">
          <el-input
              v-model="newSelectLabel"
              type="text"
              placeholder="label"
              value="" />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="value">
          <el-input
              v-model="newSelectValue"
              type="text"
              placeholder="value"
              value="" />
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <br>
        <el-button
            type="primary"
            @click="createNewOptionsPair()">
          Add Me
        </el-button>
      </el-col>
    </el-row>
  </fieldset>
</template>

<script>
export default {
  name: "FormBuilderSelectOptionsPair",
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      newSelectLabel: null,
      newSelectValue: null,
      newValue: []
    }
  },
  methods: {
    removeOptionsPair(index) {
      const newValue = JSON.parse(JSON.stringify(this.value))
      this.$delete(newValue, index)
      this.$emit("input", newValue)
    },
    updateOptionsPair() {
      this.$emit("input", this.value)
    },
    createNewOptionsPair() {
      const newValue = JSON.parse(JSON.stringify(this.value))
      const optionsPair = {
        label: this.newSelectLabel,
        value: this.newSelectValue
      }
      newValue.push(optionsPair)
      this.$emit("input", newValue)
    }
  }
}
</script>

<style lang="scss" scoped>
  fieldset {
    border: 1px solid var(--main-border-color);
    padding: 0 1em 1em 1em;
    background: var(--main-light-grey);
  }
</style>

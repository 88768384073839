import parsePermissionString from "./parsePermissionString"

const isNotPermitted = ({ settings, permissionString }) => {
  const { permissionKey } = parsePermissionString(permissionString)

  const { permissions = {} } = settings

  if (permissions.hasOwnProperty(permissionKey)) {
    return !permissions[permissionKey]
  }

  return false
}

export default isNotPermitted

import routes, { apiActions } from "./routes"

const attributes = {
    id: 1,
    clientId: 1,
    selectedUserId: null,
    locationId: null,
    patientId: null,
    productIds: null,
    date: null,
    startTime: null,
    endTime: null,
    notes: null,
    type: null,
    patientFirstName: null,
    patientLastName: null,
    patientReferringGpId: null,
    locationTitle: null,
    locationStreet: null,
    locationCity: null,
    locationZip: null,
    userFirstName: null,
    userLastName: null,
    userColor: null
}

const definition = { attributes, routes }

export { apiActions }
export default definition
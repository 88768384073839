<template>
  <el-form-item
      :prop="fieldPath"
      :rules="validation">
    <el-select
        v-bind="props"
        v-on="$listeners">
      <el-option
          v-for="item in selectOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          :disabled="item.disabled">
        <span style="float: left">{{ item.label }}</span>

        <span
            v-if="showValue"
            style="float: right; color: #8492a6; font-size: 0.8em">{{
              item.value
            }}</span>
      </el-option>
    </el-select>
  </el-form-item>
</template>

<script>
import elementUIDefaultProps from "./elementUIDefaultProps"
import isFunction from "lodash/isFunction"

export default {
  props: {
    fieldPath: { type: String, default: "" },
    validation: { type: Array, default: () => [] },
    options: { type: [Array, Function], default: () => [] }
  },
  data() {
    return {
      showValue: false
    }
  },
  computed: {
    selectOptions() {
      const { options } = this

      return isFunction(options) ? options(this.$attrs["form-ref"]) : options
    },
    props() {
      return { ...elementUIDefaultProps, ...this.$attrs }
    },
    value() {
      return this.$attrs.value
    }
  },
  watch: {
    options: {
      immediate: true,
      handler() {
        const { value, selectOptions } = this
        const { preSelect } = this.props

        if (preSelect && !value && selectOptions.length) {
          this.$emit("input", selectOptions[0].value)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-select {
  width: 100%;
}
</style>

export default {
  fields: {
    categoryDetails: {
      title: {
        type: "text",
        value: null,
        label: "Product Title",
        placeholder: "ie. Gold Package",
        validation: [{required: true, message: "Please enter a product name", trigger: "blur"}]
      },
      color: {
        type: "color-picker",
        value: null,
        label: "Color",
        placeholder: "Pick a color",
        validation: [{ required: true, message: "Please select a color", trigger: "blur" }]
      },
      status: {
        type: "select",
        filterable: true,
        value: null,
        label: "Status",
        initialValue: "active",
        options: [
          {label: "Active", value: "active"},
          {label: "Inactive", value: "inactive"}
        ]
      }
    }
  }
}

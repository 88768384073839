<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 125"
      enable-background="new 0 0 100 100"
      xml:space="preserve"
      :fill="color"
      class="svg-icon">
    <g>
      <g>
        <path
            fill="none"
            :stroke="color"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="    M90.5,82.5c0,4.418-3.582,8-8,8h-65c-4.418,0-8-3.582-8-8v-65c0-4.418,3.582-8,8-8h65c4.418,0,8,3.582,8,8V82.5z" />
        <line
            fill="none"
            :stroke="color"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="11"
            y1="25.5"
            x2="90"
            y2="25.5" />
        <line
            fill="none"
            :stroke="color"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="19"
            y1="17.5"
            x2="31"
            y2="17.5" />
        <circle
            cx="61.25"
            cy="17.5"
            r="2.436" />
        <circle
            cx="71.25"
            cy="17.5"
            r="2.436" />
        <circle
            cx="81.25"
            cy="17.5"
            r="2.436" />
      </g>
    </g>
    <g>
      <g>
        <g>
          <rect
              x="63.5"
              y="61.5"
              fill="none"
              :stroke="color"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              width="13"
              height="14" />
          <rect
              x="43.5"
              y="61.5"
              fill="none"
              :stroke="color"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              width="13"
              height="14" />
          <rect
              x="23.5"
              y="61.5"
              fill="none"
              :stroke="color"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              width="13"
              height="14" />
        </g>
        <rect
            x="23.5"
            y="41.5"
            fill="none"
            :stroke="color"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            width="53"
            height="12" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "DashboardIcon",
  props: {
    color: {
      type: String,
      default: "#000000"
    }
  }
}
</script>

<style scoped>
.svg-icon {
  width: 100%;
}
</style>

<template>
  <fragment>
    <CoreScreen
        card
        class="customerManagement-wrapper"
        header="Customer Management"
        icon="CustomerManagementIcon"
        :create-button="{ label: 'Create Customer', onCreate: createCustomer }">
      <router-view />
    </CoreScreen>
  </fragment>
</template>

<script>
import { Customer } from "@/Modules/OdysseyModels"
export default {
  name: "CustomerManagement",
  methods: {
    createCustomer() {
      new Customer().Actions.openCustomerDialog()
    }
  }
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('core-dialog',{staticClass:"dialog",attrs:{"dialog-props":{closeDialog:_vm.onClose},"title":_vm.dialogTitle,"append-to-body":true,"visible":true,"width":"800px","before-close":_vm.onClose}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"slot":"content","element-loading-text":_vm.loadingText},slot:"content"},[(!_vm.errors.length)?_c('div',{},[_c('div',{staticClass:"flex justify-between hint-box"},[_c('core-text',{attrs:{"hint":"","semibold":""},domProps:{"innerHTML":_vm._s(_vm.hintText)}}),_c('el-checkbox',{attrs:{"indeterminate":_vm.isIndeterminate},on:{"change":_vm.onCheckAll},model:{value:(_vm.checkAll),callback:function ($$v) {_vm.checkAll=$$v},expression:"checkAll"}},[_vm._v(" "+_vm._s(_vm.modeText)+" all ")])],1),_vm._l((_vm.recordsByType),function(ref){
var records = ref.records;
var type = ref.type;
return _c('div',{key:type,staticClass:"record-container unsaved-data"},[(records.length)?[_c('core-text',{class:type,attrs:{"tag":"h3","bold":""}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(type))+" "+_vm._s(_vm._f("pluralize")(_vm.modelName))+" ")]),_c('div',{staticClass:"records"},[_vm._l((records),function(record){
var _obj;
return [_c('div',{key:record.id,staticClass:"record flex-column",class:( _obj = {}, _obj[type] = true, _obj['is-checked'] = _vm.checkedItems.includes(record.id), _obj )},[_c('div',{staticClass:"flex justify-between"},[_c('core-text',{staticClass:"record-label",attrs:{"bold":""}},[(type !== 'new')?_c('span',[_vm._v("(ID: "+_vm._s(record.id)+")")]):_vm._e(),_vm._v(" "+_vm._s(record.valueList.label)+" ")]),_c('el-checkbox',{attrs:{"value":_vm.checkedItems.includes(record.id)},on:{"change":function($event){return _vm.onCheck(record.id)}}},[_vm._v(" "+_vm._s(_vm.modeText)+" ")])],1),(type==='updated')?_c('div',{staticClass:"attributes-changed-container"},[_c('core-text',{attrs:{"link":""},on:{"click":function($event){return _vm.toggleVisibleAttributes(record)}}},[_vm._v(" "+_vm._s(record.id === _vm.visibleRecordId ? 'hide': 'show')+" changed attributes ")]),_vm._l((_vm.getChangedAttributes(record)),function(ref){
var key = ref.key;
var from = ref.from;
var to = ref.to;
return [(record.id === _vm.visibleRecordId)?_c('div',{key:key,staticClass:"flex-column attribute"},[_c('core-text',{staticClass:"attribute-name",attrs:{"bold":""}},[_vm._v(" "+_vm._s(key)+" ")]),_c('div',{staticClass:"flex-column from-to"},[_c('core-text',{staticClass:"from"},[_c('strong',[_vm._v("from:")]),_vm._v(" "+_vm._s(from)+" ")]),_c('core-text',{staticClass:"to"},[_c('strong',[_vm._v("to:")]),_vm._v(" "+_vm._s(to)+" ")])],1)],1):_vm._e()]})],2):_c('div',{staticClass:"attributes-changed-container"},[_c('core-text',{attrs:{"link":""},on:{"click":function($event){return _vm.toggleVisibleAttributes(record)}}},[_vm._v(" "+_vm._s(record.id === _vm.visibleRecordId ? 'hide': 'show')+" attributes ")]),_vm._l((_vm.getNewlyCreatedAttributes(record)),function(ref){
var key = ref.key;
var value = ref.value;
return [(record.id === _vm.visibleRecordId)?_c('div',{key:key,staticClass:"flex-column attribute"},[_c('core-text',{staticClass:"attribute-name",attrs:{"bold":""}},[_c('strong',[_vm._v(_vm._s(key)+":")]),_vm._v(" "+_vm._s(value)+" ")])],1):_vm._e()]})],2)])]})],2)]:_vm._e()],2)})],2):_c('div',{staticClass:"errors flex-column"},[_c('core-text',{attrs:{"tag":"h3","bold":""}},[_vm._v(" Oops! Something went wrong. ")]),_c('core-text',{attrs:{"tag":"h4","hint":""}},[_vm._v(" Please review the following errors and make changes or report the error accordingly. ")]),_vm._l((_vm.errors),function(error,index){return _c('core-text',{key:index,attrs:{"semibold":""}},[_c('span',{staticClass:"error"},[_vm._v("Error:")]),_vm._v(_vm._s(error.detail)+" ")])})],2),_c('div',{staticClass:"flex justify-end"},[_vm._l((_vm.buttons),function(button){return [(!button.isHidden)?_c('core-button',{key:button.label,attrs:{"type":button.type,"disabled":button.disabled},on:{"click":button.onClick}},[_vm._v(" "+_vm._s(button.label)+" ")]):_vm._e()]})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }
export default {
  fields: {
    personalDetails: {
      firstName: {
        type: "text",
        value: null,
        label: "First Name",
        placeholder: "ie. John",
        validation: [{ required: true, message: "Please enter first name", trigger: "blur" }]
      },
      lastName: {
        type: "text",
        value: null,
        label: "Last Name",
        placeholder: "ie. Doe",
        validation: [{ required: true, message: "Please enter last name", trigger: "blur" }]
      },
      email: {
        type: "email",
        value: null,
        label: "Email Address",
        placeholder: "ie. John.doe@example.org"
      },
      phone: {
        type: "tel",
        value: null,
        label: "Phone Number",
        placeholder: "+61 "
      },
      dob: {
        type: "date",
        value: null,
        label: "Date of Birth",
        placeholder: "01/01/1980",
        initialValue: new Date(),
        props: {
          format: "dd/MM/yyyy"
        }
      },
    },
    addressDetails: {
      city: {
        type: "text",
        value: null,
        label: "City",
        placeholder: "ie. Brisbane",
        validation: [{ required: false, message: "Please enter a valid city", trigger: "blur" }]
      },
      street: {
        type: "text",
        value: null,
        label: "Street",
        placeholder: "ie. Leichhardt St",
        validation: [{ required: false, message: "Please enter a valid street", trigger: "blur" }]
      },
      zip: {
        type: "number",
        value: null,
        label: "Post Code",
        placeholder: "ie. 4000",
        validation: [{ required: false, message: "Please enter a valid post code", trigger: "blur" }]
      }
    }
  }
}

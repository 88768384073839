import ExtendedModel from "@/Modules/Core/utils/extended-vue-orm-model"
import definition, { apiActions } from "./definition"
import Actions from "./actions"
import config from "@/config"

const baseURL = config.apiConfig.lightwerk.api
const endpoint = "/notes"

class Note extends ExtendedModel {
  static entity = "Note"
  static globalSearchEnabled = false
  static definition = definition

  static apiConfig = {
    dataKey: "data.data",
    actions: {
      ...apiActions,
      async fetchByType(type, typeId, options = {}) {
        const url = `${baseURL}${endpoint}/list/${type}/${typeId}`
        return this.get(url, null)
      }
    },
  }

  get Actions() {
    return new Actions(this)
  }

  get createdDateTime() {
    return this.createdAt
    // return moment(this.createdAt).format("DD/MM/YYYY, h:mma")
  }

}
export default Note

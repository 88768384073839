export default {
  businessDetails: {
    facebook: {
      type: "text",
      value: null,
      label: "Facebook Page URL",
      placeholder: "ie. facebook.com/example"
    },
    placeholder1: {
      type: "placeholder"
    },
    twitter: {
      type: "text",
      value: null,
      label: "Twitter Page URL",
      placeholder: "ie. twitter.com/example"
    },
    youtube: {
      type: "text",
      value: null,
      label: "Youtube Page URL",
      placeholder: "ie. youtube.com/example"
    }
  }
}

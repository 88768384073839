<template>  
  <div
      class="color-circle"
      :style="`background-color: ${scope.row.color}`" />
</template>
<script>
  export default {
    name: "ProductCategoryColorColumn",
    props: {
      scope: {
        type: Object,
        required: true
      }
    },
  }
</script>

<style lang="scss" scoped>
  .color-circle{
    margin: auto;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    box-shadow: var(--box-shadow-container);
  }
</style>

<template>
  <div
      v-loading="isLoading"
      class="customer-history-container">
    <div class="lifetime-value">
      <div class="icon">
        <i class="el-icon-money" />
      </div>
      <div class="content">
        Lifetime Value <div class="price">
          A$ {{ parseFloat(orderTotal).toFixed(2) }}
        </div>
      </div>
    </div>
    <template v-if="orders.length > 0">
      <el-timeline>
        <el-timeline-item
            v-for="(order, index) in orders"
            :key="`order-${index}`"
            :timestamp="order.readableCreatedAt"
            placement="top">
          <el-card>
            <h4>Products purchased</h4>
            <div
                v-if="order.products"
                class="product-listing">
              <el-tag
                  v-for="(product, index2) in order.products"
                  :key="`product-${index2}`"
                  type="product">
                <div class="title">
                  {{ product.title }}
                </div>
                <div class="price">
                  A$ {{ parseFloat(product.price).toFixed(2) }}
                </div>
              </el-tag>
            </div>
            <template v-if="order.notes">
              <div class="customer-notes">
                <h4>Customer Notes</h4>
                <p>{{ order.notes }}</p>
              </div>
            </template>
            <div class="lifetime-value order-item">
              <div class="content">
                Order Total:

                <div class="price">
                  A$ {{ parseFloat(order.pricePaid).toFixed(2) }}
                </div>
              </div>
            </div>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </template>
  </div>
</template>

<script>
  import { Order, Product } from "@/Modules/OdysseyModels"
  import moment from 'moment'

  export default {
    name: "CustomerHistory",
    props: {
      customer: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        orders: [],
        products: [],
        totalOrders: 0,
        orderTotal: 0,
        isLoading: false,
      }
    },
    mounted() {
      this.getProducts()
      this.getOrders()
    },
    methods: {
      async getProducts() {
        this.isLoading = true
        const result = await Product.api().fetchAll()
        this.products = result.response.data.data.data
        this.isLoading = false
      },
      async getOrders() {
        this.isLoading = true
        const fetchParams = {
          filter: {
          customer: 'torsten'
        }

        }
        const result = await Order.api().fetchAll({ params: fetchParams})

        const orders = result.response.data.data.data
        this.orders = orders.map(order => {
           order.readableCreatedAt = moment(order.createdAt).format('DD/MM/YYYY hh:mm')

          if(order.productIds && order.productIds.length) {
            const productIdsArray = order.productIds.split(', ')
            order.products = productIdsArray.map(productId => this.products.find(product => productId === product.id))
          }

          return order
        })
        this.totalOrders = result.response.data.data.meta.count


        this.orders.forEach(order => { this.orderTotal = this.orderTotal + parseFloat(order.pricePaid) })
        this.isLoading = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .el-card__body {
    h4 {
      margin: 10px 0;
      font-weight: 500;
    }
  }
  .customer-notes {
    background: lightyellow;
    padding: 10px;
    margin: 10px 0;
  }
  .product-listing {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
  /deep/.el-tag--success {
    background: var(--main-light-green) !important;
    border: 1px solid rgba(31, 193, 126, 0.51) !important;
  }

  /deep/.el-tag--product {
    border: 1px solid #EBEEF5;
    background-color: #FFF;
    color: #303133;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
    display: flex;
    justify-content: space-between;
    padding: 0;
    gap: 0 10px;

    .title {
      flex: 1;
      font-weight: 500;
      padding:0 10px;
    }
    .price {
      padding:0 10px;
      flex: 1;
      background: var(--main-light-grey);
    }
  }
  .order-total {
    margin-top: 30px;
    font-weight: 500;
  }

  /deep/ .el-timeline-item__timestamp {
    font-weight: 500;
    font-size: 15px !important;
    color: #8d25ef !important;
  }

  .lifetime-value {
    background: var(--main-light-green);
    border: 1px solid rgba(31, 193, 126, 0.51);
    border-radius: 3px;
    box-shadow: var(--box-shadow-container);
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    max-width: 400px;

    &.order-item {
      margin-top: 20px;
      margin-bottom: 0;
      max-width: 200px;
      .content {
        padding-left: 10px;
        font-size: 13px;
      }
    }

    .icon {
      flex: 1;
      font-size: 26px;
      color: var(--main-green);
      justify-content: center;
      text-align: center;
    }
    .content {
      flex: 3;
      align-items: center;
      font-size: 17px;
      display: flex;
      justify-content: space-between;

      div {
        font-weight: 500;
        background: rgba(31, 193, 126, 0.51);
        padding: 10px;
        color: white;
      }
    }

  }

</style>
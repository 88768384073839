import TheStylex from "@/Modules/TheStylexBase/config"
// import Lightwerk from "@/Modules/LightwerkBase/config"
// import Otocare from "@/Modules/OtocareBase/config"
// import JKWMemberPortal from "@/Modules/JKWMemberPortalBase/config"
// import FMS from "@/Modules/FMSBase/config"

const exports = {
  TheStylex: TheStylex,
  // Lightwerk: Lightwerk,
  // Otocare: Otocare,
  // JKWMemberPortal: JKWMemberPortal
  // FMS: FMS
}
const environment = process.env.VUE_APP_BASE_PATH

export default exports[environment]

import get from "lodash/get"
import appConfig from "@/config"

const state = () => {
  return {
    notifications: []
  }
}

const actions = {
  sendBrowserNotification({}, payload) {
    const appBase = appConfig.appConfig.base

    const messageBody = {
      body: payload.message,
      icon: require(`@/Modules/${appBase}/assets/images/logo.png`)
    }

    const createNotification = () => {
      let notification = new Notification(payload.title, messageBody)
      notification.onclick = () => {
        window.open(window.location, "_self")
      }
    }

    // Let's check if the browser supports notifications
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification")
    }

    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === "granted") {
      createNotification()
    }
    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(function(permission) {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          createNotification()
        }
      })
    }
    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them any more.
  },
  checkForNotificationType({ state }, payload) {
    if (payload) {
      const foundNotification = state.notifications.find(
          notification => notification.type === payload
      )
      return !!foundNotification
    }
  },
  addToNotifications({ commit, dispatch }, payload) {
    const position = get(payload, "position", "bottom-right")

    const newPayload = {
      component: "Toast",
      type: payload.type,
      title: payload.title,
      message: payload.message,
      timestamp: new Date(),
      visible: true,
      decay: payload.decay,
      position: position,
      payload: get(payload, "payload", "")
    }
    commit("ADD_TO_NOTIFICATIONS", newPayload)
    if (payload.push) {
      dispatch("sendBrowserNotification", payload)
    }
  },
  removeFromNotifications({ commit }, payload) {
    commit("REMOVE_FROM_NOTIFICATIONS", payload)
  },
  removeAllNotifications({ commit }) {
    commit("REMOVE_ALL_NOTIFICATIONS")
  }
}

const mutations = {
  ADD_TO_NOTIFICATIONS(state, payload) {
    state.notifications.push(payload)
  },
  REMOVE_FROM_NOTIFICATIONS(state, payload) {
    state.notifications.splice(state.notifications.indexOf(payload), 1)
  },
  REMOVE_ALL_NOTIFICATIONS(state) {
    state.notifications = []
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}

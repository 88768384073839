<template>
  <el-form-item
      :prop="fieldPath"
      :rules="validation">
    <el-date-picker
        v-bind="props"
        v-on="$listeners" />
  </el-form-item>
</template>

<script>
import elementUIDefaultProps from "./elementUIDefaultProps"

export default {
  props: {
    fieldPath: { type: String, default: "" },
    validation: { type: Array, default: () => [] }
  },
  computed: {
    props() {
      return {
        rangeSeparator: "To",
        startPlaceholder: "Start date",
        endPlaceholder: "End date",
        ...elementUIDefaultProps,
        ...this.$attrs
      }
    }
  }
}
</script>

const arraySort = require("array-sort")

const getVersion = ({ major, minor, patch }) => {
  const tracker = { major: 0, minor: 0, patch: 0 }

  const sortedChanges = arraySort([...major, ...minor, ...patch], "version")
  const lastChangeIndex = sortedChanges.length - 1

  const changes = sortedChanges
    .map(({ type, markdown, ...rest }, index) => {
      tracker[type] += 1

      if (type === "major") {
        tracker.minor = 0
        tracker.patch = 0
      }

      if (type === "minor") {
        tracker.patch = 0
      }

      let version = `v${tracker.major}.${tracker.minor}.${tracker.patch}`

      if (index === lastChangeIndex) {
        version = `${version} (latest)`
      }

      const newMarkdown = markdown.replace("{{version_number}}", version)

      return { ...rest, markdown: newMarkdown, version }
    })
    .reverse()

  let latestVersion = ""

  if (changes[0]) {
    latestVersion = changes[0].version.replace(" (latest)", "")
  }

  return { latestVersion, changes }
}

const getMarkdownFiles = ({ context, type }) => {
  const importAll = r => {
    return r.keys().map(key => {
      const timestamp = key.replace("./", "").replace(".md", "")
      return { type, timestamp, version: key, markdown: r(key).default }
    })
  }

  const markdownFiles = importAll(context)
    .sort()
    .reverse()

  return markdownFiles
}

module.exports = { getVersion, getMarkdownFiles }

const state = () => {
  return {
    users: [],
    editModalData: {},
    showModal: false,
    selectedCustomer: null,
    selectedProducts: [],
    selectedPayment: null,
    checkoutStep: 0,
    checkoutTotal: 0,
    checkoutStatus: "pending",
    notes: null
  }
}

const actions = {
  updateStatus({ commit }, payload) {
    commit("UPDATE_STATUS", payload)
  },
  updateTotal({ commit }, payload) {
    commit("UPDATE_TOTAL", payload)
  },
  selectPayment({ commit }, payload) {
    commit("UPDATE_SELECTED_PAYMENT", payload)
  },
  resetStep({ commit }) {
    commit("UPDATE_CHECKOUT_STEP", 0)
  },
  prevStep({ state, commit }) {
    const prevStep = state.checkoutStep - 1
    commit("UPDATE_CHECKOUT_STEP", prevStep)
  },
  nextStep({ state, commit }) {
    const nextStep = state.checkoutStep + 1
    commit("UPDATE_CHECKOUT_STEP", nextStep)
  },
  clearCheckout({ commit }) {
    commit("UPDATE_SELECTED_PRODUCTS", [])
    commit("UPDATE_CHECKOUT_STEP", 0)
    commit("UPDATE_SELECTED_PAYMENT", null)
    commit("UPDATE_SELECTED_CUSTOMER", null)
    commit("UPDATE_STATUS", "pending")
    commit("UPDATE_NOTES", null)
  },
  selectCustomer({ commit }, payload) {
    commit("UPDATE_SELECTED_CUSTOMER", payload)
  },
  addToCheckout({ commit, state }, payload) {
    const newPayload = JSON.parse(JSON.stringify(payload))
    const newState = JSON.parse(JSON.stringify(state.selectedProducts))
    const foundItem = newState.find(item => item.id === newPayload.id)
    if (foundItem) {
      foundItem.quantity = foundItem.quantity + 1
      commit("UPDATE_SELECTED_PRODUCTS", newState)
    } else {
      newPayload.quantity = 1
      commit("ADD_SELECTED_PRODUCT", newPayload)
    }
  },
  addNotesToCheckout({commit}, payload) {
    commit("UPDATE_NOTES", payload)
  },
  removeFromCheckout({ commit }, payload) {
    commit("REMOVE_SELECTED_PRODUCT", payload)
  },
  updateModal({ commit }, payload) {
    let showModal = false
    showModal = payload.modal
    commit("UPDATE_EDIT_MODAL_DATA", payload.productData)
    commit("UPDATE_SHOW_MODAL", showModal)
  }
}

const mutations = {
  UPDATE_STATUS(state, payload){
    state.checkoutStatus = payload
  },
  UPDATE_TOTAL(state, payload){
    state.checkoutTotal = payload
  },
  UPDATE_SELECTED_PAYMENT(state, payload) {
    state.selectedPayment = payload
  },
  UPDATE_CHECKOUT_STEP(state, payload) {
    state.checkoutStep = payload
  },
  UPDATE_SELECTED_CUSTOMER(state, payload) {
    state.selectedCustomer = payload
  },
  UPDATE_SELECTED_PRODUCTS(state, payload) {
    state.selectedProducts = payload
  },
  UPDATE_NOTES(state, payload) {
    state.notes = payload
  },
  ADD_SELECTED_PRODUCT(state, payload) {
    state.selectedProducts.push(payload)
  },
  REMOVE_SELECTED_PRODUCT(state, payload) {
    state.selectedProducts.splice(state.selectedProducts.indexOf(payload), 1)
  },
  UPDATE_SHOW_MODAL(state, payload) {
    state.showModal = payload
  },
  UPDATE_EDIT_MODAL_DATA(state, payload) {
    state.editModalData = payload
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}

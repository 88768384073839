<template>
  <div
      class="entity-label-container">
    <core-text
        truncate
        style="white-space: nowrap"
        class="entity-label"
        :tooltip-props="coreTextTooltipProps"
        v-html="entityLabel" />

    <div
        v-if="actions.length"
        class="action-dropdown">
      <CoreActionDropdown
          :key="`${record.type}-${record.id}`"
          size="small"
          :actions="actions" />
    </div>
  </div>
</template>

<script>
import isObject from "lodash/isObject"
import get from "lodash/get"
import startCase from "lodash/startCase"
import isString from "lodash/isString"
import pascalCase from "pascalcase"

export default {
  name: "CoreGlobalSearchEntityLabel",
  props: {
    store: { type: Object, required: true },
    meta: { type: Object, required: true },
    model: { type: Function, required: true },
    type: { type: String, required: true },
    record: { type: Object, required: true },
    searchQuery: { type: String, required: true }
  },
  computed: {
    entityRecord() {
      const { record, model } = this

      return model.find(record.id)
    },
    matchingFields() {
      const { searchQuery, record, meta } = this

        const convertSearchScopesToDotNotification = (searchScopes = []) => {
        const searchScopesAsDotNotification = []

        searchScopes.forEach(searchScopeField => {
          if (isString(searchScopeField)) {
            searchScopesAsDotNotification.push(searchScopeField)
          }

          if (isObject(searchScopeField)) {
            Object.entries(searchScopeField).forEach(([key, value]) => {
              const subScopFields = convertSearchScopesToDotNotification(value).map(
                subField => `${pascalCase(key)}.${subField}`
              )

              searchScopesAsDotNotification.push(subScopFields)
            })
          }
        })

        return searchScopesAsDotNotification.flat()
      }

      return convertSearchScopesToDotNotification(meta.search_scope_fields)
        .filter(searchFieldDotNotation => {
          return `${get(record, searchFieldDotNotation, "")}`.toLowerCase().includes(searchQuery)
        })
        .map(field => ({ field, value: get(record, field) }))
    },
    entityLabel() {
      const { entityRecord, matchingFieldsString } = this

      return `<span class="entity">${entityRecord.globalSearchLabel}</span>${matchingFieldsString}`
    },
    coreTextTooltipProps() {
      return { popperClass: "entity-label", effect: "light" }
    },
    matchingFieldsString() {
      let { searchQuery, matchingFields } = this

      if (!matchingFields.length) {
        return ""
      }

      const matchingFieldsString = matchingFields
        .map(({ value, field }) => {
          if (!value) {
            return `<span>${startCase(field)}</span>`
          }

          const startIndex = value.toLowerCase().indexOf(searchQuery)
          const splitValue = value.toLowerCase().split(searchQuery)
          const endIndex = splitValue[splitValue.length - 1]
            ? value.toLowerCase().indexOf(splitValue[splitValue.length - 1])
            : startIndex
          const valueBeforeMatch = value.slice(0, startIndex)
          const valueAfterMatch = value.slice(endIndex)
          const matchedValued = value.slice(startIndex, endIndex)

          let valueWithMatchHighlighted = `<span>${valueBeforeMatch}</span>`
          valueWithMatchHighlighted += `<span class="matched-sub-string">${matchedValued ||
            valueAfterMatch}</span>`

          if (matchedValued) {
            valueWithMatchHighlighted += `<span>${valueAfterMatch}</span>`
          }

          return `<span>${startCase(field)}="</span>${valueWithMatchHighlighted}`
        })
        .join("<span>, </span>")

      return `<span>Found: </span>${matchingFieldsString}<span></span>`
    },
    actions() {
      return this.entityRecord.actions || []
    }
  },
  methods: {
    defaultMainAction() {
      const { type: entityType, record } = this
      const type = `open${pascalCase(entityType)}Dialog`

      this.store.dispatch("core/dialogStack/addDialog", {
        uuid: `${type}-view-${record.id}`,
        type
      })
    },
    mainAction() {

      if (this.entityRecord.globalSearchMainAction) {
        return this.entityRecord.globalSearchMainAction()
      }

      return this.defaultMainAction()
    }
  }
}
</script>

<style lang="scss">
.entity-label-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 var(--padding-m);
  min-height: 2rem;

  &:hover {
    background: var(--main-light-grey);
  }

  .action-dropdown {
    margin-left: auto;
  }
}

.entity-label {
  & .entity {
    padding-right: var(--padding-s);
    font-weight: 500;
    text-transform: capitalize;
  }

  span:not(.entity) {
    opacity: 0.5;
    font-size: 0.9em;
  }

  .matched-sub-string {
    background: yellow;
    font-weight: bold;
  }
}
</style>

<template>
  <div class="core-map-zoom-container">
    <core-text
        label
        tag="div"
        bold>
      <span
          class="el-icon-zoom-out"
          @click="zoom = zoom -1" />
    </core-text>
    <el-slider
        v-model="zoom"
        :format-tooltip="formatTooltip"
        :min="zoomBoundaries.min"
        :max="zoomBoundaries.max" />
    <core-text
        label
        tag="div"
        bold>
      <span
          class="el-icon-zoom-in"
          @click="zoom = zoom +1" />
    </core-text>
    <template v-if="lockable">
      <core-text
          label
          tag="div"
          bold>
        <template v-if="!locked">
          <span
              class="el-icon-unlock unlocked"
              @click="lockZoom(true)" />
        </template>
        <template v-else>
          <span
              class="el-icon-lock locked"
              @click="lockZoom(false)" />
        </template>
      </core-text>
    </template>
  </div>
</template>

<script>
export default {
  name: "CoreMapZoomSlider",
  props: {
    value: {
      type: Number,
      default: 1
    },
    lockable: {
      type: Boolean,
      default: false
    },
    zoomBoundaries: {
      type: Object,
      default: () => {
        return {
          min: 1,
          max: 10
        }
      }
    }
  },
  data() {
    return {
      locked: false
    }
  },
  computed: {
    zoom: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      }
    }
  },
  methods: {
    lockZoom(mode) {
      this.locked = mode
      this.$emit("locked", mode)
    },
    formatTooltip(val) {
      return `Zoom: ${val}X`
    }
  }
}
</script>

<style lang="scss" scoped>
.core-map-zoom-container {
  display: flex;
  border: 1px solid var(--main-border-color);
  padding: 0 1em;
  box-shadow: var(--box-shadow-container);

  .label {
    flex: 1;
    text-align: center;
    cursor: pointer;
    transition: var(--main-transition);
    font-size: 1.1em;

    &:hover {
      color: var(--main-primary);
      transform: scale(1.2);
    }
  }
  .el-slider {
    flex: 6;
  }

  span {
    &.locked {
      color: var(--main-red);
    }
    &.unlocked {
      color: var(--main-green)
    }
  }
}
</style>

<template>
  <fragment>
    <CoreScreen
        card
        class="orderManagement-wrapper"
        header="OrderManagement"
        icon="OrderManagementIcon">
      <router-view />
    </CoreScreen>
  </fragment>
</template>

<script>
export default {
  name: "OrderManagement"
}
</script>

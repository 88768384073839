<template>
  <div class="map-component">
    <GmapMap
        :center="{lat:centerPoint.lat, lng:centerPoint.lng}"
        :zoom="11"
        :options="mapOptions"
        style="width: 100%; height: 800px">
      <GmapInfoWindow
          :options="infoOptions"
          :position="infoWindowPos"
          :opened="infoWinOpen"
          @closeclick="infoWinOpen=false" />
      <GmapCluster>
        <template v-for="(location, index) in locations">
          <GmapMarker
              v-if="location.coordinates"
              :key="index"
              :position="location.coordinates"
              @click="toggleInfoWindow(location,index)" />
        </template>
      </GmapCluster>
    </GmapMap>
  </div>
</template>

<script>
  import Vue from 'vue'
  import * as VueGoogleMaps from 'vue2-google-maps'
  import GmapCluster from "vue2-google-maps/dist/components/cluster"
  import {gmapApi} from 'vue2-google-maps'

  Vue.use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyAh0Tj-cOFdOCaBWJgcdfB7Z4sZFQBV5mQ',
      libraries: 'places', // This is required if you use the Autocomplete plugin
    }
  })

  export default {
    name: "MapComponent",
    components: {
      GmapCluster
    },
    props: {
      locations: {
        type: [Array, Object],
        required: true
      }
    },
    data() {
      return {
        infoOptions: {
          content: '',
          //optional: offset infowindow so it visually sits nicely on top of our marker
          pixelOffset: {
            width: 0,
            height: -35
          }
        },
        infoWindowPos: null,
        infoWinOpen: false,
        currentMidx: null,
        markerData: {},
        infoMarkerData: [],
        series: [],
        markerPosition: null,
        loading: false,
        dragging: false,
        mounted: false,
        selectedPlace: {
          name: "",
          value: ""
        },
        options: [],
        center: {
          lat: -27.4698,
          lng: 153.0251
        },
        mapOptions: {
          fullscreenControl: false,
          mapTypeControl: false,
          streetViewControl: false,
          clickableIcons: false,
          styles: [
            {
              featureType: "poi",
              stylers: [{ visibility: "off" }]
            }
          ]
        }
      }
    },
    computed: {
      google: gmapApi,
      centerPoint() {
        if (this.locations.length &&
            this.locations[0].coordinates &&
            this.locations[0].coordinates.lat &&
            this.locations[0].coordinates.lng) {
          return {
            lat: this.locations[0].coordinates.lat,
            lng: this.locations[0].coordinates.lng,
          }
        }
        return {
          lat: this.center.lat,
          lng: this.center.lng,
        }
      }
    },
    mounted() {
    },
    methods: {
      toggleInfoWindow: function(marker, idx) {
        this.infoWindowPos = marker.coordinates;
        this.infoOptions.content = marker.infoText;

        //check if its the same marker that was selected if yes toggle
        if (this.currentMidx == idx) {
          this.infoWinOpen = !this.infoWinOpen;
        }
        //if different marker set infowindow to open and reset current marker index
        else {
          this.infoWinOpen = true;
          this.currentMidx = idx;

        }
      },
      handleMarkerClick(event) {
        return event
      }
    }
  }
</script>

<style scoped>
  h3,
  h2 {
    margin: 0 2.5em;
  }
  time {
    padding: 0.5em 0;
    display: block;
  }
  .google-map-container {
    width: 100%;
    min-height: 50vh;
    margin-bottom: 2.5em;
  }
  .tool-tip {
    padding: 8px 16px;
    background-color: #ecf8ff;
    border-radius: 4px;
    border-left: 5px solid #50bfff;
    margin: 1em;
  }
</style>
import UserStatusColumn from "./UserStatusColumn"
import UserColorColumn from "./UserColorColumn"

const defaultColumns = [
  {
    prop: "id",
    label: "ID"
  },
  {
    prop: "firstName",
    label: "First Name"
  },
  {
    prop: "lastName",
    label: "Last Name"
  },

  {
    prop: "email",
    label: "Email"
  },
  {
    prop: "color",
    label: "Color",
    Component: UserColorColumn
  },
  {
    prop: "active",
    label: "Status",
    Component: UserStatusColumn
  }
]

export default defaultColumns
<template>
  <div
      class="core-divider"
      :class="{'no-padding':noPadding}">
    <div class="divider flex-auto" />
    <core-text
        class="divider-text"
        capitalize
        semibold
        label
        :class="{hasText}">
      <slot />
    </core-text> 
    <div class="divider flex-auto" />
  </div>
</template>

<script>
import get from "lodash/get"

export default {
  name: "Divider",
  props: { noPadding: { type: Boolean, default: false } },
  computed: {
    hasText() {
      return get(this.$slots, "default", []).length
    }
  }
}
</script>

<style scoped lang="scss">
.core-divider {
  padding: 1rem 0;
  width: 100%;
  display: flex;
  align-items: center;

  &.no-padding {
    padding: 0;
  }
}

.hasText.divider-text {
  padding: 0 var(--padding-s);
}

.divider {
  background: rgba(0,0,0,0.075);
  height: 1px;
}
</style>

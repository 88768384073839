<template>
  <CoreDialog
      :title="title"
      :loading="isLoading"
      width="80%"
      :dialog-props="dialogProps">
    <template slot="content">
      <AppointmentManagementForm
          :dialog-props="dialogProps"
          :appointment-data="appointmentData" />
    </template>
  </CoreDialog>
</template>

<script>
  import AppointmentManagementForm from "../../forms/AppointmentManagementForm/AppointmentManagementForm"
  import { Appointment } from "@/Modules/OdysseyModels"
  import startCase from "lodash/startCase"

  export default {
    name: "AppointmentManagementDialog",
    components: {
      AppointmentManagementForm
    },
    props: {
      id: { type: [String, Number], default: null },
      closeDialog: { type: Function, required: true },
      dialogProps: { type: Object, required: true },
    },
    data() {
      return {
        isLoading: true
      }
    },
    computed: {
      appointmentData() {
        return Appointment.find(this.id)
      },
      mode() {
        return this.dialogProps.mode
      },
      title() {
        if (this.appointmentData) {
          let suffix = ''

          if (this.mode === 'edit') {
            suffix = ` (ID: ${this.id})`
          }
          return `${startCase(this.mode)}ing Appointment ${suffix}`
        }

        return "Create New Appointment"
      }
    },
    mounted() {
      if (this.id) {
        this.fetchAppointment()
      } else {
        this.isLoading = false
      }
    },
    methods: {
      async fetchAppointment() {
        this.isLoading = true

        await Appointment.api().fetchById(this.id)

        this.isLoading = false
      }
    }
  }
</script>
import VuexORM from "@vuex-orm/core"
import VuexORMAxios from "@vuex-orm/plugin-axios"
import Qs from "qs"
import isArray from "lodash/isArray"
import get from "lodash/get"

VuexORM.use(VuexORMAxios, {
  paramsSerializer: function(p) {
    return Qs.stringify(p, { encode: false })
  },
  dataTransformer(response) {
    let data = get(response.data, this.dataKey)

    const addSavedAttribute = record => {
      record._saved = true

      return record
    }

    if (!isArray(data)) {
      if (data.id) {
        data = addSavedAttribute(data)
      } else {
        data = data

        Object.entries(data).forEach(([key, value]) => {
          if (isArray(value)) {
            data[key] = value.map(addSavedAttribute)
          }
        })
      }
    } else {
      data = data.map(addSavedAttribute)
    }

    return data
  }
})

export default VuexORM

import createDefaultCRUD from "@/Modules/Core/utils/miragejs/createDefaultCRUD"
import config from "@/config"

const baseURL = config.apiConfig.lightwerk.api

const token =
    "eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJicmlkai5zdGFnaW5nLmJyaWRqLmNvbSIsImV4cCI6MTY2MzIwNjM3NCwidXNlcl9pZCI6MTE1LCJmaXJzdF9uYW1lIjoiVG9yc3RlbiIsImxhc3RfbmFtZSI6IlNjaG1pZHQifQ.axJYaV-xIcUnP3mmoTuC_vALJZ-ZaWjBMhTX5VusY7g"

const endpoint = "/users"

const defaults = createDefaultCRUD({
  baseURL,
  endpoint,
  endpoints: {
    changePassword: "/users/change_password",
    resetPassword: "/users/reset_password",
    forgotPassword: "/users/forgot_password",
    login: "/login/createApiLogin",
    auth0Login: "/auth0_login"
  }
})
const { endpoints } = defaults

const apiActions = {
  ...defaults.apiActions,
  resetPassword(options = {}) {
    return this.patch(endpoints.resetPassword(), options)
  },
  forgotUserPassword(user = {}) {
    return this.post(endpoints.forgotPassword(), { email: user.email })
  },
  changeUserPassword(user = {}) {
    return this.patch(endpoints.changePassword(), {
      id: user.id,
      password: user.password,
      password_confirmation: user.password
    })
  },
  async login(payload) {
    const { response, model } = await this.post(endpoints.login(), payload, { save: false })

    const token = response.data.data.token

    model.store().dispatch("auth/handleJWT", token, { root: true })
  },
  async auth0Login(payload) {
    const { response, model } = await this.post(endpoints.auth0Login(), payload, { save: false })

    const token = response.data.data.token

    model.store().dispatch("auth/handleJWT", token, { root: true })
  }
}

const routes = server => {
  defaults.routes(server)

  server.patch(endpoints.resetPassword(), function() {
    return {}
  })

  server.post(endpoints.auth0Login(), function() {
    return { data: { token } }
  })

  server.post(endpoints.forgotPassword(), function() {
    return {}
  })

  server.post(endpoints.login(), function() {
    return { data: { token } }
  })

  server.patch(endpoints.changePassword(), function(schema, request) {
    return {
      data: { password: `random password generated user${request.body.user.id}` }
    }
  })

  const passthroughEndpoints = [
    defaults.endpoints.base(),
    defaults.endpoints.baseWithId(),
    endpoints.resetPassword(),
    endpoints.forgotPassword(),
    endpoints.changePassword(),
    endpoints.auth0Login(),
    endpoints.login()
  ]

  return passthroughEndpoints
}

export { apiActions }
export default routes

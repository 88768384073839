import createDefaultCRUD from "@/Modules/Core/utils/miragejs/createDefaultCRUD"
import config from "@/config"

const baseURL = config.apiConfig.lightwerk.api
const endpoint = "/sites"

const defaults = createDefaultCRUD({
  baseURL,
  endpoint
})

const apiActions = {
  ...defaults.apiActions
}

const routes = server => {
  defaults.routes(server)

  server.get(defaults.endpoints.base(), function(schema, request) {
    const siteSearch = get(request, 'queryParams["search"]')
    const status = get(request, 'queryParams["filter["approved"]"]')

    const applySearchCriteria = d => {
      let failingCriteria = []
      const site = schema.sites.find(d.id)

      const matchesSiteSearch = () => {
        const fullName = `${site.first_name} ${site.last_name}`
        const fullNameReversed = `${site.last_name} ${site.first_name}`
        const fieldsToMatch = [fullName, fullNameReversed, site.username, site.email]

        return fieldsToMatch.find(string => string.toLowerCase().includes(siteSearch))
      }

      if (siteSearch && !matchesSiteSearch()) {
        failingCriteria.push(false)
      }

      if (status && d.status !== status) {
        failingCriteria.push(false)
      }

      return !failingCriteria.length
    }
    const data = schema.sites.where(applySearchCriteria)

    return data
  })
}

export { apiActions }
export default routes

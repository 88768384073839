import isArray from "lodash/isArray"

const paginate = ({ page, per_page, data }) => {
  const response = {}

  if (!isArray(data)) {
    response.data = data

    return response
  }

  response.meta = { count: per_page, total: data.length }

  if (per_page && data.length > per_page) {
    const from = per_page * page - per_page
    const to = per_page * page

    response.data = data.slice(from, to)
  } else {
    response.data = data
  }

  return response
}

export default paginate

<template>
  <div class="check-cart">
    <div class="checkout-products">
      <transition-group name="list">
        <Product
            v-for="(product, index) in products"
            :key="`product-${index}`"
            :product="product" />
      </transition-group>
      <div class="price-total">
        <div class="title">
          Total
        </div>
        <div class="price">
          AUD {{ priceTotal }}
        </div>
      </div>
    </div>
    <StepOptions next-title="Select Payment" />
  </div>
</template>

<script>
import Product from "@/Modules/PointOfSale/components/checkout/Product"
import StepOptions from "./StepOptions"

export default {
  name: "CheckCart",
  components: {
    Product,
    StepOptions
  },
  computed: {
    products() {
      return this.$store.state.PointOfSale.selectedProducts
    },
    priceTotal() {
      if (this.products.length > 0) {
        let total = 0
        this.products.forEach(product => {
          total = total + (parseFloat(product.price) * product.quantity)
        })
        return total.toFixed(2)
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
.check-cart {
  .checkout-products {
    background: white;
    padding: 1em;
    box-shadow: var(--box-shadow-container);

    .price-total {
      display: flex;
      justify-content: space-between;
      font-size: 1.2em;
      background: var(--main-light-grey);
      padding: 1em;
      font-weight: bold;
      margin: 1em -1em;
      box-shadow: var(--box-shadow-container-light);
      color: var(--main-success-green);

      .title {
        flex: 1;
      }

      .price {
        flex: 1;
        text-align: right;
      }
    }
  }
}
</style>
<template>
  <div
      class="form-builder-wrapper flex-column"
      :class="{compact, tabbed}">
    <component
        :is="config.form.Component"
        :ref="formName"
        v-loading="isLoading"
        :model="formData"
        class="form-builder-form flex-column">
      <slot name="before" />

      <template v-if="tabbed">
        <el-tabs v-model="activeTab">
          <template v-for="(tab, index) of tabs">
            <el-tab-pane
                :key="tab.label"
                :disabled="tab.disabled"
                :name="tab.label">
              <div
                  slot="label"
                  class="flex"
                  :class="{disabled:tab.disabled}">
                <div
                    v-if="getTabInvalidFieldCount(index)"
                    class="tab-invalid-field-count">
                  <span>*</span>
                </div>
                <div>{{ tab.label }}</div>
              </div>

              <template v-for="(fieldGroup, fieldGroupKey) in config.fields">
                <div
                    v-if="isVisible(fieldGroup,index)"
                    :key="`formgroup-${fieldGroupKey}`"
                    class="form-group-container tabs">
                  <FormGroupHeader
                      :field-group="fieldGroup"
                      :label="fieldGroupKey"
                      :compact="compact" />

                  <FieldGroup
                      v-model="formData[fieldGroupKey]"
                      :field-group="fieldGroup"
                      :form="form"
                      :field-group-key="fieldGroupKey"
                      :is-loading="isLoading"
                      :mode="mode"
                      :compact="compact" />
                </div>
              </template>
            </el-tab-pane>
          </template>
        </el-tabs>
      </template>

      <template v-else>
        <template v-for="(fieldGroup, fieldGroupKey) in config.fields">
          <div
              v-if="isVisible(fieldGroup)"
              :key="`formgroup-${fieldGroupKey}`"
              class="form-group-container">
            <FormGroupHeader
                :field-group="fieldGroup"
                :label="fieldGroupKey"
                :compact="compact" />

            <FieldGroup
                v-model="formData[fieldGroupKey]"
                :field-group="fieldGroup"
                :form="form"
                :field-group-key="fieldGroupKey"
                :is-loading="isLoading"
                :mode="mode"
                :compact="compact" />
          </div>
        </template>
      </template>

      <slot name="after" />

      <component
          :is="config.form.ButtonBar"
          :is-submitting="isSubmitting"
          :form-updated="formUpdated"
          class="form-builder-button-bar"
          v-bind="getButtonBarProps()"
          :compact="compact"
          v-on="$listeners" />
    </component>
  </div>
</template>

<script>
import get from "lodash/get"
import isFunction from "lodash/isFunction"
import set from "lodash/set"
import merge from "lodash/merge"
import FormGroupHeader from "./FieldGroupHeader"
import FieldGroup from "./FieldGroup"
import ButtonBar from "./ui/ButtonBar"
import getPermissions from "@/Modules/Core/utils/getPermissions"

export default {
  name: "CoreFormBuilder",
  components: {
    FormGroupHeader,
    FieldGroup
  },
  props: {
    data: { type: Object, default: null },
    formConfig: { type: Object, required: true },
    formMeta: { type: Object, default: () => ({}) },
    isLoading: { type: Boolean, default: false },
    compact: { type: Boolean, default: false },
    mode: { type: String, default: "edit" }
  },
  data() {
    return {
      activeTab: null,
      configMerged: false,
      initialized: false,
      isSubmitting: false,
      formUpdated: false,
      formError: false,
      formData: {},
      test: {},
      config: {},
      invalidTabs: []
    }
  },
  computed: {
    tabbed() {
      return get(this, "config.tabs", []).length
    },
    tabs() {
      return get(this, "config.tabs", [])
        .map(({ permissions, featureFlagged, isVisible, ...rest }) => {
          let hidden = permissions && featureFlagged ? !getPermissions(permissions) : false

          if (isVisible) {
            hidden = !isVisible(this)
          }

          return {
            ...rest,
            permissions,
            hidden,
            disabled: permissions ? !getPermissions(permissions) : false
          }
        })
        .filter(({ hidden }) => !hidden)
    },
    form() {
      return this
    },
    formName() {
      return this.config.form.name || "form"
    },
    fieldGroups() {
      return this.config.fields
    },
    isBulkEditMode() {
      return this.mode === "bulkedit"
    }
  },
  watch: {
    isLoading: {
      handler(newValue, oldValue) {
        if (newValue && !oldValue) {
          this.isSubmitting = false
        }
      },
      immediate: true
    },
    config: {
      handler() {
        if (!this.configMerged) {
          this.configMerged = true
        }
      },
      deep: true
    },
    formData: {
      handler() {
        if (this.initialized && this.configMerged) {
          this.$emit("onChange", this)
          this.formUpdated = true
        } else {
          this.initialized = true
        }
      },
      deep: true
    }
  },
  created() {
    const defaultFormConfig = {
      form: { Component: "el-form", ButtonBar },
      fields: {}
    }

    this.config = merge(defaultFormConfig, this.formConfig)

    if (this.config.tabs) {
      this.activeTab = get(this.config, "tabs[0]", {}).label
    }

    this.mapData()
  },
  methods: {
    setFormUpdatedState(formIsUpdated) {
      this.formUpdated = formIsUpdated
    },
    getTabInvalidFieldCount(tabIndex) {
      return this.invalidTabs.filter(fieldTabIndex => fieldTabIndex === tabIndex).length
    },
    getButtonBarProps() {
      const props = {}
      const handlers = ["onDelete", "onCancel", "onSubmit", "onReset"]

      handlers.forEach(handler => {
        if (this.$listeners[handler]) {
          props[handler] = this[handler]
        }
      })

      return props
    },
    getFormItemProps(props = {}) {
      const { compact } = this

      const defaults = {
        size: compact ? "mini" : "small"
      }

      if (isFunction(props)) {
        return { ...defaults, ...props(this) }
      } else {
        return { ...defaults, ...props }
      }
    },
    getFlatData() {
      const flatFormData = {}
      const blacklistFieldNames = ["placeholder", "_options"]

      const shouldSetValue = ({ fieldName, fieldConfig }) => {
        if (blacklistFieldNames.includes(fieldName)) {
          return false
        }

        if (this.isBulkEditMode && !fieldConfig.bulkEnabled) {
          return false
        }

        return true
      }

      Object.keys(this.formData).forEach(formGroup => {
        Object.keys(this.formData[formGroup]).forEach(fieldName => {
          const fieldConfig = get(this.formConfig.fields, `${formGroup}.${fieldName}`)
          if (shouldSetValue({ formGroup, fieldName, fieldConfig })) {
            let value = get(this.formData, `${formGroup}.${fieldName}.value`, null)

            if (value === null || `${value}` === "undefined") {
              value = fieldConfig.defaultvalue
            }

            set(flatFormData, fieldName.replace("/", "."), value)
          }
        })
      })

      return flatFormData
    },

    isVisible(fieldGroup, tabIndex) {
      const { isBulkEditMode, checkGroupDependency } = this

      if (isBulkEditMode) {
        return Object.values(fieldGroup).filter(field => field.bulkEnabled).length
      }

      if (get(fieldGroup, "_options.tab") !== tabIndex) {
        return false
      }

      return checkGroupDependency(fieldGroup._options)
    },
    checkGroupDependency(formGroupOptions = {}) {
      const { dependency } = formGroupOptions

      if (dependency) {
        const fieldPath = `${dependency.group}.${dependency.field}`
        const groupExists = get(this.formData, dependency.group)
        const fieldExists = get(this.formData, fieldPath)

        if (groupExists) {
          if (fieldExists) {
            const fieldValue = get(this.formData, fieldPath).value

            return dependency.value === "*" ? fieldValue : dependency.value === fieldValue
          } else {
            //eslint-disable-next-line
            console.warn("FormGroup Group Field does not exist", dependency.field)
          }
        } else {
          //eslint-disable-next-line
          console.warn("FormGroup Group does not exist", dependency.group)
        }
      }

      return true
    },
    mapData() {
      Object.entries(this.fieldGroups).filter(([formGroupKey, formGroup]) => {
        if (formGroupKey !== "_options") {
          this.formData = { ...this.formData, [formGroupKey]: {} }

          Object.entries(formGroup).filter(([fieldKey, field]) => {
            this.formData[formGroupKey] = {
              ...this.formData[formGroupKey],
              [fieldKey]: { value: "" }
            }

            const dataFieldPath = `${fieldKey.replace("/", ".")}`
            const fieldValuePath = `${formGroupKey}.${fieldKey}.value`
            const finalValue = `${field.initialValue}` !== "undefined" ? field.initialValue : null
            const dataValue = get(this.data, dataFieldPath, finalValue)

            if (fieldKey === "_options") {
              return null
            }

            if (field.mutateValue) {
              const mutatedValue = field.mutateValue(dataValue, this.data, this.formData)

              set(this.formData, fieldValuePath, mutatedValue)
            } else {
              set(this.formData, fieldValuePath, dataValue)
            }
          })
        }
      })
    },
    setSubmitting(isSubmitting) {
      this.isSubmitting = isSubmitting
    },
    onSubmit() {
      this.$refs[this.formName].validate((valid, invalidFields) => {
        if (valid) {
          this.formError = false
          this.setSubmitting(true)
          this.$emit("onSubmit", this)
        } else {
          this.invalidTabs = Object.keys(invalidFields).map(field => {
            const [fieldGroupKey] = field.split(".")
            return get(this.formConfig, `fields.${fieldGroupKey}._options.tab`)
          })

          this.formError = true
          return false
        }
      })
    },
    onReset() {
      this.$refs[this.formName].resetFields()
      this.$emit("onReset", this)
    },
    onCancel() {
      this.$emit("onCancel", this)
    },
    onDelete() {
      this.$emit("onDelete", this)
    }
  }
}
</script>

<style lang="scss">
.hint {
  cursor: help;
}

.disabled {
  cursor: not-allowed;
}

.form-group-container.tabs {
  overflow: auto;
}

.tab-invalid-field-count {
  padding-right: var(--padding-xs);

  span {
    color: red;
  }
}

.hint-tooltip {
  max-width: 500px !important;
}
</style>

<template>
  <div class="flex region-and-zone-filter">
    <template v-for="filter of filters">
      <div
          v-if="!filter.isHidden"
          :key="filter.label"
          class="flex-column filter">
        <core-text
            tag="label"
            :for="filter.name">
          {{ filter.label }}
        </core-text>

        <el-select
            :id="filter.name"
            :value="filter.value"
      
            loading-text="loading..."
            :size="size"
            v-bind="filter.props"
            @change="filter.onChange">
          <el-option
              v-for="item in filter.options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            <span style="float: left">{{ item.label }}</span>
          </el-option>
        </el-select>
      </div>
    </template>
  </div>
</template>

<script>
import { Region, Zone } from "@/Modules/OdysseyModels"
import get from "lodash/get"
import isArray from "lodash/isArray"
import isEqual from "lodash/isEqual"

export default {
  name: "CoreRegionAndZoneSelector",
  props: {
    hideZoneSelector: { type: Boolean, default: false },
    allowMultipleZones: { type: Boolean, default: false },
    size: { type: String, default: "small" }
  },
  data() {
    const { allowMultipleZones } = this
    const selectedZone = allowMultipleZones ? [] : null

    return {
      regionsLoading: true,
      zonesLoading: true,
      selectedRegion: null,
      initialZoneFetchComplete: false,
      selectedZone
    }
  },
  computed: {
    filters() {
      const { allowMultipleZones, zones, hideZoneSelector } = this

      return [
        {
          name: "region-filter",
          label: "Region Filter",
          options: this.regions,
          value: this.selectedRegion,
          loading: this.regionsLoading,
          onChange: this.onRegionChange,
          props: {}
        },
        {
          name: "zone-filter",
          label: "Zone Filter",
          isHidden: hideZoneSelector,
          options: zones,
          value: this.selectedZone,
          loading: this.zonesLoading,
          onChange: this.onZoneChange,
          props: {
            multiple: allowMultipleZones,
            clearable: allowMultipleZones,
            collapseTags: allowMultipleZones,
            placeholder: zones.length ? "Filtering By All Zones" : "No Zones To Filter By"
          }
        }
      ]
    },
    regionId() {
      return this.$route.query.region
    },
    zoneId() {
      return this.$route.query.zone
    },
    regions() {
      return Region.all().map(region => region.valueList)
    },
    zones() {
      if (this.hideZoneSelector) {
        return []
      }

      return Zone.getters("byRegion")(this.regionId).map(zone => zone.valueList)
    },
    zoneIds() {
      return this.zones.map(({ id }) => id)
    }
  },

  watch: {
    regionId: {
      immediate: true,
      handler() {
        if (this.regionId) {
          if (!this.hideZoneSelector) {
            this.fetchZones()
          }

          if (this.initialZoneFetchComplete) {
            this.onZoneChange(undefined)
          }
        }
      }
    },
    regions: {
      immediate: true,
      handler() {
        const { regionId, onRegionChange } = this

        if (this.regions.length) {
          const selectedRegionId = parseInt(regionId, 10) || get(this.regions, "[0].value", null)
          onRegionChange(selectedRegionId)
        }
      }
    },
    zones: {
      immediate: true,
      deep: true,
      handler() {
        const { zoneId, zoneIds, onZoneChange, allowMultipleZones } = this

        if (this.zones.length && !allowMultipleZones) {
          const shouldUseURLZoneId = zoneId && zoneIds.includes(parseInt(zoneId, 10))
          const selectedZoneId = shouldUseURLZoneId
            ? parseInt(zoneId, 10)
            : get(this.zones, "[0].value", null)

          onZoneChange(selectedZoneId)
        }

        if (this.zones.length && zoneId && allowMultipleZones) {
          const zoneIdsFromQuery = zoneId
            .split(",")
            .map(zId => parseInt(zId, 10))
            .filter(zId => zoneIds.includes(zId))

          onZoneChange(zoneIdsFromQuery)
        }
      }
    }
  },
  mounted() {
    this.fetchRegions()
  },

  methods: {
    async fetchZones() {
      this.zonesLoading = true

      await Zone.api().fetchAll({ params: { filter: { region: this.regionId } } })

      this.initialZoneFetchComplete = true
      this.zonesLoading = false
    },
    async fetchRegions() {
      this.regionsLoading = true

      await Region.api().fetchAll()

      this.regionsLoading = false
    },
    onRegionChange(value) {
      this.selectedRegion = value

      this.updateURLQueries({ region: this.selectedRegion })
    },
    onZoneChange(value) {
      const { allowMultipleZones } = this

      if (value) {
        this.selectedZone = allowMultipleZones ? [value].flat() : value
      } else {
        this.selectedZone = allowMultipleZones ? [] : value
      }

      const handleZoneIdArray = arr => {
        return arr.join(",")
      }

      const zoneQuery = isArray(this.selectedZone)
        ? handleZoneIdArray(this.selectedZone)
        : this.selectedZone

      const nextQuery = { zone: zoneQuery }

      if (!zoneQuery) {
        nextQuery.zone = undefined
      }

      this.updateURLQueries(nextQuery)
    },
    updateURLQueries(queriesToChange = {}) {
      const currentRoute = this.$route
      const nextQuery = { ...currentRoute.query, ...queriesToChange }

      const newPath = { ...currentRoute, query: nextQuery }

      const shouldChangeRoute = !isEqual(currentRoute.query, nextQuery)

      if (shouldChangeRoute) {
        this.$router.push(newPath).catch(e => {
          const errorPayload = {
            type: "Error in Select Region (RegionBreadcrumb.vue)",
            error: e,
            message: e,
            visible: true
          }
          this.$store.dispatch("core/errors/addToErrors", errorPayload)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.table-container {
  .region-and-zone-filter {
    padding-bottom: var(--padding-s);
  }
}

.region-and-zone-filter {
  .filter {
    margin-right: var(--margin-m);
  }
}
</style>

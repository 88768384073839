import OrderManagementTableView from "./views/OrderManagementTableView"

const routes = [
  {
    path: "",
    meta: {
      title: "Orders"
    },
    name: "orderManagement-list-view",
    component: OrderManagementTableView
  }
]

export default routes
<template>
  <div
      v-if="foundRecords.length"
      class="gs-entity-list">
    <CoreGlobalSearchEntityLabel
        v-for="(record, index) of foundRecords"
        :key="`${index}-${type}_${record.id}`"
        :record="record"
        :meta="meta"
        :type="type"
        :search-query="searchQuery"
        :store="store"
        :model="model" />
  </div>
</template>

<script>
import CoreGlobalSearchEntityLabel from "./CoreGlobalSearchEntityLabel"

export default {
  name: "CoreGlobalSearchEntityList",
  components: { CoreGlobalSearchEntityLabel },
  props: {
    store: { type: Object, required: true },
    meta: { type: Object, required: true },
    model: { type: Function, required: true },
    type: { type: String, required: true },
    searchQuery: { type: String, required: true },
    label: { type: String, required: true },
    dataIds: { type: Array, required: true },
    showDivider: { type: Boolean, default: false }
  },
  computed: {
    foundRecords() {
      const { dataIds = [], model } = this

      return model.findIn(dataIds)
    }
  }
}
</script>

<style lang="scss">
.gs-entity-list {
  .gs-entity-title {
    margin-left: var(--margin-m);
  }
}
</style>

<template>
  <div class="reset-password-container">
    <div class="logo-container">
      <Logo />
    </div>

    <div class="illustration-container">
      <div class="message">
        <h1>Reset your password!</h1>
        <h3>Set your new password here.</h3>
      </div>
    </div>
    <div class="request-password-container">
      <template v-if="status === 'success'">
        <el-alert
            type="success"
            effect="dark">
          <strong>Thank you</strong>
          <br>
          We have updated your password.
        </el-alert>
      </template>
      <template v-else>
        <h3>Please enter a new password.</h3>
        <form
            class="login-form"
            @submit.prevent="submit()">
          <div class="form-group">
            <template v-if="!tokenDisabled">
              <div class="input-group full-width">
                <div class="input">
                  <label for="token">
                    Activation Token
                  </label>
                  <input
                      id="token"
                      v-model="token"
                      type="text"
                      data-test-id="newToken"
                      class="form-control neumorphic"
                      :class="{ disabled: loading || tokenDisabled }">
                </div>
              </div>
            </template>
            <div class="input-group">
              <div class="input">
                <label for="password">
                  Your new password
                </label>
                <input
                    id="password"
                    v-model="password"
                    type="password"
                    data-test-id="newPassword"
                    class="form-control neumorphic"
                    :class="{ disabled: loading }">
              </div>
              <p :class="{ success: password && password.length > 7 }">
                <template v-if="password && password.length > 7">
                  <span class="el-icon-circle-check" /> Your password looks fine
                </template>
                <template v-else>
                  Hint: You need {{ requiredCharacters }} more characters
                </template>
              </p>
            </div>
            <div class="input-group">
              <div class="input">
                <label for="confirmPassword">
                  Please confirm your new password
                </label>
                <input
                    id="confirmPassword"
                    v-model="confirmPassword"
                    type="password"
                    data-test-id="confirmPassword"
                    class="form-control neumorphic"
                    :class="{ disabled: loading }">
              </div>
              <template v-if="password && confirmPassword && password === confirmPassword">
                <p class="success">
                  <span class="el-icon-circle-check" /> Your confirmation looks fine
                </p>
              </template>
              <template v-else-if="password && confirmPassword">
                Your passwords don't match
              </template>
            </div>

            <transition name="fade">
              <template v-if="canSubmit">
                <button
                    type="submit"
                    class="button btn-confirm"
                    :class="{ disabled: loading }"
                    :disabled="loading">
                  Update Password
                </button>
              </template>
            </transition>
          </div>
        </form>
      </template>
    </div>
    <CoreWaveyMcWaveFace
        container-background="transparent"
        wave1="#eb3514"
        wave2="#a2240e"
        wave3="#ce2e13"
        wave4="#ef4325"
        :animated="loading" />
    <div class="fill-container" />
  </div>
</template>

<script>
import { Traveler, User } from "@/Modules/OdysseyModels"
import Logo from "../../../components/Branding/Logo"
export default {
  name: "ResetPassword",
  components: {
    Logo
  },
  data() {
    return {
      password: null,
      confirmPassword: null,
      status: null,
      loading: null,
      token: this.$router.currentRoute.params.token,
      tokenDisabled: false
    }
  },
  computed: {
    currentRoute() {
      return this.$router.currentRoute.path
    },
    requiredCharacters() {
      const minChar = 8
      if (this.password && this.password.length < minChar) {
        return minChar - this.password.length
      } else {
        return 8
      }
    },
    canSubmit() {
      return (
        this.password != null &&
        this.password === this.confirmPassword &&
        this.token &&
        this.token.length > 5 &&
        this.password.length > 7
      )
    }
  },
  mounted() {
    if (this.$router.currentRoute.params.token) {
      this.tokenDisabled = true
    }
  },
  methods: {
    async submit() {
      const payload = {
        reset_password_token: this.token,
        password: this.password,
        password_confirmation: this.confirmPassword
      }
      let response
      if (this.currentRoute.includes("Traveller")) {
        response = await Traveler.api().resetPassword(payload)
      } else if (this.currentRoute.includes("Admin")) {
        response = await User.api().resetPassword(payload)
      }
      if (response.response.status === 200) {
        const notificationsPayload = {
          component: "Toast",
          type: "success",
          title: "Thank you",
          message: `Your password has been successfully reset.`,
          visible: true,
          decay: 4500
        }
        this.$store.dispatch("core/notifications/addToNotifications", notificationsPayload)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.reset-password-container {
  background: var(--main-white);
  height: 100vh;
  padding: 2em 0 0 0;
  text-align: center;

  .logo-container {
    text-align: center;
    width: 8em;
    margin: 2em auto;
    img {
      width: 100%;
    }
  }

  .fill-container {
    background: var(--main-brand);
    height: calc(100vh - 30em);
  }
  .illustration-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 600px;
    margin: auto;

    .message {
      flex: 2;
    }
    .image {
      flex: 1;
      max-width: 20em;

      img {
        width: 100%;
        filter: drop-shadow(9px 9px 8px rgba(0, 0, 0, 0.2));
      }
    }
  }
  .request-password-container {
    background: #f1f3f8;
    box-shadow: var(--box-shadow-neumorphic);
    border-radius: 0.25em;
    padding: 2em;
    min-width: 20em;
    max-width: 50em;
    margin: auto;

    h2 {
      margin: 0 1em 1em 0.5em;
    }

    button {
      margin: 1em;
      border-radius: 0.25em;
      width: 100%;
    }

    .input-group {
      &.full-width {
        min-width: 90%;
      }
    }

    p.success {
      color: var(--main-success-green);
    }
  }
}
</style>

import ExtendedModel from "@/Modules/Core/utils/extended-vue-orm-model"
import definition, { apiActions } from "./definition"
import Actions from "./actions"

class Product extends ExtendedModel {
  static entity = "Product"
  static globalSearchEnabled = true
  static definition = definition

  static apiConfig = {
    dataKey: "data.data",
    actions: apiActions
  }

  get Actions() {
    return new Actions(this)
  }

  get timeBlock() {
    return `${this.timeFrame} min`
  }

  get productPrice() {
    return `${parseFloat(this.price).toFixed(2)} AUD`
  }

  get valueList() {
    const {id} = this
    return {label: this.title, value: id, id, extra: this.categoryTitle}
  }

}
export default Product

const defaultColumns = [
    {
      prop: "id",
      label: "ID"
    },
    {
      prop: "firstName",
      label: "First Name"
    },
    {
      prop: "lastName",
      label: "Last Name",
    },
    {
      prop: "email",
      label: "Email",
    },
    {
      prop: "phone",
      label: "Phone",
    },
    {
      prop: "city",
      label: "City",
    },
    {
      prop: "birthday",
      label: "Birthday",
    }
  ]

export default defaultColumns
<template>
  <core-text class="flex items-center">
    <span>{{ label }}</span><el-tooltip
        :content="openDialogTooltipContent"
        placement="top">
      <i
          v-if="hasDialog"
          class="el-icon-top-right open-dialog"
          @click="onClick" />
    </el-tooltip>
  </core-text>
</template>

<script>
import Models from "@/Modules/OdysseyModels"
import get from "lodash/get"

export default {
  props: {
    value: { type: [String, Number], default: "n/a" },
    entity: { type: String, required: true }
  },
  computed: {
    model() {
      return Models[this.entity]
    },
    record() {
      return this.model.find(this.value)
    },
    label() {
      return get(this, "record.valueList.label", "N/A")
    },
    openDialogTooltipContent() {
      return `Open ${this.entity} dialog`
    },
    hasDialog() {
      return get(this, "record.Actions.openDialog")
    }
  },
  methods: {
    onClick() {
      this.record.Actions.openDialog()
    }
  }
}
</script>
<style lang="scss" scoped>
.open-dialog {
  border: 1px solid;
  border-color: inherit;
  border-radius: 3px;
  margin-left: var(--margin-m);
  cursor: pointer;
  transform: scale(0.85);

  &:hover {
    opacity: 0.7;
  }
}
</style>
export default {
  fields: {
    personalDetails: {
      firstName: {
        type: "text",
        value: null,
        label: "First Name",
        placeholder: "ie. John",
        validation: [{required: true, message: "Please enter first name", trigger: "blur"}]
      },
      lastName: {
        type: "text",
        value: null,
        label: "Last Name",
        placeholder: "ie. Doe",
        validation: [{required: true, message: "Please enter last name", trigger: "blur"}]
      },
      dob: {
        type: "date",
        value: null,
        label: "Date of Birth",
        placeholder: "01/01/1980"
      }
    },
    contactDetails: {
      email: {
        type: "email",
        value: null,
        label: "Email address",
        placeholder: "ie. john.doe@example.org",
        validation: [{required: false, message: "Please enter a valid email", trigger: "blur"}]
      },
      phone: {
        type: "phone",
        value: null,
        label: "Phone Number",
        placeholder: "ie. +61 411 111 111",
        validation: [{required: false, message: "Please enter a valid Phone Number", trigger: "blur"}]
      }
    }
  }
}

<template>
  <div>
    <core-data-table-v2
        :model="productModel"
        :columns="columns"
        table-name="productManagement-management"
        v-on="$listeners" />
  </div>
</template>

<script>
import { Product } from "@/Modules/OdysseyModels"
import columns from "../components/ProductManagementTableView/columns"
export default {
  name: "ProductManagementTableView",
  computed: {
    productModel() {
      return Product
    },
    columns() {
      return columns
    }
  }
}
</script>

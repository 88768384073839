import ExtendedModel from "@/Modules/Core/utils/extended-vue-orm-model"
import definition, { apiActions } from "./definition"
import Actions from "./actions"
import config from "@/config";

const baseURL = config.apiConfig.lightwerk.api
const endpoint = "/keyStatistics"

class KeyStatistic extends ExtendedModel {
  static entity = "KeyStatistic"
  static globalSearchEnabled = false
  static definition = definition

  static apiConfig = {
    dataKey: "data.data",
    actions: {
      ...apiActions,
      async fetchByType(type, days, options = {}) {
        const url = `${baseURL}${endpoint}/get/${type}/${days}`
        return this.get(url, null)
      }
    }
  }

  get Actions() {
    return new Actions(this)
  }

  get valueList() {
    const {id} = this
    return {label: this.fullName, value: id, id}
  }

}
export default KeyStatistic

import routes, { apiActions } from "./routes"

const attributes = {
    id: null,
    archived: null,
    billingAbn: null,
    billingAddressCountry: null,
    billingAddressState: null,
    billingAddressStreet: null,
    billingAddressSuburb: null,
    billingAddressZip: null,
    billingCompany: null,
    billingEmail: null,
    billingFirstName: null,
    billingLastName: null,
    billingMobile: null,
    billingPhone: null,
    billingTitle: null,
    cost: null,
    ctime: null,
    daily_deal: null,
    invoiceLocation: null,
    notes: null,
    paid: null,
    payment_type: null,
    shippingAddressCountry: null,
    shippingAddressExtra: null,
    shippingAddressState: null,
    shippingAddressStreet: null,
    shippingAddressSuburb: null,
    shippingAddressZip: null,
    shippingCompany: null,
    shippingFirstName: null,
    shippingLastName: null,
    shippingPhone: null,
    shippingTitle: null,
    shipping_type: null,
    status: null,
    tracking_code: null,
    vin: null,
    voucher_id: null,
    shippingCompanyTitle: null,
    shippingCompanyUrl: null
}

const definition = { attributes, routes }

export { apiActions }
export default definition
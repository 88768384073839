import ExtendedModel from "@/Modules/Core/utils/extended-vue-orm-model"
import definition, { apiActions } from "./definition"
import Actions from "./actions"
import startCase from "lodash/startCase"

class UserRole extends ExtendedModel {
  static entity = "userRole"
  static definition = definition
  static alwaysSeedData = true

  static apiConfig = {
    dataKey: "data",
    actions: apiActions
  }

  get statusColor() {
    switch (this.state) {
      case "enabled":
        return "success"

      case "disabled":
        return "error"

      default:
        return "default"
    }
  }

  get Actions() {
    return new Actions(this)
  }

  get roleName() {
    return startCase(this.name)
  }

  get valueList() {
    return { label: this.roleName, value: this.id }
  }
}

export default UserRole

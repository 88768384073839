export default {
  fields: {
    orderDetails: {
      customerId: {
        type: "entity-select",
        props: {
          entity: "Customer",
          filterable: true,
          clearable: true
        },
        label: "Customer",
        validation: [{required: true, message: "Please select a customer", trigger: "blur"}]
      },
      createdAt: {
        type: "date",
        value: null,
        label: "Order Date",
        placeholder: "01/01/1980"
      },
      paymentType: {
        type: "text",
        value: null,
        label: "Payment Type",
        placeholder: "ie. Doe",
        validation: [{required: true, message: "Please enter last name", trigger: "blur"}]
      },
      priceCostAud: {
        type: "text",
        value: null,
        label: "Price Cost",
        placeholder: ""
      },
      pricePaidAud: {
        type: "text",
        value: null,
        label: "Price Paid",
        placeholder: ""
      },
      status: {
        type: "text",
        label: "Order Status"
      }
    }
  }
}

const state = () => {
  return {
    nextRoute: false
  }
}

const actions = {
  updateNextRoute({ commit }, payload) {
    commit("UPDATE_NEXT_ROUTE", payload)
  }
}

const mutations = {
  UPDATE_NEXT_ROUTE(state, payload) {
    state.nextRoute = payload
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}

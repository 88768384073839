const autoLoader = ({ prefix = "Core", context }) => Vue => {
  const importAll = r => {
    return r.keys().map(key => {
      Vue.component(`${prefix}${key.replace("./", "").replace(".vue", "")}`, r(key).default)
    })
  }

  importAll(context)
}

export default autoLoader

// import mockAlerts from "../../mockdata/alerts/mockAlerts"
import Vue from "vue"

const state = () => {
  return {
    items: {},
    alertModules: [],
    totalNotificationCount: {},
    showUnsavedDataAlert: false
  }
}

const actions = {
  /**
   * @param commit
   * @param payload: { key: value }
   */
  addAlertModule({ commit }, payload) {
    commit("ADD_ALERT_MODULE", payload)
  },
  addAlert({ commit }, payload) {
    commit("ADD_ALERT", payload)
  },
  removeAlert({ commit }, payload) {
    commit("REMOVE_ALERT", payload)
  },
  clearAlerts({ commit }) {
    commit("CLEAR_ALERTS")
  },
  addTotalNotificationCount({ commit }, payload) {
    commit("UPDATE_NOTIFICATION_COUNT", payload)
  },
  addTotalNotificationCountKey({ commit }, payload) {
    commit("ADD_NOTIFICATION_COUNT_KEY", payload)
  },
  removeTotalNotificationCountKey({ commit }, payload) {
    commit("REMOVE_NOTIFICATION_COUNT_KEY", payload)
  },
  toggleUnsavedDataAlert({ commit }, payload) {
    commit("TOGGLE_UNSAVED_DATA_ALERT", payload)
  }
}

const mutations = {
  ADD_ALERT(state, payload) {
    if (state.items[payload.key]) {
      state.items[payload.key].push(payload.value)
    } else {
      state.items = {
        [payload.key]: [payload.value]
      }
    }
  },
  ADD_ALERT_MODULE(state, payload) {
    state.alertModules.push(payload)
  },
  REMOVE_ALERT(state, payload) {
    state.items[payload.key] = null
  },
  CLEAR_ALERTS(state) {
    state.items = {}
  },
  UPDATE_NOTIFICATION_COUNT(state, payload) {
    Vue.set(state.totalNotificationCount, payload.key, payload.count)
  },
  ADD_NOTIFICATION_COUNT_KEY(state, payload) {
    Vue.set(state.totalNotificationCount, payload.key, 0)
  },
  REMOVE_NOTIFICATION_COUNT_KEY(state, payload) {
    Vue.delete(state.totalNotificationCount, payload.key)
  },
  TOGGLE_UNSAVED_DATA_ALERT(state, payload) {
    state.showUnsavedDataAlert = payload
  }
}

const getters = {
  numberOfAlerts: state => {
    let count = 0
    Object.keys(state.items).filter(index => {
      Object.keys(state.items[index]).filter(index2 => {
        if (state.items[index][index2].status === "unread") {
          count = count + 1
        }
      })
    })
    return count
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

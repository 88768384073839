<template>
  <div class="form-wrapper">
    <CoreFormBuilder
        :form-data="formData"
        :is-loading="isLoading"
        @submitForm="submitForm()"
        @cancelForm="cancelForm()" />
  </div>
</template>

<script>
  import businessDetails from "./businessDetails"
  import seoSettings from "./seoSettings"
  import smsAndEmails from "./smsAndEmails"
  import socialNetworks from "./socialNetworks"
  import apiSettings from "./apiSettings"
  
  import { Settings } from "@/Modules/OdysseyModels"

  export default {
    name: "FormWrapper",
    props: {
      form: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        isLoading: false,
        initialising: true,
        formUpdated: false,
        formData: {},
        settings: {}
      }
    },
    watch: {
      settings: {
        handler(val) {
          if (val) {
            this.mapEditData(val)
          }
        },
        immediate: true
      },
      formData: {
        handler(val) {
          if (val && !this.initialising) {
            this.formUpdated = true
          }
        },
        deep: true
      }
    },
    created() {
      this.settings = Settings.find(1)

      switch (this.form) {
        case "businessDetails":
          this.formData = businessDetails
          break
        case "seoSettings":
          this.formData = seoSettings
          break
        case "smsAndEmails":
          this.formData = smsAndEmails
          break
        case "socialNetworks":
          this.formData = socialNetworks
          break
        case "apiSettings":
          this.formData = apiSettings
          break
        default:
          return false
      }
      this.mapEditData(this.settings)
    },
    methods: {
      cancelForm() {
        this.$emit("cancelForm")
      },
      async submitForm() {
        const flatFormData = {}
        // Go through each field and only select the value-field to pair with the key
        Object.keys(this.formData).filter(index => {
          Object.keys(this.formData[index]).filter(key => {
            if (key !== "placeholder") {
              flatFormData[key] = this.formData[index][key].value
            }
          })
        })
        await Settings.api().update({
          id: this.settings.id,
          ...flatFormData
        })
        const notificationsPayload = {
          component: "Toast",
          type: "success",
          title: "Thank you",
          message: "Your settings have been successfully updated",
          visible: true,
          decay: 3500
        }
        this.$store.dispatch("core/notifications/addToNotifications", notificationsPayload)
        this.formUpdated = false
        this.$emit("closeForm")
      },
      mapEditData(editData) {
        Object.keys(this.formData).filter(index => {
          Object.keys(this.formData[index]).filter(key => {
            this.formData[index][key].value = editData[key]
          })
        })
      }
    }
  }
</script>

<style scoped>

</style>
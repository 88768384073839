const state = () => {
  return {
    currentDate: new Date(),
    reloadAppointments: false
  }
}

const actions = {
  updateCurrentDate({ commit }, payload) {
    commit("UPDATE_CURRENT_DATE", payload)
  },
  updateReloadAppointments({ commit }, payload) {
    commit("UPDATE_RELOAD_APPOINTMENTS", payload)

    setTimeout(() => {
      commit("UPDATE_RELOAD_APPOINTMENTS", false)
    }, 400)
  }
}

const mutations = {
  UPDATE_CURRENT_DATE(state, payload) {
    state.currentDate = payload
  },
  UPDATE_RELOAD_APPOINTMENTS(state, payload) {
    state.reloadAppointments = payload
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}

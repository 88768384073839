const date = new Date()
const dateFrom = new Date(date.getDate() - 7)
const dateTo = new Date()

export default [
  {
    label: "Customer",
    options: [
      {
        selectedByDefault: true,
        label: "Customer Details",
        queryType: "search",
        urlKey: "filter.customer",
        inputType: "text",
        props: {
          placeholder: "Filter by Customer Details (Name, Email, Phone)"
        }
      },
      {
        label: "Booking Date",
        inputType: "daterange",
        queryType: "search",
        urlKey: "filter.bookingDate",
        defaultValue: [dateFrom, dateTo],
        props: {
          rangeSeparator: "To",
          startPlaceholder: "Start date",
          endPlaceholder: "End date",
          format: "dd/MM/yyyy",
          valueFormat: "yyyy-MM-dd 00:00:00",
          placeholder: "Filter by Booking Date"
        }
      },
      {
        label: "Birthday",
        inputType: "daterange",
        queryType: "search",
        urlKey: "filter.birthday",
        defaultValue: [dateFrom, dateTo],
        props: {
          rangeSeparator: "To",
          startPlaceholder: "Start date",
          endPlaceholder: "End date",
          format: "dd/MM/yyyy",
          valueFormat: "yyyy-MM-dd 00:00:00",
          placeholder: "Filter by Birthday"
        }
      },
      {
        label: "Creation Date",
        inputType: "daterange",
        queryType: "search",
        urlKey: "filter.createdAt",
        defaultValue: [dateFrom, dateTo],
        props: {
          rangeSeparator: "To",
          startPlaceholder: "Start date",
          endPlaceholder: "End date",
          format: "dd/MM/yyyy",
          valueFormat: "yyyy-MM-dd 00:00:00",
          placeholder: "Filter by Creation Date"
        }
      }
    ]
  }
]
const defaultShapeError = (error, index) => {
  return {
    status: "error",
    title: error.message,
    description: "This is my description of the error. It is great.",
    index
  }
}

const requestAndHandleMultiple = (requests, shapeError = defaultShapeError) => {
  return new Promise(async (resolve, reject) => {
    const result = await Promise.all(requests.map(request => request.catch(error => error)))

    const errors = result
      .map((response, index) => {
        return { response, index }
      })
      .filter(err => err.response instanceof Error)
      .map(shapeError)

    if (errors.length) {
      return reject(errors)
    } else {
      return resolve(result)
    }
  })
}

const createDefaultCRUD = (config = {}) => {
  const { resource } = config

  if (!config.endpoints) {
    config.endpoints = {}
  }

  config.endpoints.base = config.endpoint
  config.endpoints.baseWithId = `${config.endpoint}/get/:id`
  config.endpoints.baseUpdateWithId = `${config.endpoint}/update/:id`
  config.endpoints.baseDeleteWithId = `${config.endpoint}/delete/:id`
  config.endpoints.baseCreate = `${config.endpoint}/create`

  const endpoints = {}

  Object.entries(config.endpoints || {}).forEach(([key, value]) => {
    endpoints[key] = (options = {}) => {
      const { scope = "", ...params } = options

      let endpoint = `${config.baseURL}${scope}${value}`

      Object.entries(params).forEach(([key, value]) => {
        endpoint = endpoint.replace(`:${key}`, value)
      })

      return endpoint
    }
  })

  const apiActions = {
    fetchAll(options = {}) {
      const url = endpoints.base({ ...config, ...options })

      return this.get(`${url}/list/`, options)
    },
    fetchById(id = "", options = {}) {
      const url = endpoints.baseWithId({ ...config, ...options, id })

      return this.get(url, options)
    },
    create(payload = {}, options = {}) {
      const url = endpoints.baseCreate({ ...config, ...options })

      return this.post(url, { data: payload }, options)
    },
    update(payload = {}, options = {}) {

      const { id = "" } = payload
      const url = endpoints.baseUpdateWithId({ ...config, ...options, id })

      return this.post(url, { data: payload, id: payload.id }, options)
    },
    updateMany(payloads = [], options = {}) {
      return requestAndHandleMultiple(payloads.map(payload => this.update(payload, options)))
    },
    destroy(payload = {}, options = {}) {
      const { id = "" } = payload

      options.delete = id

      const url = endpoints.baseDeleteWithId({ ...config, ...options, id })

      return this.delete(url, options)
    },
    destroyMany(payloads = [], options = {}) {
      return requestAndHandleMultiple(payloads.map(payload => this.destroy(payload, options)))
    }
  }

  const routes = server => {
    server.get(endpoints.base({ ...config }), resource)
    server.post(endpoints.base({ ...config }), resource)
    server.get(endpoints.baseWithId({ ...config }), resource)
    server.patch(endpoints.baseWithId({ ...config }), resource)
    server.delete(endpoints.baseWithId({ ...config }), resource)
  }

  return {
    routes,
    apiActions,
    endpoints,
    constructEndpoint: (options = {}) => {
      const { endpoint, baseURL = config.baseURL, params = {} } = options

      let endpointWithParams = `${baseURL}${endpoint}`

      Object.entries(params).forEach(([key, value]) => {
        endpointWithParams = endpointWithParams.replace(`:${key}`, value)
      })

      return endpointWithParams
    }
  }
}

export default createDefaultCRUD

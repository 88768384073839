<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 3"
      viewBox="0 0 64 80"
      class="svg-icon"
      :fill="color"
      x="0px"
      y="0px">
    <path
        d="M15.59,13H10a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V14.41l1.71-1.7-1.42-1.42Zm-2,2L11,17.59V15ZM15,19H12.41L15,16.41Z" />
    <path
        d="M15.59,23H10a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V24.41l1.71-1.7-1.42-1.42Zm-2,2L11,27.59V25ZM15,29H12.41L15,26.41Z" />
    <path
        d="M15.59,33H10a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V34.41l1.71-1.7-1.42-1.42Zm-2,2L11,37.59V35ZM15,39H12.41L15,36.41Z" />
    <path
        d="M17.29,41.29,15.59,43H10a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V44.41l1.71-1.7ZM13.59,45,11,47.59V45ZM15,49H12.41L15,46.41Z" />
    <rect
        x="21"
        y="13"
        width="18"
        height="2" />
    <rect
        x="21"
        y="19"
        width="18"
        height="2" />
    <rect
        x="21"
        y="23"
        width="18"
        height="2" />
    <rect
        x="21"
        y="29"
        width="8"
        height="2" />
    <rect
        x="21"
        y="33"
        width="8"
        height="2" />
    <rect
        x="21"
        y="39"
        width="8"
        height="2" />
    <path
        d="M62,63a1,1,0,0,0,1-1V46a1,1,0,0,0-1-1H53V30a1,1,0,0,0-1-1H49V4a1,1,0,0,0-1-1H37V2a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1V3H2A1,1,0,0,0,1,4V60a1,1,0,0,0,1,1H21v1a1,1,0,0,0,1,1ZM53,47v4H51V47ZM33,45V31h6v5a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V31h6V45Zm0,2v4H31V47Zm8-16h2v4H41ZM47,5V29H45V8a1,1,0,0,0-1-1H37V5ZM15,3H35V9H15ZM3,59V5H13V7H6A1,1,0,0,0,5,8V54a1,1,0,0,0,1,1H21v4ZM21,46v7H7V9h6v1a1,1,0,0,0,1,1H36a1,1,0,0,0,1-1V9h6V29H32a1,1,0,0,0-1,1V45H22A1,1,0,0,0,21,46Zm2,1h6v5a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V47h6V61H23ZM61,61H43V47h6v5a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V47h6Z" />
    <rect
        x="55"
        y="57"
        width="4"
        height="2" />
    <rect
        x="45"
        y="41"
        width="4"
        height="2" />
    <rect
        x="35"
        y="57"
        width="4"
        height="2" />
  </svg>
</template>

<script>
  export default {
    name: "OrderManagementIcon",
    props: {
      color: {
        type: String,
        default: "#000000"
      }
    }
  }
</script>

<style scoped>
  .svg-icon {
    width: 100%;
  }
</style>

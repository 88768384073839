<template>
  <div class="action-wrapper">
    <template v-if="showAsDropdown">
      <el-tooltip
          v-if="actions.length"
          :manual="true"
          placement="bottom-end"
          effect="light"
          :popper-class="`action-dropdown-menu ${size}`"
          :value="showActionMenu">
        <div
            ref="button"
            class="action-dropdown-wrapper"
            :data-test-id="`${dataTestId}-trigger`"
            @click="onClick">
          <slot>
            <el-button
                round
                type="primary"
                class="action-dropdown-button flex-center"
                :class="`${size} ${showActionMenu && 'active'}`">
              <span
                  v-for="index of 3"
                  :key="index"
                  class="dot" />
            </el-button>
          </slot>
        </div>

        <div
            v-if="actions.length"
            slot="content"
            ref="menu"
            class="action-menu-wrapper"
            :data-test-id="`${dataTestId}-menu`">
          <template
              v-for="(action, index) in actions">
            <el-dropdown-item
                :key="action.label"
                :divided="action.divided">
              <el-popconfirm
                  v-if="action.onConfirm"
                  :id="`${dataTestId}-action-${index}`"
                  :class="`${dataTestId}-action-${index}`"
                  :popper-class="`${dataTestId}-action-${index}`"
                  v-bind="{...defaultConfirmProps, ...action.confirmProps}"
                  @confirm="popConfirm(action.onConfirm)">
                <core-text
                    slot="reference"
                    class="action-menu-item fit-parent"
                    :data-test-id="`${dataTestId}-action-${index}`"
                    :class="action.class">
                  <i :class="action.icon" />{{ action.label }}
                </core-text>
              </el-popconfirm>

              <core-text
                  v-else
                  class="action-menu-item fit-parent"
                  :data-test-id="`${dataTestId}-action-${index}`"
                  :class="action.class"

                  @click="onActionClick(action.onClick)">
                <i
                    v-if="action.icon"
                    :class="action.icon" />{{ action.label }}
              </core-text>
            </el-dropdown-item>
          </template>
        </div>
      </el-tooltip>
    </template>
    <template v-else>
      <div class="actions-grid">
        <template
            v-for="(action, index) in actions">
          <core-button
              :key="action.label"
              :title="action.label"
              size="mini"
              class="action-button">
            <el-popconfirm
                v-if="action.onConfirm"
                :id="`${dataTestId}-action-${index}`"
                :class="`${dataTestId}-action-${index}`"
                :popper-class="`${dataTestId}-action-${index}`"
                v-bind="{...defaultConfirmProps, ...action.confirmProps}"
                @confirm="popConfirm(action.onConfirm)">
              <core-text
                  slot="reference"
                  class="action-menu-item fit-parent"
                  :data-test-id="`${dataTestId}-action-${index}`"
                  :class="action.class"
                  tag="span">
                <el-tooltip
                    placement="bottom-end"
                    :content="action.label">
                  <i
                      :class="action.icon"
                      :title="action.label" />
                </el-tooltip>
              </core-text>
            </el-popconfirm>

            <core-text
                v-else
                class="action-menu-item fit-parent"
                :data-test-id="`${dataTestId}-action-${index}`"
                :class="action.class"
                tag="span"
                size="mini"
                @click="onActionClick(action.onClick)">
              <el-tooltip
                  placement="bottom-end"
                  :content="action.label">
                <i
                    v-if="action.icon"
                    :class="action.icon" />
              </el-tooltip>
            </core-text>
          </core-button>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
  import kebabCase from "lodash/kebabCase"
  export default {
    name: "CoreActionsDrodown",
    props: {
      actions: { type: Array, default: () => [] },
      rowId: { type: [String, Number], default: null },
      size: { type: String, default: "medium" },
      dataTestId: { type: String, default: "medium" },
      manual: { type: Boolean, default: false },
      value: { type: Boolean, default: false },
      showAsDropdown: { type: Boolean, default: false }
    },
    data() {
      return {
        isOpen: false,
      }
    },
    computed: {
      showActionMenu() {
        if (this.manual) {
          return this.value
        }

        return this.isOpen
      },
      defaultConfirmProps() {
        return {
          title: "Hey, You forgot to add a title.",
          icon: "el-icon-info",
          iconColor: "red",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel"
        }
      }
    },
    mounted() {
      document.addEventListener("click", this.onDocumentClick)
    },
    beforeDestroy() {
      document.removeEventListener("click", this.onDocumentClick)
    },
    methods: {
      createDataTestId(str) {
        return kebabCase(str)
      },
      onDocumentClick(e) {
        const { button, menu } = this.$refs
        const shouldClose = button && !button.contains(e.target) && menu && !menu.contains(e.target)

        if (shouldClose) {
          this.isOpen = false
          this.$emit("onChange", false)
        }
      },
      onClick(e) {
        e.stopPropagation()
        this.isOpen = !this.isOpen
      },
      async onActionClick(onClick) {
        onClick()

        this.isOpen = false
        this.$emit("onChange", false)
      },
      async popConfirm(onConfirm) {
        await onConfirm()
        this.isOpen = false
        this.$emit("onChange", false)
      }
    }
  }
</script>


<style lang="scss" scoped>
  @import "@/Modules/Core/assets/css/index.scss";

  .action-wrapper {
    width: 100%;

    .action-dropdown-menu {
      padding: 0 !important;

      &.small {
        transform: scale(0.9);
        line-height: 30px;
      }

      .action-menu-wrapper {
        overflow: hidden;
        border-radius: inherit;
        padding: var(--padding-s) 0;
      }

      .action-menu-item {
        display: flex;
        align-items: center;
      }
    }

    .actions-grid {
      display: flex;
      flex-wrap: wrap;
      padding: 8px 5px;

      .action-button {
        flex: 1;
        min-width: 22px;
        max-width: 22px;
        padding: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-color: transparent;
        background: transparent !important;
        margin: 3px 4px;
        //box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);

        &.el-button + .el-button {
          margin-left: 1px;
        }

        &:hover {
          box-shadow: var(--box-shadow-container-light);
          background: rgba(0,0,0,0.1);
          color: #606266 !important;
        }

        &:active {
          box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.1);
        }
      }
    }

    .danger {
      color: #f56c6c;
    }

    /deep/ span {
      padding: 0 !important;
    }

    .core-text {
      padding: 0 1rem;
    }

    .action-dropdown-wrapper {
      cursor: pointer;
    }

    .action-dropdown-button {
      background: linear-gradient(180deg,#fff 0,#fbfcfd 1%,#ecedef 87%,#e5e7e8 94%,#ccdcf7)!important;
      border: 0;
      color: var(--main-primary);
      box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.1), 2px 2px 6px 2px rgba(0, 0, 0, 0.08);
      margin: 0.25em;
      padding: 0 !important;
      transition: var(--main-transition);
      height: 2em;
      width: 3em;

      &.medium {
        transform: scale(1);
      }

      &.small {
        transform: scale(0.8);
      }

      & :first-child {
        width: 100%;
        @extend .flex-center;
      }

      .dot {
        background-color: var(--main-primary);
        height: 2px;
        width: 2px;
        border-radius: 50%;
        margin: 2px;
      }

      &:hover,
      &.active,
      &.active {
        background: var(--main-primary-dark);
        color: var(--main-primary);
        border-color: var(--main-primary-dark);
        box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.5);

        .dot {
          background-color: white;
        }
      }
    }
  }
</style>
import routes, { apiActions } from "./routes"

const attributes = {
    id: 1,
    title: null,
    color: null,
    status: 1
}

const definition = { attributes, routes }

export { apiActions }
export default definition
<template>
  <footer v-if="!isMinified">
    <div
        v-if="devMode"
        class="version-container">
      <div
          class="abel-logo">
        <AbelLogo color="#ee4324" />
      </div>
      <sup class="version-number">
        <core-version-dialog :changelogs="changelogs" />
      </sup>
    </div>

    <div
        class="environment-badge"
        :title="`GitHash: ${gitHash}`">
      {{ env }} {{ subEnv }}
      <sup
          v-if="!devMode"
          class="version-number">
        <core-version-dialog :changelogs="changelogs" />
      </sup>
    </div>
  </footer>
</template>

<script>
import CoreVersionDialog from "@/Modules/Core/components/VersionDialog/VersionDialog"
import AbelLogo from "../../assets/vue-icons/AbelLogo"
import changelogs from "@/changelogs"
import config from "@/config/config"

export default {
  name: "AppFooter",
  components: { AbelLogo, CoreVersionDialog },
  props: {
    isMinified: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      changelogs,
      env: process.env.NODE_ENV,
      subEnv: config.subEnvironment,
      gitHash: process.env.VUE_APP_GIT_HASH
    }
  },
  computed: {
    devMode() {
      return this.env === "development" || this.env === "staging"
    },
    brandName() {
      return this.$store.state.settings.app.brandName
    },
    appName() {
      return this.$store.state.settings.app.appName
    },
    version() {
      return "1.1.0"
    }
  }
}
</script>

<style lang="scss" scoped>
footer {
  background: white;
  padding: 0.8em 0.2em;

  .version-container {
    width: 100%;
    margin-top: auto;
    display: flex;

    .abel-logo {
      flex: 1;
      height: 1em;
      text-align: right;
      max-width: 3.5em;
      margin-right: 0.5em;
      margin-left: 4em;
      svg {
        width: 100%;
      }
    }
    .version-number {
      flex: 1;

      /deep/ .el-button--text {
        color: var(--main-primary);

        &:hover {
          color: var(--main-brand);
        }
      }
    }
  }

  .environment-badge {
    background: var(--main-light-grey);
    color: var(--main-primary-dark);
    letter-spacing: 1px;
    border-radius: 0.5em;
    padding: 0.1em 0.5em;
    font-size: 0.8em;
    width: 90%;
    text-transform: capitalize;
    margin: 1em auto;
    text-align: center;
  }

  .dev-container {
    margin-top: 0.25em;

    .environment {
      margin: 0 0.5em;
      padding: 0.15em 0.25em;
      text-align: center;
      text-transform: capitalize;
      background: var(--main-primary);
      color: white;
      border-radius: 0.25em;
    }
  }
}
</style>

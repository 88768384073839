import routes, { apiActions } from "./routes"

const attributes = {
    id: 1,
    productCategoryId: null,
    created: null,
    updated: null,
    title: null,
    type: null,
    price: null,
    timeFrame: null,
    status: null,
    categoryTitle: null,
    categoryColor: null
}

const definition = { attributes, routes }

export { apiActions }
export default definition
<template>
  <el-form-item
      :prop="fieldPath"
      :rules="validation">
    <el-radio-group
        class="radio-group"
        :class="{column:props.column}"
        v-bind="props"
        v-on="$listeners">
      <template v-for="(option, index) in radioOptions">
        <el-radio
            :key="`radio-option-${index}`"
            class="radio-option"
            :label="option.value">
          {{ option.label }}
        </el-radio>
      </template>
    </el-radio-group>
  </el-form-item>
</template>

<script>
import elementUIDefaultProps from "./elementUIDefaultProps"
import isFunction from "lodash/isFunction"

export default {
  props: {
    formRef: { type: Object, required: true },
    fieldPath: { type: String, default: "" },
    validation: { type: Array, default: () => [] },
    options: { type: [Array, Function], default: () => [] }
  },
  computed: {
    radioOptions() {
      const { options, formRef } = this

      return isFunction(options) ? options(formRef) : options
    },
    props() {
      return { ...elementUIDefaultProps, ...this.$attrs }
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .radio-group {
  display: flex;
  flex-wrap: wrap;
}

.radio-option {
  padding-bottom: var(--padding-s);
}

/deep/ .column {
  flex-direction: column;
}
</style>

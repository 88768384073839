import Account from "./Account"
import Notifications from "./Notifications"
import Screen from "./Screen"
import SideMenu from "./SideMenu/SideMenu"
import FormBuilder from "./FormBuilder/FormBuilder"
import FormBuilderV2 from "./FormBuilder.v2/FormBuilder.v2"
import AlertWidget from "./Alerts/AlertWidget"
import CoreAlertsDropdown from "./Alerts/CoreAlertsDropdown"
import CoreDialog from "./CoreDialog"
import CoreBulkActionConfirm from "./CoreBulkActionConfirm"
import CoreMultiSelectionBanner from "./CoreMultiSelectionBanner"
import CoreText from "./CoreText"
import CoreHint from "./CoreHint"
import CoreIcon from "./CoreIcon"
import CoreFogOfWar from "./CoreFogOfWar"
import CoreDraftWrapper from "./CoreDraftWrapper"
import CoreButton from "./CoreButton"
import CoreDialogStack from "./CoreDialogStack"
import CoreMap from "./CoreMap/CoreMap"
import CoreDataImporter from "./CoreDataImporter/CoreDataImporter"
import DataTableV2 from "./DataTable"
import BreadCrumbs from "./BreadCrumbs/BreadCrumbs"
import CoreGlobalSearch from "./CoreGlobalSearch/CoreGlobalSearch"
import CoreRegionAndZoneSelector from "./CoreRegionAndZoneSelector"
import CoreActionDropdown from "./CoreActionDropdown"
import BreadCrumbDropdown from "./BreadCrumbs/BreadCrumbDropdownItem"
import LabelAndText from "./LabelAndText"
import TabRouter from "./TabRouter/TabRouter"
import MagicScrollbar from "./MagicScrollbar"
import VueCurrencyInput from "vue-currency-input/dist/vue-currency-input.esm"
import PortalVue from "portal-vue"

const index = function(Vue) {
  Account(Vue)
  Notifications(Vue)
  Screen(Vue)

  Vue.use(VueCurrencyInput)

  Vue.use(PortalVue)

  Vue.component("CoreRegionAndZoneSelector", CoreRegionAndZoneSelector)
  Vue.component("CoreAlertsDropdown", CoreAlertsDropdown)
  Vue.component("CoreActionDropdown", CoreActionDropdown)
  Vue.component("CoreBulkActionConfirm", CoreBulkActionConfirm)
  Vue.component("CoreMultiSelectionBanner", CoreMultiSelectionBanner)
  Vue.component("CoreText", CoreText)
  Vue.component("CoreButton", CoreButton)
  Vue.component("CoreHint", CoreHint)
  Vue.component("CoreIcon", CoreIcon)
  Vue.component("CoreFogOfWar", CoreFogOfWar)
  Vue.component("CoreDraftWrapper", CoreDraftWrapper)
  Vue.component("CoreDialog", CoreDialog)
  Vue.component("CoreGlobalSearch", CoreGlobalSearch)
  Vue.component("CoreDialogStack", CoreDialogStack)
  Vue.component("CoreSideMenu", SideMenu)
  Vue.component("CoreFormBuilder", FormBuilder)
  Vue.component("CoreFormBuilderV2", FormBuilderV2)
  Vue.component("CoreAlertWidget", AlertWidget)
  Vue.component("CoreBreadCrumbs", BreadCrumbs)
  Vue.component("CoreBreadCrumbDropdown", BreadCrumbDropdown)
  Vue.component("CoreMagicScrollbar", MagicScrollbar)
  Vue.component("CoreTabRouter", TabRouter)
  Vue.component("CoreLabelAndText", LabelAndText)
  Vue.component("CoreMap", CoreMap)
  Vue.component("CoreDataImporter", CoreDataImporter)

  // Lazy loaded
  Vue.component("CoreDataTableV2", DataTableV2)
}

export default index

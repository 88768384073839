import createDefaultCRUD from "@/Modules/Core/utils/miragejs/createDefaultCRUD"

const baseURL = window.location.origin
const endpoint = "/version"

const defaults = createDefaultCRUD({
  baseURL,
  endpoint,
  endpoints: {
    versionJson: `/version/version.json`
  }
})

const { endpoints } = defaults

const apiActions = {
  ...defaults.apiActions,
  async getVersionJson(options = {}) {
    return this.get(endpoints.versionJson(), options)
  }
}

const routes = server => {
  defaults.routes(server)

  server.get(endpoints.versionJson(), function() {
    const mockResponse = {
      data: {
        id: 1,
        version: "1234abc"
      }
    }

    return { data: mockResponse }
  })

  return [endpoints.versionJson()]
}

export { apiActions }
export default routes

import config from "./config"
import routes from "./routes"
import Base from "@/Modules/Core/Base"
import OdysseyModels from "@/Modules/OdysseyModels"

const AppSettings = {
  ...config,
  component: Base,
  children: routes,
  models: Object.values(OdysseyModels)
}

export default AppSettings

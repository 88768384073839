import routes, { apiActions } from "./routes"

const attributes = {
  id: null,
  email: null,
  firstName: null,
  lastName: null,
  street: null,
  city: null,
  zip: null,
  country: null,
  medicareProviderNumber: null,
  mobile: null,
  notes: null,
  nursePractitioner: null,
  phone: null,
  status: null,
}

const definition = { attributes, routes }

export { apiActions }
export default definition
<template>
  <div
      class="appointment-details"
      :class="{ 'week-view': weekView }">
    <template v-if="!isLoading">
      <el-popover
          placement="top-start"
          :title="`${appointment.startTime} - ${appointment.endTime} | ${appointment.firstName} ${appointment.lastName}`"
          width="400"
          trigger="click">
        <div class="appointment-info-container">
          <el-tabs
              v-model="activeName">
            <el-tab-pane
                label="Appointment Details"
                name="first">
              <template v-if="appointment.startTime || appointment.endTime">
                <h3>Appointment Time</h3>
                <template v-if="appointment.startTime">
                  {{ appointment.startTime }}
                </template>
                <template v-if="appointment.startTime && appointment.endTime">
                  &ndash;
                </template>
                <template v-if="appointment.endTime">
                  {{ appointment.endTime }}
                </template>
              </template>

              <template v-if="appointment.staffId">
                <h3>Person in charge</h3>
                <span
                    class="tag"
                    :style="staffMemberColor">
                  {{ appointment.userFirstName }} {{ appointment.userLastName }}
                </span>
              </template>

              <template v-if="appointment.productIds">
                <h3>Selected Products & Services</h3>
                <template v-if="appointment.productIds">
                  <div
                      v-if="appointment.productIds"
                      class="product-listing">
                    <el-tag
                        v-for="(product, index) in productList"
                        :key="`product-${index}`"
                        type="product">
                      <div class="title">
                        {{ product.title }}
                      </div>
                      <div class="price">
                        A$ {{ parseFloat(product.price).toFixed(2) }}
                      </div>
                    </el-tag>
                  </div>
                </template>
              </template>

              <template v-if="appointment.locationId">
                <h3>Facility</h3>
                <strong>{{ appointment.locationTitle }}</strong>
                <p>{{ appointment.locationStreet }}, {{ appointment.locationCity }} {{ appointment.locationZip }}</p>
              </template>
              
              <template v-if="appointment.notes">
                <h3>Notes</h3>
                {{ appointment.notes }}
              </template>
            </el-tab-pane>
          </el-tabs>

          <div class="options">
            <el-popconfirm
                icon="el-icon-delete"
                icon-color="red"
                :title="`Do you want to delete this appointment?`"
                confirm-button-text="OK"
                cancel-button-text="Cancel"
                @confirm="deleteClientAppointment">
              <el-button
                  slot="reference"
                  size="small"
                  type="delete"
                  icon="el-icon-delete">
                Delete
              </el-button>
            </el-popconfirm>
            <el-button
                size="small"
                type="abel"
                icon="el-icon-edit"
                @click="editAppointment(appointment)">
              Edit
            </el-button>

            <el-button
                size="small"
                type="success"
                icon="el-icon-edit"
                @click="checkout(appointment)">
              POS Checkout
            </el-button>
          </div>
        </div>
        <div
            slot="reference"
            class="appointment-block"
            :class="appointmentStatus"
            :style="`${appointmentColor};min-height:${height}px;max-height:${height}px; height: ${height}px; top: ${topPosition}px`">
          {{ appointment.startTime }} - {{
            appointment.endTime }} | {{ appointment.firstName }}
          {{ appointment.lastName }}
          <template v-if="appointment.productIds">
            <div
                v-if="appointment.productIds"
                class="product-listing small">
              <el-tag
                  v-for="(product, index) in productList"
                  :key="`product-${index}`"
                  type="product">
                <div class="title">
                  {{ product.title }}
                </div>
              </el-tag>
            </div>
          </template>
          <strong />
        </div>
      </el-popover>
    </template>
  </div>
</template>

<script>
  import {Appointment, Product, Settings, Customer } from "@/Modules/OdysseyModels"

  export default {
    name: "AppointmentDetails",
    props: {
      appointment: {
        type: Object,
        required: true
      },
      businessStartingTime: {
        type: String,
        default: null
      },
      weekView: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        appointmentNotes: null,
        patient: null,
        location: null,
        client: null,
        activeName: "first",
        productList: null
      }
    },
    computed: {
      isLoading: {
        get() {
          if (this.appointment) {
            return false
          }
          return true
        },
        set(val) {
          return val
        }
      },
      hasContactDetails() {
        if (this.appointment.client.phone || this.appointment.client.email || this.appointment.client.city ) {
          return true
        }
        return false
      },
      appName() {
        return process.env.VUE_APP_BASE_PATH
      },
      settings() {
        return Settings.find(1)
      },
      staffMemberColor() {
        if (this.appointment.userColor) {
          return `color: white; text-shadow: 1px 1px 0 rgba(0,0,0,0.1); background-color: ${this.appointment.userColor}`
        }
        return null
      },
      appointmentColor() {
        if (this.appointment.staffId > 0 && this.appointmentStatus === "active") {
          return `color: white; text-shadow: 1px 1px 0 rgba(0,0,0,0.1); background-color: ${this.appointment.userColor};`
        }
        return null
      },
      topPosition() {
        const offSet = 47
        const { appointment } = this
        const date = new Date()
        const compareDate = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
        const startTime = new Date(`${compareDate} ${this.businessStartingTime}`).getTime()
        const endTime = new Date(`${compareDate} ${appointment.startTime}`).getTime()
        let timeDiff = endTime - startTime
        timeDiff = timeDiff / 60 / 1000


        return timeDiff + offSet
      },
      height() {
          const { appointment } = this
          const date = new Date()
          const compareDate = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
          const startTime = new Date(`${compareDate} ${appointment.startTime}`).getTime()
          const endTime = new Date(`${compareDate} ${appointment.endTime}`).getTime()

          let timeDiff = endTime - startTime
          timeDiff = timeDiff / 60 / 1000

          return timeDiff
      },
      appointmentStatus() {
        let appointmentDate = this.convertDateForIOS(this.appointment.date)
        appointmentDate = appointmentDate.setHours(0, 0, 0, 0)
        if (appointmentDate >= Date.parse(this.currentDate)) {
          return "active"
        }
        return "inactive"
      },
      currentDate() {
        let date = new Date()
        // return new Date()
        return new Date(date.setDate(date.getDate() - 1))
      }
    },
    async mounted() {
      this.productList = await this.getProductNames()
    },
    methods: {
      async checkout(appointment) {
        this.$store.dispatch("PointOfSale/clearCheckout", { root: true })

        if (appointment.productIds) {
          let products = await this.getProducts()
          if(Array.isArray(products)) {
            products.forEach(product => this.$store.dispatch('PointOfSale/addToCheckout', product, {root: true}))
          } else {
            this.$store.dispatch('PointOfSale/addToCheckout', products, {root: true})
          }
        }

        if (appointment.notes) {
          this.$store.dispatch('PointOfSale/addNotesToCheckout', appointment.notes, {root: true})
        }

        const result = await Customer.api().fetchById(appointment.customerId)
        const customer = result.response.data.data.data
        this.$store.dispatch("PointOfSale/selectCustomer", customer, { root: true })
        this.$router.push("/PointOfSale")
      },
      editAppointment() {
        const type = "AppointmentDialog"
        const mode = "edit"
        const id = this.appointment.id
        this.$store.dispatch("core/dialogStack/addDialog", { type, mode, id })
      },
      async getProducts() {
        if (this.appointment.productIds) {
          const result = await Product.api().fetchById(this.appointment.productIds)
          return result.response.data.data.data
        }
      },
      async getProductNames() {
        if (this.appointment.productIds) {
          const result = await Product.api().fetchById(this.appointment.productIds)
          const products = result.response.data.data.data


          if(Array.isArray(products)) {
            return products.map(product => {return { title: product.title, price: product.price }})
          } else {
            return [products]
          }
        }
      },
      convertDateForIOS(date) {
        const arr = date.split(/[- :]/)
        return new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5])
      },
      async deleteClientAppointment() {
        await Appointment.api().destroy({id: this.appointment.id})
        Appointment.delete(this.appointment.id)
        this.$emit("reloadAppointments")
      }
    }
  }
</script>

<style lang="scss" scoped>

  .week-view {
    .appointment {
      z-index: 10;
      left: 0;
      opacity: 0.8;
      width: 100%;
    }
  }
  .appointment-block {
    width: 90%;
    line-height: 1.6;
    padding: 0.2em 1em;
    border-radius: 0.75em;
    display: inline-block;
    margin: 0.2em;
    box-shadow: var(--box-shadow-container-light);
    color: var(--main-primary);
    transition: var(--main-transition);
    background: var(--main-light-grey);
    cursor: pointer;
    border: 1px solid transparent;
    z-index: 10;
    position: absolute;
    overflow-y: auto;

    &.active {
      background: var(--main-secondary);
      color: var(--main-white);
    }

    &:hover {
      background: var(--main-primary);
      color: var(--main-white);
      opacity: 1;
      border-color: white;
      z-index: 12;
    }
  }

  .appointment-info-container {
    padding: 1em;

    ul.products {
      li {
        display: block;
      }
    }
    h3 {
      font-weight: 500;
      font-size: 0.9em;
      margin: 2em 0 0.5em 0;
      color: var(--main-primary);

      &:first-child {
        margin-top: 0;
      }
    }

    dl {
      display: flex;

      dt {
        font-weight: 500;
        width: 4em;
      }
    }

    .tag {
      display: inline-block;
      color: white;
      padding: 0.25em 1em;
      border-radius: 0.25em;
    }

    .options {
      margin: 4em -1em 0 -1em;
      text-align: right;
      display: flex;
      justify-content: space-between;

      button {
        margin:0 1em;
      }
    }


    ul.appointment-notes {
      li {
        align-items: center;
        background: var(--main-light-grey);
        .flex-container {
          display: flex;
          flex-wrap: wrap;
          padding: 0.5em 1em;
          justify-content: space-between;

          div {
            flex: 1;

            &.actions {
              display: flex;
              justify-content: space-between;
            }
          }
        }

        .appointment-note-view {
          font-size: 0.8em;

          /deep/ .action-buttons {
            text-align: right;
            margin-top: -2.5em;
          }
        }
      }
    }
  }

  ul.products {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25em 0.5em;
    li {

    }
  }

  /deep/ .el-alert__icon {
    font-size: 1em !important;
  }

  .product-listing {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 5px;

    &.small {
      /deep/.el-tag--product {
        height: 20px !important;
        line-height: 1.6;
      }
    }
  }

  /deep/.el-tag--product {
    border: 1px solid #EBEEF5;
    background-color: #FFF;
    color: #303133;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
    display: flex;
    justify-content: space-between;
    padding: 0;
    gap: 0 10px;

    .title {
      flex: 1;
      font-weight: 500;
      padding:0 10px;
    }
    .price {
      padding:0 10px;
      flex: 1;
      background: var(--main-light-grey);
    }
  }
</style>
import routes, { apiActions } from "./routes"

const attributes = {
    id: 1,
    userId: null,
    updatedAt: null,
    createdAt: null,
    type: null,
    typeId: null,
    title: null,
    description: null
}

const definition = { attributes, routes }

export { apiActions }
export default definition
import { Version } from "@/Modules/OdysseyModels"
import store from "@/Modules/Core/store"
import config from "@/config/config"
import appConfig from "@/config"
import get from "lodash/get"
import startCase from "lodash/startCase"

const getVersion = async () => {
  const queryParams = {
    v: Math.floor(Date.now() / 1000)
  }
  const result = await Version.api().getVersionJson({ params: queryParams })
  const serverVersion = get(result.response.data.data, "version", "")
  const localVersion = getVersionFromLocalStorage()

  if (localVersion === null) {
    setVersionLocalStorage(serverVersion)
  } else if (localVersion !== serverVersion) {
    // Check for existing version-update notification
    const notificationExist = await store.dispatch(
        "core/notifications/checkForNotificationType",
        "version-update"
    )

    if (!notificationExist) {
      let message = `A new ${appConfig.appConfig.appName} update is available.`
      if (process.env.NODE_ENV !== "production") {
        let environment = startCase(process.env.NODE_ENV)

        if (config.subEnvironment) {
          environment += ` | ${startCase(config.subEnvironment)}`
        }
        message += `[${environment}]`
      }

      const notificationsPayload = {
        component: "Toast",
        type: "version-update",
        title: `${appConfig.appConfig.appName} Update`,
        visible: true,
        message: message,
        decay: false,
        payload: {
          version: serverVersion
        },
        push: true
      }

      store.dispatch("core/notifications/addToNotifications", notificationsPayload)
    }
  }
}

const getVersionFromLocalStorage = () => {
  return localStorage.getItem("git-version")
}

const setVersionLocalStorage = version => {
  localStorage.setItem("git-version", version)
}

export default getVersion

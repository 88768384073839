<template>
  <div
      class="product"
      @click="addToCheckout(product)">
    <el-card class="box-card">
      <h3>{{ product.title }}</h3>
      <div class="product-details">
        <div
            v-if="product.timeFrame > 0"
            class="product-timeframe">
          {{ product.timeFrame }} min
        </div>
        <div class="product-price">
          A$ {{ productPrice }}
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "Product",
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    productPrice() {
      return parseFloat(this.product.price).toFixed(2)
    }
  },
  methods: {
    addToCheckout(product) {
      this.$store.dispatch('PointOfSale/addToCheckout', product, { root: true })
    }
  }
}
</script>

<style lang="scss" scoped>
.product {
  /deep/ .el-card {
    height: 100%;
  }
  cursor: pointer;

  .box-card {
    position: relative;
    /deep/ .el-card__body {
      padding: 0.5em 0;
    }

    &:hover {
      background: var(--main-light-blue);
    }

    h3 {
      line-height: 1.6;
      padding: 0 1em;
      height: 6em;
    }
    .product-details {
      display: flex;
      justify-content: space-between;
      background: var(--main-light-grey);
      width: 100%;
      position: absolute;
      bottom: 0;
      padding: 0.5em 1em;

      .product-timeframe {
        flex: 1;
      }
      .product-price {
        flex: 1;
        font-weight: bold;
        color: var(--main-brand);
      }
    }
  }
}

</style>
<template>
  <core-text v-if="value">
    {{ displayValue }}
  </core-text>
</template>

<script>
export default {
  props: {
    value: { type: [String, Number, Array], default: "n/a" }
  },
  computed: {
    displayValue() {
      if (this.value) {
        let newString = ""
        if (typeof this.value === "object") {
          const newValue = this.value.map(item => item.replace(/_/g, " "))
          newString = newValue.join(", ")
          return newString.charAt(0).toUpperCase() + newString.slice(1)
        }
        else {
          const newString = this.value.replace(/_/g, " ")
          return newString.charAt(0).toUpperCase() + newString.slice(1)
        }
      }
      return null
    }
  }
}
</script>

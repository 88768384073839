import ExtendedModel from "@/Modules/Core/utils/extended-vue-orm-model"
import definition, { apiActions } from "./definition"
import Actions from "./actions"
import BulkActions from "./bulkActions"
import config from "@/config"

const baseURL = config.apiConfig.lightwerk.api
const endpoint = "/appointmentNotes"

class AppointmentNote extends ExtendedModel {
  static entity = "AppointmentNote"
  static globalSearchEnabled = true
  static definition = definition


  static apiConfig = {
    dataKey: "data.data",
    actions: {
      ...apiActions,
      async fetchByBookingId(bookingId, options = {}) {
        const url = `${baseURL}${endpoint}/list/bookingId/${bookingId}`
        return this.get(url, null)
      },
      async fetchByPatientId(patientId, options = {}) {
        const url = `${baseURL}${endpoint}/list/patientId/${patientId}`
        return this.get(url, null)
      },
      async createPDF(appointmentNoteId, options = {}) {
          const url = `${baseURL}${endpoint}/createPDF/${appointmentNoteId}`
        return this.get(url, null)
      },
      async createBulkPDF(appointmentNoteIds, options = {}) {
          const url = `${baseURL}${endpoint}/createBulkPDF/${appointmentNoteIds}`
        return this.get(url, null)
      },
      async createBulkEPCClaims(appointmentNoteIds, options = {}) {
          const url = `${baseURL}${endpoint}/createBulkEPCClaims/${appointmentNoteIds}`
        return this.get(url, null)
      },
      async createEPCClaim(appointmentNoteId, options = {}) {
        const url = `${baseURL}${endpoint}/createEPCClaim/${appointmentNoteId}`
        return this.get(url, null)
      }
    }
  }

  static recommendationsValueList = [
    {label: "Review 6 months",
      value: "review_6_months"},
    {label: "Asymmetry - refer to ENT",
      value: "asymmetry_refer_to_ent"},
    {label: "Middle ear - pathology refer to ENT",
      value: "middle_ear_pathology_refer_to_ent"},
    {label: "Refer for diagnostic audiology",
      value: "refer_for_diagnostic_audiology"},
    {label: "Refer for hearing services",
      value: "refer_for_hearing_services"},
    {label: "Soften ear wax repeat appointment",
      value: "soften_ear_wax_repeat_appointment"},
    {label: "Foreign object refer to ENT",
      value: "foreign_object_refer_to_ent"},
    {label: "Issue TV headset",
      value: "issue_tv_headset"},
    {label: "Issue pocket talker",
      value: "issue_pocket_talker"},
    {label:  "Repair hearing aid/s",
      value: "repear_hearing_aids"},
    {label: "Reprogram hearing aid/s",
      value: "reprogram_hearing_aids"},
    {label: "Hearing aid unsuitable re-fit with higher power hearing aid",
      value: "hearing_aid_unsuitable_re_fit_with_higher_power_hearing_aid"},
    {label: "No further action",
      value: "no_further_action"},
    {label: "Provide consent form prior to procedure",
      value: "provide_consent_form_prior_to_procedure"},
    {label: "Administer wax softening solvent regime",
      value: "administer_wax_softening_solvent_regime"},
  ]

  get Actions() {
    return new Actions(this)
  }

  get BulkActions() {
    return new BulkActions(this)
  }

  get patientName() {
    return `${this.patientFirstName} ${this.patientLastName}`
  }

  get PDFAttachment() {
    if (this.pdfLocation) {
      const host = document.location.host.replace("app.", "")
      return `https://${host}/${this.pdfLocation}`
    }
    return ""
  }

  get EPCClaimAttachment() {
    if (this.epcClaimLocation) {
      const host = document.location.host.replace("app.", "")
      return `https://${host}/${this.epcClaimLocation}`
    }
    return ""
  }

  get creationDate() {
    return AppointmentNote.formatTimeStamp(this.createdAt, "dateOnly")
  }

  get creationDateTime() {
    return AppointmentNote.formatTimeStamp(this.createdAt, "dateAndTime")
  }

  get clinician () {
    return `${this.staffFirstName} ${this.staffLastName}`
  }

  get valueList() {
    const {id} = this
    return {label: this.fullName, value: id, id}
  }

}
export default AppointmentNote

<template>
  <div>
    <el-cascader
        v-if="cascadeBy && options.length > 1"
        :options="options"
        v-bind="cascadeProps"
        :filter-method="cascadeFilterMethod"
        :value="cascadeValue"
        v-on="$listeners"
        @change="onChange">
      <template v-slot="{node}">
        <core-text>
          {{ node.label }}
          <el-tooltip
              v-if="node.data.entity && hasDialog(node.data.entity)"
              :content="`Open ${node.data.entity.valueList.label} Dialog`"
              placement="top">
            <i
                class="el-icon-top-right open-dialog"
                @click="(e) =>openEntityDialog(node.data.entity,e)" />
          </el-tooltip>
        </core-text>
      </template>
    </el-cascader>

    <el-select
        v-else
        v-bind="props"
        :loading="loading"
        :disabled="loading"
        v-on="$listeners">
      <template v-if="groupBy || cascadeBy">
        <el-option-group
            v-for="group in options"
            :key="group.value"
            :label="group.label">
          <el-option
              v-for="item in group.children"
              :key="item.value"
              class="option"
              :label="item.label"
              :value="item.value">
            <core-text>
              {{ item.label }} {{ filteredBy }}
            </core-text>

            <core-text class="id">
              {{	
                item.value	
              }}
              <el-tooltip
                  v-if="item.entity && hasDialog(item.entity)"
                  :content="`Open ${item.entity.valueList.label} Dialog`"
                  placement="top">
                <i
                    class="el-icon-top-right open-dialog"
                    @click="(e) => openEntityDialog(item.entity, e)" />
              </el-tooltip>
            </core-text>
          </el-option>
        </el-option-group>
      </template>

      <template v-else>
        <el-option
            v-for="item in options"
            :key="item.value"
            class="option"
            :label="item.label"
            :value="item.value">
          <core-text>
            {{ item.label }}
          </core-text>

          <core-text class="id">
            <template v-if="item.extra">
              {{ item.extra }}
            </template>
            <template v-else>
              {{ item.value }}
            </template>
            <el-tooltip
                v-if="item.entity && hasDialog(item.entity)"
                :content="`Open ${item.entity.valueList.label} Dialog`"
                placement="top">
              <i
                  class="el-icon-top-right open-dialog"
                  @click="(e) => openEntityDialog(item.entity, e)" />
            </el-tooltip>
          </core-text>
        </el-option>
      </template>
    </el-select>
    <core-button
        href="#"
        size="mini"
        class="create-entity-button"
        @click="openCreateDialog()">
      <i class="el-icon-top-right open-dialog" />
      Create {{ entityName }}
    </core-button>
  </div>
</template>

<script>
import Models from "@/Modules/OdysseyModels"
import elementUIDefaultProps from "./elementUIDefaultProps"
import get from "lodash/get"
import merge from "lodash/merge"

export default {
  props: {
    fieldPath: { type: String, default: "" },
    validation: { type: Array, default: () => [] },
    addOptions: { type: Array, default: () => [] },
    groupBy: { type: Object, default: null },
    cascadeBy: { type: Object, default: null },
    preFilter: { type: Object, default: null }
  },
  data() {
    return {
      loading: true,
      ids: [],
      openedCreateDialog: false
    }
  },
  computed: {
    filteredBy() {
      return get(this.formItem, "filteredBy")
    },
    cascadeValue() {
      const { getParentValueAndLabel, loading, $attrs } = this
      if (loading) {
        return []
      }

      return $attrs.value.map(id => {
        const record = this.entity.find(id)
        const parent = getParentValueAndLabel(record)

        return [parent.value, id]
      })
    },
    cascadeProps() {
      const { multiple, ...rest } = this.props

      return { ...rest, props: { multiple } }
    },
    flatOptions() {
      return [
        ...this.entity.findIn(this.ids).map(record => ({ ...record.valueList, entity: record })),
        ...this.addOptions
      ]
    },
    options() {
      if (this.cascadeBy || this.groupBy) {
        return this.cascadeAndGroupByOptions()
      }

      if (this.preFilter) {
        return this.preFilterOptions()
      }

      return this.flatOptions
    },
    entity() {
      return Models[this.$attrs.entity]
    },
    props() {
      return { ...elementUIDefaultProps, filterable: true, ...this.$attrs }
    },
    value() {
      return this.$attrs.value
    },
    newEntity() {
      return new this.entity
    },
    entityName() {
      return this.entity.entity
    },
    dialogStack() {
      return this.$store.state.core.dialogStack.stack
    }
  },
  watch: {
    dialogStack: {
      handler() {
        const items = this.dialogStack.filter(dialog =>
         dialog.type.indexOf(this.entityName) !== -1
        )
        if (items.length > 0) {
          this.openedCreateDialog = true
        } else {
          if (this.openedCreateDialog) {
            this.openedCreateDialog = false
          }
        }
      },
      deep: true
    },
    openedCreateDialog: {
      handler(val) {
        if(!val) {
          this.getEntityItems()
        }
      }
    },
    options: {
      immediate: true,
      handler() {
        const { value, options } = this
        const { preSelect } = this.props

        if (preSelect && !value && options.length) {
          this.$emit("input", options[0].value)
        }
      }
    }
  },
  async beforeMount() {
    await this.getEntityItems()
  },
  methods: {
    async getEntityItems() {
      this.loading = true

      // HACK: Our index endpoints have a default count of 20 so we will only ever get back 20 results. This is a short term hack that needs to have a better solution.
      const { response } = await this.entity
          .api()
          .fetchAll(
              merge(
                  { params: this.props.params || { per_page: 1000, page: 1 } },
                  get(this.props, "axiosOptions", {})
              )
          )

      this.ids = response.data.data.data.map(({ id }) => id)

      this.loading = false
    },
    getParentValueAndLabel(record) {
      const config = this.cascadeBy || this.groupBy
      const entity = get(record, config.entity, { valueList: {} })

      const value = get(record, `entity.${config.key}`, get(entity, "valueList.value"))
      const label = get(record, `entity.${config.label}`, get(entity, "valueList.label"))

      return { value, label, entity }
    },
    hasDialog(entity) {
      return get(entity, "Actions.openDialog")
    },
    openCreateDialog() {
      this.newEntity.Actions.openDialog()
    },
    openEntityDialog(entity, e) {
      e.stopPropagation()
      entity.Actions.openDialog()
    },
    cascadeFilterMethod(node, keyword) {
      return node.text.toLowerCase().includes(keyword.toLowerCase())
    },
    onChange(nextValue) {
      //eslint-disable-next-line
      const ids = nextValue.map(([parentId, id]) => id)

      this.$emit("input", ids)
    },
    preFilterOptions() {
      if (this.preFilter.id) {
        return this.flatOptions.filter(item => {
          return item.entity[this.preFilter.filterBy] === this.preFilter.id
        })
      }
      return this.flatOptions
    },
    cascadeAndGroupByOptions() {
      const { getParentValueAndLabel } = this

      return this.flatOptions.reduce((groupedOptions, nextOption) => {
        const parent = getParentValueAndLabel(nextOption.entity)

        const optionGroupMatchesValue = optionGroup => optionGroup.value === parent.value
        const optionGroupIndex = groupedOptions.findIndex(optionGroupMatchesValue)

        if (optionGroupIndex >= 0) {
          groupedOptions[optionGroupIndex].children.push(nextOption)
        } else {
          groupedOptions.push({ ...parent, children: [nextOption] })
        }
        return groupedOptions
      }, [])
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-select {
  width: 100%;
}

.option {
  display: flex;
  justify-content: space-between;

  .id {
    color: #8492a6;
    font-size: 0.8em;
  }
  &.selected::after {
    right: 50px !important;
  }
}

.open-dialog {
  border: 1px solid;
  border-color: inherit;
  border-radius: 3px;
  cursor: pointer;
  transform: scale(0.85);

  &:hover {
    opacity: 0.7;
  }
}
.create-entity-button {
  font-size: 0.75em;
  i {
    margin-right: 1em;
  }
}
</style>

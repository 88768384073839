import store from "../../store"
import get from "lodash/get"
import isDisabledForEnvironment, { getEnvironment } from "./isDisabledForEnvironment"
import hasMissingDependencies from "./hasMissingDependencies"
import isNotPermitted from "./isNotPermitted"
import isDisabled from "./isDisabled"
import { environments } from "./constants"
// import { environments, PERMISSION_STRING_EXAMPLE } from "./constants"
import parsePermissionString from "./parsePermissionString"

const passesAllCriteria = (config = {}) => {
  const { settings } = config
  // const { moduleName, featureName } = parsePermissionString(permissionString)

  const showMissingPermissionConfigWarning = () => {
    const ENV = getEnvironment()

    const enabledEvironments = [environments.dev]

    return !settings.permissions && enabledEvironments.includes(ENV)
  }

  if (showMissingPermissionConfigWarning()) {
    // console.warn(
    //   `Feature settings were not found for: "${moduleName}.${featureName}". Check getPemissions args to ensure you are using correct names/naming convention. e.g. '${PERMISSION_STRING_EXAMPLE}'.`
    // )
  }

  const criteria = [hasMissingDependencies, isDisabledForEnvironment, isDisabled, isNotPermitted]

  const results = criteria.map(criterion => criterion(config))

  // if any criteria is found to be true then feature is not available to user.
  if (results.includes(true)) {
    return false
  }

  return true
}

const removeVuexObservers = object => JSON.parse(JSON.stringify(object))

const getPermissions = (featurePermissionString, dependencies = []) => {
  const { moduleName, featureName, permissionKey } = parsePermissionString(featurePermissionString)

  const featureFlagKey = permissionKey ? "auth.user.permissions" : "settings.featureFlags"

  const featureFlags = get(store.state, featureFlagKey, null)

  if (!featureFlags) {
    return false
  }

  const modulePermissionString = `${moduleName}:Module`
  const moduleKey = `${moduleName}.Module`
  const featureKey = `${moduleName}.${featureName}`

  const moduleSettings = removeVuexObservers(get(featureFlags, moduleKey, {}))
  const featureSettings = removeVuexObservers(get(featureFlags, featureKey, {}))

  moduleSettings.dependencies = [...get(moduleSettings, "dependencies", []), ...dependencies]
  featureSettings.dependencies = [...get(featureSettings, "dependencies", []), ...dependencies]

  const moduleSettingsPassed = passesAllCriteria({
    permissionString: modulePermissionString,
    settings: moduleSettings
  })

  if (!moduleSettingsPassed) {
    return false
  }

  const featureSettingsPassed = passesAllCriteria({
    permissionString: featurePermissionString,
    settings: featureSettings
  })

  if (!featureSettingsPassed) {
    return false
  }

  return true
}

export { environments }

export default getPermissions

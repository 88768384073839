<template>
  <div class="form">
    <CoreFormBuilderV2
        :data="updatedAppointmentData"
        :form-config="formConfig"
        :is-loading="isLoading"
        :mode="mode"
        @onSubmit="onSubmit"
        @onCancel="onCancel" />
  </div>
</template>

<script>
  import { Appointment } from "@/Modules/OdysseyModels"
  import formConfig from "./formConfig"
  import moment from 'moment'

  export default {
    name: "AppointmentManagementForm",
    props: {
      dialogProps: { type: Object, required: true },
      appointmentData: {
        type: Object, default: () => ({})
      },
      patientData: {
        type: Object, default: () => ({})
      }
    },
    data() {
      return {
        isLoading: false,
        updatedAppointmentData: {},
        formConfig
      }
    },
    computed: {
      mode() {
        return this.dialogProps.mode
      }
    },
    watch: {
      patientData: {
        handler() {
          if (this.patientData) {
            if (this.patientData.locationId) {
              this.updatedAppointmentData.locationId = this.patientData.locationId
            } 
            if (this.patientData.id) {
              this.updatedAppointmentData.patientId = this.patientData.id
              this.updatedAppointmentData.selectedUserId = this.$store.state.auth.user.uid
            }
          }
        },
        deep: true,
        immediate: true
      }
    },
    created() {

      if (this.appointmentData) {
        this.updatedAppointmentData = this.appointmentData
        this.updatedAppointmentData.productIds = this.appointmentData.productIds.split(',')
      }
      this.updatedAppointmentData.selectedUserId = this.$store.state.auth.user.uid
      this.updatedAppointmentData.date = moment(this.$store.state.AppointmentManagement.currentDate).format('YYYY-MM-DD hh:mm:ss' )

      if (this.mode === "create") {
        if (this.dialogProps.optionsParams && this.dialogProps.optionsParams.customerId) {
          this.updatedAppointmentData.customerId = this.dialogProps.optionsParams.customerId
        }

        if (this.dialogProps.optionsParams && this.dialogProps.optionsParams.startTime) {
          this.updatedAppointmentData.startTime = `${this.dialogProps.optionsParams.startTime}:00`
        }

        if (this.dialogProps.optionsParams && !this.dialogProps.optionsParams.endTime && this.dialogProps.optionsParams.startTime) {
          this.updatedAppointmentData.endTime = `${parseInt(this.dialogProps.optionsParams.startTime) + 1}:00`
        }

        if (this.dialogProps.optionsParams && this.dialogProps.optionsParams.staffId) {
          this.updatedAppointmentData.staffId = this.dialogProps.optionsParams.staffId
        } else {
          this.updatedAppointmentData.staffId = this.$store.state.auth.user.uid
        }
      }
    },
    methods: {
      onCancel() {
        this.dialogProps.closeDialog()
      },
      async onSubmit(Form) {
        try {
          const flatFormData = Form.getFlatData()

          if (this.mode === "create") {
            // console.log("flatFormData", flatFormData)
            await Appointment.api().create(flatFormData)
          } else if (this.mode === "edit") {
            flatFormData.id = this.appointmentData.id
            await Appointment.api().update(flatFormData)
          }

          const notificationsPayload = {
            component: "Toast",
            type: "success",
            title: "Thank You",
            message: "Your Appointment details have been successfully updated.",
            visible: true,
            decay: 3500
          }

          this.$store.dispatch("core/notifications/addToNotifications", notificationsPayload)

          this.dialogProps.closeDialog()
          this.$store.dispatch("AppointmentManagement/updateReloadAppointments", true)
        } catch (error) {
          Form.setSubmitting(false)
          // @todo: handle error
        }
      }
    }
  }
</script>
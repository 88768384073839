<template>
  <div class="step-options">
    <el-button
        type="warning"
        @click="resetStep()">
      Cancel
    </el-button>

    <template v-if="activeStep > 0 && prevTitle">
      <el-button
          type="info"
          @click="prevStep()">
        &lsaquo; {{ prevTitle }}
      </el-button>
    </template>
    <template v-if="nextTitle">
      <el-button
          type="success"
          @click="nextStep()">
        {{ nextTitle }} &rsaquo;
      </el-button>
    </template>
    <template v-if="closeTill">
      <el-button
          type="success"
          @click="close()">
        Close Till and Clear Cart
      </el-button>
    </template>
  </div>
</template>

<script>
export default {
  name: "StepOptions",
  props: {
    nextTitle: {
      type: String,
      default: null
    },
    prevTitle: {
      type: String,
      default: null
    },
    closeTill: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    activeStep() {
      return this.$store.state.PointOfSale.checkoutStep
    }
  },
  methods: {
    nextStep() {
      this.$store.dispatch("PointOfSale/nextStep", { root: true })
    },
    prevStep() {
      if (this.$store.state.PointOfSale.checkoutStatus !== "finalised") {
        this.$store.dispatch("PointOfSale/prevStep", {root: true})
      }
    },
    resetStep() {
      this.$store.dispatch("PointOfSale/resetStep", { root: true })
      this.$store.dispatch("PointOfSale/selectPayment", null, { root: true })
      this.$emit("closeModal")
    },
    close() {
      this.$emit("closeModal")
      this.$store.dispatch("PointOfSale/clearCheckout", { root: true })
    }
  }
}
</script>

<style lang="scss" scoped>
.step-options {
  text-align: right;
  margin-top: 2em;
}
</style>
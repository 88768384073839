import routes, { apiActions } from "./routes"

const attributes = {
    id: 1,
    active: 1,
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    mobile: null,
    status: null
}

const definition = { attributes, routes }

export { apiActions }
export default definition
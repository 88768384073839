export default {
  businessDetails: {
    companyName: {
      type: "text",
      value: null,
      label: "Company Name",
      placeholder: "ie. ACME Pty Ltd",
      validation: [{required: true, message: "Please enter a company name", trigger: "blur"}]
    },
    abn: {
      type: "text",
      value: null,
      label: "ABN",
      placeholder: ""
    },
    firstName: {
      type: "text",
      value: null,
      label: "First Name",
      placeholder: "ie. John",
      validation: [{required: true, message: "Please enter a product name", trigger: "blur"}]
    },
    lastName: {
      type: "text",
      value: null,
      label: "Last Name",
      placeholder: "ie. Doe",
      validation: [{required: true, message: "Please enter a product name", trigger: "blur"}]
    }
  },
  businessAddress: {
    street: {
      type: "text",
      value: null,
      label: "Street",
      placeholder: "ie. 123 Fake Street",
      validation: [{required: true, message: "Please enter a street", trigger: "blur"}]
    },
    suburb: {
      type: "text",
      value: null,
      label: "Suburb",
      placeholder: "ie. Redfern"
    },
    city: {
      type: "text",
      value: null,
      label: "City",
      placeholder: "ie. Sydney",
      validation: [{required: true, message: "Please enter a City", trigger: "blur"}]
    },
    zip: {
      type: "number",
      value: null,
      label: "Postcode",
      placeholder: "ie. 2000",
      validation: [{required: true, message: "Please enter a Postcode", trigger: "blur"}]
    },
    state: {
      type: "text",
      value: null,
      label: "State",
      placeholder: "ie. NSW",
      validation: [{required: true, message: "Please enter a State", trigger: "blur"}]
    },
    country: {
      type: "text",
      value: null,
      label: "Country",
      placeholder: "ie. Australia",
      validation: [{required: true, message: "Please enter a Country", trigger: "blur"}]
    }
  },
  contactDetails: {
    email: {
      type: "email",
      value: null,
      label: "Email",
      placeholder: "ie. john@doe.com",
      validation: [{required: true, message: "Please enter company email address", trigger: "blur"}]
    },
    placeholder2: {
      type: "placeholder"
    },
    phone: {
      type: "text",
      value: null,
      label: "Phone",
      placeholder: ""
    },
    mobile: {
      type: "text",
      value: null,
      label: "Mobile Phone",
      placeholder: ""
    }
  }
}

<template>
  <div class="form-builder-wrapper">
    <component
        :is="wrapper"
        v-model="formData"
        :form-meta="formMeta"
        :is-loading="isLoading"
        v-on="$listeners" />
  </div>
</template>

<script>
import ElementUI from "./ElementUI"
import get from "lodash/get"
import set from "lodash/set"

export default {
  name: "CoreFormBuilder",
  components: {
    ElementUI
  },
  props: {
    formData: { type: Object, required: true },
    formMeta: { type: Object, default: () => ({}) },
    rawData: { type: Object, default: null },
    isLoading: { type: Boolean, default: false },
    wrapper: { type: String, default: "ElementUI" }
  },
  created() {
    this.formUpdated = false

    if (this.rawData) {
      this.mapRawData()
    }
  },
  methods: {
    mapRawData() {
      Object.keys(this.formData).filter(formGroup => {
        if (formGroup !== "_options") {
          Object.keys(this.formData[formGroup]).filter(fieldKey => {
            const rawDataFieldPath = `${fieldKey.replace("/", ".")}`
            const fieldValuePath = `${formGroup}.${fieldKey}.value`
            const fieldMutateValuePath = `${formGroup}.${fieldKey}.mutateValue`

            const mutateValue = get(this.formData, fieldMutateValuePath)
            const rawDataValue = get(this.rawData, rawDataFieldPath, null)

            if (mutateValue) {
              const mutatedValue = mutateValue(rawDataValue, this.rawData)

              set(this.formData, fieldValuePath, mutatedValue)
            } else {
              set(this.formData, fieldValuePath, rawDataValue)
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped></style>

import NodeCache from "node-cache"
import queryString from "qs"

const cacheable = true

const applyRequestCache = axios => {
  const cache = new NodeCache()

  axios.interceptors.request.use(
    async request => {
      // Only cache GET requests
      if (request.method === "get" && cacheable) {
        let url = request.url

        request.validateStatus = status => {
          if (status === 304) return true
          return status >= 200 && status < 300
        }

        // Append the params
        if (request.params) url += queryString.stringify(request.params)

        const _cached = cache.get(url)

        if (_cached) {
          _cached._fromCache = true

          request.headers["If-None-Match"] = _cached._etag
        }
      }

      return request
    },
    error => Promise.reject(error)
  )

  // On response, set or delete the cache
  axios.interceptors.response.use(
    response => {
      const { params, method } = response.config
      const { etag } = response.headers
      let url = response.config.url

      // if you dont want to cache a specific url, send a param `_cache = false`
      const isCacheable = !params || (params && params._cache !== false)

      if (cacheable && isCacheable) {
        if (params) url += queryString.stringify(params)

        if (response.status === 304) {
          response.data = cache.get(url)
        } else {
          if (method === "get" && etag) {
            response.data._etag = etag

            cache.set(url, response.data)
          }
        }
      }

      return response
    },
    error => Promise.reject(error)
  )
}

export default applyRequestCache

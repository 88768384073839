<template>
  <core-alerts-dropdown
      :notification-count="notificationCount">
    <div class="message-options">
      <el-button
          v-if="notificationCount > 0"
          size="mini"
          icon="el-icon-refresh-left"
          @click="clearAllMessages()">
        Clear All
      </el-button>
    </div>

    <div
        v-if="showEmptyState"
        class="flex-center">
      <core-text
          label
          semibold>
        No New Notifications
      </core-text>
    </div>

    <template v-else>
      <AlertBox
          v-for="(items, index) in alerts"
          :key="`alert-${index}`"
          :items="items"
          :alert-type="index" />
    </template>
  </core-alerts-dropdown>
</template>

<script>
import AlertBox from "./AlertBox"

export default {
  name: "CoreAlertsAndNotifications",
  components: { AlertBox },
  computed: {
    notificationCount() {
      return this.$store.getters["core/alerts/numberOfAlerts"]
    },
    showEmptyState() {
      return !Object.keys(this.alerts).length
    },
    alerts() {
      return this.$store.state.core.alerts.items
    }
  },
  methods: {
    clearAllMessages() {
      this.$store.dispatch("core/alerts/clearAlerts", { root: true })
      this.closeDropdown()
    }
  }
}
</script>


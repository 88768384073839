export default {
  emailSettings: {
    mailMode: {
      type: "select",
      filterable: true,
      value: null,
      label: "Mail Mode",
      options: [
        {label: "Pop3", value: "pop3"},
        {label: "IMAP", value: "imap"}
      ],
      validation: [{required: true, message: "Please select a product type", trigger: "blur"}]
    },
    mailNotificationsAddress: {
      type: "email",
      value: null,
      label: "Email Address for Notifications",
      placeholder: "ie. noreply@example.org",
      validation: [{required: true, message: "Please enter an email address", trigger: "blur"}]
    },
    mailReplyName: {
      type: "text",
      value: null,
      label: "Sender Name",
      placeholder: "ie. ACME Support ",
      validation: [{required: true, message: "Please enter an Sender Name", trigger: "blur"}]
    },
    mailPlaceholder: {
      type: "placeholder"
    },
    mailFooter: {
      type: "textarea",
      value: null,
      label: "Email Footer Text Template"
    }
  },
  SMSSettings: {
    smsEnabled: {
      type: "switch",
      value: null,
      label: "Enable SMS"
    },
    smsPlaceholder: {
      type: "placeholder"
    },
    smsReplyName: {
      type: "text",
      value: null,
      label: "SMS Sender Name"
    },
    smsFooter: {
      type: "textarea",
      value: null,
      label: "SMS Footer Text Template"
    }
  }
}

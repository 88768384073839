<template>
  <router-link
      v-slot="{ href, navigate, isActive }"
      :to="link"
      :style="tab.style"
      :exact="tab.to.exact">
    <slot name="icon" />

    <div
        class="tab-container"
        :class="{ active:isActive }">
      <div class="tab-inner">
        <core-text
            :href="href"
            class="tab-link flex-auto"
            tag="a"
            truncate
            :tooltip-props="tab.tooltipProps">
          {{ tab.label }}
        </core-text>

        <slot
            name="tab-status" />

        <slot
            name="close-button" />
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "LinkTab",
  props: { tab: { type: Object, default: () => ({}) } },
  computed: {
    link() {
      return { query: this.$route.query, ...this.tab.to }
    }
  }
}
</script>

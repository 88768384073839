<template>
  <div class="product">
    <div class="product-title">
      {{ product.title }}
    </div>
    <div
        class="product-quantity"
        :class="{ animated : animateQuantity }">
      {{ product.quantity }}x
    </div>
    <div class="product-price">
      AUD {{ productPrice }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Product",
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      animateQuantity: false
    }
  },
  computed: {
    quantity() {
      return this.product.quantity
    },
    productPrice() {
      return parseFloat(this.product.price).toFixed(2) * this.product.quantity
    }
  },
  watch: {
    quantity() {
      this.animateQuantity = true

      setTimeout(() => {
        this.animateQuantity = false
      }, 1000)
    }
  }
}
</script>

<style scoped lang="scss">
.product {
  display: flex;
  padding: 0.5em 0;
  border-bottom: 1px dotted var(--main-darker);

  &:last-child {
    border: 0;
  }

  .product-title {
    flex: 2;
  }
  .product-quantity {
    flex: 1;
    text-align: center;

    &.animated {

    }
  }
  .product-price {
    flex: 1;
    text-align: right;
  }
}
</style>
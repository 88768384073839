<template>
  <div class="select-payment">
    <ul :class="{ selected: selectedPayment !== null }">
      <li
          :class="{ active: selectedPayment === 'cash' }"
          @click="selectPayment('cash')">
        <h3>Cash</h3>
      </li>
      <li
          :class="{ active: selectedPayment === 'credit-card' }"
          @click="selectPayment('credit-card')">
        <h3>Credit Card</h3>
      </li>
      <li
          :class="{ active: selectedPayment === 'voucher' }"
          @click="selectPayment('voucher')">
        <h3>Voucher</h3>
      </li>
    </ul>
    <div class="payment-screen">
      <template v-if="selectedPayment === 'cash'">
        <CashPayment />
      </template>
      <template v-else-if="selectedPayment === 'credit-card'">
        <CreditCardPayment />
      </template>
    </div>

    <StepOptions
        :next-title="finalise"
        prev-title="Check Cart" />
  </div>
</template>

<script>
import StepOptions from "./StepOptions"
import CashPayment from "../CashPayment"
import CreditCardPayment from "../CreditCardPayment";

export default {
  name: "SelectPayment",
  components: {
    CashPayment,
    CreditCardPayment,
    StepOptions
  },
  computed: {
    selectedPayment() {
      return this.$store.state.PointOfSale.selectedPayment
    },
    finalise() {
      if (this.selectedPayment) {
        return "Finalise Checkout"
      }
      return null
    },
    products() {
      return this.$store.state.PointOfSale.selectedProducts
    },
    priceTotal() {
      if (this.products.length > 0) {
        let total = 0
        this.products.forEach(product => {
          total = total + (parseFloat(product.price) * product.quantity)
        })
        return total.toFixed(2)
      }
      return null
    }
  },
  mounted() {
    this.$store.dispatch("PointOfSale/updateTotal", this.priceTotal, { root: true })
  },
  methods: {
    selectPayment(payment) {
      this.$store.dispatch("PointOfSale/selectPayment", payment, { root: true })
    }
  }
}
</script>

<style lang="scss" scoped>
.select-payment {
  ul {
    list-style: none;
    display: flex;
    transition: var(--main-transition);

    &.selected {
      font-size: 0.7em;

      li {
        padding: 0.5em 0.25em;
        position: relative;
      }
    }

    li {
      background: white;
      padding: 2em 1em;
      text-align: center;
      box-shadow: var(--box-shadow-container);
      flex: 1;
      margin: 1em;
      border-radius: 0.5em;
      cursor: pointer;
      border: 1px solid transparent;
      transition: var(--main-transition);

      &:hover,
      &.active {
        color: var(--main-success-green);
        border-color: var(--main-success-green);
      }
      &.active {
        :after {
          content: "✔";
          position: absolute;
          top: -.75em;
          right: -.75em;
          width: 2.5em;
          height: 2.5em;
          background: var(--main-success-green);
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0.25em;
          border: 2px solid white;
        }
      }
    }
  }
}
</style>
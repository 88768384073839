export default {
  SMTPServerSettings: {
    smtpServer: {
      type: "text",
      value: null,
      label: "SMTP Server",
      placeholder: ""
    },
    placeholder1: {
      type: "placeholder"
    },
    smtpServerUsername: {
      type: "text",
      value: null,
      label: "SMTP Server Username",
      placeholder: ""
    },
    smtpServerPassword: {
      type: "password",
      value: null,
      label: "SMTP Server Password",
      placeholder: ""
    }
  },
  SMSGatewaySettings: {
    smsGateway: {
      type: "text",
      value: null,
      label: "SMS Gateway",
      placeholder: ""
    },
    placeholder: {
      type: "placeholder"
    },
    smsApiKey: {
      type: "text",
      value: null,
      label: "API Key",
      placeholder: ""
    },
    smsApiSecret: {
      type: "password",
      value: null,
      label: "API Secret",
      placeholder: ""
    },
    smsPlaceholder2: {
      type: "placeholder"
    }
  }
}

const models = {}

function importAll(r) {
  r.keys().forEach(key => {
    const [modelName] = key.match(/([A-Z\s]+)\w*/)

    models[modelName] = r(`./${modelName}/${modelName}.js`).default
  })
}

importAll(require.context("./", true, /([A-Z\s]+)\w*.js$/))

Object.keys(models).forEach(key => {
  models[key]._ALL_MODELS = models
})
module.exports = models

<template>
  <div>
    <el-tabs
        v-if="!isLoading"
        type="border-card">
      <el-tab-pane
          v-loading="isLoading"
          v-for="(page, index) in pages"
          :key="`page-${index}`"
          :label="page.label">
        <FormWrapper
            :form="page.form" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import FormWrapper from "../forms/SystemSettingsForm/FormWrapper"
import { Settings } from "@/Modules/OdysseyModels";
export default {
  name: "SystemSettingsTableView",
  components: {
    FormWrapper
  },
  data() {
    return {
      isLoading: false,
      pages: [
        {
          label: "Business Details",
          form: "businessDetails"
        },
        {
          label: "SMS & Emails",
          form: "smsAndEmails"
        },
        {
          label: "SEO Settings",
          form: "seoSettings"
        },
        {
          label: "Social Networks",
          form: "socialNetworks"
        },
        {
          label: "API Settings",
          form: "apiSettings"
        }
      ],
    }
  },
  async created() {
    this.isLoading = true
    await Settings.api().fetchById()
    this.isLoading = false
  }
}
</script>

<style lang="scss" scoped>
  /deep/ .el-tabs__header {
    margin: 0.5em 0 !important;
  }
  /deep/ .el-tabs--border-card {
    border-radius: 0.5em;
  }
</style>

const useMock = ({ globalMock, localMock }) => {

  // Never use mock data in production or staging
  if (["staging", "production"].includes(process.env.NODE_ENV)) {
    return false
  }

  return globalMock || localMock
}

export default useMock

export default {
  // When the bound element is inserted into the DOM...
  inserted: function(el, binding) {
    el.setAttribute("role", binding.arg)
    el.setAttribute("aria-label", binding.value)

    if (binding.arg === "button") {
      el.setAttribute("tabindex", "0")
      el.addEventListener("keyup", e => {
        if (e.keyCode === 13) {
          el.click()
        }
      })
    }
  }
}

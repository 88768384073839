<template>
  <label
      :class="{ loading: loading, indeterminate: indeterminate }"
      class="switch">
    <input
        :checked="value"
        type="checkbox"
        class="switch-input"
        @change="handleInput">
    <span
        :style="`color: ${color}`"
        class="switch-label"
        :data-on="labelOn"
        :data-off="labelOff" />
    <span class="switch-handle" />
    <loader v-if="loading" />
  </label>
</template>

<script>
export default {
  name: "Swoggle",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    labelOn: {
      type: String,
      required: false,
      default: "on"
    },
    labelOff: {
      type: String,
      required: false,
      default: "off"
    },
    color: {
      type: String,
      required: false,
      default: "#409EFF"
    },
    loading: {
      type: Boolean,
      default: false
    },
    indeterminate: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      content: this.value
    }
  },
  methods: {
    handleInput(e) {
      this.$emit("input", e.target.checked)
    }
  }
}
</script>

<style lang="scss" scoped>
.switch {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 5em;
  padding: 2px;
  border-radius: 18px;
  cursor: pointer;
  background: transparent;

  &.loading {
    span {
      background: #fafafa !important;
    }
  }

  &.indeterminate {
    .switch-handle {
      left: 1.95em !important;
    }
    .switch-label {
      background: var(--main-primary);
    }
    .switch-label:before,
    .switch-label:after {
      content: "";
    }
  }
}

.switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.switch-label {
  position: relative;
  display: block;
  height: inherit;
  padding: 1.5em;
  font-size: 0.7em;
  text-transform: uppercase;
  background: #eceeef;
  border-radius: inherit;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
  -webkit-transition: 0.15s ease-out;
  -moz-transition: 0.15s ease-out;
  -o-transition: 0.15s ease-out;
  transition: 0.15s ease-out;
  -webkit-transition-property: opacity background;
  -moz-transition-property: opacity background;
  -o-transition-property: opacity background;
  transition-property: opacity background;
}
.switch-label:before,
.switch-label:after {
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  line-height: 1;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
}
.switch-label:before {
  content: attr(data-off);
  right: 11px;
  color: #aaa;
  text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}
.switch-label:after {
  content: attr(data-on);
  left: 11px;
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
  opacity: 0;
}
.switch-input:checked ~ .switch-label {
  background: var(--main-primary);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
}
.switch-input:checked ~ .switch-label:before {
  opacity: 0;
}
.switch-input:checked ~ .switch-label:after {
  opacity: 1;
}

.switch-handle {
  position: absolute;
  top: 0.4em;
  left: 0.5em;
  width: 1.5em;
  height: 1.5em;
  background: white;
  border-radius: 50%;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  background-image: -webkit-linear-gradient(top, white 40%, #f0f0f0);
  background-image: -moz-linear-gradient(top, white 40%, #f0f0f0);
  background-image: -o-linear-gradient(top, white 40%, #f0f0f0);
  background-image: linear-gradient(to bottom, white 40%, #f0f0f0);
  -webkit-transition: left 0.15s ease-out;
  -moz-transition: left 0.15s ease-out;
  -o-transition: left 0.15s ease-out;
  transition: left 0.15s ease-out;
}
.switch-handle:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -6px 0 0 -6px;
  width: 12px;
  height: 12px;
  background: #f9f9f9;
  border-radius: 6px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
  background-image: -webkit-linear-gradient(top, #eeeeee, white);
  background-image: -moz-linear-gradient(top, #eeeeee, white);
  background-image: -o-linear-gradient(top, #eeeeee, white);
  background-image: linear-gradient(to bottom, #eeeeee, white);
}
.switch-input:checked ~ .switch-handle {
  left: 3em;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

.switch-green > .switch-input:checked ~ .switch-label {
  background: #4fb845;
}

$loader-duration: 1.4s;

$body-bg: #fff;

/deep/ .loader-wrapper {
  width: 40px !important;
  height: 60px !important;
  position: absolute !important;
  margin: -10px 0 0 -14px !important;
  left: 3em !important;
  top: 1em !important;

  .loader {
    width: 20px;
    height: 60px;
    top: 0;
    left: 0;
    position: absolute;

    &:after {
      display: none;
    }
  }
  .roller,
  .roller:last-child {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    animation-duration: $loader-duration;
    transform: rotate(135deg);
  }
  .roller:last-child {
    left: auto;
    right: 0;
    animation-duration: $loader-duration;
    transform: rotate(-45deg);
  }
  .roller:before,
  .roller:last-child:before {
    content: "";
    display: block;
    width: 5px;
    height: 5px;
    background: var(--main-primary);
    border-radius: 50%;
  }
}
</style>

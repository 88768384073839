const hasMissingDependencies = ({ settings = {} }) => {
  const { dependencies = [] } = settings

  if (
    dependencies.includes(undefined) ||
    dependencies.includes(null) ||
    dependencies.includes(false)
  ) {
    return true
  }

  return false
}

export default hasMissingDependencies

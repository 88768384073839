import autobind from "auto-bind"
import capitalize from "lodash/capitalize"

class Actions {
    constructor(entity) {
        this.entity = entity
        autobind(this)
    }

    openDialog(options = {}) {
        this.openUserRoleDialog(options)
    }

    openUserRoleDialog(options = {}) {
        const type = "UserRoleDialog"
        const { mode = "view" } = options
        const { id, store } = this.entity

        store.dispatch("core/dialogStack/addDialog", { type, mode, id })
    }

    get actionsList() {
        const { getPermissions, status: currentStatus } = this.entity
        const self = this.entity

        const isApproved = currentStatus === "enabled"
        const statusChangeText = isApproved ? "disable" : "enable"

        return [
            {
                visible: getPermissions("UserRoleManagement:userRole:canRead"),
                label: "View User Role",
                icon: "el-icon-view",
                onClick: () => this.openUserRoleDialog({ mode: "view" })
            },
            {
                visible: getPermissions("UserRoleManagement:userRole:canUpdate"),
                label: "Edit User Role",
                icon: "el-icon-edit",
                onClick: () => this.openUserRoleDialog({ mode: "edit" })
            },
            {
                visible: getPermissions("UserRoleManagement:userRole:canUpdate"),
                label: `${capitalize(statusChangeText)} User Role`,
                icon: isApproved ? "el-icon-close" : "el-icon-check",
                onConfirm: () =>
                    self.Api.update({ id: self.id, status: isApproved ? "disabled" : "enabled" }),
                confirmProps: {
                    title: `Are you sure you want to ${statusChangeText} this user role?`,
                    confirmButtonText: `${capitalize(statusChangeText)} User Role`
                }
            },
            {
                visible: getPermissions("UserRoleManagement:userRole:canDestroy"),
                divided: true,
                class: "danger",
                label: "Delete",
                icon: "el-icon-delete",
                onConfirm: () => self.Api.destroy(self),
                confirmProps: {
                    title: "Are you sure you want to delete this User Role?",
                    confirmButtonText: "Delete User Role"
                }
            }
        ].filter(({ visible }) => visible)
    }
}

export default Actions

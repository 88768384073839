<template>
  <fragment>
    <CoreScreen
        card
        class="appointmentManagement-wrapper"
        header="AppointmentManagement"
        icon="AppointmentManagementIcon"
        :create-button="createButtons">
      <router-view />
    </CoreScreen>
  </fragment>
</template>

<script>
import { Appointment } from "@/Modules/OdysseyModels"
export default {
  name: "AppointmentManagement",
  computed: {
    createButtons() {
      return [
        { label: 'Create Appointment', onCreate: this.createAppointment },
      ]
    }
  },
  methods: {
    createAppointment() {
      new Appointment().Actions.openAppointmentDialog()
    }
  }
}
</script>

<template>
  <div class="cash-payment-container">
    <div class="calculate-change">
      <h3>Calculate Change</h3>
    </div>
    <div class="cash-payment-options">
      <div
          v-for="(preset, index) in presets"
          :key="index"
          class="cash-payment-option"
          :class="{ selected: isSelected(index) }"
          @click="selectPreset(preset, index)">
        <template v-if="index === 0">
          <span>Exact Amount</span>
        </template>
        {{ preset | currency }}
      </div>
      <div
          class="cash-payment-option cash-payment-option-custom"
          :class="{ selected: isCustomSelected }"
          @click="selectCustom()">
        Custom Amount
      </div>
    </div>
    <div
        v-if="isCustomSelected"
        class="cash-payment-custom">
      <div class="cash-payment-custom-prefix">
        $
      </div>
      <input
          v-model.number="cashReceived"
          class="cash-payment-custom-input"
          type="number"
          :step="0.01"
          :min="0"
          placeholder="$0.00">
    </div>
    <div class="change-container">
      <h2>
        <template v-if="change > 0">
          Change: {{ change | currency }}
        </template>
        <template v-else>
          No Change
        </template>
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "CashPayment",
  filters: {
    currency(amount) {
      return `AUD ${parseFloat(amount).toFixed(2)}`
    }
  },
  data() {
    return {
      selectedPreset: null,
      cashReceived: 0
    }
  },
  computed: {
    products() {
      return this.$store.state.PointOfSale.selectedProducts
    },
    total() {
      if (this.products.length > 0) {
        let total = 0
        this.products.forEach(product => {
          total = total + (parseFloat(product.price) * product.quantity)
        })
        return total.toFixed(2)
      }
      return null
    },
    presets() {
      return [
        this.total,
        ...this.nearestAmounts(this.total)
      ]
    },
    isCustomSelected() {
      return this.selectedPreset === "_custom"
    },
    change() {
      return this.cashReceived - this.total
    },
    canContinue() {
      if (this.isCustomSelected) {
        return this.cashReceived >= this.total
      }
      else if (this.selectedPreset != null && this.selectedPreset >= 0) {
        return true
      }
      return false
    }
  },
  methods: {
    updateAmount(isPrice = false, fieldname) {
      this.$store.commit('UPDATE_SHOW_CALCULATOR', { number: this[fieldname],
        show: true,
        isPrice: isPrice,
        callBack: (val) => {
          this[fieldname] = val
        } })
    },
    selectPreset(preset, key) {
      if (key != null) {
        this.selectedPreset = key
        this.cashReceived = preset
      }
      else {
        this.selectedPreset = null
        this.cashReceived = 0
      }
    },
    isSelected(key) {
      return this.selectedPreset === key
    },
    selectCustom() {
      if (this.selectedPreset === "_custom") {
        this.selectedPreset = null
      } else {
        this.selectedPreset = "_custom"
        this.cashReceived = 0
      }
    },
    nearestAmounts(val) {
      const amounts = []
      const multiples = [1, 5, 10, 50]
      multiples.forEach(multiple => {
        if (val % multiple !== 0) {
          amounts.push(Math.ceil(val / multiple) * multiple)
        }
      })
      return amounts
    },
    next() {
      if (this.canContinue) {
        this.$emit('setTotal', this.total)
        this.$emit('setChange', this.change)
        this.$emit('next')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cash-payment-container {

  .cash-total {
    font-size: 1.5em;
    text-align: center;
    margin: 1rem 0 0 0;
    padding: .5em;
    color: var(--main-brand);
    background: var(--main-light-grey);
    font-weight: bold;
    box-shadow: var(--box-shadow-container);
  }
  .calculate-change {
    text-align: center;
    font-size: 1.3em;
    margin: 0;
  }
  .cash-payment-options {
    display: grid;
    grid-gap: 1em;
    grid-row-gap: 1em;
    grid-auto-rows: minmax(6em, auto);
    grid-template-columns: repeat(4, 1fr);
    padding: 0.5em;
    .cash-payment-option {
      &:first-child {
        grid-column: span 2;
        grid-row: span 2;
      }
      &.cash-payment-option-custom {
        grid-column: span 4;
      }
      background: white;
      box-shadow: var(--box-shadow-container);
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 0.5em;
      cursor: pointer;
      font-weight: bold;
      font-size: 1.1em;
      span {
        display: block;
        text-align: center;
        width: 100%;
      }
      .payment-method-title {
        margin-top: 1em;
        font-size: 1.4em;
      }
      &:hover, &:focus {
        background-color: var(--main-secondary);
        color: var(--main-white);
      }
      &.selected {
        background-color: var(--main-secondary);
        color: var(--main-white);
      }
    }
  }
  .cash-payment-custom {
    margin-top: 1em;
    width: 100%;
    display: flex;
    .cash-payment-custom-input {
      width: 100%;
      padding: 1em;
      line-height: 1;
      border-radius: 0 3px 3px 0;
      border: 1px solid var(--main-dark);
    }
    .cash-payment-custom-prefix {
      width: 4em;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--main-dark);
      color: var(--main-white);
      border-radius: 3px 0 0 3px;
    }
  }
  .continue-button {
    margin-top: 1em;
    width: 100%;
  }
}

.change-container {
  background: #000000;
  box-shadow: var(--box-shadow-container);
  text-align: center;
  color: white;
  padding: 0.25em 0.5em;
  margin: 0.5em;
}

.dark-mode {
  .cash-payment-container {
    .cash-total {
      color: var(--main-primary-link);
      border: none;
    }
  }
}
</style>
<template>
  <div class="the-stylex-container">
    <div class="section-wrapper">
      <TopContainer
          v-for="(stat, index) in stats"
          :key="`stat-${index}`">
        <KeyStats
            :type="stat.type"
            :icon="stat.icon" />
      </TopContainer>
    </div>
    <div class="section-wrapper">
      <Appointments />
    </div>
  </div>
</template>

<script>
import KeyStats from "@/Modules/Dashboard/components/stats/KeyStats"
import TopContainer from "@/Modules/Dashboard/components/TopContainer"
import Appointments from "@/Modules/Dashboard/components/appointments/Appointments"
export default {
  name: "TheStylex",
  components: {
    KeyStats,
    TopContainer,
    Appointments
  },
  data() {
    return {
      stats: [
        { type: "customers", icon: "CustomerManagementIcon" },
        { type: "appointments", icon: "AppointmentManagementIcon" },
        { type: "orders", icon: "OrderManagementIcon" }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
 .section-wrapper {
  display: flex;
  margin-top: 1em;

  div {
    flex: 1;
    margin: 1em;
  }
}
</style>
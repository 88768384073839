import CustomerManagement from "./CustomerManagement"
import CustomerManagementIcon from "./components/CustomerManagementIcon"

import dialogs from "./dialogs"
import features from "./features"
import routes from "./routes"

const CustomerManagementModule = {
  moduleGrouping: "Customer Management",
  name: "CustomerManagement",
  menuName: "Customers",
  component: CustomerManagement,
  path: "CustomerManagement",
  icon: CustomerManagementIcon,
  iconTitle: "Do you want to know more about your business?",
  iconDescription: "View CustomerManagement data here",
  enabled: true,
  features,
  dialogs,
  children: routes,
  registerComponents: Vue => {
    Vue.component("CustomerManagement", CustomerManagement)
    Vue.component("CustomerManagementIcon", CustomerManagementIcon)
  }
}
export default CustomerManagementModule

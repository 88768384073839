<template>
  <div class="form">
    <CoreFormBuilder
        :form-data="formData"
        :is-loading="isLoading"
        :extra-submit-options="extraSubmitOptions"
        @submitForm="submitForm"
        @cancelForm="cancelForm()" />
  </div>
</template>

<script>
import {Client} from "@/Modules/OdysseyModels"
import userFormData from "../formData/ClientFormData"
export default {
  name: "UserManagementUserDetails",
  props: {
    mode: {
      type: String,
      default: "createUser"
    },
    clientData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      blockPopoverVisible: false,
      dialogImageUrl: "",
      dialogVisible: false,
      initialising: true,
      formUpdated: false,
      isLoading: false,
      formData: JSON.parse(JSON.stringify(userFormData)),
      extraSubmitOptions: []
    }
  },
  watch: {
    clientData: {
      handler(val) {
        if (val) {
          this.mapEditData(val)
        }
      },
      immediate: true
    },
    formData: {
      handler(val) {
        if (val && !this.initialising) {
          this.formUpdated = true
        }
      },
      deep: true
    }
  },
  created() {
    this.formUpdated = false
  },
  mounted() {

    if (this.mode === "create-and-book") {
      this.extraSubmitOptions.push({
        label: "Submit and Create Booking",
        emit: "createClientBooking"
      })
    }
  },
  methods: {
    cancelForm() {
      this.$emit("cancelForm")
    },
    async submitForm(extra = null) {
      const flatFormData = {}
      let clientResponse = null
      // Go through each field and only select the value-field to pair with the key
      Object.keys(this.formData).filter(index => {
        Object.keys(this.formData[index]).filter(key => {
          if (key !== "placeholder") {
            flatFormData[key] = this.formData[index][key].value
          }
        })
      })
      const dobDate = new Date(flatFormData.dob)
      flatFormData.dob = `${dobDate.getFullYear()}-${dobDate.getMonth() + 1}-${dobDate.getDate()}`
      if (this.mode === "create" || this.mode === "create-and-select" || this.mode === "create-and-book") {
        clientResponse = await Client.api().create({
          data: flatFormData
        })

        if (this.mode === "create-and-select") {
          await this.$store.dispatch("PointOfSale/selectCustomer", clientResponse.response.data.data, { root: true })
          this.$emit('closeModal')
        }
      } else if (this.mode === "edit") {
        await Client.api().update({
          id: this.clientData.id,
          data: flatFormData
        })
      }
      const notificationsPayload = {
        component: "Toast",
        type: "success",
        title: "Thank you",
        message: "Your details have been successfully updated",
        visible: true,
        decay: 3500
      }
      this.$store.dispatch("core/notifications/addToNotifications", notificationsPayload)
      this.formUpdated = false

      this.$emit("closeForm")
      if (extra && clientResponse) {
        this.$emit(extra, clientResponse.response.data.data)
      }
      this.formData = JSON.parse(JSON.stringify(userFormData))
    },
    mapEditData(editData) {
      Object.keys(this.formData).filter(index => {
        Object.keys(this.formData[index]).filter(key => {
          this.formData[index][key].value = editData[key]
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  h3 {
    text-transform: capitalize;
    margin: 0 2em;
  }

  .reset-password-container {
    padding: 0 2em 0 0;
    text-align: right;
  }
}
</style>

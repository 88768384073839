import Vue from "vue"
import createAuth0Client from "@auth0/auth0-spa-js"

const DEFAULT_REDIRECT_CALLBACK = () => {
  window.history.replaceState({}, document.title, window.location.pathname)
}

let instance

const hasUrlCredentials = () =>
  window.location.search.includes("code=") && window.location.search.includes("state=")

export const getInstance = () => instance

const handleError = error => {
  // const fromUrl = localStorage.getItem("fromUrl")
  return error
}

export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = `${window.location.origin}/#/`,
  store,
  ...options
}) => {
  if (instance) return instance

  instance = new Vue({
    data() {
      return {
        loading: hasUrlCredentials(),
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null
      }
    },
    async created() {
      this.auth0Client = await createAuth0Client({
        domain: options.domain,
        client_id: options.clientId,
        audience: options.audience,
        redirect_uri: redirectUri
      })

      if (hasUrlCredentials() && !store.state.auth.loggedIn) {
        await this.handleRedirectCallback()
      }
    },
    methods: {
      async handleRedirectCallback() {
        this.loading = true

        try {
          await this.auth0Client.handleRedirectCallback()

          this.isAuthenticated = await this.auth0Client.isAuthenticated()

          this.user = await this.auth0Client.getUser()

          this.isAuthenticated = true
        } catch (e) {
          handleError(e)
          this.error = e
        } finally {
          this.loading = false

          onRedirectCallback(this)
        }
      },
      loginWithRedirect(o) {
        return this.auth0Client.loginWithRedirect(o)
      },
      getIdTokenClaims(o) {
        return this.auth0Client.getIdTokenClaims(o)
      },
      getTokenSilently(o) {
        return this.auth0Client.getTokenSilently(o)
      },
      getTokenWithPopup(o) {
        return this.auth0Client.getTokenWithPopup(o)
      },
      logout(o) {
        return this.auth0Client.logout(o)
      }
    }
  })

  return instance
}

// Create a simple Vue plugin to expose the wrapper object throughout the application
export default {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options)
  }
}

const features = {
  user: {
    permissions: {
      canRead: true,
      canCreate: true,
      canUpdate: true,
      canDestroy: true,
    }
  }
}

export default features
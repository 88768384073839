// import Modules here and add them to the routes array to see them in the Side bar menu and screen
import Auth from "@/Modules/Core/Modules/Auth"
import Dashboard from "@/Modules/Dashboard"
import Main from "./views/Main"
import config from "./config"
import modules from "./modules"
import store from "@/Modules/Core/store"

const routes = [
  {
    beforeEnter: (to, from, next) => {
      const { loggedIn } = store.state.auth
      const { href, hash } = window.location

      if (!hash.includes("Login") && !href.includes("code") && !href.includes("state")) {
        localStorage.setItem("fromUrl", hash)
      }

      if (loggedIn) {
        next()
      } else {
        next({ name: "Login" })
      }
    },
    registerChildren: true,
    component: Main,
    path: "",
    enabled: false,
    main: true,
    meta: {
      requiresAuth: true
    },
    children: [{ ...Dashboard, path: "" }, Dashboard, ...modules],
    name: config.appConfig.appName
  },
  Auth.odyssey
]


// const routes = [
//   {
//     path: "/",
//     // TODO: make data driven
//     redirect: "/clients/-"
//   },
//
//   {
//     registerChildren: true,
//     component: Main,
//     path: "clients/:clientId",
//     enabled: false,
//     main: true,
//     meta: {
//       requiresAuth: true
//     },
//     // Hack-- To get Dashboard ot show on Home we have to pass it in as the index route aswell
//     children: [{ ...Dashboard, path: "" }, Dashboard, ...modules],
//     name: config.appConfig.appName
//   },
//   Auth.nunki,
//   // TODO: make 404NotFound page
//   { path: "/*", redirect: "/" }
// ]

export default routes

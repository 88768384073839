import routes, { apiActions } from "./routes"

const attributes = {
    id: 1,
    active: 1,
    rating: null,
    totalScore: null,
    productTitle: null,
    productPicture: null,
    description: null,
    ctime: null
}

const definition = { attributes, routes }

export { apiActions }
export default definition
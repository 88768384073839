export default {
  nunki: {
    lightwerk: {
      api: "otocare.nunki.com.au/api",
      secure: true,
      useToken: false
    }
  },
  google: {
    maps: {
      apikey: "",
      url: ""
    }
  }
}

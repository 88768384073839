<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      :fill="color"
      class="svg-icon"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 64 80"
      style="enable-background:new 0 0 64 64;"
      xml:space="preserve"><path d="M31,31V15c0-0.553-0.447-1-1-1H10c-0.553,0-1,0.447-1,1v16c0,0.553,0.447,1,1,1h20C30.553,32,31,31.553,31,31z M29,30H11V16  h18V30z" />
    <rect
        x="16"
        y="48"
        width="8"
        height="2" />
    <rect
        x="13"
        y="18"
        width="2"
        height="2" />
    <rect
        x="13"
        y="22"
        width="2"
        height="2" />
    <rect
        x="13"
        y="26"
        width="2"
        height="2" />
    <rect
        x="17"
        y="18"
        width="2"
        height="2" />
    <rect
        x="17"
        y="22"
        width="2"
        height="2" />
    <rect
        x="17"
        y="26"
        width="2"
        height="2" />
    <rect
        x="21"
        y="18"
        width="2"
        height="2" />
    <rect
        x="21"
        y="22"
        width="2"
        height="2" />
    <rect
        x="21"
        y="26"
        width="2"
        height="2" />
    <rect
        x="25"
        y="18"
        width="2"
        height="10" />
    <path
        d="M61.964,60.733l-1.662-6C60.182,54.3,59.787,54,59.338,54H57v-6c0-2.206-1.794-4-4-4h-4c-2.206,0-4,1.794-4,4v6h-1.279  l-0.507-1.521C42.428,50.121,40.4,48.448,38,48.079V41c0-0.553-0.447-1-1-1H24v-4h7.734C33.535,36,35,34.535,35,32.734V30h5.469  L41,32.123V37c0,1.654,1.346,3,3,3h8c1.654,0,3-1.346,3-3v-4.877L55.281,31H59c0.553,0,1-0.447,1-1V20c0-0.553-0.447-1-1-1h-3.78  c-0.549-0.609-1.337-1-2.22-1H43c-1.654,0-3,1.346-3,3v3h-5v-7h3c0.553,0,1-0.447,1-1V8h4c0.553,0,1-0.447,1-1V3  c0-0.553-0.447-1-1-1H33c-0.553,0-1,0.447-1,1v4c0,0.553,0.447,1,1,1h4v7h-2v-1.734C35,11.465,33.535,10,31.734,10H8.266  C6.465,10,5,11.465,5,13.266v19.469C5,34.535,6.465,36,8.266,36H16v4H3c-0.553,0-1,0.447-1,1v12c0,0.553,0.447,1,1,1h34  c0.553,0,1-0.447,1-1v-2.876c1.531,0.345,2.805,1.452,3.316,2.987l0.474,1.423c-0.034,0.064-0.072,0.127-0.093,0.199l-1.662,6  c-0.083,0.301-0.021,0.623,0.168,0.872S40.688,62,41,62h20c0.312,0,0.606-0.146,0.796-0.395S62.047,61.034,61.964,60.733z   M55.97,28.242C55.99,28.163,56,28.082,56,28v-7h2v8h-2.22L55.97,28.242z M42,21c0-0.552,0.448-1,1-1h10c0.552,0,1,0.448,1,1v6.877  l-0.97,3.881C53.01,31.837,53,31.918,53,32v5c0,0.552-0.448,1-1,1h-8c-0.552,0-1-0.448-1-1v-5c0-0.082-0.01-0.163-0.03-0.242  L42,27.877V21z M34,6V4h8v2H34z M35,26h5v2h-5V26z M8.266,34C7.567,34,7,33.433,7,32.734V13.266C7,12.567,7.567,12,8.266,12h23.469  C32.433,12,33,12.567,33,13.266v19.469C33,33.433,32.433,34,31.734,34H8.266z M22,36v4h-4v-4H22z M36,52H4v-6h32V52z M36,44H4v-2h32  V44z M47,48c0-1.103,0.897-2,2-2h4c1.103,0,2,0.897,2,2v8h-8V48z M42.314,60l1.108-4H45v1c0,0.553,0.447,1,1,1h10  c0.553,0,1-0.447,1-1v-1h1.577l1.108,4H42.314z" />
    <path
        d="M51,47c-1.654,0-3,1.346-3,3s1.346,3,3,3s3-1.346,3-3S52.654,47,51,47z M51,51c-0.552,0-1-0.448-1-1s0.448-1,1-1  s1,0.448,1,1S51.552,51,51,51z" />
    <path
        d="M45,28h6c0.553,0,1-0.447,1-1v-4c0-0.553-0.447-1-1-1h-6c-0.553,0-1,0.447-1,1v4C44,27.553,44.447,28,45,28z M46,24h4v2h-4  V24z" />
    <rect
        x="45"
        y="30"
        width="6"
        height="2" />
    <rect
        x="45"
        y="34"
        width="6"
        height="2" />
  </svg>
</template>
<script>
  export default {
    name: "OrderManagementIcon",
    props: {
      color: {
        type: String,
        default: "#000000"
      }
    }
  }
</script>
<style scoped>
  .svg-icon {
    width: 100%;
  }
</style>

<template>
  <div class="form-footer-options">
    <div class="options-panel">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "CoreFormBuilderFormFooterOptions"
}
</script>

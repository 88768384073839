<template>
  <fragment>
    <CoreScreen
        card
        class="productManagement-wrapper"
        header="ProductManagement"
        icon="ProductManagementIcon"
        :create-button="{ label: 'Create Product', onCreate: createProduct }">
      <router-view />
    </CoreScreen>
  </fragment>
</template>

<script>
import { Product } from "@/Modules/OdysseyModels"
export default {
  name: "ProductManagement",
  methods: {
    createProduct() {
      new Product().Actions.openProductDialog()
    }
  }
}
</script>

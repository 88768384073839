import { PERMISSION_STRING_EXAMPLE } from "./constants"

const parsePermissionString = permissionString => {
  if (!permissionString) {
    throw new Error(
      `An string with the following pattern must be passed into getPermissions: '${PERMISSION_STRING_EXAMPLE}'`
    )
  }

  const [moduleName, featureName, permissionKey] = permissionString.split(":")

  return { moduleName, featureName, permissionKey }
}

export default parsePermissionString

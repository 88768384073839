export default {
  fields: {
    personalDetails: {
      userGroup: {
        type: "select",
        multiple: false,
        filterable: true,
        options: [
          { value: "administrator", label: "Administrator" },
          { value: "operator", label: "Operator" },
          { value: "planner", label: "Planner" },
          { value: "customer_service", label: "Customer Service" },
          { value: "guest_access", label: "Guest Access" }
        ],
        value: null,
        label: "User Group",
        placeholder: "Please select",
        validation: [{ required: true, message: "Please enter a valid User Group", trigger: "blur" }]
      },
      placeholder: {
        type: "placeholder"
      },
      firstName: {
        type: "text",
        value: null,
        label: "First Name",
        placeholder: "ie. John",
        validation: [{ required: true, message: "Please enter first name", trigger: "blur" }]
      },
      lastName: {
        type: "text",
        value: null,
        label: "Last Name",
        placeholder: "ie. Doe",
        validation: [{ required: true, message: "Please enter last name", trigger: "blur" }]
      },
      email: {
        type: "email",
        value: null,
        label: "Email Address",
        placeholder: "ie. John.doe@example.org",
        validation: [
          { required: true, message: "Please enter email address", trigger: "blur" },
          {
            type: "email",
            message: "Please enter a valid email address",
            trigger: ["blur", "change"]
          }
        ]
      },
      color: {
        type: "color-picker",
        value: null,
        label: "Color",
        placeholder: "Pick a color",
        validation: [{ required: true, message: "Please select a color", trigger: "blur" }]
      }
    }
  }
}

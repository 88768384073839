<template>
  <div class="form">
    <div
        v-if="searchingForCustomer"
        v-loading="searchingForCustomer"
        element-loading-text="Checking if Customer exists in Database ..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="#FFF">
      <core-text />
    </div>

    <transition name="fade">
      <div v-if="customersFound.length">
        <core-text class="hint">
          One ore more customers with this name have been found in our data base.
        </core-text>

        <ul class="found-customers">
          <li
              v-for="(customer, key) in customersFound"
              :key="`patient-${key}`"
              @click="openCustomerDialog(customer.id)">
            {{ customer.firstName }} {{ customer.lastName }}
            <template v-if="customer.dob">
              (Age {{ customer.dob }})
            </template>
            | <strong>{{ customer.city }}</strong>
          </li>
        </ul>
      </div>
    </transition>
    
    <CoreFormBuilderV2
        :data="customerData"
        :form-config="formConfig"
        :is-loading="isLoading"
        :mode="mode"
        @onChange="onChange"
        @onSubmit="onSubmit"
        @onCancel="onCancel" />
  </div>
</template>

<script>
  import {Customer} from "@/Modules/OdysseyModels"
  import formConfig from "./formConfig"
  import debounce from "lodash/debounce"
  import moment from "moment";

  export default {
    name: "CustomerManagementForm",
    props: {
      dialogProps: { type: Object, required: true },
      customerData: {
        type: Object, default: () => ({})
      }
    },
    data() {
      return {
        isLoading: false,
        formConfig,
        searchingForCustomer: false,
        customersFound: [],
        firstName: null,
        lastName: null
      }
    },
    computed: {
      mode() {
        return this.dialogProps.mode
      },
      fullName() {
        if (this.firstName && this.lastName) {
          return `${this.firstName} ${this.lastName}`
        }
        return null
      }
    },
    watch: {
      fullName() {
        this.debouncedCustomerNameCheck()
      }
    },
    mounted() {
      this.debouncedCustomerNameCheck = debounce(this.checkCustomerName, 250)
    },
    methods: {
      openCustomerDialog(id) {
        const type = "CustomerDialog"
        const mode = "view"

        this.$store.dispatch("core/dialogStack/addDialog", { type, mode, id })

      },
      async checkCustomerName() {
        if (this.firstName && this.lastName) {
          this.searchingForCustomer = true

          const params = {
            filter: {
              customer: this.fullName
            },
            filterType: "exact"
          }
          const results = await Customer.api().fetchAll({params: params })

          const customers = results.response.data.data.data
          const count = results.response.data.data.meta.count

          if (count > 0) {
            this.customersFound = customers
          } else {
            this.customersFound = []
          }
          this.searchingForCustomer = false
        }
      },
      onChange(Form) {
        if (this.mode === "create") {
          const flatFormData = Form.getFlatData()

          if (flatFormData.firstName && flatFormData.firstName.length >= 3) {
            this.firstName = flatFormData.firstName
          }
          if (flatFormData.lastName && flatFormData.lastName.length >= 3) {
            this.lastName = flatFormData.lastName
          }
        }
      },
      onCancel() {
        this.dialogProps.closeDialog()
      },
      async onSubmit(Form) {
        try {
          const flatFormData = Form.getFlatData()

          if (flatFormData.dob) {
            flatFormData.dob = moment(flatFormData.dob).format('YYYY-MM-DD hh:mm:ss')
          }


          if (this.mode === "create") {
            await Customer.api().create(flatFormData)
          } else if (this.mode === "edit") {
            flatFormData.id = this.customerData.id
            await Customer.api().update(flatFormData)
          }

          const notificationsPayload = {
            component: "Toast",
            type: "success",
            title: "Thank You",
            message: "Your CustomerManagement details have been successfully updated.",
            visible: true,
            decay: 3500
          }

          this.$store.dispatch("core/notifications/addToNotifications", notificationsPayload)

          this.dialogProps.closeDialog()
        } catch (error) {
          Form.setSubmitting(false)
          // @todo: handle error
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .hint {
    background: var(--main-light-yellow);
    font-style: normal !important;
    border-radius: 0.5em;
    color: rgba(0,0,0,0.4);
    width: 100%;
    padding: 0 0.5em;
  }

  .found-customers {
    margin: 0.5em 0;
    display: flex;
    li {
      background: var(--main-apple-blue);
      border-radius: 1em;
      padding: 0.05em 0.5em;
      color: white;
      font-size: 0.8em;
      cursor: pointer;
      transition: var(--main-transition);
      box-shadow: 2px 2px 5px rgba(0,0,0,0.15);

      &:hover {
        box-shadow: inset 1px 1px 4px rgba(0,0,0,0.2);
      }
    }
  }
</style>
<template>
  <div class="appointment-wrapper">
    <div class="date">
      {{ appointmentDate }}
      <span class="time">
        {{ appointment.startTime }} -
        {{ appointment.endTime }}
      </span>
    </div>
    <div class="customer">
      {{ appointment.firstName }} {{ appointment.lastName }}
      <a
          class="open-dialog"
          @click="openCustomerDialog(appointment.customerId)">
        <span class="el-icon-top-right" /></a>
    </div>
    <div class="staff">
      <div
          class="staff-tag"
          :style="`background-color: ${staffMember.color}`">
        {{ staffMember.name }}
      </div>
    </div>
  </div>
</template>

<script>
  import moment from "moment"
  import { User } from "@/Modules/OdysseyModels"
  export default {
    name: "Appointment",
    props: {
      appointment: {
        type: Object,
        required: true
      }
    },
    computed: {
      appointmentDate() {
        return moment(this.appointment.date).format("DD/MM/YYYY")
      },
      staffMember() {
        const staffMember = User.find(this.appointment.staffId)
        let result
        if (staffMember) {
          result = { name: staffMember.fullName, color: staffMember.color }
        } else {
          result = false
        }
        return result
      }
    },
    created() {
      User.api().fetchAll()
    },
    methods: {
      openCustomerDialog(customerId) {
        const type = "CustomerDialog"
        const mode = "view"
        const id = customerId

        this.$store.dispatch("core/dialogStack/addDialog", { type, mode, id })

      }
    }
  }
</script>

<style lang="scss" scoped>
  .appointment-wrapper {
    background: white;
    border-radius: 1em;
    padding: 0.25em 0.5em;
    box-shadow: var(--main-box-shadow-long);
    margin: 0.5em;
    display: flex;

    .date,
    .time,
    .customer,
    .staff
    {
      flex: 1;
      margin: 0 0.5em;
    }

    .staff {
      .staff-tag {
        color: white;
        padding: 0.1em 0.5em;
        border-radius: 0.5em;
      }
    }

    .open-dialog {
      border: 1px solid;
      border-color: inherit;
      border-radius: 3px;
      cursor: pointer;
      transform: scale(0.85);
      width: 2em;
      display: inline-block;
      text-align: center;
    }
  }
</style>
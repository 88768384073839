import { Model } from "@vuex-orm/core"
import get from "lodash/get"
import camelCase from "lodash/camelCase"
import startCase from "lodash/startCase"
import axios from "axios"
import applyRequestCache from "./apply-request-cache"
import applyInterceptors from "./apply-axios-interceptors"
import useMock from "./useMock"
import autobind from "auto-bind"
import getPermissions from "./getPermissions"
import pluralize from "pluralize"

applyRequestCache(axios)

const pascalCase = string => {
  return startCase(string)
    .split(" ")
    .join("")
}

export default class ExtendedModel extends Model {
  constructor(...args) {
    super(...args)
    autobind(this, { exclude: [/(?!\$self)\$(\w+)(?!\w)/] })
  }

  static axios = axios

  static fields() {
    const attrs = {
      id: this.attr(),
      _saved: this.attr(true),
      _delete: this.attr(false),
      _loading: this.attr(false)
    }
    const { attributes = {}, relations = {} } = this.definition
    const { _ALL_MODELS = {} } = this

    Object.keys(attributes).forEach(key => {
      attrs[key] = this.attr(null)
    })

    Object.entries(relations).forEach(([key, value]) => {
      if (value.modelName && value.type === "hasMany") {
        const idArrayKey = value.opts.keyForRelationshipIds || `${pluralize.singular(key)}_ids`
        const modelName = camelCase(value.modelName)
        const relatedModel = _ALL_MODELS[pascalCase(modelName)] || modelName

        attrs[key] = this.hasManyBy(relatedModel, idArrayKey)
        attrs[idArrayKey] = this.attr([])
      }

      if (value.modelName && value.type === "belongsTo") {
        const foreignKey = `${pluralize.singular(key)}_id`
        const modelName = camelCase(value.modelName)
        const relatedModel = _ALL_MODELS[pascalCase(modelName)] || modelName

        attrs[key] = this.belongsTo(relatedModel, foreignKey)
        attrs[foreignKey] = this.number(null).nullable()
      }
    })

    return attrs
  }

  get getPermissions() {
    return getPermissions
  }

  toJson(options = {}) {
    const { withRelations } = options
    let json = this.$toJson()

    if (withRelations) {
      json = this.$query()
        .withAllRecursive()
        .where("id", this.id)
        .get()[0]
        .$toJson()
    }

    if (`${json.id}`.includes("$")) {
      delete json.id
    }

    delete json._saved
    delete json._delete
    delete json._loading

    return json
  }

  static useMock() {
    return useMock({
      globalMock: this.store().state.settings.useMockData,
      localMock: this.mock
    })
  }

  get store() {
    return this.$self().store()
  }

  get Api() {
    return this.$self().api()
  }

  get actions() {
    return get(this, "Actions.actionsList", [])
  }

  get valueList() {
    const { id } = this
    const model = this.$self().entity

    return { label: `${model}_${id}`, value: id, id }
  }

  get globalSearchLabel() {
    const { id } = this
    const model = this.$self().entity

    return `${model} ${id}`
  }

  static async init() {}

  static initializeModel() {
    return new Promise(async resolve => {
      if (this.init.constructor.name === "AsyncFunction") {
        await this.init()
      } else {
        this.init()
      }

      applyInterceptors(this.axios, this.store())

      resolve()
    })
  }

  static convertDateForIOS(date) {
    if (date) {
      const arr = date.split(/[- :]/)
      return new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5])
    }
    return null
  }

  static formatDate(date) {
    const dateString = new Date(date)
    const day = dateString.getDate()
    const month = dateString.getMonth() + 1
    const year = dateString.getFullYear()
    return `${day}/${month}/${year}`
  }

  static formatTimeStamp(dateString, format = "timeOnly") {
    if (dateString) {
      const newDate = new Date(dateString)
      let displayTime

      let hours = newDate.getHours()
      let minutes = newDate.getMinutes()
      if (minutes < 10) {
        minutes = `0${minutes}`
      }
      let amPm = hours >= 12 ? "pm" : "am"

      if (hours === 24) amPm = "am"

      hours %= 12
      hours = hours || 12

      displayTime = `${hours}:${minutes}${amPm}`

      if (format === "timeOnly") {
        return displayTime
      } else if (format === "dateOnly") {
        return newDate.toLocaleDateString("en-AU", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit"
        })
      } else if (format === "dateOnlyNoYear") {
        return newDate.toLocaleDateString("en-AU", {
          month: "2-digit",
          day: "2-digit"
        })
      } else if (format === "dateAndTime") {
        return newDate.toLocaleDateString("en-AU", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit"
        })
      }
    }

    return "–"
  }

  static currentTimeAddHours(range = 1, add = true) {
    const dt = new Date()
    const hours = Math.floor(Math.random() * range + 1)
    let operator = 1
    if (add) {
      operator = -1
    }
    dt.setHours(dt.getHours() + hours * operator)
    return dt
  }

  static state() {
    return {
      draft: { history: [], currentHistoryStep: 0 }
    }
  }

  get isDraft() {
    return `${this.id}`.includes("$")
  }

  static draft() {
    const self = this

    const mutateData = data => {
      const mutatedData = { ...data, _saved: false }

      return mutatedData
    }

    const getUnsavedRecords = (where = () => true) => {
      return self
        .query()
        .where("_saved", false)
        .where(where)
        .get()
    }

    const shouldResetHistory = ({ recordsToDelete, recordsToUpdate, recordsToCreate }) => {
      const count = recordsToCreate.length + recordsToDelete.length + recordsToUpdate.length
      const unsavedRecordCount = self
        .query()
        .where("_saved", false)
        .get().length

      return count === unsavedRecordCount
    }

    const createHistoryPoint = async changedRecord => {
      const originalData = self.find(changedRecord.id)
      const from = originalData ? originalData.$toJson() : null
      const updatedRecords = await self.insertOrUpdate({ data: [changedRecord] })

      const to = updatedRecords[self.entity][0].$toJson()
      const id = to.id

      self.commit(state => {
        const { currentHistoryStep: step, history } = state.draft

        if (step !== null && step < history.length) {
          state.draft.history.length = step
        }

        state.draft.history.push({ from, to, id })
        state.draft.currentHistoryStep = step + 1
      })

      return to
    }

    const resetHistory = (options = {}) => {
      self.commit(state => {
        if (options.revert) {
          state.draft.history
            .concat()
            .reverse()
            .map(historyPoint => {
              handleHistoryUndo(historyPoint)
            })
        }

        state.draft.history = []
        state.draft.currentHistoryStep = 0
      })
    }

    const canUndo = currentHistoryStep => {
      return currentHistoryStep !== 0
    }

    const canRedo = currentHistoryStep => {
      const { history } = self.store().state.entities[self.entity].draft

      return currentHistoryStep !== history.length
    }

    const handleHistoryUndo = ({ from, id }) => {
      if (!from) {
        return self.delete(id)
      }

      self.update(from)
    }

    const scrubHistory = ({ id, revert = false }) => {
      if (id) {
        let hasReverted = false
        const shouldRevert = !hasReverted && revert

        self.commit(state => {
          state.draft.history = state.draft.history.filter(historyPoint => {
            if (id === historyPoint.id) {
              if (shouldRevert) {
                handleHistoryUndo(historyPoint)
                hasReverted = true
              }

              return false
            }

            return true
          })

          state.draft.currentHistoryStep = state.draft.history.length
        })
      }
    }

    return {
      canUndo,
      canRedo,
      createHistoryPoint,
      getUnsavedRecords,
      undo() {
        const { history, currentHistoryStep } = self.store().state.entities[self.entity].draft

        const nextStep = currentHistoryStep - 1
        const index = nextStep

        if (nextStep >= 0) {
          if (history[index]) {
            handleHistoryUndo(history[index])
          }

          self.commit(state => {
            state.draft.currentHistoryStep = nextStep
          })
        }
      },
      redo() {
        const { history, currentHistoryStep } = self.store().state.entities[self.entity].draft

        const handleHistoryRedo = ({ to }) => {
          self.insertOrUpdate({ data: [to] })
        }

        const nextStep = currentHistoryStep + 1
        const index = nextStep - 1

        if (nextStep <= history.length) {
          if (history[index]) {
            handleHistoryRedo(history[index])
          }

          self.commit(state => {
            state.draft.currentHistoryStep = nextStep
          })
        }
      },
      create(data) {
        return createHistoryPoint(mutateData(data))
      },
      update(data) {
        return createHistoryPoint(mutateData(data))
      },
      delete(id) {
        const data = self.find(id).$toJson()
        data._delete = true
        return createHistoryPoint(mutateData(data))
      },
      discardChanges(checkedRecords) {
        const { recordsToDelete, recordsToUpdate, recordsToCreate } = checkedRecords

        if (shouldResetHistory(checkedRecords)) {
          resetHistory({ revert: true })
        } else {
          ;[...recordsToDelete, ...recordsToUpdate, ...recordsToCreate].forEach(({ id }) => {
            scrubHistory({ id, revert: true })
          })
        }
      },
      onSaveSuccess(checkedRecords) {
        const { recordsToUpdate } = checkedRecords
        const { history } = self.store().state.entities[self.entity].draft

        const updatedIds = recordsToUpdate.map(({ id }) => id)

        history
          .concat()
          .reverse()
          .map(historyPoint => {
            if (!updatedIds.includes(historyPoint.id)) {
              handleHistoryUndo(historyPoint)
            }
          })

        resetHistory()
      },
      clearHistory(options) {
        resetHistory(options)
      }
    }
  }
}

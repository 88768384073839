import routes, { apiActions } from "./routes"

const attributes = {
    companyName: null,
    abn: null,
    firstName: null,
    lastName: null,
    street: null,
    zip: null,
    suburb: null,
    city: null,
    state: null,
    country: null,
    phone: null,
    email: null,
    mobile: null,
    mailNotificationsAddress: null,
    shopCreditCardSurCharge: null,
    smtpServerUsername: null,
    smtpServerPassword: null,
    smtpServer: null,
    mailMode: null,
    mailFooter: null,
    mailReplyName: null,
    facebook: null,
    twitter: null,
    youtube: null,
    pageTitle: null,
    pageDescription: null,
    pageKeywords: null,
    smsEnabled: null,
    smsGateway: null,
    smsApiKey: null,
    smsApiSecret: null,
    smsFooter: null,
    startingTime: null,
    finishingTime: null
}

const definition = { attributes, routes }

export { apiActions }
export default definition
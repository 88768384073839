import routes, { apiActions } from "./routes"
import seeds from "./seeds"
import { uniqueNamesGenerator, starWars } from "unique-names-generator"

const attributes = {
  firstName: null,
  lastName: null,
  email: null,
  username: null,
  color: null
}

// const relations = {
//   roles: hasMany("userRole")
// }

const hooks = {
  afterCreate(user) {
    const config = { length: 2, dictionaries: [starWars, starWars] }

    const [firstName, lastName] = uniqueNamesGenerator(config).split(" ")
    const username = `${firstName}${lastName}`
    const email = `${username}@nunki.com.au`
    // const roles = [server.schema.userRoles.findOrCreateBy({ id: 1 })]

    user.update({ firstName, lastName, username, email })
  }
}

// const serializer = DefaultSerializer => {
//   return DefaultSerializer.extend({
//     include: ["roles"]
//   })
// }

const definition = { attributes, seeds, routes, hooks }

export { apiActions }
export default definition

export default {
  fields: {
    productDetails: {
      title: {
        type: "text",
        label: "Product Title",
        placeholder: "ie. Gold Package",
        validation: [{required: true, message: "Please enter a product name", trigger: "blur"}]
      },
      placeholder1: {
        type: "placeholder"
      },
      productCategoryId: {
        type: "entity-select",
        props: {
          entity: "ProductCategory",
          filterable: true,
          clearable: true,
        },
        label: "Product Category",
        options: [],
        validation: [{required: true, message: "Please select a product category", trigger: "blur"}]
      },
      type: {
        type: "select",
        label: "Product type",
        initialValue: "bookable",
        options: [
          {label: "Shippable", value: "shippable"},
          {label: "Bookable", value: "bookable"},
          {label: "Printable", value: "printable"}
        ],
        validation: [{required: true, message: "Please select a product type", trigger: "blur"}]
      },
      timeFrame: {
        type: "select",
        label: "Time Frame",
        initialValue: 15,
        options: [
          {label: "15 Minutes", value: 15},
          {label: "30 Minutes", value: 30},
          {label: "45 Minutes", value: 45},
          {label: "60 Minutes", value: 60},
          {label: "90 Minutes", value: 90},
          {label: "120 Minutes", value: 120}
        ],
        validation: [{required: true, message: "Please select a product type", trigger: "blur"}]
      }
    },
    productFeatures: {
      price: {
        type: "text",
        label: "Product Price",
        placeholder: "100",
        append: "AUD",
        validation: [{required: true, message: "Please enter a product price", trigger: "blur"}]
      },
      status: {
        type: "select",
        initialValue: "active",
        label: "Status",
        options: [
          {label: "Active", value: "active"},
          {label: "Inactive", value: "inactive"},
          {label: "Out of stock", value: "out_of_stock"}
        ]
      }
    }
  }
}


import ExtendedModel from "@/Modules/Core/utils/extended-vue-orm-model"
import definition, { apiActions } from "./definition"
import Actions from "./actions"

class Client extends ExtendedModel {
  static entity = "Client"
  static globalSearchEnabled = false
  static definition = definition

  static apiConfig = {
    dataKey: "data",
    actions: apiActions,
    async sendTextMessage(payload, options = {}) {
      const url = `/clients/send_text_message`

      if (Client.mock) {
        return {
          response: {
            status: 200
          }
        }
      } else {
        return this.post(url, payload, this.addAuthorizationHeader(options))
      }
    },
    async resetClientPassword(client = {}, options = {}) {
      const url = `/clients/reset_password`

      if (Client.mock) {
        return {
          response: {
            status: 200
          }
        }
      } else {
        return this.post(url, {email: client.email}, this.addAuthorizationHeader(options))
      }
    },
    async changeClientPassword(client = {}, options = {}) {
      const url = `/clients/change_password`

      if (Client.mock) {
        // @todo: update mock data response
        return {
          response: {
            data: {
              data: {
                password: `random password generated client${client.id}`
              }
            }
          }
        }
      } else {
        return this.patch(
            url,
            {password: client.password, password_confirmation: client.password},
            this.addAuthorizationHeader(options)
        )
      }
    }
  }

  get Actions() {
    return new Actions(this)
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`
  }

  get valueList() {
    const {id} = this
    return {label: this.fullName, value: id, id}
  }

  get extendedValueList() {
    const {id} = this
    let email = ""
    let phone = ""

    if (this.email !== "") {
      email = ` || Mail: ${this.email}`
    }
    if (this.phone !== "") {
      phone = ` || Ph: ${this.phone}`
    }

    return {label: `${this.fullName} ${email} ${phone}`, value: id, id}
  }

}
export default Client

<template>
  <div
      id="point-selector"
      class="fit-parent">
    <div class="searchbox">
      <el-select
          v-model="selectedPlace"
          :remote-method="fetchLocations"
          :loading="loading"
          :disabled="dragging"
          filterable
          remote
          reserve-keyword
          :size="size">
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value" />
      </el-select>
    </div>

    <GmapMap
        ref="mapRef"
        :options="mapOptions"
        :center="{ lat: 10, lng: 10 }"
        :zoom="7"
        style="width: 100%; height: 300px"
        @drag="onMapDrag"
        @dragstart="onMapDragStart"
        @dragend="onMapDragEnd">
      <GmapMarker
          v-if="markerPosition"
          :position="markerPosition" />
    </GmapMap>
  </div>
</template>

<script>
import { gmapApi } from "vue2-google-maps"
import get from "lodash/get"
import isArray from "lodash/isArray"
import uniq from "lodash/uniq"

// TODO: move all google maps utils into CORE
const getLatLngFromGoogle = ({ lat, lng }) => ({
  lat: lat(),
  lng: lng()
})

export default {
  components: {},
  props: {
    formMeta: { type: Object, default: () => ({}) },
    value: { type: [Array, String, Object], default: () => [] },
    initialSearchValue: { type: String, default: null },
    label: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    size: { type: String, default: "medium" },
    arrayPoint: { type: Boolean, default: false }
  },
  data() {
    return {
      geocoder: null,
      markerPosition: null,
      loading: false,
      dragging: false,
      mounted: false,
      selectedPlace: "",
      options: [],
      mapOptions: {
        fullscreenControl: false,
        mapTypeControl: false,
        gestureHandling: "cooperative",
        streetViewControl: false,
        clickableIcons: false,
        styles: [
          {
            featureType: "poi",
            stylers: [{ visibility: "off" }]
          }
        ]
      }
    }
  },
  computed: {
    google: gmapApi,

    position() {
      if (!this.value.length) return null
      return { lat: this.value[1], lng: this.value[0] }
    }
  },
  watch: {
    selectedPlace: {

      async handler() {
        if (this.selectedPlace && this.selectedPlace !== "Finding Location...") {
          const location = this.options.find(({value}) => value == this.selectedPlace)

          this.$emit("input", location.point)

          this.setMapCenter(this.getPointLatLng(location.point))
        }
      }
    },
    google: {
      immediate: true,

      async handler() {
        this.$nextTick(async () => {
          if (this.$refs.mapRef && this.google) {
            if (!this.geocoder) {
              this.geocoder = new this.google.maps.Geocoder()
            }

            this.dragging = true
            this.selectedPlace = "Finding Location..."

            const hasInitialValue = isArray(this.value) ? this.value.length : this.value
            if (hasInitialValue) {
              const nextCenter = this.getPointLatLng(this.value)

              await this.getSearchValueFromLatLng(nextCenter)

              this.setMapCenter(nextCenter)
            } else if (this.initialSearchValue) {
              await this.fetchLocations(this.initialSearchValue)

              const nextCenter = this.getPointLatLng(this.options[0].point)

              this.selectedPlace = this.options[0].value
              this.setMapCenter(nextCenter)
            } else {
              this.setMarkerPosition()
            }
          }
        })
      }
    }
  },
  methods: {
    mutateOption(point) {
      if (this.arrayPoint) {
        const { lng, lat } = point

        return [lng, lat]
      }

      return point
    },
    getPointLatLng(point) {
      if (this.arrayPoint) {
        const [lng, lat] = point

        return { lng, lat }
      }

      return point
    },
    setMapCenter(nextCenter) {
      return this.$refs.mapRef.$mapPromise
        .then(async map => {
          map.panTo(nextCenter)

          map.setZoom(14)
        })
        .then(() => {
          this.setMarkerPosition()
          this.dragging = false
        })
    },
    setMarkerPosition() {
      this.$refs.mapRef.$mapPromise.then(async map => {
        this.markerPosition = getLatLngFromGoogle(map.getCenter())
      })
    },
    onMapDrag() {
      this.setMarkerPosition()
    },
    onMapDragStart() {
      this.dragging = true
      this.selectedPlace = "Finding Location..."
    },
    onMapDragEnd() {
      this.getSearchValueFromLatLng(this.markerPosition)
    },
    getSearchValueFromLatLng({ lat, lng }) {
      const { mutateOption } = this
      const self = this

      return new Promise(async resolve => {
        this.dragging = true
        const latlng = { lat, lng }

        this.geocoder.geocode({ location: latlng }, function(results, status) {

          if (status === "OK") {
            if (results[0]) {
              const address = results[0]

              const addressParts = uniq(
                address.address_components
                  //  NOTE: Not sure why I originally filtered these parts out? Anyway, commented due to using 'formatted_address' instead
                  // .filter(({ types }) => {
                  //   if (types.includes("route")) return false
                  //   if (types.includes("street_number")) return false
                  //   if (types.includes("postal_code")) return false

                  //   return true
                  // })
                  .map(({ long_name }) => long_name)
              )

              const id = get(address, "place_id")
              const label = get(address, "formatted_address", addressParts.join(", "))

              self.options = [{ label, value: id, point: mutateOption({ lng, lat }) }]
              self.selectedPlace = self.options[0].value

              self.dragging = false
              resolve()
            } else {
              window.alert("No results found")
            }
          } else {
            window.alert("Geocoder failed due to: " + status)
          }
        })
      })
    },

    fetchLocations(query) {
      const { mutateOption } = this

      if (this.google && this.$refs.mapRef) {
        return new Promise(resolve => {
          this.$refs.mapRef.$mapPromise.then(async map => {
            const self = this

            self.loading = true

            const service = new this.google.maps.places.PlacesService(map)

            const request = { query }

            service.textSearch(request, function(place) {
              self.options = place.map(({ formatted_address, place_id, geometry }) => {
                const { lat, lng } = getLatLngFromGoogle(geometry.location)

                return {
                  label: formatted_address,
                  value: place_id,
                  point: mutateOption({ lng, lat })
                }
              })

              resolve(self.options)

              self.loading = false
            })
          })
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.searchbox {
  padding-bottom: var(--padding-m);
  /deep/ .el-select {
    width: 100%;
  }

  .searchbox-label {
    font-weight: 700;
  }
}
</style>

import ProductManagement from "./ProductManagement"
import ProductManagementIcon from "./components/ProductManagementIcon"

import dialogs from "./dialogs"
import features from "./features"
import routes from "./routes"

const ProductManagementModule = {
  moduleGrouping: "Inventory Management",
  name: "ProductManagement",
  menuName: "Products",
  component: ProductManagement,
  path: "ProductManagement",
  icon: ProductManagementIcon,
  iconTitle: "Do you want to know more about your business?",
  iconDescription: "View ProductManagement data here",
  enabled: true,
  features,
  dialogs,
  children: routes,
  registerComponents: Vue => {
    Vue.component("ProductManagement", ProductManagement)
    Vue.component("ProductManagementIcon", ProductManagementIcon)
  }
}
export default ProductManagementModule

import axios from "axios"

const state = () => {
  return {
    httpConfig: {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8"
      }
    }
  }
}

const getCookie = name => {
  const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"))
  if (match) return match[2]
}

const token = getCookie("token")

const queryString = payload => {
  let params = `?token=${token}`

  Object.keys(payload).map(key => {
    params += "&" + key + "=" + payload[key]
  })
  return params
}

const actions = {
  get({ rootState }, payload) {
    let data = {}
    if (typeof payload.data !== "undefined") {
      data = payload.data
    }
    const callUrl = `${rootState.settings.app.api.endPoint}${payload.endpoint}${queryString(data)}`
    return axios.get(callUrl)
  },
  post({ rootState, state }, payload) {
    let data = {}
    if (typeof payload.data !== "undefined") {
      data = payload.data
    }
    let mergedConfig
    if (typeof state.config !== "undefined") {
      mergedConfig = { ...state.httpConfig, ...payload.additionalConfig }
    } else {
      mergedConfig = state.httpConfig
    }
    if (typeof data.token === "undefined") {
      data.token = token
    }
    const callUrl = `${rootState.settings.app.api.endPoint}${payload.endpoint}`

    return axios.post(callUrl, data, mergedConfig)
  },
  put({ rootState }, payload) {
    let data = {}
    if (typeof payload.data !== "undefined") {
      data = payload.data
    }
    data.token = token
    return axios.put(
      `${rootState.settings.app.api.endPoint}${payload.endpoint}`,
      payload.data,
      state.httpConfig
    )
  },
  delete({ rootState }, payload) {
    return axios.delete(
      `${rootState.settings.app.api.endPoint}${payload.endpoint}${queryString(payload.data)}`
    )
  }
}

export default {
  namespaced: true,
  state,
  actions
}

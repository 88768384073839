import OrderManagementStatusColumn from "./OrderManagementStatusColumn";

const defaultColumns = [
    {
      prop: "id",
      label: "ID"
    },
    {
      prop: "createdDateTime",
      label: "Date"
    },
    {
      prop: "customerFullName",
      label: "Customer"
    },
    {
      prop: "paymentType",
      label: "Payment Type",
    },
    {
      prop: "priceCostAud",
      label: "Cost",
    },
    {
      prop: "pricePaidAud",
      label: "Paid",
    },
    {
      label: "Status",
      Component: OrderManagementStatusColumn
    }
  ]

export default defaultColumns
import AppointmentDialog from "./AppointmentDialog/AppointmentDialog"
import CustomerAppointmentDialog from "./AppointmentDialog/CustomerAppointmentDialog"

export default [
  {
    name: "AppointmentDialog",
    Component: AppointmentDialog,
    permissions: "AppointmentManagement:Appointments:canCreate"
  },
  {
    name: "CustomerAppointmentDialog",
    Component: CustomerAppointmentDialog,
    permissions: "AppointmentManagement:Appointments:canCreate"
  }
]
import { Server, Model, Factory, RestSerializer } from "miragejs"
import snakeCase from "lodash/snakeCase"
import camelCase from "lodash/camelCase"
import isObject from "lodash/isObject"
import get from "lodash/get"
import isArray from "lodash/isArray"
import paginate from "./paginate"
import { api } from "@/config/api"
import config from "@/config/config"

const passthroughRoutes = {
  hereMaps: `${get(api, "here.router.url", "")}/**`,
  athena: get(api, "athena.url", ""),
  auth0: `https://${get(config, "auth0.domain", "")}/**`
}

const parseIntIds = data => {
  if (isArray(data)) {
    return data.map(parseIntIds)
  }

  if (data.id) {
    data.id = parseInt(data.id, 10)
  }

  Object.entries(data).forEach(([key, value]) => {
    if (isArray(value)) {
      data[key] = value.map(parseIntIds)
    }

    if (isObject(value)) {
      data[key] = parseIntIds(value)
    }
  })

  return data
}

const makeServer = (config = {}) => {
  const { vuexModels = {}, isHybridMock = false } = config
  const models = {}
  const factories = {}
  const serializers = {}
  const vuexModelArray = Object.values(vuexModels)

  const DefaultSerializer = RestSerializer.extend({
    paginate,
    keyForModel: () => "data",
    keyForCollection: () => "data",
    keyForAttribute: attr => snakeCase(attr),
    keyForForeignKey(relationshipName) {
      return underscore(relationshipName) + "_id"
    },
    keyForEmbeddedRelationship: attr => snakeCase(attr),
    embed: true,
    serialize(object, request) {
      let json = RestSerializer.prototype.serialize.apply(this, arguments)

      if (isHybridMock) {
        localStorage.setItem("hybridMockDB", JSON.stringify(this.schema.db.dump()))
      }

      json = paginate({ data: json.data, ...request.queryParams })

      json.data = parseIntIds(json.data)

      return json
    },
    normalize(json) {
      const { type } = this

      const normalizedJson = { data: { type } }

      normalizedJson.data.attributes = json

      Object.entries(json).forEach(([key, value]) => {
        if (isObject(value) && value.id) {
          delete normalizedJson.data.attributes[key]
        }

        if (key.includes("_id")) {
          normalizedJson.data.attributes[camelCase(key)] = value
        }
      })

      return normalizedJson
    }
  })

  vuexModelArray.forEach(vuexModel => {
    if (vuexModel.definition) {
      const {
        serializer,
        attributes = {},
        hooks = {},
        traits = {},
        relations = {}
      } = vuexModel.definition

      factories[vuexModel.entity] = Factory.extend({ ...attributes, ...hooks, ...traits })

      models[vuexModel.entity] = Model.extend({ ...relations })

      if (serializer) {
        serializers[vuexModel.entity] = serializer(DefaultSerializer)
      }
    }
  })

  return new Server({
    logging: false,
    serializers: {
      ...serializers,
      application: DefaultSerializer
    },

    models,
    routes() {
      const self = this

      vuexModelArray.forEach(vuexModel => {
        const { routes } = get(vuexModel, "definition", {})

        if (routes) {
          const passthroughRoutes = routes(self)

          if (isHybridMock && passthroughRoutes) {
            passthroughRoutes.forEach(passthroughPath => {
              self.passthrough(passthroughPath)
            })
          }
        }
      })

      Object.values(passthroughRoutes).forEach(route => {
        this.passthrough(route)
      })
    },
    seeds(server) {
      vuexModelArray.forEach(vuexModel => {
        const { seeds } = get(vuexModel, "definition", {})

        if (seeds && !isHybridMock) {
          seeds(server, config)
        } else if (seeds && vuexModel.alwaysSeedData) {
          seeds(server, config)
        }
      })
    },
    factories
  })
}

export default makeServer

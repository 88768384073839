import OrderManagement from "./OrderManagement"
import OrderManagementIcon from "./components/OrderManagementIcon"

import dialogs from "./dialogs"
import features from "./features"
import routes from "./routes"

const OrderManagementModule = {
  moduleGrouping: "Shop Management",
  name: "OrderManagement",
  menuName: "Orders",
  component: OrderManagement,
  path: "OrderManagement",
  icon: OrderManagementIcon,
  iconTitle: "Do you want to know more about your business?",
  iconDescription: "View OrderManagement data here",
  enabled: true,
  features,
  dialogs,
  children: routes,
  registerComponents: Vue => {
    Vue.component("OrderManagement", OrderManagement)
    Vue.component("OrderManagementIcon", OrderManagementIcon)
  }
}
export default OrderManagementModule

import autobind from "auto-bind"
import capitalize from "lodash/capitalize"

class Actions {
  constructor(entity) {
    this.entity = entity
    autobind(this)
  }

  openDialog(options = {}) {
    this.openClientDialog(options)
  }

  openClientDialog(options = {}) {
    const type = "ClientDialog"
    const { mode = "view" } = options
    const { id, store } = this.entity

    store.dispatch("core/dialogStack/addDialog", { type, mode, id })
  }

  get actionsList() {
    const { status: currentStatus } = this.entity
    const self = this.entity

    const isApproved = currentStatus === "approved"
    const statusChangeText = isApproved ? "block" : "approve"

    return [
      {
        visible: true,
        label: "View Client",
        icon: "el-icon-view",
        onClick: () => this.openClientDialog({ mode: "view" })
      },
      {
        visible: true,
        label: "Edit Client",
        icon: "el-icon-edit",
        onClick: () => this.openClientDialog({ mode: "edit" })
      },
      {
        visible: true,
        label: `${capitalize(statusChangeText)} Client`,
        icon: isApproved ? "el-icon-close" : "el-icon-check",
        onConfirm: () =>
            self.Api.update({ id: self.id, status: isApproved ? "blocked" : "approved" }),
        confirmProps: {
          title: `Are you sure you want to ${statusChangeText} this client?`,
          confirmButtonText: `${capitalize(statusChangeText)} Client`
        }
      },
      {
        visible: true,
        divided: true,
        class: "danger",
        label: "Delete",
        icon: "el-icon-delete",
        onConfirm: () => self.Api.destroy(self),
        confirmProps: {
          title: "Are you sure you want to delete this Client?",
          confirmButtonText: "Delete Client"
        }
      }
    ].filter(({ visible }) => visible)
  }
}

export default Actions

import UserManagement from "@/Modules/UserManagement"
import CustomerManagement from "@/Modules/CustomerManagement"
import AppointmentManagement from "@/Modules/AppointmentManagement"
// import ClientManagement from "@/Modules/ClientManagement"
// import OrderManagement from "@/Modules/OrderManagement"
// import BookingManagement from "@/Modules/BookingManagement"
// import ClientAlbums from "@/Modules/ClientAlbums"
// import Dashboard from "@/Modules/Dashboard"
import OrderManagement from "@/Modules/OrderManagement"
import ProductManagement from "@/Modules/ProductManagement"
import ProductCategoryManagement from "@/Modules/ProductCategoryManagement"
import PointOfSale from "@/Modules/PointOfSale"
import SystemSettings from "@/Modules/SystemSettings"
// import DataImporter from "@/Modules/DataImporter"
// import LocationManagement from "@/Modules/LocationsManagement"

export default [
  AppointmentManagement,
  // ClientManagement,
  // BookingManagement,
  PointOfSale,
  OrderManagement,
  // Dashboard,
  // ClientAlbums,
  ProductManagement,
  ProductCategoryManagement,
  // LocationManagement,
  CustomerManagement,
  UserManagement,
  SystemSettings,
  // DataImporter
]

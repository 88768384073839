<template>
  <svg 
      class="svg-icon"
      :fill="color"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 125"
      style="enable-background:new 0 0 100 100;"
      xml:space="preserve"><path d="M64.9,13.4h15.6c3.6,0,6.6,3,6.6,6.6l0.3,38.7c1.1,0.4,2.2,0.9,3.2,1.4L90.2,20c0-5.4-4.4-9.8-9.8-9.8H64.9  V2.5h-3.2v19.6h3.2V13.4z" />
    <rect
        x="34.2"
        y="10.7"
        width="23.5"
        height="3.2" />
    <path
        d="M80.2,59.6c-10.4,0-18.9,8.5-18.9,18.9s8.5,18.9,18.9,18.9S99.1,89,99.1,78.6S90.6,59.6,80.2,59.6z   M80.2,94.3c-8.7,0-15.7-7.1-15.7-15.7s7.1-15.7,15.7-15.7s15.7,7.1,15.7,15.7S88.9,94.3,80.2,94.3z" />
    <circle
        cx="19.5"
        cy="33.6"
        r="3.1" />
    <circle
        cx="32.5"
        cy="33.6"
        r="3.1" />
    <circle
        cx="45.5"
        cy="33.6"
        r="3.1" />
    <circle
        cx="58.5"
        cy="33.6"
        r="3.1" />
    <circle
        cx="71.4"
        cy="33.6"
        r="3.1" />
    <circle
        cx="19.7"
        cy="45.8"
        r="3.1" />
    <circle
        cx="32.6"
        cy="45.8"
        r="3.1" />
    <circle
        cx="45.6"
        cy="45.8"
        r="3.1" />
    <circle
        cx="58.6"
        cy="45.8"
        r="3.1" />
    <circle
        cx="71.6"
        cy="45.8"
        r="3.1" />
    <circle
        cx="19.8"
        cy="58.1"
        r="3.1" />
    <circle
        cx="32.8"
        cy="58.1"
        r="3.1" />
    <circle
        cx="45.8"
        cy="58.1"
        r="3.1" />
    <circle
        cx="58.7"
        cy="58.1"
        r="3.1" />
    <circle
        cx="20"
        cy="70.4"
        r="3.1" />
    <circle
        cx="32.9"
        cy="70.4"
        r="3.1" />
    <circle
        cx="45.9"
        cy="70.4"
        r="3.1" />
    <path
        d="M10.7,86.5c-3.6,0-6.6-3-6.6-6.6V20c0-3.6,3-6.6,6.6-6.6h16.4v8.7h3.2V2.5h-3.2v7.7H10.7  c-5.4,0-9.8,4.4-9.8,9.8v59.9c0,5.4,4.4,9.8,9.8,9.8h51.6c-0.6-1-1.2-2.1-1.6-3.2H10.7z" />
    <path
        d="M89.2,78.4h-7.9v-8.3c0-0.9-0.7-1.6-1.6-1.6s-1.6,0.7-1.6,1.6V80c0,0.9,0.7,1.6,1.6,1.6h9.4  c0.9,0,1.6-0.7,1.6-1.6S90.1,78.4,89.2,78.4z" />
  </svg>
</template>

<script>
  export default {
    name: "AppointmentManagementIcon",
    props: {
      color: {
        type: String,
        default: "#000000"
      }
    }
  }
</script>

<style scoped>
  .svg-icon {
    width: 100%;
  }
</style>

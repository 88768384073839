import routes, { apiActions } from "./routes"
import seeds from "./seeds"
import operatorPermissions from "../mockPermissions/Operator.json"
import superadminPermissions from "../mockPermissions/Superadmin.json"
import plannerPermissions from "../mockPermissions/Planner.json"
import customerServicePermissions from "../mockPermissions/CustomerService.json"
import guestPermissions from "../mockPermissions/Guest.json"

const mockPermissions = {
  superadmin: superadminPermissions,
  operator: operatorPermissions,
  planner: plannerPermissions,
  customerService: customerServicePermissions,
  guest: guestPermissions
}
const attributes = {
  id: null,
  name: "superadmin",
  status: "enabled",
  permissions: superadminPermissions
}

const mockRoles = ["none", "superadmin", "operator", "planner", "customerService", "guest"]

const hooks = {
  afterCreate(userRole) {
    const name = mockRoles[userRole.id]
    const status = "enabled"
    const permissions = mockPermissions[name]

    userRole.update({ name, status, permissions })
  }
}

const definition = { attributes, seeds, routes, hooks }

export { apiActions }
export default definition

<template>
  <div class="version-tracker">
    <el-badge
        value="1"
        :hidden="isUpToDate"
        is-dot
        class="new-badge">
      <el-button
          class="version-tracker__dialog-anchor"
          type="text"
          @click="dialogVisible = true">
        <span> {{ latestVersion }}</span>
      </el-button>
    </el-badge>

    <el-dialog
        title=""
        :append-to-body="true"
        :visible.sync="dialogVisible"
        width="60em">
      <div class="changes">
        <el-tabs
            tab-position="left"
            class="changes__tabs">
          <el-tab-pane
              v-for="change in changes"
              :key="change.version"
              :label="change.version">
            <vue-markdown class="changes__list__change">
              {{ change.markdown }}
            </vue-markdown>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getVersion } from "../../utils/version-tracker"
import VueMarkdown from "vue-markdown"

export default {
  name: "CoreVersionDialog",
  components: { VueMarkdown },
  props: { changelogs: { type: Object, default: () => ({}) } },
  data() {
    return {
      dialogVisible: false,
      latestVersion: "v0.0.0",
      isUpToDate: true,
      changes: []
    }
  },
  watch: {
    dialogVisible() {
      localStorage.setItem("lastViewedVersion", this.latestVersion)
      this.isUpToDate = true
    }
  },
  mounted() {
    const lastViewedVersion = localStorage.getItem("lastViewedVersion")
    const { latestVersion, changes } = getVersion(this.changelogs)

    this.latestVersion = latestVersion || "v0.0.0"
    this.isUpToDate = lastViewedVersion === this.latestVersion || !changes.length
    this.changes = changes
  },
  methods: {
    getDateString(date) {
      const d = new Date(parseInt(date, 10))
      return d.toDateString()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/Modules/Core/assets/css/index.scss";
.version-tracker__dialog-anchor {
  font-size: 0.8rem;
  font-weight: bold;
  padding: 0em;
}
/deep/ .new-badge {
  & sup.el-badge__content {
    top: 2px !important;
    right: 2px;
    font-size: 0.6rem;
    font-weight: bold;
    padding: 0.1em;
  }
}

/deep/ .changes {
  display: flex;
  height: auto;

  & .changes__tabs {
    width: 100%;

    & .el-tabs__content {
      padding: var(--padding-m);
      background: #f9fafb;
      height: 100%;
      border: var(--border-divider);
      border-radius: var(--border-radius-s);
      overflow-y: scroll;
      word-break: keep-all;
    }

    & h1, h2, h3 {
      color: var(--main-primary);
    }

    & h3 {
      margin-top: 0;
    }

    & ul {
      padding-left: var(--padding-xl);
    }

    & li {
      list-style-type: square;
      margin: 0;
      padding: 0;
      line-height: 1.6;

      li {
        list-style: disc;

        li {
          list-style: circle;
        }
      }
    }

    p {
      font-size: 1.1em;
      line-height: 1.6;
    }
  }
}
</style>

<template>
  <div
      id="app"
      :style="rootColorVariables">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  computed: {
    brandColor() {
      return this.$store.state.settings.app.brandColor
    },
    rootColorVariables() {
      return `--brand-hue: ${this.brandColor.h}; --brand-sat: ${this.brandColor.s}; --brand-light :${this.brandColor.l};`
    }
  }
}
</script>

<template>
  <div class="status-color">
    <template v-if="scope.row.color">
      <div
          class="color-circle"
          :style="`background-color: ${scope.row.color}`" />
    </template>
  </div>
</template>

<script>
export default {
  name: "UserStatusColumn",
  props: {
    scope: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.color-circle {
  margin: auto;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  box-shadow: var(--box-shadow-container);
}
</style>

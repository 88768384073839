<template>
  <core-text
      v-show="isVisible"
      semibold
      tag="h3">
    <span>
      {{ label | camelToWords }}
    </span>

    <el-tooltip
        v-if="hintText"
        popper-class="hint-tooltip"
        :open-delay="450">
      <i class="el-icon-info hint" />

      <div
          slot="content"
          class="hint-tooltip">
        {{ hintText }}
      </div>
    </el-tooltip>
  </core-text>
</template>

<script>
export default {
  name: "CoreFieldGroupHeader",

  filters: {
    camelToWords(string) {
      const result = string.replace(/([A-Z])/g, " $1")

      return result.charAt(0).toUpperCase() + result.slice(1)
    }
  },
  props: {
    fieldGroup: { type: Object, required: true },

    label: { type: String, default: "" }
  },

  computed: {
    options() {
      return this.fieldGroup._options || {}
    },
    isVisible() {
      return !this.options.hideGroupTitle || true
    },
    hintText() {
      return this.options.hintText
    }
  }
}
</script>

<style lang="scss" scoped>
.compact {
  h3 {
    font-size: 15px;
    padding: var(--padding-s) 0;
  }
}

h3 {
  font-size: 15px;
  margin: 0;
  padding: var(--padding-s) 0;
}
</style>

import GhostLoader from "./GhostLoader.vue"

export default () => ({
  component: import(/* webpackChunkName: "Core-DataTableV2" */ "./DataTableV2.vue"),
  loading: GhostLoader,
  // @TODO: Create catchall component.
  // error: ErrorComponent,
  delay: 0,
  timeout: 3000
})

import PointOfSale from "./PointOfSale"
import PointOfSaleIcon from "./components/PointOfSaleIcon"
import store from "./store"

const PointOfSaleModule = {
  moduleGrouping: "Shop Management",
  name: "PointOfSale",
  menuName: "Point of Sale",
  component: PointOfSale,
  path: "PointOfSale",
  icon: PointOfSaleIcon,
  iconTitle: "Do you want to know more about your business?",
  iconDescription: "View reports and statistics here",
  enabled: true,
  store,
  registerChildren: true,
  registerComponents: Vue => {
    Vue.component("PointOfSale", PointOfSale)
    Vue.component("PointOfSaleIcon", PointOfSaleIcon)
  }
}
export default PointOfSaleModule

<template>
  <svg
      id="Abel_Logo"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 943.888 337.756"
      style="enable-background:new 0 0 943.888 337.756;"
      xml:space="preserve"
      :fill="color"
      class="svg-icon">
    <g id="Abel">
      <path
          id="L"
          class="st0"
          d="M910.045,336.906h7.686c14.386,0,26.157-11.771,26.157-26.157V30c0-16.5-13.5-30-30-30h0
		c-16.5,0-30,13.5-30,30v280.75C883.888,325.136,895.659,336.906,910.045,336.906z" />
      <path
          id="e"
          class="st0"
          d="M719.369,74.279h-11.314c-58.197,0-105.375,47.178-105.375,105.375v3.863v27.669v7.186
		c0,5.027,0,10.701,0,17.093c0,100.465,102.139,102.139,102.139,102.139c74.023,2.349,99.114-23.08,103.004-39.605
		c2.918-12.393-3.77-25.249-15.569-30.032c-4.882-1.979-10.255-1.803-16.272,1.823c-17.791,10.721-51.907,14.233-51.907,14.233
		c-73.674,0-65.372-65.302-65.372-65.302v-0.349H784.59c17.594,0,33.857-14.263,33.857-31.857v-15.158
		C818.447,117.742,772.984,74.279,719.369,74.279z M761.982,173.069l-100.962,0c0.58-5.941,1.48-42.735,42.497-49.674
		c7.698-1.302,13.459-0.396,16.953,0c23.504,2.661,41.512,23.45,41.512,47.104V173.069z" />
      <path
          id="b"
          class="st0"
          d="M438.436,77.279h-6.573c-47.154,2.238-60.974,28.102-60.974,28.102V30c0-16.5-13.5-30-30-30h0
		c-16.5,0-30,13.5-30,30v196.75c0,0.103,0.007,0.204,0.008,0.307l-0.008,2.85c0,59.094,47.906,107,107,107h12.413
		c59.094,0,107-47.906,107-107v-53.761C537.303,121.543,493.039,77.279,438.436,77.279z M480.716,232.674c0,27.614-22.386,50-50,50
		h-9.827c-27.614,0-50-22.386-50-50v-5.924v-42.953c0-28.927,23.45-52.378,52.378-52.378h0c31.728,0,57.449,25.721,57.449,57.449
		V232.674z" />
      <g id="A">
        <path
            id="Right"
            class="st0"
            d="M245.507,301.204L170.815,66.847l-2.706,7.191L139.94,154.39l19.428,60.959H118.57l-17.097,48.77
			h73.439l17.24,54.091c4.696,14.734,20.447,22.871,35.18,18.175l0,0C242.066,331.689,250.203,315.938,245.507,301.204z" />
        <polygon
            id="Shade"
            class="st1"
            points="118.57,215.348 101.473,264.118 111.52,264.118 128.617,215.348 		" />
        <path
            id="Left"
            class="st0"
            d="M17.611,335.292L17.611,335.292c-13.763-4.833-20.964-20.017-16.083-33.915L99.381,22.713
			c1.653-4.706,4.446-9.129,7.998-13.011c15.272-16.695,42.875-9.106,48.029,12.894v0L51.367,318.878
			C46.487,332.776,31.374,340.125,17.611,335.292z" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "AbelLogo",
  props: {
    color: {
      type: String,
      default: "#000000"
    }
  }
}
</script>

<style scoped>
.svg-icon {
  width: 100%;
}
</style>

<template>
  <div
      class="waves-container"
      :style="`background-color: ${containerBackground}`">
    <svg
        :class="{ animated: animated }"
        class="waves"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28"
        preserveAspectRatio="none">
      <defs>
        <path
            id="wave"
            d="M-160 44c30 0
          58-18 88-18s
          58 18 88 18
          58-18 88-18
          58 18 88 18
          v44h-352z" />
      </defs>
      <g class="wave-set">
        <use
            xlink:href="#wave"
            x="50"
            y="0"
            class="wave-1"
            :fill="wave1" />
        <use
            xlink:href="#wave"
            x="50"
            y="4"
            class="wave-2"
            :fill="wave2" />
        <use
            xlink:href="#wave"
            x="50"
            y="5"
            class="wave-3"
            :fill="wave3" />
        <use
            xlink:href="#wave"
            x="50"
            y="7"
            class="wave-4"
            :fill="wave4" />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "WaveyMcWaveFace",
  props: {
    animated: {
      type: Boolean,
      default: false
    },
    containerBackground: {
      type: String,
      default: "#694cbe"
    },
    wave1: {
      type: String,
      default: "#7d42f3"
    },
    wave2: {
      type: String,
      default: "#682cde"
    },
    wave3: {
      type: String,
      default: "#4b3ab9"
    },
    wave4: {
      type: String,
      default: "#FFF"
    }
  }
}
</script>

<style lang="scss">
//start
:root {
  --wave-set-height: 5em;
  //seed value gets multiplied per wave so that there's variation in speed
  --wave-animation-speed: 5s;
}

.waves-container {
  padding-top: 5em;
}

.waves {
  margin-bottom: -5px;
  width: 100%;
  height: var(--wave-set-height);

  @media (min-width: 768px) {
    height: calc(var(--wave-set-height) * 2.5);
  }

  @media (min-width: 1280px) {
    height: calc(var(--wave-set-height) * 2);
  }

  .wave-1 {
    transform: translateX(50%);
  }
  .wave-2 {
    transform: translateX(90%);
  }

  .wave-3 {
    transform: translateX(25%);
  }
  .wave-4 {
    transform: translateX(0%);
  }

  &.animated {
    //reuse a single element, or a group of elements defined with the <g> element  of the svg

    .wave-set > use {
      animation: infinite-wave var(--wave-animation-speed) ease-in-out infinite;
      //ensure you're using the parent selector with > use or it won't work eg: &.wave-1 not .wave-1
      //updated to offset wave timing
      &.wave-1 {
        animation-delay: -1s;
        animation-duration: calc(var(--wave-animation-speed) * 2.5);
      }

      &.wave-2 {
        animation-delay: -2s;
        animation-duration: calc(var(--wave-animation-speed) * 1);
      }

      &.wave-3 {
        animation-delay: -4s;
        animation-duration: calc(var(--wave-animation-speed) * 1.5);
      }
      &.wave-1 {
        animation-delay: -5s;
        animation-duration: calc(var(--wave-animation-speed) * 2);
      }
    }
  }
}

@keyframes infinite-wave {
  0% {
    transform: translate(-90px, 0%);
  }

  100% {
    transform: translate(85px, 0%);
  }
}
//might not be able to get this to work...
@keyframes bobbing-wave {
  0% {
    transform: translatey(0);
  }
  50% {
    transform: translatey(0.25rem);
  }
  100% {
    transform: translatey(0);
  }
}
</style>

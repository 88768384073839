const date = new Date()
const dateFrom = new Date(date.getDate() - 7)
const dateTo = new Date()

export default [
  {
    label: "Customer",
    options: [
      {
        selectedByDefault: true,
        label: "Customer Details",
        queryType: "search",
        urlKey: "filter.customer",
        inputType: "text",
        props: {
          placeholder: "Filter by Customer Details (Name, Email, Phone)"
        }
      }
    ]
  },
  {
    label: "Order",
    options: [
     {
        selectedByDefault: true,
        label: "Order ID",
        queryType: "search",
        urlKey: "filter.orderId",
        inputType: "text",
        props: {
          placeholder: "Search by Order ID"
        }
      },
      {
        label: "Order Date",
        inputType: "daterange",
        queryType: "search",
        urlKey: "filter.createdAt",
        defaultValue: [dateFrom, dateTo],
        props: {
          rangeSeparator: "To",
          startPlaceholder: "Start date",
          endPlaceholder: "End date",
          format: "dd/MM/yyyy",
          valueFormat: "yyyy-MM-dd 00:00:00",
          placeholder: "Filter by Booking Date"
        }
      },
      {
        label: "Payment Type",
        inputType: "select",
        queryType: "filter",
        urlKey: "filter.paymentType",
        props: {
          options: [
            { label: "Cash", value: "cash" },
            { label: "Credit Card", value: "credit-card" },
            { label: "Voucher", value: "voucher" }
          ]
        }
      },
      {
        label: "Order Status",
        inputType: "select",
        queryType: "filter",
        urlKey: "filter.orderStatus",
        props: {
          options: [
            { label: "completed",  value: "completed" },
            { label: "unpaid", value: "unpaid" },
            { label: "paid but not sent", value: "paid but not sent" },
            { label: "paid and sent", value: "paid and sent" },
            { label: "refunded", value: "refunded" }
          ]
        }
      },
    ]
  }
]
<template>
  <div class="flex color-column">
    <div
        class="color-circle"
        :style="`background-color: ${scope.row.categoryColor}`" />
    {{ scope.row.title }}
  </div>
</template>
<script>
  export default {
    name: "ProductCategoryColorColumn",
    props: {
      scope: {
        type: Object,
        required: true
      }
    },
  }
</script>

<style lang="scss" scoped>
  .color-column {
    align-items: center;

    .color-circle {
      margin: auto;
      width: 1.5em;
      height: 1.5em;
      border-radius: 50%;
      box-shadow: var(--box-shadow-container);
      margin-right: 1em;
    }
  }
</style>

<template>
  <div class="point-of-sale">
    <main>
      <Catalog />
    </main>
    <aside>
      <Checkout />
    </aside>
  </div>
</template>

<script>
import Catalog from "./components/catalog/Catalog"
import Checkout from "./components/checkout/Checkout"
export default {
  name: "PointOfSale",
  components: {
    Catalog,
    Checkout
  }
}
</script>

<style lang="scss" scoped>
.point-of-sale {
  display: flex;
  main {
    flex: 4;
    background: white;
    padding: 1em;
    height: calc(100vh - 5em);
  }
  aside {
    flex: 1;
    min-width: 24em;
  }
}
</style>
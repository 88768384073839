import ExtendedModel from "@/Modules/Core/utils/extended-vue-orm-model"
import definition, { apiActions } from "./definition"
import Actions from "./actions"
import startCase from "lodash/startCase"
import config from "@/config";

const baseURL = config.apiConfig.lightwerk.api
const endpoint = "/invoices"

class Invoice extends ExtendedModel {
  static entity = "Invoice"
  static globalSearchEnabled = true
  static definition = definition

  static apiConfig = {
    dataKey: "data.data",
    actions: {
      ...apiActions,
      async createPDF(orderId, options = {}) {
        const url = `${baseURL}${endpoint}/createPDF/${orderId}`
        return this.get(url, null)
      }
    }
  }

  get Actions() {
    return new Actions(this)
  }

  get shippingType() {
    return startCase(this.shipping_type)
  }
  get paymentType() {
    return startCase(this.payment_type)
  }

  get Total() {
    return `AUD ${parseFloat(this.cost).toFixed(2)}`
  }

  get createdAt() {
    return new Date(this.ctime).toLocaleDateString()
  }

  get PDFAttachment() {
    if (this.invoiceLocation) {
      const host = document.location.host.replace("app.", "")
      return `https://${host}/${this.invoiceLocation}`
    }
    return ""
  }

  get Paid() {
    if (this.paid) {
      return `AUD ${parseFloat(this.paid).toFixed(2)}`
    }
    return "-"
  }

  get valueList() {
    const {id} = this
    return {label: this.fullName, value: id, id}
  }

}
export default Invoice

export default {
  businessDetails: {
    pageTitle: {
      type: "text",
      value: null,
      label: "Page Title",
      placeholder: "ie. My awesome company page",
      validation: [{required: true, message: "Please enter a page title", trigger: "blur"}]
    },
    pageKeywords: {
      type: "text",
      value: null,
      label: "Page Keywords (comma separated)",
      placeholder: "ie. keyword1, keyword2, keyword3"
    },
    pageDescription: {
      type: "textarea",
      value: null,
      label: "Page Description",
      placeholder: "ie. Learn more about our super awesome products",
      validation: [{required: true, message: "Please enter a page description", trigger: "blur"}]
    }
  }
}

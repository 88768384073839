<template>
  <div>
    <div class="categories">
      <template v-if="categories">
        <div
            v-for="(category, index) in categories"
            :key="`category-${index}`"
            class="product-category"
            :class="{ active: category.id === selectedCategory }"
            @click="selectCategory(category.id)">
          <div
              class="header"
              :style="`border-color: ${category.color}`" />
          <div class="content">
            {{ category.title }}
          </div>
        </div>
      </template>
    </div>

    <div class="catalog">
      <template v-for="(product, index) in products">
        <SingleProduct
            :key="`product-${index}`"
            :product="product" />
      </template>
    </div>
  </div>
</template>

<script>
import {Product, ProductCategory} from "@/Modules/OdysseyModels";
import SingleProduct from './Product'

export default {
  name: "Catalog",
  components: {
    SingleProduct
  },
  data() {
    return {
      page: 1,
      perPage: 500,
      dialogVisible: false,
      isLoading: true,
      viewMode: "ProductsTableView",
      selectedCategory: null
    }
  },
  computed: {
    products() {
      const products = Product.all()

      if (this.selectedCategory) {
        return products.filter(product =>
          product.productCategoryId === this.selectedCategory
        )
      }
      return products
    },
    categories() {
      return ProductCategory.all()
    }
  },
  mounted() {
    this.getProducts()
    this.getProductCategories()
  },
  methods: {
    selectCategory(val) {
      this.selectedCategory = val
    },
    async getProducts() {
      this.isLoading = true
      await Product.api().fetchAll({params: {page: this.page, per_page: this.perPage}})
      this.isLoading = false
    },
    async getProductCategories() {
      this.isLoading = true
      await ProductCategory.api().fetchAll()
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.categories,
.catalog {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1em;
  grid-template-areas: ". . . ." ". . . ." ". . . .";
}

.categories {
  text-align: center;
  font-weight: bold;
  color: white;
  margin-bottom: 2em;
  padding: 1em;

  .product-category {
    background: white;
    margin: 0.1em;
    font-size: 1.1em;
    letter-spacing: 0.1em;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--main-text);
    transition: var(--main-transition);
    box-shadow: var(--box-shadow-container-light);
    border: 2px solid transparent;
    border-radius: 5px;

    .header {
      border-top: 3px solid;
      margin: 0.25em 0.5em;
    }

    .content {
      padding: 0.5em;
    }

    &:hover,
    &.active {
      border-color: var(--main-success-green);
      box-shadow: var(--box-shadow-container);
      position: relative;

      &:after {
        position: absolute;
        top: -0.5em;
        right: -0.5em;
        width: 1.5em;
        height: 1.5em;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--main-success-green);
        color: white;
        content: "✔";
        border-radius: 1em;
      }
    }
  }
}
</style>
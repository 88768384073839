import routes, { apiActions } from "./routes"

const attributes = {
    id: 1,
    firstName: null,
    lastName: null,
    dob: null,
    company: null,
    picture: null,
    phone: null,
    mobile: null,
    email: null,
    website: null,
    street: null,
    zip: null,
    suburb: null,
    city: null,
    country: null,
    referer: null,
    source: null,
    ctime: null,
    package: null,
    checked: null,
    password: null,
    additional: null,
    state: null,
    session: null,
    abn: null,
    inv_street: null,
    inv_city: null,
    inv_suburb: null,
    inv_zip: null,
    inv_state: null,
    inv_firstname: null,
    inv_lastname: null
}

const definition = { attributes, routes }

export { apiActions }
export default definition
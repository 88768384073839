import uniq from "lodash/uniq"
import uniqBy from "lodash/uniqBy"
import get from "lodash/get"
import isArray from "lodash/isArray"
import router from "../../router"
import getPermissions from "@/Modules/Core/utils/getPermissions"
import Vue from "vue"

const handleRouteQuery = newDialogUUIDS => {
  const { path, query: oldQuery } = router.currentRoute
  const dialogQuery = uniq(newDialogUUIDS)
  const newQuery = {}

  Object.assign(newQuery, oldQuery)

  delete newQuery.d

  if (dialogQuery.length) {
    newQuery.d = dialogQuery
  }

  router.push({ path, query: newQuery })
}

const state = () => {
  return { stack: [] }
}

const createUUID = options => {
  const { type, mode, id, tab } = options
  const MODE = id ? mode : "create"
  const TAB = tab ? `-${tab}` : ""
  const ID = id ? `-${id}` : ""

  return `${type}-${MODE}${ID}${TAB}`
}

const checkForPermissions = dialog => {
  return dialog.permissions ? getPermissions(dialog.permissions) : true
}

const buildDialogFromUUIDAndAddToStack = ({ uuid, rootState, commit, dispatch, props, order }) => {
  const { registeredDialogs } = rootState.settings
  const stack = get(rootState, "core.dialogStack.stack", [])

  const [type, mode, id, currentTab] = uuid.split("-")

  const dialog = {
    uuid,
    id,
    mode,
    currentTab,
    props,
    order: order || stack.length + 1,
    ...registeredDialogs[type]
  }

  if (checkForPermissions(dialog)) {
    commit("ADD_DIALOG", dialog)
  } else {
    const notificationsPayload = {
      component: "Toast",
      type: "warning",
      title: "Attention:",
      message: `You do not have the correct permissions to view this content. Please contact an administrator to request access.`,
      visible: true,
      decay: 5000
    }

    dispatch("core/notifications/addToNotifications", notificationsPayload, { root: true })
  }
}

const actions = {
  updateDialogRouterQueries({ state }) {
    handleRouteQuery(state.stack.map(({ uuid }) => uuid))
  },
  getDialogsFromUrl({ commit, rootState, dispatch }) {
    const urlDialogs = router.currentRoute.query.d

    if (urlDialogs && isArray(urlDialogs)) {
      urlDialogs.map((uuid, index) => {
        buildDialogFromUUIDAndAddToStack({ uuid, rootState, commit, dispatch, order: index + 1 })
      })
    } else if (urlDialogs) {
      buildDialogFromUUIDAndAddToStack({ uuid: urlDialogs, rootState, commit, dispatch, order: 0 })
    }
  },
  addDialog({ commit, dispatch, rootState }, payload) {
    const { uuid = createUUID(payload), props = {}, optionsParams } = payload
    props.optionsParams = optionsParams
    const { registeredDialogs } = rootState.settings

    const [type] = uuid.split("-")

    if (!registeredDialogs[type]) {
      return alert("Dialog has not been registered! " + type)
    }

    if (uuid) {
      buildDialogFromUUIDAndAddToStack({ uuid, props, rootState, commit, dispatch })

      dispatch("updateDialogRouterQueries")
    }
  },
  removeDialog({ commit, dispatch }, payload) {
    commit("REMOVE_DIALOG", payload)
    dispatch("updateDialogRouterQueries")
  },
  updateDialogUrl({ commit, dispatch, state }, { oldUUID, payload }) {
    const dialogIndex = state.stack.findIndex(dialog => dialog.uuid === oldUUID)

    commit("UPDATE_DIALOGS", { dialogIndex, oldUUID, payload })
    dispatch("updateDialogRouterQueries")
  }
}

const mutations = {
  UPDATE_DIALOGS(state, { dialogIndex, payload }) {
    Vue.set(state.stack, dialogIndex, { ...state.stack[dialogIndex], ...payload })
  },
  ADD_DIALOG(state, payload) {
    state.stack = uniqBy([...state.stack, payload], "uuid")
  },
  REMOVE_DIALOG(state, payload) {
    state.stack = state.stack.filter(dialog => dialog.uuid !== payload)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}

<template>
  <el-tooltip
      v-if="hintText"
      :content="hintText"
      :open-delay="750">
    <el-button
        class="flex"
        v-bind="props"
        v-on="$listeners">
      <core-icon
          v-if="icon"
          :icon="icon" />
      <slot />
    </el-button>
  </el-tooltip>

  <el-button
      v-else
      class="flex"
      v-bind="props"
      v-on="$listeners">
    <core-icon
        v-if="icon"
        :icon="icon" />
    <slot />
  </el-button>
</template>

<script>
export default {
  props: {
    hintText: { type: String, default: null },
    icon: { type: String, default: null }
  },
  computed: {
    props() {
      // eslint-disable-next-line
      const { icon, ...rest } = this.$attrs
      return { ...rest }
    }
  }
}
</script>

<style lang="scss" scoped>
.flex {
  /deep/ span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.el-button:not(.is-circle) {
  /deep/ .core-icon {
    margin-right: var(--margin-xs);
  }
}
</style>

import routes, { apiActions } from "./routes"

const attributes = {
    id: 1,
    active: 1,
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    mobile: null,
    nursePractitioner: null,
    medicareNumber: null,
    medicareReferenceNumber: null,
    dvaNumber: null,
    pensionCardNumber: null,
    referralDate: null,
    dob: null,
    referringGPId: null,
    consent: null,
    numberOfVisits: null,
    deceased: null,
    epcClaimed: null,
    notes: null,
    status: null,
    locationId: null,
    locationTitle: null,
    locationStreet: null,
    locationCity: null,
    locationZip: null,
    gpFirstName: null,
    gpLastName: null
}

const definition = { attributes, routes }

export { apiActions }
export default definition
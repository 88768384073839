import { render, staticRenderFns } from "./BreadCrumbDropdownItem.vue?vue&type=template&id=59ea8ef2&scoped=true&"
import script from "./BreadCrumbDropdownItem.vue?vue&type=script&lang=js&"
export * from "./BreadCrumbDropdownItem.vue?vue&type=script&lang=js&"
import style0 from "./BreadCrumbDropdownItem.vue?vue&type=style&index=0&id=59ea8ef2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59ea8ef2",
  null
  
)

export default component.exports
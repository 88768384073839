import Vue from "vue"
import merge from "lodash/merge"

const createAppSettings = (config = {}) => {
  const { initialState, appConfig, namespaced = true } = config

  const state = {
    ...initialState,
    loggingEnabled: true,
    app: appConfig,
    modules: {},
    featureFlags: {},
    registeredDialogs: {}
  }

  // actions
  const actions = {
    addToModules({ state, dispatch, commit }, payload) {
      const defaultValue = {
        dashboardTile: "default-dashboard-tile",
        enabled: true,
        mainMenu: true
      }

      const entryPoint = payload.moduleGrouping
      if (payload.features) {
        commit("UPDATE_FEATURE_FLAGS", payload)

        delete payload.features
      }

      if (payload.dialogs) {
        payload.dialogs.forEach(dialog => {
          const type = `${payload.name}DS${dialog.name}`

          Vue.component(type, dialog.Component)

          delete dialog.Component

          dialog.type = type

          commit("REGISTER_DIALOG", dialog)
        })

        delete payload.dialogs
      }

      if (payload.name) {
        const newPayload = {
          ...state.modules[entryPoint],
          [payload.name]: { ...defaultValue, ...payload }
        }

        commit("UPDATE_MODULES", { entryPoint: entryPoint, value: newPayload })

        if (newPayload[payload.name].alertModule) {
          dispatch("core/alerts/addAlertModule", newPayload[payload.name].alertModule, {
            root: true
          })
        }
      }
    },
    updateAllModules({ commit }, payload) {
      commit("UPDATE_ALL_MODULES", payload)
    },
    updateAppSettings({ commit }, payload) {
      commit("UPDATE_APP_SETTINGS", payload)
    },
    updateUseMockData({ commit }, payload) {
      commit("UPDATE_USE_MOCK_DATA", payload)
    },
    updateLoggingEnabled({ commit }, payload) {
      commit("UPDATE_LOGGIN_TOGGLE", payload)
    },
    updateAppBrandSettings({ state, commit }, payload) {
      const newSettings = JSON.parse(JSON.stringify(state.app))
      newSettings.appName = payload.appName
      newSettings.brandName = payload.brandName
      newSettings.brandColor = payload.brandColor

      localStorage.setItem("appSettings", JSON.stringify(newSettings))
      commit("UPDATE_BRAND_SETTINGS", newSettings)
    }
  }

  // mutations
  const mutations = {
    REGISTER_DIALOG(state, payload) {
      state.registeredDialogs[payload.name] = payload
    },
    UPDATE_FEATURE_FLAGS(state, { name, features }) {
      state.featureFlags[name] = merge({ Module: { permissions: {} } }, features)
    },
    UPDATE_USE_MOCK_DATA(state, payload) {
      state.useMockData = payload
    },
    UPDATE_LOGGIN_TOGGLE(state, payload) {
      state.loggingEnabled = payload
    },
    UPDATE_ALL_MODULES(state, payload) {
      state.modules = payload
    },
    UPDATE_MODULES(state, payload) {
      if (!payload.entryPoint) {
        state.modules.noGroup = payload.value
      } else {
        state.modules[payload.entryPoint] = payload.value
      }
    },
    UPDATE_APP_SETTINGS(state, payload) {
      state.app = payload
    },
    UPDATE_BRAND_SETTINGS(state, payload) {
      state.app = payload
    }
  }

  return { namespaced, state, actions, mutations }
}

export default createAppSettings

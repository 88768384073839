import get from "lodash/get"
import store from "./store"
import routes from "./routes"
import features from "./features"
import Base from "./Base"
import config from "@/config/config.js"
import { api } from "@/config/api.js"
import Auth0Plugin from "./plugins/Auth0"
import { User } from "@/Modules/OdysseyModels"
import getPermissions from "../../utils/getPermissions"

const Module = {
  odyssey: {
    name: "auth",
    path: ``,
    enabled: false,
    component: Base,
    children: routes,
    store,
    backend: "odyssey",
    features,
    installPlugins({ Vue, store }) {
      if (getPermissions("auth:auth0")) {
        const domain = get(config, "auth0.domain")
        const clientId = get(config, "auth0.clientId")

        Vue.use(Auth0Plugin, {
          store,
          domain,
          clientId,
          backendURL: `${api.bridj.Odyssey.v2.ops.url}/auth0login`,
          onRedirectCallback: async auth0Client => {
            await User.api().auth0Login(auth0Client.user)
          }
        })
      }
    }
  }
}

export default Module

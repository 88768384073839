<template>
  <router-link
      class="operators-badge"
      to="/"
      aria-label="Link to Dashboard"
      tag="div">
    <div class="logo-container">
      <Logo />
    </div>
    <h1 v-if="!isMinified">
      <div
          class="app-name"
          aria-label="App Name">
        {{ appName }}
      </div>
    </h1>
  </router-link>
</template>

<script>
import config from "@/config"
import Logo from "../Branding/Logo"
export default {
  name: "OperatorsBadge",
  components: {
    Logo
  },
  props: {
    isMinified: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    config() {
      return config
    },
    brandName() {
      return this.$store.state.settings.app.brandName
    },
    appName() {
      return this.$store.state.settings.app.appName
    }
  }
}
</script>

<style lang="scss" scoped>
.operators-badge {
  background: white;
  text-align: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: var(--main-transition);
  margin: 0;

  min-height: 40px;
  max-height: 40px;

  .logo-container {
    width: 5.5em;
  }

  h1 {
    color: var(--main-brand);
    margin: 0;
    text-transform: uppercase;
    justify-content: center;
    flex: 1;

    .brand-name {
      letter-spacing: 1px;
    }

    .app-name {
      color: var(--main-text);
      font-size: 0.9em;
      text-transform: none;
    }
  }
  img {
    max-height: 5em;
    min-width: 5.5em;
    box-shadow: var(--box-shadow-container);
  }
}
</style>

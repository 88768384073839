<template>
  <div
      class="form-builder-field-group"
      :class="{'view-mode':mode === 'view'}">
    <template v-for="(field, fieldKey) in fieldGroup">
      <div
          v-if="fieldKey !== '_options' && checkDependency(field, fieldKey)"
          :key="fieldKey"
          class="form-builder-field-wrapper flex-column field-width"
          :class="field.type"
          :style="`--fraction: ${field.width || defaultFieldWidth}`">
        <div
            v-if="!isCheckbox(field)"
            class="form-builder-label-container"
            :class="{ 'is-required': checkIsRequired(field.validation) }">
          <core-text
              v-if="isVisible(fieldKey, value[fieldKey].value)"
              tag="label"
              :for="fieldKey"
              semibold
              class="el-form-item__label truncate"
              @click="focusField(fieldKey)">
            <template v-if="fieldGroup._options && fieldGroup._options.formatting && fieldGroup._options.formatting === 'sentenceCase'">
              {{ field.label | sentenceCase }}
            </template>
            <template v-else>
              {{ field.label }}
            </template>
          </core-text>

          <el-tooltip
              v-if="field.hintText"
              popper-class="hint-tooltip"
              :open-delay="450">
            <i class="el-icon-info hint" />
            <div
                slot="content"
                class="hint-tooltip">
              {{ field.hintText }}
            </div>
          </el-tooltip>
        </div>

        <template v-if="field.type === 'placeholder'">
          <div class="placeholder" />
        </template>
        
        <el-form-item
            v-else
            :prop="`${fieldGroupKey}.${fieldKey}.value`"
            :rules="field.validation"
            :class="{ 'prepend-group': field.prepend}">
          <div
              v-if="field.prepend"
              class="prepend">
            {{ field.prepend }}
          </div>

          <component
              :is="field.Component || getFormField(field.type)"
              :id="fieldKey"
              :ref="fieldKey"
              v-model="value[fieldKey].value"
              :form-ref="fieldKey"
              :mode="mode"
              :label="field.label"
              v-bind="form.getFormItemProps(field.props)"
              :field-path="`${fieldGroupKey}.${fieldKey}.value`"
              :validation="field.validation"
              :data-testid="`form-${formName}-input-${fieldKey}`"
              :options="field.options"
              :disabled="getFieldDisabledState(field)"
              :type="field.type"
              :field-name="fieldKey"
              @click="focusField(fieldKey)">
            <div
                v-if="isCheckbox(field)"
                class="form-builder-label-container"
                :class="{ 'is-required': checkIsRequired(field.validation) }">
              <core-text
                  tag="label"
                  :for="fieldKey"
                  class="el-form-item__label truncate">
                {{ field.label }}
              </core-text>

              <el-tooltip
                  v-if="field.hintText"
                  popper-class="hint-tooltip"
                  :open-delay="450">
                <i class="el-icon-info hint" />
                <div
                    slot="content"
                    class="hint-tooltip">
                  {{ field.hintText }}
                </div>
              </el-tooltip>
            </div>
          </component>
        </el-form-item>
      </div>
    </template>
  </div>
</template>

<script>
import getFormField from "./ui/getFormField"
import get from "lodash/get"
import isObject from "lodash/isObject"
import isString from "lodash/isString"
import isFunction from "lodash/isFunction"
import lowerCase from "lodash/lowerCase"

export default {
  name: "CoreFieldGroup",
  filters: {
    sentenceCase(str) {
      str = lowerCase(str)
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  },
  props: {
    fieldGroup: { type: Object, required: true },
    value: { type: Object, required: true },
    isLoading: { type: Boolean, default: false },
    compact: { type: Boolean, default: false },
    form: { type: Object, required: true },
    fieldGroupKey: { type: String, default: "fieldGroupKey" },
    mode: { type: String, default: "edit" },
  },
  data() {
    return { isSubmitting: false, formUpdated: false, formError: false }
  },
  computed: {
    formName() {
      return this.form.formName
    },
    options() {
      return this.fieldGroup._options || {}
    },
    defaultFieldWidth() {
      return this.options.defaultFieldWidth || 12
    }
  },
  created() {
    Object.entries(this.fieldGroup).forEach(([fieldKey, field]) => {
      this.$watch(`value.${fieldKey.replace("/", ".")}.value`, function(newValue, oldValue) {
        if (field.onChange) {
          field.onChange(newValue, oldValue, this.form)
        }
      })
    })
  },
  methods: {
    focusField(fieldKey) {

      // This is a hack to fix the soft keyboard on search selects on iOS Safari
      const element = document.getElementById(fieldKey)
      const newElement = element.getElementsByClassName('el-input')

      newElement[0].click()
        const inputElement = newElement[0].querySelectorAll('.el-input__inner')
        const el =  inputElement[0]

        if(el) {
          // Align temp input element approximately where the input element is
          // so the cursor doesn't jump around
          const __tempEl__ = document.createElement('input')
          __tempEl__.style.position = 'absolute'
          __tempEl__.style.top = (el.offsetTop + 7) + 'px'
          __tempEl__.style.left = el.offsetLeft + 'px'
          __tempEl__.style.height = 0
          __tempEl__.style.opacity = 0

          // Put this temp element as a child of the page <body> and focus on it
          document.body.appendChild(__tempEl__)
          inputElement[0].blur()

          __tempEl__.focus();

          // The keyboard is open. Now do a delayed focus on the target element
          // setTimeout(function() {
          //   // Remove the temp element
          //   document.body.removeChild(__tempEl__)
          //   el.focus()
          //   el.click()
          // }, timeout)
        }

        setTimeout(() => {
          inputElement[0].click()
          inputElement[0].focus()

        }, 10)
    },
    isVisible(field, value) {
      if (this.mode === "view" && value === "") {
        return false
      }
      return true
    },
    isCheckbox(field) {
      return get(field, "type", "").includes("checkbox")
    },
    getFieldDisabledState(field) {
      if (this.mode === "view") {
        return true
      }

      const props = isFunction(get(field, "props"))
        ? get(field, "props")(this.form)
        : get(field, "props", {})

      // TODO: When needed, work on disabled state determiner. Perhaps we can add permission based disabled functionality?
      return this.isLoading || props.disabled || this.options.disabled
    },
    getFormField(type) {
      const { mode } = this

      const modesToShowEditMode = ["edit", "bulkedit", "create"]

      const fieldType = modesToShowEditMode.includes(mode) ? type : `${type}-view-mode`

      return getFormField(fieldType)
    },
    checkIsRequired(validation = []) {
      if (this.mode === "view") {
        return false
      }

      return validation.find(({ required }) => required)
    },
    checkDependency(field = {}) {
      const { mode } = this
      const { dependency, bulkEnabled } = field

      if (!bulkEnabled && mode === "bulkedit") {
        return false
      }

      if (!dependency) {
        return true
      }

      if (isString(dependency)) {
        return get(this.form, `formData.${dependency}.value`)
      }

      if (isFunction(dependency)) {
        return dependency(field, this.form)
      }

      if (isObject(dependency) && dependency.field) {
        let dependencyValue
        if (dependency.group) {
          dependencyValue = get(this.form, `formData.${dependency.group}.${dependency.field}.value`)
        } else {
          dependencyValue = get(this.form, `formData.${dependency.field}.value`)
        }
        const expectedValue = dependency.value

        return expectedValue === "*" ? !!dependencyValue : dependencyValue === expectedValue
      }
    }
  }
}
</script>

<style lang="scss">
.compact {
  /deep/ .el-form-item__error {
    top: 85%;
  }

  /deep/ label.el-form-item__label {
    font-size: 12px;
    margin-bottom: 0;
  }

  .el-form-item__content {
    line-height: 36px;
  }

  .checkbox {
    .el-form-item__content {
      line-height: 30px;
    }
  }
}
</style>

<style lang="scss" scoped>
.form-builder-field-group {
  display: flex;
  flex-wrap: wrap;
}
.form-builder-label-container {
  display: flex;
  align-items: center;
}

.compact {
  /deep/ .el-form-item__error {
    top: 80%;
  }

  /deep/ label.el-form-item__label {
    font-size: 10px;
  }

  .form-builder-field-wrapper {
    padding: 0 var(--padding-s);
    padding-bottom: var(--padding-m);

    &.checkbox {
      padding-bottom: var(--padding-s);
    }

    &.field-width {
      width: calc(var(--fraction) / 24 * 100%);
    }
  }
}
.form-builder-field-wrapper {
  padding: 0 var(--padding-s);
  padding-bottom: var(--padding-xl);

  &.field-width {
    width: calc(var(--fraction) / 24 * 100%);
  }
}

/deep/ .is-disabled {
  i {
    color: #606266 !important;
  }
}

.view-mode {
  /deep/ .el-form-item__content {
    line-height: unset;
  }
}

/deep/ .el-form-item {
  margin-bottom: 0;
}
/deep/ .el-form-item__label {
  font-weight: bold;
  line-height: 1.6;
  text-align: unset;
  padding-right: var(--padding-xs);
}

.form-builder-label-container.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  content: "*";
  color: #f56c6c;
}

.placeholder {
  width: 100%;
  height: 6em;
}

.prepend-group {
  .prepend {
    float: left;
    background: #f5f7fa;
    border: 1px solid rgba(36, 46, 53, 0.15);
    box-shadow: 1px solid rgba(36, 46, 53, 0.15);
    border-radius: 4px 0 0 4px;
    padding: 0 5px;
    height: 36px;
    line-height: 36px;
    margin-top: 1px;
    color: #909399;
  }
  /deep/ .el-input__inner {
    border-radius: 0 4px 4px 0;
  }
}
</style>

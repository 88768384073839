import routes, { apiActions } from "./routes"

const attributes = {
  id: null,
  title: null,
  street: null,
  city: null,
  zip: null,
  state: null,
  country: null,
  contactName: null,
  contactType: null,
  contactPhone: null,
  contactEmail: null,
  notes: null,
  status: null,
  created: null
}

const definition = { attributes, routes }

export { apiActions }
export default definition
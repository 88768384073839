import DashboardBase from "./DashboardBase"
import DashboardIcon from "./components/DashboardIcon"

const DashboardModule = {
  moduleGrouping: "Customer Management",
  name: "Dashboard",
  menuName: "Dashboard",
  component: DashboardBase,
  path: "Dashboard",
  icon: DashboardIcon,
  iconTitle: "Dashboard",
  iconDescription: "Dashboard",
  enabled: true,
  registerChildren: true,
  registerComponents: Vue => {
    Vue.component("DashboardBase", DashboardBase)
    Vue.component("DashboardIcon", DashboardIcon)
  }
}
export default DashboardModule

<template>
  <svg
      id="artwork"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 600 216.6"
      style="enable-background:new 0 0 600 216.6;"
      xml:space="preserve"
      class="svg-icon">
    <!-- <style type="text/css">
      .st0{fill:#333333;}
      .st1{fill:#EB5424;}
    </style> -->
    <title>auth0-logo-whitebg</title>
    <path
        class="st0"
        d="M297.8,119.3h-26.2c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.2-0.2-0.3-0.1-0.5l13.1-45.1c0.1-0.3,0.4-0.5,0.7-0.4
	c0.2,0.1,0.4,0.2,0.4,0.4l13.1,45.1c0.1,0.2,0,0.4-0.1,0.5C298.2,119.2,298,119.3,297.8,119.3z M310,158.4h17L301.1,69
	c-2.7-9-12.2-14.2-21.2-11.5c-5.5,1.6-9.8,6-11.5,11.5l-25.9,89.4h17l7.5-25.9c0.1-0.3,0.3-0.4,0.6-0.4h34.3c0.3,0,0.5,0.2,0.6,0.4
	L310,158.4z M405.8,107.1h10c0.3,0,0.6,0.3,0.6,0.6v35.3c0.1,9.3,7.7,16.8,17,16.8c3.4,0,6.8-0.5,10-1.4v-11.6
	c-1.9,0.2-4,0.3-5.6,0.3c-2.8,0-5-2.2-5-5v-34.3c0-0.3,0.3-0.6,0.6-0.6h10V94.3h-10c-0.3,0-0.6-0.3-0.6-0.6V72.5h-16.5v21.2
	c0,0.3-0.3,0.6-0.6,0.6h-10L405.8,107.1z M500.4,158.4h16.4v-44.3c-0.1-11.7-9.7-21.2-21.4-21.1c-6.8,0.1-13.4,2.2-19,5.9
	c-0.2,0.1-0.4,0.1-0.6,0c-0.2-0.1-0.3-0.3-0.3-0.5V58.1h-16.4v100.3h16.4v-47.9c0-0.2,0.1-0.4,0.3-0.5c3.1-1.6,9.2-4.2,15.2-4.2
	c2.5,0,4.9,1,6.7,2.7c1.8,1.7,2.8,4.1,2.8,6.5L500.4,158.4L500.4,158.4z M336.2,94.3v44.3c0.1,11.8,9.7,21.2,21.4,21.2
	c7.6-0.1,14.9-2.7,20.9-7.2c0.2-0.1,0.4-0.2,0.6-0.1c0.2,0.1,0.3,0.2,0.4,0.4l1.7,5.6H394V94.3h-16.4v47.9c0,0.2-0.1,0.4-0.3,0.5
	c-3.1,1.6-9.2,4.2-15.2,4.2c-5.2,0-9.4-4.1-9.4-9.3V94.3H336.2z M564.6,147c-5,0-9-2.8-12.2-8.6c-3.8-7-5.9-17.7-5.9-30.1
	s2.1-23.1,5.9-30.1c3.2-5.8,7.1-8.6,12.2-8.6s9,2.8,12.2,8.6c3.8,7,5.9,17.7,5.9,30.1s-2.1,23.1-5.9,30.1
	C573.6,144.2,569.7,147,564.6,147z M564.6,56.8c-10.5,0-19.5,4.9-25.8,14.2s-9.6,22.1-9.6,37.3s3.3,28,9.6,37.3s15.2,14.2,25.8,14.2
	s19.5-4.9,25.8-14.2s9.6-22.1,9.6-37.3s-3.3-28-9.6-37.3S575.2,56.8,564.6,56.8L564.6,56.8z" />
    <path
        id="NEW"
        class="st1"
        d="M189,66.9L167.2,0H96.8l21.8,66.9H189z M96.8,0H26.5L4.8,66.9h70.4L96.8,0z M4.8,66.9L4.8,66.9
	c-13,39.9,1.2,83.6,35.2,108.3l21.7-66.9L4.8,66.9z M189,66.9L189,66.9l-57,41.4l21.7,66.9l0,0C187.7,150.6,201.9,106.8,189,66.9
	L189,66.9z M39.9,175.2L39.9,175.2l56.9,41.4l56.9-41.4l-56.9-41.4L39.9,175.2z" />
  </svg>
</template>

<script>
export default {
  name: "Auth0Icon",
  props: {
    color: {
      type: String,
      default: "#000000"
    }
  }
}
</script>

<style scoped>
.svg-icon {
  width: 100%;
}
.st0 {
  fill: #333333;
}
.st1 {
  fill: #eb5424;
}
</style>

import { environments } from "./constants"
import config from "@/config/config"

const getEnvironment = () => {
  const { host } = document.location

  if (config.subEnvironment) {
    return config.subEnvironment
  }

  if (host.includes("uat")) {
    return environments.uat
  }

  if (host.includes("localhost")) {
    return environments.dev
  }

  if (host.includes("staging")) {
    return environments.staging
  }

  return environments.prod
}

const isDisabledForEnvironment = ({ settings = {} }) => {
  const { disabledEnvironments = [] } = settings

  const ENV = getEnvironment()

  return disabledEnvironments.includes(ENV)
}

export { getEnvironment }
export default isDisabledForEnvironment

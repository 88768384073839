import ExtendedModel from "@/Modules/Core/utils/extended-vue-orm-model"
import definition, { apiActions } from "./definition"
import Actions from "./actions"

class ClientOrder extends ExtendedModel {
  static entity = "ClientOrder"
  static globalSearchEnabled = false
  static definition = definition

  static apiConfig = {
    dataKey: "data.data",
    actions: apiActions
  }

  get Actions() {
    return new Actions(this)
  }

  get shippingType() {
    return startCase(this.shipping_type)
  }
  get paymentType() {
    return startCase(this.payment_type)
  }

  get Total() {
    return `AUD ${parseFloat(this.cost).toFixed(2)}`
  }

  get createdAt() {
    return new Date(this.ctime).toLocaleDateString()
  }

  get Tracking() {
    if (this.tracking_code && this.shippingCompanyTitle && this.shippingCompanyUrl) {
      return `${this.tracking_code} (${this.shippingCompanyTitle})`
    } else if (this.tracking_code) {
      return this.tracking_code
    }
    return "-"
  }

  get PDFAttachment() {
    if (this.invoiceLocation) {
      const host = document.location.host.replace("app.", "")
      return `https://${host}/${this.invoiceLocation}`
    }
    return ""
  }

  get Paid() {
    if (this.paid) {
      return `AUD ${parseFloat(this.paid).toFixed(2)}`
    }
    return "-"
  }

  get valueList() {
    const {id} = this
    return {label: this.fullName, value: id, id}
  }

}
export default ClientOrder

<template>
  <div
      v-loading="isLoading"
      class="mapping-table-wrapper">
    <div
        v-if="total && !totalImportedFiles"
        class="response-wrapper">
      Total {{ entityName }} to import:
      <strong>{{ total }} {{ entityName }}</strong>

      <div class="hint yellow">
        Please map the data columns to the correct fields in order to import the rows correctly.
      </div>
    </div>

    <div
        v-if="tableRows.length"
        class="response-container">
      <table>
        <thead>
          <tr>
            <th
                v-for="(header, key) in tableHeaders"
                :key="`header2-${key}`">
              {{ header | capitalise }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
                v-for="(value, key) in tableRows[0]"
                :key="`body-${key}`">
              {{ value }}
            </td>
          </tr>
          <tr>
            <th
                colspan="20"
                valign="center"
                style="height: 100px;vertical-align: middle;text-align: center; background: var(--main-light-blue)">
              <strong>... Map fields these database columns ...</strong>
            </th>
          </tr>
          <tr>
            <th
                v-for="(header, key) in tableHeaders"
                :key="`header-${key}`">
              <el-select
                  v-model="mapValues[header]"
                  size="mini"
                  filterable
                  placeholder="Map to">
                <el-option
                    v-for="item in mappingToFields"
                    :key="item"
                    :label="item"
                    :value="item" />
              </el-select>
            </th>
          </tr>
        </tbody>
      </table>

      <template v-if="defaultFieldSelectors">
        <div class="extra-import-selectors">
          <div
              v-for="(field, key) in defaultFieldSelectors"
              :key="`select-${key}`">
            <div
                v-if="checkNotSelected(key)"
                class="location-selector-wrapper">
              <label>Select a {{ field.label }}</label>
              <el-select
                  v-model="defaultFieldValues[key]"
                  size="mini"
                  filterable
                  placeholder="Select">
                <el-option
                    v-for="item in field.options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value" />
              </el-select>
              <core-text
                  hint
                  class="yellow">
                Set the <strong>{{ field.label }}</strong> ID of to-be-imported records to this ID.
              </core-text>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import startCase from "lodash/startCase"
  
  export default {
    name: "CoreDataImporterMappingTable",
    filters: {
      capitalise(string) {
        return startCase(string)
      }
    },
    props: {
      isLoading: {
        type: Boolean,
        required: true
      },
      totalImportedFiles: {
        type: Number,
        required: false
      },
      tableHeaders: {
        type: Array,
        required: true
      },
      tableRows: {
        type: Array,
        required: true
      },
      mapValues: {
        type: Object,
        required: true
      },
      mappingToFields: {
        type: Array,
        required: true
      },
      entityName: {
        type: String,
        required: true
      },
      total: {
        type: Number,
        required: false
      },
      defaultFieldSelectors: {
        type: Object,
        required: false
      },
      defaultFieldValues: {
        type: Object,
        required: false
      }
    },
    methods: {
      checkNotSelected(fieldKey) {
        const found = Object.keys(this.mapValues).find(key => this.mapValues[key] === fieldKey)

        if (found) {
          // Reset the field if user maps via the table columns
          this.$set(this.defaultFieldValues, fieldKey, {})
          return false
        }
        return true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .hint {
    color: rgba(0, 0, 0, 0.7) !important;
    margin: 1em 0;
    padding: 0.5em 1em;

    &.yellow {
      background: var(--main-light-yellow);
      border-radius: var(--border-radius-xs)

    }
  }
  .extra-import-selectors {
    display: flex;
    .gp-selector-wrapper,
    .location-selector-wrapper {
      margin: 2em;

      label {
        margin-right: 1em;
      }
    }
  }

  table {
    border: 1px solid var(--main-border-color);
    width: 100%;
    font-size: 0.8em;
    tr {
      th, td {
        vertical-align: top;
        word-break: break-word;
        padding: 0.5em;
        border-right: 1px solid var(--main-border-color);
        text-align: left;

        &:last-child {
          border: 0;
        }
      }
    }
  }
</style>
import Qs from "qs"
import VueRouter from "vue-router"

// @hack: The following code prevents the NavigationDuplicated error that is thrown globally by vue-router.
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  parseQuery: Qs.parse,
  stringifyQuery: p => Qs.stringify(p, { addQueryPrefix: true, encode: false })
})

export default router

<template>
  <fragment>
    <CoreScreen
        card
        class="productCategoryManagement-wrapper"
        header="ProductCategoryManagement"
        icon="ProductCategoryManagementIcon"
        :create-button="{ label: 'Create ProductCategory', onCreate: createProductCategory }">
      <router-view />
    </CoreScreen>
  </fragment>
</template>

<script>
import { ProductCategory } from "@/Modules/OdysseyModels"
export default {
  name: "ProductCategoryManagement",
  methods: {
    createProductCategory() {
      new ProductCategory().Actions.openProductCategoryDialog()
    }
  }
}
</script>

<template>
  <div class="status-column">
    <template v-if="scope.row.active">
      <el-tag
          :type="status !== 'active' ? 'danger' : 'success'"
          disable-transitions>
        {{ status }}
      </el-tag>
    </template>
  </div>
</template>
<script>
  export default {
    name: "UserStatusColumn",
    filters: {
      spaceUnderscore(string) {
        return string.replace("_", " ")
      }
    },
    props: {
      scope: {
        type: Object,
        required: true
      }
    },
    computed: {
      status() {
        if (this.scope.row.deceased === "1") {
          return "deceased"
        } else if (this.scope.row.active === "0") {
          return "inactive"
        } else {
          return "active"
        }
      }
    },
    methods: {
      filterStatus(value, row) {
        return row.active === value
      }
    }
  }
</script>

<style lang="scss" scoped>

  .status-column {
    margin: 0 auto;
    .el-tag {
      min-width: 90%;
      margin: auto;
      text-align: center;
      box-shadow: var(--box-shadow-container-light);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
</style>

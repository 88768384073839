import routes, { apiActions } from  "./routes"

const attributes = {
    id: 1,
    userId: null,
    createdAt: null,
    bookingId: null,
    locationId: null,
    patientId: null,
    otoscopyLeft: null,
    otoscopyRight: null,
    hearingScreenLeft: null,
    hearingScreenRight: null,
    middleEarAssessmentLeft: null,
    middleEarAssessmentRight: null,
    actionTakenLeft: [],
    actionTakenRight: [],
    recommendation: null,
    extraNotes: null,
    patientFirstName: null,
    patientLastName: null,
    staffFirstName: null,
    staffLastName: null,
    locationName: null,
    recommendTVHeadPhones: false,
    recommendALD: false,
    pdfLocation: null,
    epcClaimLocation: null,
    epcClaimed: 0
}

const definition = { attributes, routes }

export { apiActions }
export default definition
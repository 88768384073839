import LogRocket from "logrocket"

const state = () => {
  return {
    errors: []
  }
}

// actions
const actions = {
  addToErrors({ rootState, commit, dispatch }, payload) {
    const newPayload = {
      type: payload.type,
      message: payload.message,
      timestamp: new Date(),
      lockScreen: false
    }
    if (payload.visible) {
      const payloadNotification = {
        component: "Toast",
        type: "error",
        title: payload.type,
        message: payload.message,
        decay: false
      }
      dispatch("core/notifications/addToNotifications", payloadNotification, { root: true })
    }
    commit("ADD_TO_ERRORS", newPayload)

    // Check if LogRocket is enabled and if so, forward the exception
    if (rootState.settings.app.errorReporting.logRocket) {
      LogRocket.captureException(payload.error)
    }
  },
  removeFromErrors({ commit }, payload) {
    commit("REMOVE_FROM_ERRORS", payload)
  }
}

// mutations
const mutations = {
  ADD_TO_ERRORS(state, payload) {
    state.errors.push(payload)
  },
  REMOVE_FROM_ERRORS(state, payload) {
    state.splice(state.errors.indexOf(payload), 1)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}

import api from "./modules/api"
import errorStore from "./modules/errors"
import dialogStackStore from "./modules/dialogStack"
import notificationsStore from "./modules/notificationsStore"
import routingStore from "./modules/routing"
import alertsStore from "./modules/alerts"
import Vue from "vue"
import Vuex from "vuex"
import VuexORM from "./vuexORM"

const database = new VuexORM.Database()

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== "production"

// Global getter to find any module and its enabled/disabled state
// @todo: if we get more getters we will externalise those in its own getter file
const getters = {
  findModule: state => moduleName => {
    const foundModule = Object.keys(state.settings.modules).filter(item => {
      return !!(
        state.settings.modules[item][moduleName] && state.settings.modules[item][moduleName].enabled
      )
    })
    return !!foundModule
  }
}

const store = new Vuex.Store({
  modules: {},
  strict: debug,
  plugins: [VuexORM.install(database)],
  getters: getters
})

store.$database = database

const combinedStore = {
  namespaced: true,
  modules: {
    api: api,
    errors: errorStore,
    notifications: notificationsStore,
    routing: routingStore,
    alerts: alertsStore,
    dialogStack: dialogStackStore
  }
}

store.registerModule("core", combinedStore)

export { database }
export default store
